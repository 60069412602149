import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';

import { ChatList } from 'features/communities/components/ChatsList/ChatList';
import { ConfirmBlock } from 'features/communities/components/ConfirmBlock/ConfirmBlock';
import {
    useCreateConditionalRuleMutation,
    useReadConditionalRulesDictQuery,
    useReadRulesQuery,
    useUpdateRuleMutation,
} from 'features/communities/services';
import { SpodialAdapterError } from 'utils/common/error-parser';
import {
    IConditional,
    Rule,
} from 'features/communities/interfaces/rules.interface';

import { RuleEditor } from './index';
import { ITree } from './Tree/tree';
import { HeaderScenarios } from '../HeaderScenarios/HeaderScenarios';
import { ConditionalAction } from './view/ConditionalAction';
import { RuleType } from '../../../../shared/contants/rule-types';

type Props = {
    readonly id?: number;
};

const transformConditionalRule = (raw_rule: any): Rule<IConditional> => {
    const { uuid, rules, title, communities_ids, type } = raw_rule;
    const { callback, ...tree } = JSON.parse(rules);

    return {
        id: uuid,
        rule: {
            tree,
            callback,
        },
        title,
        type,
        communities: communities_ids || [],
    };
};

export const RuleEditorContainer: React.FC<Props> = () => {
    const [callback, setCallback] = useState<{
        type: string;
        parameter?: string;
    }>();
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [ruleName, setRuleName] = useState('001 Правило Если-То');
    const { notification } = App.useApp();
    const [
        saveRule,
        {
            isError: isCreateError,
            error: createError,
            isLoading: isSaveLoading,
        },
    ] = useCreateConditionalRuleMutation();
    const [
        updateRule,
        {
            isError: isUpdateError,
            error: updateError,
            isLoading: isUpdateLoading,
        },
    ] = useUpdateRuleMutation();
    const navigate = useNavigate();
    const [rule, setRule] = useState<any>({
        type: 'AND',
        children: [
            {
                type: 'EXPRESSION',
            },
        ],
    });
    const {
        data: dict = {
            actions: [],
            callbacks: [],
            subjects: [],
            values: [],
        },
    } = useReadConditionalRulesDictQuery();
    const params = useParams();
    const {
        data: rules,
        isLoading: isRuleLoading,
        isSuccess,
    } = useReadRulesQuery(
        {
            rule_uuid: params.id,
            rule_type: 'if_then_rule',
        },
        {
            skip: !params.id,
        }
    );

    useEffect(() => {
        if (dict.callbacks.length && !callback) {
            setCallback({ type: dict.callbacks[0].name });
        }
    }, [dict.callbacks.length]);

    useEffect(() => {
        if (isSuccess && rules) {
            const { rule, title, communities } = transformConditionalRule(
                rules.items[0]
            );
            setRule(rule.tree);
            setRuleName(title);
            setCallback(rule.callback);
            setSelectedRows(communities || []);
        }
    }, [rules, isSuccess]);

    useEffect(() => {
        const error = createError || updateError;
        if (error) {
            const parsedError = new SpodialAdapterError(error);
            notification.error({
                message: `Spodial Adapter Error`,
                description: parsedError.toString(),
            });
        }
    }, [isCreateError, isUpdateError]);

    const onSave = async (): Promise<void> => {
        const payload = {
            communities_ids: [...selectedRows.map(i => Number(i))],
            rules: Object.assign(
                {},
                {
                    ...rule,
                    callback,
                }
            ),
            title: ruleName,
        };
        try {
            if (params.id) {
                await updateRule({
                    id: params.id,
                    type: 'if_then_rule',
                    ...payload,
                });
            } else {
                await saveRule(payload);
            }
        } catch (e) {
            notification.error({
                message: 'Ошибка',
                description: 'Не удалось сохранить правило',
            });
        } finally {
            notification.success({
                message: 'Успех',
                description: 'Правило сохранено',
            });
            navigate(-1);
        }
    };

    const onChange = (tree: ITree): void => {
        setRule(tree);
    };

    if (params.id && isRuleLoading) {
        return null;
    }

    return (
        <Fragment>
            <HeaderScenarios
                inputValue={ruleName}
                onChangeHeaderInput={({ target }) => {
                    setRuleName(target.value);
                }}
            />
            <RuleEditor dict={dict} tree={rule} onChage={onChange} />
            <ConditionalAction
                value={callback}
                options={dict.callbacks.map(c => ({
                    ...c,
                    label: c.label,
                    value: c.name,
                }))}
                onChange={setCallback}
            />
            <ChatList
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                tableOptions={{
                    entityType: RuleType.Conditional,
                    rowExpandable: true,
                }}
            />
            <ConfirmBlock
                loading={isUpdateLoading || isSaveLoading}
                onSave={onSave}
                onCancel={() => {
                    navigate(-1);
                }}
            >
                <Fragment>
                    Текущее правило Если-То применится к{' '}
                    {selectedRows?.length || 0} выбранным чатам.
                </Fragment>
            </ConfirmBlock>
        </Fragment>
    );
};
