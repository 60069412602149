import React from 'react';
import styled from 'styled-components';

import { IStatisticFilters } from 'features/analytics/services/interfaces';
import { ModerationChart } from 'features/analytics/components/Charts/ModerationChart';
import { ChatListContainer } from 'features/analytics/components/ChatList/containers/ChatListContainer';

import { ViolationsTableContainer } from './containers/ViolationsTableContainer';

type Props = {
    readonly filters: IStatisticFilters;
    readonly onFiltersUpdate: (update: Partial<IStatisticFilters>) => void;
};

export const ModerationLayout: React.FC<Props> = props => {
    return (
        <StyledWrapper>
            <StyledCharts>
                <ModerationChart height={150} filters={props.filters} />
                <div style={{ flex: '1 0 49%' }} />
            </StyledCharts>
            {!props.filters.chat && (
                <ChatListContainer
                    filters={props.filters}
                    onFiltersUpdate={props.onFiltersUpdate}
                />
            )}
            {props.filters.chat && (
                <ViolationsTableContainer filters={props.filters} />
            )}
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const StyledCharts = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
`;
