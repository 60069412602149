import React from 'react';
import styled from 'styled-components';
import { Empty, Table } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import { TableFilter } from 'shared/ui/TableFilter';
import {
    IChatMember,
    IChatMemberQueryParams,
} from 'features/communities/interfaces';
import { PaginatedResponse } from 'features/communities/interfaces/PaginatedResponse';

type Props = {
    readonly source: PaginatedResponse<IChatMember>;
    readonly loading?: boolean;
    readonly filters: IChatMemberQueryParams;
    readonly value: number[];
    readonly onFiltersUpdate: (filters: IChatMemberQueryParams) => void;
    readonly onSelect: (ids: number[]) => void;
};

export const MembersTableSelector: React.FC<Props> = props => {
    return (
        <StyledTable
            loading={props.loading}
            rowSelection={{
                onChange: props.onSelect,
                selectedRowKeys: props.value,
            }}
            locale={{ emptyText: <Empty description='Нет участников' /> }}
            dataSource={props.source.items}
            rowKey={member => member.telegram_id}
            pagination={{
                total: props.source.total,
                showSizeChanger: true,
                showTotal: () => `Пользователей: ${props.source.total}`,
                onChange: (page, pageSize) => {
                    props.onFiltersUpdate({
                        offset: (page - 1) * pageSize,
                        limit: pageSize,
                    });
                },
                onShowSizeChange: (current, size) => {
                    props.onFiltersUpdate({
                        offset: (current - 1) * size,
                        limit: size,
                    });
                },
            }}
            scroll={{ x: 600 }}
        >
            <Table.Column
                title={
                    <TableFilter<string>
                        title='Имя'
                        onChange={name => props.onFiltersUpdate({ name })}
                        value={props.filters.name}
                    >
                        <TableFilter.Text />
                    </TableFilter>
                }
                fixed='left'
                width={200}
                render={(member: IChatMember) => member.name}
            />
            <Table.Column
                title={
                    <TableFilter<string>
                        onChange={user_name =>
                            props.onFiltersUpdate({ user_name })
                        }
                        value={props.filters.user_name}
                        title='Юзернейм'
                    >
                        <TableFilter.Text />
                    </TableFilter>
                }
                width={200}
                dataIndex={'user_name'}
            />
            <Table.Column
                title={
                    <TableFilter icon={<FilterOutlined />} title='Чаты'>
                        <TableFilter.Text />
                    </TableFilter>
                }
                render={communities => communities.map(c => c.title).join(', ')}
                dataIndex={'communities'}
                width={400}
            />
        </StyledTable>
    );
};

const StyledTable = styled(Table<IChatMember>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;
