export const chartBarOptions = props => {
    const translatedCategories = props.categories.map(
        cat =>
            (cat === 'no' && 'Не понравился') ||
            (cat === 'yes' && 'Понравился') ||
            (cat === 'neutral' && 'Нейтрально') ||
            (cat === 'enough' && 'Объем удовлетворяет') ||
            (cat === 'not_enough' && 'Увеличить объем') ||
            (cat === 'too_many' && 'Уменьшить объем')
    );

    return {
        title: {
            text: props.title,
            offsetY: 0,
            offsetX: -1,
            style: {
                color: '#25212973',
            },
        },
        chart: {
            type: 'bar',
            height: 250,
            stacked: false,
            stackType: '100%',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff'],
        },
        xaxis: {
            categories: translatedCategories,
        },

        colors: ['#597EF7'],

        tooltip: {
            enabled: false,
        },
        fill: {
            opacity: 1,
        },
        legend: {
            show: false,
        },
        noData: {
            text: 'Нет данных',
            align: 'center',
            verticalAlign: 'middle',
            style: {
                color: '#25212973',
            },
        },
    };
};

export const chartDonutOptions = props => {
    const translatedCategories = props.labels.map(
        cat =>
            (cat === 'all_ok' && 'Все устраивает') ||
            (cat === 'add_audio_video' && 'Аудио/видео материалы') ||
            (cat === 'add_images' && 'Изображения') ||
            (cat === 'add_text' && 'Текстовые описания') ||
            (cat === 'make_webinar' &&
                'Проведение вебинара на выбранную тему') ||
            (cat === 'not_interesting' &&
                'Не публиковать ничего на эту тему') ||
            (cat === 'less_audio' && 'Аудио материалы') ||
            (cat === 'less_video' && 'Видео материалы') ||
            (cat === 'less_images' && 'Изображения') ||
            (cat === 'less_text' && 'Текстовые описания') ||
            (cat === 'less_webinars' && 'Меньше вебинаров на выбранную тему')
    );

    return {
        chart: {
            type: 'donut',
        },
        colors: ['#597EF7', '#85A5FF', '#ADC6FF'],
        labels: translatedCategories,
        legend: {
            width: 200,
            position: 'left',
            formatter: function (val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
            },
            offsetY: 14,
            itemMargin: {
                horizontal: 0,
                vertical: 7,
            },
        },
        title: {
            text: props.title,
            offsetY: 0,
            offsetX: -1,
            style: {
                color: '#25212973',
            },
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            },
        },
        dataLabels: {
            enabled: true,
            dropShadow: false,
        },
        noData: {
            text: 'Нет данных',
            align: 'center',
            verticalAlign: 'middle',
            style: {
                color: '#25212973',
            },
        },
    };
};
