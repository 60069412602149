import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Typography } from 'antd';

export const CommunitySelect = props => {
    const [selectedValue, setSelectedValue] = useState('');

    const community = props.formik.values.community;

    useEffect(() => {
        if (community) {
            setSelectedValue(community.title);
            props.formik.setFieldValue('community', community);
        }
    }, [community]);

    return (
        <Fragment>
            <StrongText>Сообщество</StrongText>
            <StyledSelect
                disabled
                defaultValue={community}
                value={selectedValue}
            />
        </Fragment>
    );
};

const StrongText = styled(Typography.Text)`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
`;

const StyledSelect = styled(Input)`
    max-width: 300px;
`;
