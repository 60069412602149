import React, { useEffect } from 'react';
import { App, Input } from 'antd';
import styled from 'styled-components';

import { GenericModal } from '../../../../../shared/ui/GenericModal';
import { usePayoutMutation } from '../../../services/finance.service';
import { useAddBankCardMutation } from '../../../../user/services';

export const FinancesWithdrawContainer = props => {
    const { notification } = App.useApp();

    const { isOpen, setIsOpen } = props;

    const [payout, { isSuccess, isError }] = usePayoutMutation();
    const [getAddBankCardAPI, { data }] = useAddBankCardMutation();

    const onConfirm = () => {
        if (props.info?.cards[0]?.CardId) {
            payout({
                cardId: props.info?.cards[0]?.CardId,
            }).then(() => {
                setIsOpen(false);
            });
        }
    };

    const handleAddBankCardRequest = async () => {
        const res = await getAddBankCardAPI().unwrap();

        if (res?.data?.redirect) {
            window.location.assign(res?.data?.redirect);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Успешно',
                placement: 'top',
            });
        }
        if (isError) {
            notification.error({
                message: 'Что-то не так',
                placement: 'top',
            });
        }
    }, [isSuccess, isError]);

    return (
        <GenericModal
            title='Вывести средства'
            open={isOpen}
            okText={'Вывести'}
            okButtonProps={{
                disabled: !(props.info?.amount && props.info?.cards?.length),
            }}
            onCancel={() => {
                setIsOpen(false);
            }}
            onConfirm={() => onConfirm()}
        >
            {props.info?.cards?.length ? (
                <div style={{ marginBottom: '1rem' }}>
                    <div style={{ marginBottom: 8 }}>Карта</div>
                    <Input value={props.info?.cards[0]?.Pan || ''} disabled />
                </div>
            ) : (
                <CardNotFoundWrap>
                    <div
                        style={{
                            marginBottom: 8,
                            width: '100%',
                            textAlign: 'start',
                        }}
                    >
                        Карт не найдено{' '}
                        <a onClick={() => handleAddBankCardRequest()}>
                            добавьте новую
                        </a>
                    </div>
                </CardNotFoundWrap>
            )}
            <div>
                <div style={{ marginBottom: 8 }}>Сумма</div>
                <Input value={props.info?.amount || ''} disabled />
            </div>
        </GenericModal>
    );
};

const CardNotFoundWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
`;
