import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, Divider, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useReadChatTagsQuery } from 'features/communities/services';

type TProps = {
    readonly value?: string[];
    readonly onChange?: (tags: string[]) => void;
    readonly onClose?: () => void;
};

export const TagFilter: React.FC<TProps> = props => {
    const [selectedTags, setSelectedTags] = useState<string[]>(
        props.value || []
    );
    const [allTags, setAllTags] = useState<string[]>([]);

    const { data: tags = [] } = useReadChatTagsQuery();

    useEffect(() => {
        setSelectedTags(props.value);
    }, [props.value]);

    useEffect(() => {
        setAllTags(tags.map(t => t.name));
    }, [JSON.stringify(tags)]);

    const handleSelect = (name: string, checked: boolean) => {
        if (checked) {
            setSelectedTags([...selectedTags, name]);
        } else setSelectedTags(selectedTags.filter(tag => tag !== name));
    };

    const handleChange = () => {
        props.onChange(selectedTags);
        props.onClose();
    };

    const handleClear = () => {
        props.onChange([]);
        props.onClose();
    };

    const handleTagsSearch = (value: string) => {
        if (value) setAllTags(allTags.filter(tag => tag.startsWith(value)));
        else setAllTags(tags.map(t => t.name));
    };

    return (
        <>
            <Input
                onChange={({ currentTarget }) => {
                    handleTagsSearch(currentTarget.value);
                }}
                prefix={<SearchOutlined />}
                placeholder='Поиск тегов'
            />
            <Divider style={{ margin: 0 }} />
            <StyledContainer>
                {allTags.map((tag, index) => (
                    <StyledCheckbox
                        checked={selectedTags?.includes(tag)}
                        onChange={({ target }) => {
                            handleSelect(tag, target.checked);
                        }}
                        key={index}
                    >
                        {tag}
                    </StyledCheckbox>
                ))}
            </StyledContainer>

            <Divider style={{ margin: 0 }} />
            <StyledActions>
                <Button size='small' type='text' onClick={handleClear}>
                    Очистить
                </Button>
                <Button size='small' type='primary' onClick={handleChange}>
                    Ок
                </Button>
            </StyledActions>
        </>
    );
};

const StyledCheckbox = styled(Checkbox)`
    margin-inline-start: 0 !important;
`;

const StyledActions = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;

    max-height: 330px;
    overflow-y: scroll;
    overflow-x: unset;
`;
