import React, { FC, useEffect, useState } from 'react';
import { App, Button } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { TariffList } from '../views/TariffList';
import {
    useDeleteByIdMutation,
    useGetListQuery,
    useUpdateMutation,
} from '../../../services/tariff.service';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { AdaptiveCard } from '../views/AdaptiveCard';
import { Page } from '../../../../../shared/ui/Page';
import { Warning } from '../../../../../shared/ui/Warning';
import { useProfileData } from '../../../../../hooks/useProfileData';

export const TariffContainer: FC = () => {
    const { notification } = App.useApp();
    const [searchParams] = useSearchParams();
    const { size } = useWindowSize();
    const navigate = useNavigate();

    const [selectedRows, setSelectedRows] = useState([]);

    const [filters, setFilters] = useState({
        sort_direction: 'desc',
        limit: size === 'small' ? 5 : 10,
        offset: Number(searchParams.get('page'))
            ? (Number(searchParams.get('page')) - 1) * 10
            : 0,
    });
    const [update, { isSuccess: isSuccess, error: error }] =
        useUpdateMutation();
    const [deleteById, { isSuccess: isSuccessDel, error: errorDel }] =
        useDeleteByIdMutation();
    const { data = { list: [], total: 0 }, isFetching } =
        useGetListQuery(filters);

    const { user } = useProfileData();
    const isPhoneConfirmed = user?.phone_confirmed;

    useEffect(() => {
        if (isSuccessDel) {
            notification.success({
                message: 'Тариф удален успешно',
                placement: 'top',
            });
            setSelectedRows([]);
        }
        if (isSuccess) {
            notification.success({
                message: 'Тариф обновлен успешно',
                placement: 'top',
            });
            setSelectedRows([]);
        }
    }, [isSuccessDel, isSuccess]);

    const onSetStatus = (id, status) => {
        update({
            id,
            body: {
                tariff_is_payable: status,
            },
        });
    };

    return (
        <Page title='Тарифы'>
            {isPhoneConfirmed ? (
                <Button
                    type='primary'
                    style={{ marginBottom: 16 }}
                    onClick={() => navigate('add')}
                >
                    <PlusOutlined />
                    <span style={{ marginLeft: 8 }}>Добавить тариф</span>
                </Button>
            ) : (
                <Warning
                    text={'Укажите свой номер телефона, чтобы получать платежи'}
                />
            )}

            {size === 'small' ? (
                <>
                    <AdaptiveCard
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        source={data?.list}
                        onSetStatus={onSetStatus}
                    />
                    <Button
                        disabled={filters.limit >= data.total}
                        onClick={() => {
                            setFilters({
                                ...filters,
                                //offset: filters.offset * filters.limit,
                                limit: filters.limit + 5,
                            });
                        }}
                        style={{ width: '100%' }}
                    >
                        {filters.limit >= data.total
                            ? 'Загружены все'
                            : 'Загрузить еще'}
                    </Button>
                </>
            ) : (
                <TariffList
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    onSetStatus={onSetStatus}
                    setFilters={setFilters}
                    deleteById={deleteById}
                    filters={filters}
                    isFetching={isFetching}
                    data={data}
                    isPhoneConfirmed={isPhoneConfirmed}
                />
            )}
        </Page>
    );
};
