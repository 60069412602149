import React, { Dispatch, FC, Fragment, SetStateAction } from 'react';
import { Avatar, Button, Space, Table, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { TableSort } from 'shared/ui/TableSort/TableSort';
import { TableFilter } from 'shared/ui/TableFilter';

import { Dropdown } from '../../../../../shared/ui/Dropdown';
import { getSrc } from '../../../../../utils/common/getSrc';

interface Filters {
    limit: number;
    offset: number;
    tariff_title?: string;
    community_title?: string;
    sort_field?: string;
    sort_direction?: string;
}

interface TariffList {
    onSetStatus: (id: number, status: boolean) => void;
    setFilters: Dispatch<SetStateAction<Filters>>;
    deleteById: (id: number) => void;
    isFetching: boolean;
    readonly filters: Filters;
    data: any;
    selectedRows: number[];
    setSelectedRows: React.Dispatch<React.SetStateAction<React.Key[]>>;
    readonly isPhoneConfirmed?: boolean;
}

export const TariffList: FC<TariffList> = props => {
    const {
        selectedRows,
        setSelectedRows,
        isPhoneConfirmed,
        filters,
        setFilters,
    } = props;

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const columns = [
        {
            title: () => (
                <TableFilter
                    title='Тариф'
                    onChange={name => {
                        setFilters(
                            Object.assign({}, filters, {
                                tariff_title: name,
                            })
                        );
                    }}
                    value={filters.tariff_title}
                >
                    <TableFilter.Text
                        onClear={() => {
                            const _filters = { ...filters };
                            delete _filters.tariff_title;
                            setFilters(_filters);
                        }}
                    />
                </TableFilter>
            ),
            key: 'id',
            render: tariff => (
                <>
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`${tariff.id}`)}
                    >
                        {tariff.title}
                    </div>
                </>
            ),
        },
        {
            title: () => (
                <TableSort
                    setFilters={setFilters}
                    filters={filters}
                    fieldName={'price'}
                >
                    Стоимость
                </TableSort>
            ),
            dataIndex: 'price',
            key: 'price',
            render: price => price,
        },
        {
            title: () => (
                <TableFilter
                    title='Сообщество'
                    onChange={name => {
                        setFilters(
                            Object.assign({}, filters, {
                                community_title: name,
                            })
                        );
                    }}
                    value={filters.community_title}
                >
                    <TableFilter.Text
                        onClear={() => {
                            const _filters = { ...filters };
                            delete _filters.community_title;
                            setFilters(_filters);
                        }}
                    />
                </TableFilter>
            ),
            key: 'id',
            render: tariff => (
                <div>
                    <Avatar src={getSrc(tariff.community_image)} />
                    <span style={{ marginLeft: 16 }}>
                        <Typography.Text>{tariff.chat_name}</Typography.Text>
                    </span>
                </div>
            ),
        },
        {
            title: () => (
                <TableSort
                    setFilters={setFilters}
                    filters={filters}
                    fieldName={'followers'}
                >
                    Участников
                </TableSort>
            ),
            dataIndex: 'followers',
            key: 'id',
            render: followers => followers,
        },
        {
            title: () => (
                <TableSort
                    setFilters={setFilters}
                    filters={filters}
                    fieldName={'tariff_is_payable'}
                >
                    Статус
                </TableSort>
            ),
            key: 'id',
            render: tariff => (
                <>
                    <StyledDropdown
                        disabled={!isPhoneConfirmed}
                        dropdownContent={() => (
                            <Fragment>
                                <Button
                                    type='text'
                                    onClick={() =>
                                        props.onSetStatus(tariff.id, true)
                                    }
                                >
                                    Активен
                                </Button>
                                <Button
                                    type='text'
                                    onClick={() =>
                                        props.onSetStatus(tariff.id, false)
                                    }
                                >
                                    Отключён
                                </Button>
                            </Fragment>
                        )}
                    >
                        <Button type='default'>
                            {tariff?.tariff_is_payable ? 'Активен' : 'Отключён'}
                            <DownOutlined />
                        </Button>
                    </StyledDropdown>
                </>
            ),
        },
    ];

    const onDelete = () => {
        if (selectedRows.length) {
            selectedRows.forEach(id => props.deleteById(id));
        }
    };

    const onSetActive = status => {
        if (selectedRows.length) {
            selectedRows.forEach(id => {
                props.onSetStatus(id, status);
            });
        }
    };

    const rowSelection = {
        selectedRows: selectedRows,
        onChange: setSelectedRows,
    };

    const pagination = {
        total: props?.data?.total || 0,
        showTotal: () => `Тарифов: ${props?.data?.total || 0}`,
        onChange: (page, pageSize) => {
            setSearchParams({ page });
            setFilters({
                ...filters,
                offset: (page - 1) * pageSize,
                limit: pageSize,
            });
        },
        showQuickJumper: false,
        showSizeChanger: false,
        current: +searchParams.get('page') || 1,
    };

    return (
        <>
            {Boolean(selectedRows.length) && (
                <Space size='middle' style={{ marginBottom: 16 }}>
                    <Button danger type='primary' onClick={onDelete}>
                        Удалить
                    </Button>
                    <StyledDropdownWithBorder
                        dropdownContent={() => (
                            <Fragment>
                                <Button
                                    type='text'
                                    onClick={() => onSetActive(true)}
                                >
                                    Активен
                                </Button>
                                <Button
                                    type='text'
                                    onClick={() => onSetActive(false)}
                                >
                                    Отключён
                                </Button>
                            </Fragment>
                        )}
                    >
                        <Button>
                            Статус <DownOutlined />
                        </Button>
                    </StyledDropdownWithBorder>
                    <div>
                        <Typography.Text>
                            Выбрано: {selectedRows.length || 0}
                        </Typography.Text>
                    </div>
                </Space>
            )}

            <Table
                rowSelection={rowSelection}
                scroll={{ x: 900 }}
                loading={props.isFetching}
                columns={columns}
                rowKey={entity => entity.id}
                dataSource={props.data?.list || []}
                pagination={pagination}
            />
        </>
    );
};

const StyledDropdownWithBorder = styled(Dropdown)`
    margin-left: auto;
    cursor: pointer;
`;

const StyledDropdown = styled(Dropdown)`
    cursor: pointer;

    button {
        text-align: start;
        padding-left: 0;
        padding-right: 0;
        width: auto;
        border: none;
        box-shadow: none;
    }
`;
