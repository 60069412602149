import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, DatePicker } from 'antd';
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
dayjs.extend(dayOfYear);

type TProps = {
    readonly value?: string[];
    readonly onChange?: (tags: string[]) => void;
    readonly onClose?: () => void;
    readonly setFilters?: (filters) => void;
    readonly filters?: {
        date_to: number | null;
        date_from: number | null;
    };
    readonly setFilterTitle?: (string) => void;
    readonly setShowBadge?: (boolean) => boolean;
};
export const DateFilter: React.FC<TProps> = props => {
    const [open, setOpen] = useState<boolean>(false);
    const [currentMonth, setCurrentMonth] = useState('');
    const [currentYear, setCurrentYear] = useState(0);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        if (
            props.filters &&
            (!props.filters.date_to || !props.filters.date_from)
        ) {
            setStartDate(null);
            setEndDate(null);
            setOpen(false);
            props.setShowBadge(false);
        }
    }, [props.filters]);

    useEffect(() => {
        const currentMonth = dayjs().month();
        const currentYear = dayjs().year();
        setCurrentYear(currentYear);

        switch (currentMonth) {
            case 0:
                return setCurrentMonth('Январь');
            case 1:
                return setCurrentMonth('Февраль');
            case 2:
                return setCurrentMonth('Март');
            case 3:
                return setCurrentMonth('Апрель');
            case 4:
                return setCurrentMonth('Май');
            case 5:
                return setCurrentMonth('Июнь');
            case 6:
                return setCurrentMonth('Июль');
            case 7:
                return setCurrentMonth('Август');
            case 8:
                return setCurrentMonth('Сентябрь');
            case 9:
                return setCurrentMonth('Октябрь');
            case 10:
                return setCurrentMonth('Ноябрь');
            case 11:
                return setCurrentMonth('Декабрь');
        }
    }, []);

    const onSelect = date => {
        if (date) {
            props.setFilters({
                date_from: date[0].unix(),
                date_to: date[1].unix(),
            });

            setStartDate(date[0]);
            setEndDate(date[1]);

            props.setFilterTitle('Ваша дата');
            props.setShowBadge(true);

            props.onClose();
        }
    };

    const onChange = e => {
        if (props.filters.date_to || props.filters.date_from) {
            setStartDate(null);
            setEndDate(null);
        }
        setOpen(false);

        const type = e.currentTarget.value;
        const now = dayjs().unix();

        switch (e.currentTarget.value) {
            case '7': {
                const week = dayjs().subtract(+type, 'day').unix();
                props.setFilters({
                    date_from: week,
                    date_to: now,
                });
                props.setFilterTitle('Последние 7 дней');
                break;
            }
            case '30': {
                const month = dayjs().subtract(+type, 'day').unix();
                props.setFilters({
                    date_from: month,
                    date_to: now,
                });
                props.setFilterTitle('30 дней');
                break;
            }
            case 'this_month': {
                const daysGone = dayjs().date();
                const this_month = dayjs().subtract(daysGone, 'day').unix();
                props.setFilters({
                    date_from: this_month,
                    date_to: now,
                });
                props.setFilterTitle(`${currentMonth}, ${currentYear}`);
                break;
            }
            case 'year': {
                const daysGoneYear = dayjs().dayOfYear();
                const this_year = dayjs().subtract(daysGoneYear, 'day').unix();
                props.setFilters({
                    date_from: this_year,
                    date_to: now,
                });
                props.setFilterTitle(`С начала года`);
                break;
            }
        }
        props.setShowBadge(true);
        props.onClose();
    };

    return (
        <>
            <StyledMenuButton
                size='small'
                type='text'
                value={'7'}
                onClick={e => onChange(e)}
            >
                Последние 7 дней
            </StyledMenuButton>
            <StyledMenuButton
                size='small'
                type='text'
                value={'30'}
                onClick={e => onChange(e)}
            >
                30 дней
            </StyledMenuButton>
            <StyledMenuButton
                size='small'
                type='text'
                value={'this_month'}
                onClick={e => onChange(e)}
            >
                {currentMonth}, {currentYear}
            </StyledMenuButton>
            <StyledMenuButton
                size='small'
                type='text'
                value={'year'}
                onClick={e => onChange(e)}
            >
                С начала года
            </StyledMenuButton>

            {open ? (
                <RangePicker
                    onChange={onSelect}
                    size={'small'}
                    value={[startDate, endDate]}
                    allowClear={false}
                />
            ) : (
                <StyledMenuButton
                    size='small'
                    type='text'
                    onClick={() => setOpen(true)}
                >
                    Выбрать...
                </StyledMenuButton>
            )}
        </>
    );
};

const StyledMenuButton = styled(Button)`
    min-width: 285px;
    text-align: left;
`;
