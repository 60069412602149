import { RouteObject } from 'react-router';

import { ShopsContainer } from './containers/Shops/ShopsContainer';
import { ShopContainer } from './containers/Shops/ShopContainer';
import { ProductCreateContainer } from './containers/Products/ProductCreateContainer';
import { ProductEditContainer } from './containers/Products/ProductEditContainer';
import { ProductsContainer } from './containers/Products/ProductsContainer';
import { ProductsArchiveContainer } from './containers/Products/ProductsArchiveContainer';
import UkassaContainer from './containers/Payments/UkassaContainer';
import OrdersContainer from './containers/Orders/OrdersContainer';

const routes: RouteObject[] = [
    {
        path: 'shops',
        element: <ShopsContainer />,
    },
    {
        path: 'shops/:id',
        element: <ShopContainer />,
        children: [
            {
                path: 'products',
                element: <ProductsContainer />,
            },
            {
                path: 'archive',
                element: <ProductsArchiveContainer />,
            },
            {
                path: 'orders',
                element: <OrdersContainer />,
            },
        ],
    },
    {
        path: 'shops/:id/product-create',
        element: <ProductCreateContainer />,
    },
    {
        path: 'shops/:id/product-edit/:id',
        element: <ProductEditContainer />,
    },
];
export default routes;
