import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { Button, Col, Divider, Row } from 'antd';

import { LikeSwitch } from './components/LikeSwitch';
import { SizeSwitch } from './components/SizeSwitch';
import { AddContentSwitch } from './components/AddContentSwitch';
import { DeleteContentSwitch } from './components/DeleteContentSwitch';

export const FeedbackForm = props => {
    const formik = useFormik({
        initialValues: props.formik.initialValues,
        onSubmit: props.onSave,
    });

    return (
        <>
            <StyledRow gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <LikeSwitch formik={formik} />
                </Col>
                <Col xs={24} md={12}>
                    <SizeSwitch formik={formik} />
                </Col>
            </StyledRow>

            <StyledRow gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <AddContentSwitch formik={formik} />
                </Col>
                <Col xs={24} md={12}>
                    <DeleteContentSwitch formik={formik} />
                </Col>
            </StyledRow>

            <Divider />
            <Button
                disabled={props.loading}
                onClick={() => formik.submitForm()}
                type={'primary'}
            >
                Отправить
            </Button>
        </>
    );
};

const StyledRow = styled(Row)`
    margin-top: 2rem;
`;
