import React, { FC } from 'react';
import { Col, Row, Typography, Upload } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

import { validateImages } from '../../../../../../utils/common/validateImages';
import { TextEditor } from '../../../../../../shared/ui/TextEditor/TextEditor';
import { ICreateQuestionProps } from '../../../../interfaces/questions.interface';

export const Answer: FC<ICreateQuestionProps> = props => {
    const { formik, imgRef, img } = props;

    return (
        <>
            <StyledTitle level={5}>Ответ</StyledTitle>
            <StyledRow justify={'start'} wrap>
                <div style={{ marginRight: 14 }}>
                    <Upload
                        style={{ marginTop: 10 }}
                        maxCount={1}
                        listType='picture-card'
                        className='avatar-uploader'
                        showUploadList={false}
                        beforeUpload={() => false}
                        onChange={value => {
                            if (validateImages(value.file)) {
                                imgRef.current = value.file;
                                formik.setFieldValue(
                                    `answer_image`,
                                    value.file
                                );
                            }
                        }}
                    >
                        {formik.values?.answer_image ? (
                            <StyledImage src={img} alt='Картинка' />
                        ) : (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Картинка</div>
                            </div>
                        )}
                    </Upload>
                </div>

                <Col xs={24} sm={18} md={12}>
                    <TextEditor
                        placeholder={'Введите ответ'}
                        value={
                            formik.values?.answer_text ||
                            formik.values?.answer?.context
                        }
                        onChange={value => {
                            formik.setFieldValue(`answer_text`, value);
                        }}
                        containerHeight={150}
                    />
                </Col>
            </StyledRow>
        </>
    );
};

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 10px;
`;

const StyledRow = styled(Row)`
    margin: 0 0 1rem 0;
`;
