import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Card, Col, Row, Typography } from 'antd';

import { VideoView } from '../containers/post/components/VideoView';
import { AudioView } from '../containers/post/components/AudioView';
import { ImageView } from '../containers/post/components/ImageView';
import { useTheme } from '../../../../../hooks/useTheme';

interface IData {
    order: number;
    type: number;
    text: string | null;
    file_path: string | null;
}
interface IProps {
    readonly parts: Array<IData> | any;
}

export const PartsCard: React.FC<IProps> = props => {
    const { parts } = props;
    const token = useTheme();

    const partWidth = {
        xs: 24,
        sm: 20,
        lg: 16,
    };

    return (
        <StyledCard type='inner' style={token.Card}>
            <Row gutter={[0, 20]}>
                {parts
                    ?.slice()
                    ?.sort((a, b) => a.order - b.order)
                    .map((part, index) => (
                        <Fragment key={index}>
                            {part.type === 6 ? (
                                <Col {...partWidth}>
                                    <StyledTitle level={4}>
                                        {part.text}
                                    </StyledTitle>
                                </Col>
                            ) : null}

                            {part.type === 1 ? (
                                <Col {...partWidth}>
                                    <StyledText>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: part.text,
                                            }}
                                        ></span>
                                    </StyledText>
                                </Col>
                            ) : null}

                            {part.type === 2 ? (
                                <Col {...partWidth}>
                                    <VideoView src={part.file_path} />
                                </Col>
                            ) : null}

                            {part.type === 3 ? (
                                <Col {...partWidth}>
                                    <AudioView src={part.file_path} />
                                </Col>
                            ) : null}

                            {part.type === 4 ? (
                                <Col {...partWidth}>
                                    <ImageView src={part.file_path} />
                                </Col>
                            ) : null}
                        </Fragment>
                    ))}
            </Row>
        </StyledCard>
    );
};

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    /*margin-bottom: 20px;*/
`;

const StyledText = styled(Typography.Text)`
    margin-block-start: 0;
    margin-block-end: 0;
    /*  margin-bottom: 20px;*/
`;

const StyledCard = styled(Card)`
    width: 100%;
    margin-top: 1rem;
`;
