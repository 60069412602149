import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Empty, Table, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import {
    IChatMember,
    IChatMemberQueryParams,
} from 'features/communities/interfaces';
import { TableFilter } from 'shared/ui/TableFilter';
import { PaginatedResponse } from 'features/communities/interfaces/PaginatedResponse';
import { ConfirmationDialog } from 'shared/ui/ConfirmationDialog';
import getEnvConfig from 'utils/config/env';

import { ChatsFilter } from '../components/ChatsFilter';
import { useTheme } from '../../../../../hooks/useTheme';
import { DELETE } from '../../../../../shared/contants/actions';

type TProps = {
    readonly source: PaginatedResponse<IChatMember>;
    readonly loading?: boolean;
    readonly filters: IChatMemberQueryParams;
    readonly onFiltersUpdate: (filters: IChatMemberQueryParams) => void;
    readonly onBulkRemove: (accounts: number[]) => void;
};

export const AllMembersTable: React.FC<TProps> = props => {
    const token = useTheme();

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

    const navigate = useNavigate();

    const rowSelection = {
        selectedRows: selectedRows,
        onChange: setSelectedRows,
    };

    useEffect(() => {
        if (sessionStorage.allMemberCurrentPage) {
            setCurrentPage(
                Number(sessionStorage.getItem('allMemberCurrentPage'))
            );
        }
    }, [sessionStorage.allMemberCurrentPage]);

    const getSelectedUsersChats = () => {
        return props.source.items.reduce((acc, chatMember) => {
            if (
                selectedRows
                    .map(Number)
                    .some(telegram_id => telegram_id === chatMember.telegram_id)
            )
                acc.push(chatMember.communities.map(chat => chat.id));
            return acc;
        }, []);
    };

    const handleChangePage = page => {
        sessionStorage.setItem('allMemberCurrentPage', page);
    };

    return (
        <StyledWrapper>
            <StyledActions>
                {!!selectedRows.length && (
                    <StyledBulkRemove>
                        <ConfirmationDialog
                            activator={<Button type='primary'>Удалить</Button>}
                            onConfirm={() => {
                                props.onBulkRemove(
                                    selectedRows.map(r => Number(r))
                                );
                            }}
                            title='Удалить?'
                            description={`Вы уверены что хотите удалить пользователей из ${
                                getSelectedUsersChats().length
                            } сообществ?`}
                        />
                        <div>Выбрано пользователей: {selectedRows.length}</div>
                    </StyledBulkRemove>
                )}
            </StyledActions>
            <StyledTable
                loading={props.loading}
                rowSelection={rowSelection}
                scroll={{ x: 600 }}
                locale={{ emptyText: <Empty description='Нет участников' /> }}
                dataSource={props.source.items}
                rowKey={member => member.telegram_id}
                pagination={{
                    total: props.source.total,
                    showSizeChanger: true,
                    showTotal: () => `Пользователей: ${props.source.total}`,
                    onChange: (page, pageSize) => {
                        props.onFiltersUpdate({
                            offset: (page - 1) * pageSize,
                            limit: pageSize,
                        });
                        handleChangePage(page);
                    },
                    onShowSizeChange: (current, size) => {
                        props.onFiltersUpdate({
                            offset: (current - 1) * size,
                            limit: size,
                        });
                    },
                    defaultCurrent: 1,
                    current: currentPage,
                }}
            >
                <Table.Column
                    title={
                        <TableFilter<string>
                            title='Имя'
                            onChange={name => props.onFiltersUpdate({ name })}
                            value={props.filters.name}
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    fixed='left'
                    width={200}
                    render={(member: IChatMember) => (
                        <Typography.Link
                            style={{ color: token.colorPrimary }}
                            onClick={() => {
                                navigate(
                                    `${
                                        getEnvConfig().PUBLIC_PATH
                                    }/communities/members/view-member/${
                                        member.telegram_id
                                    }`
                                );
                            }}
                        >
                            {member.name}
                        </Typography.Link>
                    )}
                />
                <Table.Column
                    title={
                        <TableFilter<string>
                            onChange={user_name =>
                                props.onFiltersUpdate({ user_name })
                            }
                            value={props.filters.user_name}
                            title='Юзернейм'
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    width={200}
                    dataIndex={'user_name'}
                />
                <Table.Column
                    title={
                        <ChatsFilter
                            title={'Чаты'}
                            filters={props.filters}
                            onChange={props.onFiltersUpdate}
                        />
                    }
                    render={communities =>
                        communities.map(c => c.title).join(', ')
                    }
                    dataIndex={'communities'}
                    width={400}
                />

                <Table.Column
                    title='Действие'
                    dataIndex={'telegram_id'}
                    render={(telegram_id: number) => (
                        <Typography.Link
                            style={{ color: token.colorPrimary }}
                            onClick={() => {
                                props.onBulkRemove([telegram_id]);
                            }}
                        >
                            {DELETE}
                        </Typography.Link>
                    )}
                    width={100}
                />
            </StyledTable>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
`;

const StyledTable = styled(Table<IChatMember>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;

const StyledActions = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: -56px;
    z-index: 10;
`;

const StyledBulkRemove = styled.div`
    display: flex;
    align-items: center;
    div {
        margin-left: 16px;
    }
`;
