import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';

import { FinancesTable } from 'features/monetization/pages/finances/views/FinancesTable';
import {
    useGetPaymentsListQuery,
    useGetPaymentsStatisticQuery,
    useGetPayoutInfoQuery,
} from 'features/monetization/services/finance.service';

import { FinancesWithdrawContainer } from './FinancesWithdrawContainer';
import { FinanceStatsContainer } from './FinanceStatsContainer';
import { Page } from '../../../../../shared/ui/Page';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { FinanceCard } from '../views/FinanceCard';
import { FetchButton } from '../../../../../shared/ui/FetchButton';
import { FinanceStatsCard } from '../views/FinanceStatsCard';

export const FinancesContainer: FC = () => {
    const { size } = useWindowSize();

    const [filters, setFilters] = useState({
        limit: size === 'small' ? 3 : 10,
        offset: 0,
    });
    const [isOpen, setIsOpen] = useState(false);

    const { data: statistic, isFetching: isStatisticFetch } =
        useGetPaymentsStatisticQuery({});

    const {
        data: source = { items: [], total: 0 },
        isFetching: isSourceFetch,
    } = useGetPaymentsListQuery(filters);

    const { data: info } = useGetPayoutInfoQuery({});

    useEffect(() => {
        if (size !== 'small') {
            setFilters({ ...filters, limit: 10 });
        }
    }, [size]);

    return (
        <Page title='Финансы'>
            <FinancesWithdrawContainer
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                info={info}
            />

            {size === 'small' ? (
                <FinanceStatsCard statistic={statistic} />
            ) : (
                <FinanceStatsContainer
                    isStatisticFetch={isStatisticFetch}
                    statistic={statistic}
                />
            )}

            <Button
                type='primary'
                style={{ margin: '1rem 0 ' }}
                onClick={() => setIsOpen(true)}
            >
                Вывести средства
            </Button>

            {size === 'small' ? (
                <>
                    <FinanceCard
                        source={source.items}
                        loading={isSourceFetch}
                    />
                    <FetchButton
                        filters={filters}
                        setFilters={setFilters}
                        total={source.total}
                    />
                </>
            ) : (
                <FinancesTable
                    source={source.items}
                    loading={isSourceFetch}
                    total={source.total}
                    setFilters={setFilters}
                />
            )}
        </Page>
    );
};
