import React, { useState } from 'react';
import { Radio, RadioChangeEvent, Space } from 'antd';
import styled from 'styled-components';

import { Label } from './Label';

export const SizeSwitch = props => {
    const [value, setValue] = useState(
        props.formik.initialValues.enough_material
    );

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
        props.formik.setFieldValue('enough_material', e.target.value);
    };
    return (
        <StyledCheckWrapper>
            <Label label={'Достаточный ли объем материала?'} />
            <Radio.Group onChange={onChange} value={value}>
                <Space direction='vertical'>
                    <Radio value={'enough'}>Да, материала достаточно</Radio>
                    <Radio value={'not_enough'}>
                        Нет, нужно гораздо больше
                    </Radio>
                    <Radio value={'too_many'}>Нет, слишком много</Radio>
                </Space>
            </Radio.Group>
        </StyledCheckWrapper>
    );
};

const StyledCheckWrapper = styled.div`
    min-width: 50%;
    display: flex;
    flex-direction: column;
`;
