import { Empty, Space, Table, Tag } from 'antd';
import React from 'react';
import { FilterOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import {
    ICommunity,
    ICommunityQueryParams,
    ICommunityTag,
} from 'features/communities/interfaces';
import { PaginatedResponse } from 'features/communities/interfaces/PaginatedResponse';
import { TableFilter } from 'shared/ui/TableFilter';
import { format } from 'lib/date';

type Props = {
    readonly source: PaginatedResponse<ICommunity>;
    readonly loading?: boolean;
    readonly value: number[];
    readonly filters: ICommunityQueryParams;
    readonly onFiltersUpdate: (filters: ICommunityQueryParams) => void;
    readonly onSelect: (ids: number[]) => void;
};
export const ChatsTableSelector: React.FC<Props> = props => {
    return (
        <StyledTable
            loading={props.loading}
            rowSelection={{
                onChange: props.onSelect,
                selectedRowKeys: props.value,
            }}
            rowKey={community => community.id}
            locale={{ emptyText: <Empty description='Нет чатов' /> }}
            dataSource={props.source.items}
            pagination={{
                total: props.source.total,
                showSizeChanger: true,
                showTotal: () => `Чатов: ${props.source.total}`,
                onChange: (page, pageSize) => {
                    props.onFiltersUpdate({
                        offset: (page - 1) * pageSize,
                        limit: pageSize,
                    });
                },
                onShowSizeChange: (current, size) => {
                    props.onFiltersUpdate({
                        offset: (current - 1) * size,
                        limit: size,
                    });
                },
            }}
            scroll={{ x: 600 }}
        >
            <Table.Column
                title={
                    <TableFilter<string>
                        title='Название сообщества'
                        onChange={name => {
                            props.onFiltersUpdate({ name });
                        }}
                        value={props.filters.name}
                    >
                        <TableFilter.Text />
                    </TableFilter>
                }
                dataIndex={'title'}
                fixed='left'
                width={220}
            />
            <Table.Column
                title={
                    <TableFilter
                        icon={<FilterOutlined />}
                        title='Дата добавления'
                    >
                        <TableFilter.Date />
                    </TableFilter>
                }
                dataIndex={'created_at'}
                width={200}
                render={(createdAt: number) => format(createdAt)('DD.MM.YY')}
            />
            <Table.Column
                width={400}
                title={
                    <TableFilter<string[]>
                        icon={<FilterOutlined />}
                        onChange={tags_names => {
                            props.onFiltersUpdate({ tags_names });
                        }}
                        title='Теги'
                        value={props.filters.tags_names}
                    >
                        <TableFilter.Tags />
                    </TableFilter>
                }
                dataIndex={'tags'}
                render={(tags: ICommunityTag[]) => (
                    <React.Fragment>
                        <StyledSpace>
                            {tags.map((tag, index) => (
                                <Tag key={index}>{tag.name}</Tag>
                            ))}
                        </StyledSpace>
                    </React.Fragment>
                )}
            />
        </StyledTable>
    );
};

const StyledTable = styled(Table<ICommunity>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;

const StyledSpace = styled(Space)`
    margin-bottom: 8px;
`;
