import React, { useEffect, useState } from 'react';
import { App } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import {
    useReadBehaviorRuleQuery,
    useUpdateBehaviorRuleMutation,
} from 'features/communities/services';

import { BehavioralForm } from '../views/BehavioralForm';
import { createFormData } from '../../../../../utils/common/createFormData';
import { IUpdateBehaviorRequest } from '../../../interfaces/behavioral.interface';
import { IFormikInitialValues } from './BehavioralContainerCreate';
import { BehavioralMapper } from '../BehavioralMapper';

export const BehavioralContainerEdit = () => {
    const { notification } = App.useApp();
    const params = useParams();
    const { data, isSuccess: isSuccessRead } = useReadBehaviorRuleQuery(
        params.id
    );
    const [initialValues, setInitialValues] =
        useState<IFormikInitialValues | null>(null);

    const navigate = useNavigate();

    const [updateBehaviorRule, { isSuccess, error }] =
        useUpdateBehaviorRuleMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Сценарий обновлен успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any).data.message || 'Что-то не так',
                placement: 'top',
            });
        }
    }, [error]);

    useEffect(() => {
        if (data && isSuccessRead) {
            const response = BehavioralMapper.convertDto(data);
            setInitialValues(response);
        }
    }, [data, isSuccessRead]);

    const onSave = async (values: IFormikInitialValues) => {
        const dto = BehavioralMapper.createDto(values);
        if (!(dto?.content_image instanceof File)) delete dto.content_image;
        if (!(dto?.warning_image instanceof File)) delete dto.warning_image;
        if (!(dto?.user_complaint_image instanceof File))
            delete dto.user_complaint_image;

        const requestObject: IUpdateBehaviorRequest = {
            uuid: params.id,
            body: createFormData(dto),
        };
        await updateBehaviorRule(requestObject);
    };

    return (
        <>
            {initialValues && (
                <BehavioralForm onSave={onSave} initialValues={initialValues} />
            )}
        </>
    );
};
