import React, { Fragment, useState } from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import { IChatMemberQueryParams } from 'features/communities/interfaces';
import {
    useReadChatMembersQuery,
    useRemoveMemberFromListMutation,
} from 'features/communities/services';

import { BlackListedMembersTable } from '../views/BlackListedMembersTable';

export const BlackListedMembersPage: React.FC = () => {
    const { notification } = App.useApp();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<IChatMemberQueryParams>({
        banned: true,
        community_id: null,
    });

    const {
        data: source = { items: [], total: 0 },
        isFetching: isReadLoading,
    } = useReadChatMembersQuery(filters);

    const [removeFromlist, { isLoading: isRemoveLoading }] =
        useRemoveMemberFromListMutation();

    const handleFiltersUpdate = (update: IChatMemberQueryParams | null) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({});
        }
    };

    const onBulkRemove = async args => {
        const options = {
            banned: true,
            muted: false,
            whitelisted: false,
            is_spammer: 0,
        };

        const payloads = args.map(arg => ({
            telegram_id: arg.telegram_id,
            community_ids: arg.communities.map(c => c.id),
            ...options,
        }));

        await Promise.all(payloads.map(removeFromlist));
        navigate(-1);

        if (args.length === 1) {
            return notification.success({
                message: 'Участник успешно удален',
                placement: 'top',
            });
        } else
            notification.success({
                message: 'Участники успешно удалены',
                placement: 'top',
            });
    };

    return (
        <Fragment>
            <BlackListedMembersTable
                onBulkRemove={onBulkRemove}
                filters={filters}
                onFiltersUpdate={handleFiltersUpdate}
                loading={isReadLoading || isRemoveLoading}
                source={source}
            />
        </Fragment>
    );
};
