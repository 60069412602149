import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Badge, Button, Card, Dropdown, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useTheme } from 'hooks/useTheme';

import { TagFilter } from '../TableFilter/TagFilter';
import { TextFilter } from '../TableFilter/TextFilter';
import { DateFilter } from '../TableFilter/DateFilter';
import { SelectorFilter } from '../TableFilter/SelectorFilter';
import { MultiCheckBoxFilter } from '../TableFilter/MultiCheckBoxFilter';

type Children<T> = {
    readonly onChange: (value: T | '') => void;
    readonly onClose: () => void;
    readonly onClear?: () => void;
    readonly value: T | '';
};

type TProps<T> = {
    readonly title: string;
    readonly value?: T | '';
    readonly onChange?: (value: T | '') => void;
    readonly icon?: React.ReactElement;
    readonly children:
        | ((renderProp: Children<T>) => React.ReactNode)
        | React.ReactElement;
    readonly setFilters?: (filters) => void;
    readonly filters?: {
        date_to?: number | string;
        date_from?: number | string;
    };
    readonly closeOnSelect?: boolean;
};

export const MobileFilter = <T,>(props: TProps<T>) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<T | ''>();
    const [filterTitle, setFilterTitle] = useState(props.title);
    const [showBadge, setShowBadge] = useState(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (value === undefined || value === '' || value?.length === 0) {
            setShowBadge(false);
        }
    }, [value]);

    const onChange = (value: T) => {
        props.onChange && props.onChange(value);
        props.closeOnSelect ? setOpen(false) : null;
        setShowBadge(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onClear = () => {
        props.onChange && props.onChange('');
        props.closeOnSelect ? setOpen(false) : null;
        setShowBadge(false);
    };

    const token = useTheme();

    return (
        <Badge dot={showBadge}>
            <StyledWrapper>
                <StyledFilterButton>
                    {props.icon || <SearchOutlined />}
                </StyledFilterButton>

                <Dropdown
                    autoAdjustOverflow
                    placement={'bottomRight'}
                    open={isOpen}
                    onOpenChange={setOpen}
                    trigger={['click']}
                    dropdownRender={() => (
                        <StyledDropDownCard style={{ ...token.Card }}>
                            {typeof props.children === 'function'
                                ? props.children({
                                      onChange: onChange,
                                      onClear: onClear,
                                      onClose: onClose,
                                      value: props.value,
                                  })
                                : React.cloneElement(props.children, {
                                      onChange,
                                      onClear,
                                      onClose,
                                      value,
                                      setFilters: props.setFilters,
                                      filters: props.filters,
                                      setFilterTitle,
                                      setShowBadge,
                                  })}
                        </StyledDropDownCard>
                    )}
                >
                    <StyledTitle>{filterTitle || ''}</StyledTitle>
                </Dropdown>
            </StyledWrapper>
        </Badge>
    );
};

MobileFilter.Tags = TagFilter;
MobileFilter.Text = TextFilter;
MobileFilter.Date = DateFilter;
MobileFilter.Selector = SelectorFilter;
MobileFilter.MultiCheckBox = MultiCheckBoxFilter;

const StyledWrapper = styled(Button)`
    display: flex;
    align-items: center;
    width: max-content;
`;

const StyledTitle = styled(Typography.Text)`
    margin-left: 8px;
`;

const StyledFilterButton = styled.span`
    cursor: pointer;
`;

const StyledDropDownCard = styled(Card)`
    max-width: 300px;

    .ant-card-body {
        padding: 0 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;
