import React from 'react';
import styled, { keyframes } from 'styled-components';

export const OnlineDot = () => {
    return (
        <StyledContainer>
            <StyledRing />
            <StyledCircle />
        </StyledContainer>
    );
};

const pulsate = keyframes`
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
`;

const StyledContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
`;

const StyledRing = styled.div`
    border: 3px solid #e24041;
    -webkit-border-radius: 30px;
    height: 25px;
    width: 25px;
    left: -5px;
    position: absolute;
    -webkit-animation: ${pulsate} 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0;
`;

const StyledCircle = styled.div`
    width: 15px;
    height: 15px;
    background-color: #e24041;
    border-radius: 50%;
`;
