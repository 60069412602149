import React, { FC, useEffect } from 'react';
import Quill from 'quill';
import {
    BoldOutlined,
    ItalicOutlined,
    UnderlineOutlined,
    StrikethroughOutlined,
    LinkOutlined,
} from '@ant-design/icons';
import 'quill/dist/quill.snow.css';
import styled from 'styled-components';
import { useQuill } from 'react-quilljs';

interface IProps {
    containerHeight?: number;
    value?: string;
    onChange?: (value: string) => void;
    placeholder?: string;
}

export const TextEditor: FC<IProps> = ({
    containerHeight = 230,
    value,
    onChange,
    placeholder,
}) => {
    const icons = Quill.import('ui/icons');
    icons.bold = null;
    icons.italic = null;
    icons.underline = null;
    icons.strike = null;
    icons.link = null;
    const hex = +Date.now();

    const { quillRef, quill } = useQuill({
        modules: {
            toolbar: `#toolbar${hex}`,
        },
        formats: ['bold', 'italic', 'underline', 'strike', 'link'], // Important
        placeholder: placeholder || 'Начните писать',
    });

    useEffect(() => {
        if (quill && value) {
            quill.clipboard.dangerouslyPasteHTML(value);
        }
        if (quill && onChange) {
            quill.on('text-change', () => {
                onChange(quill.root.innerHTML);
            });
        }
    }, [quill]);

    return (
        <StyledContainer containerHeight={containerHeight}>
            <div id={`toolbar${hex}`} className={'ql-toolbar ql-snow'}>
                <button className='ql-bold'>
                    <BoldOutlined />
                </button>
                <button className='ql-italic'>
                    <ItalicOutlined />
                </button>
                <button className='ql-underline'>
                    <UnderlineOutlined />
                </button>
                <button className='ql-strike'>
                    <StrikethroughOutlined />
                </button>
                <button className='ql-link'>
                    <LinkOutlined />
                </button>
            </div>

            <div ref={quillRef} />
        </StyledContainer>
    );
};

const StyledContainer = styled.div<{ containerHeight: number }>`
    width: auto;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (max-width: 768px) {
        width: auto;
    }

    .ql-toolbar {
        border: unset;
        padding: 0 0 10px 0;
    }

    .ql-container {
        min-height: ${props => props.containerHeight + 'px'};
        border: 1px dashed rgba(37, 33, 41, 0.15) !important;
        border-radius: 5px;
        resize: vertical;
        //  overflow-y: auto;
    }
`;
