import React, { FC, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { TariffPreview } from 'features/monetization/pages/tariff/views/TariffPreview';

import {
    useGetByIDQuery,
    usePayTariffMutation,
} from '../../../services/tariff.service';
import { TariffConfirmModal } from '../views/components/TariffConfirmModal';

interface IProps {
    isModalOpen: boolean;
}

interface IConfirmPayTariff {
    email: string;
    isTrial?: boolean;
}

export const TariffContainerPreview: FC<IProps> = props => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(props.isModalOpen);
    const params = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    if (props.isModalOpen && !searchParams.get('telegram_user_id')) {
        return <></>;
    }
    const [payTariff] = usePayTariffMutation();
    const { data: tariff } = useGetByIDQuery({ hash: params.id });
    const isTrial = useRef<string>();
    const onConfirmPay = async (values: IConfirmPayTariff) => {
        const redirectUrl = await payTariff({
            id: tariff.id,
            'e-mail': values.email,
            try_trial: isTrial.current || searchParams.get('try_trial') || '0',
            ...(searchParams.get('telegram_user_id') && {
                telegram_user_id: searchParams.get('telegram_user_id'),
            }),
        });
        window.location.replace((redirectUrl as any).data.redirect);
    };
    if (!params.id) {
        return <></>;
    }
    if (!tariff) {
        return <></>;
    }
    return (
        <>
            <TariffConfirmModal
                tariff={tariff}
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
                onConfirm={onConfirmPay}
                isTrial={isTrial.current || searchParams.get('try_trial')}
            />
            <TariffPreview
                tariff={tariff}
                onPay={() => {
                    isTrial.current = '0';
                    window.open(tariff.link_buy);
                }}
                onTrial={() => {
                    isTrial.current = '1';
                    setIsOpenModal(true);
                }}
            />
        </>
    );
};
