import React, { FC, Fragment, useEffect } from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useCreateReputationMutation } from 'features/communities/services';
import { createFormData } from 'utils/common/createFormData';

import { ReputationForm } from '../views/ReputationForm';
import {
    RatePeriodEnum,
    WhoCanRateEnum,
} from '../../../interfaces/reputation.interface';
import { ReputationMapper, ReputationSwitchesEnum } from '../ReputationMapper';

export interface IFormikInitialValues {
    title: string;
    keyword_rate_up: string[];
    keyword_rate_down: string[];
    switches: {
        [ReputationSwitchesEnum.whoCanRateSwitch]: {
            isChecked: boolean;
            who_can_rate?: WhoCanRateEnum;
        };
        [ReputationSwitchesEnum.restrictRatePeriodSwitch]: {
            isChecked: boolean;
            restrict_rate_member_period?: number;
        };
        [ReputationSwitchesEnum.delayStartRulesTimeSwitch]: {
            isChecked: boolean;
            delay_start_rules_seconds?: string;
            delay_start_rules_total_messages?: number;
        };
        [ReputationSwitchesEnum.userRateSwitch]: {
            isChecked: boolean;
            show_rating_tables?: boolean;
            show_rating_tables_period?: RatePeriodEnum;
            show_rating_tables_time?: string;
            show_rating_tables_number_of_users?: number;
            show_rating_tables_image?: string | File;
            show_rating_tables_message?: string;
        };
        [ReputationSwitchesEnum.notifyChangeRateSwitch]: {
            isChecked: boolean;
            notify_about_rate_change?: boolean;
            notify_about_rate_change_points?: number;
            notify_about_rate_change_image?: string | File;
            notify_about_rate_change_message?: string;
        };
        [ReputationSwitchesEnum.restrictAccumulateRateSwitch]: {
            isChecked: boolean;
            restrict_accumulate_rate?: boolean;
            restrict_accumulate_rate_time?: string;
            restrict_accumulate_rate_period?: RatePeriodEnum;
            restrict_accumulate_rate_image?: string | File;
            restrict_accumulate_rate_message?: string;
        };
    };
    selectedChatIds: number[];
}

export const ReputationContainerCreate: FC = () => {
    const { notification } = App.useApp();

    const navigate = useNavigate();

    const [createReputation, { isSuccess, error }] =
        useCreateReputationMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Сценарий создан успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any)?.data?.message || 'Что-то не так',
                placement: 'top',
            });
        }
    }, [error]);

    const initialValues: IFormikInitialValues = {
        title: '001 Правила репутации',
        keyword_rate_up: [],
        keyword_rate_down: [],
        switches: {
            [ReputationSwitchesEnum.whoCanRateSwitch]: {
                isChecked: false,
            },
            [ReputationSwitchesEnum.restrictRatePeriodSwitch]: {
                isChecked: false,
            },
            [ReputationSwitchesEnum.delayStartRulesTimeSwitch]: {
                isChecked: false,
            },
            [ReputationSwitchesEnum.userRateSwitch]: {
                isChecked: false,
            },
            [ReputationSwitchesEnum.notifyChangeRateSwitch]: {
                isChecked: false,
            },
            [ReputationSwitchesEnum.restrictAccumulateRateSwitch]: {
                isChecked: false,
            },
        },
        selectedChatIds: [],
    };
    const onSave = async (values: IFormikInitialValues) => {
        const requestObject = ReputationMapper.createDto(values);
        await createReputation(createFormData(requestObject));
    };
    return (
        <Fragment>
            <ReputationForm onSave={onSave} initialValues={initialValues} />
        </Fragment>
    );
};
