export const mediaQueryBreakpoints = {
    tablet: 768,
    desktop: 1200,
};

export type Orientations = 'mobile' | 'tablet' | 'desktop';

export const orientations: Record<Orientations, Orientations> = {
    mobile: 'mobile',
    tablet: 'tablet',
    desktop: 'desktop',
};
