import React, { Fragment, useEffect, useState } from 'react';

import {
    IStatisticFilters,
    IStatisticFinanceHistory,
} from 'features/analytics/services/interfaces';
import { useGetFinancePaymentHistoryQuery } from 'features/analytics/services';

import { FinanceTableView } from '../views/FinanceTableView';

type Props = {
    readonly filters: IStatisticFilters;
};

export const FinanceTableContainer: React.FC<Props> = props => {
    const [source, setSource] = useState<IStatisticFinanceHistory[]>([]);
    const [filters, setFilters] = useState<{
        first_name: string;
        user_name: string;
        email: string;
    }>({
        email: '',
        first_name: '',
        user_name: '',
    });

    const { data } = useGetFinancePaymentHistoryQuery({
        period: props.filters.period,
        community_id: Number(props.filters.chat) || null,
    });

    useEffect(() => {
        setSource(data);
    }, [data]);

    useEffect(() => {
        let _source = data ? [...data] : [];

        _source = _source
            .filter(item =>
                filters.first_name
                    ? new RegExp(filters.first_name, 'igm').test(
                          item.first_name
                      )
                    : true
            )
            .filter(item =>
                filters.user_name
                    ? new RegExp(filters.user_name, 'igm').test(item.user_name)
                    : true
            )
            .filter(item =>
                filters.email
                    ? new RegExp(filters.email, 'igm').test(item.email)
                    : true
            );

        setSource(_source);
    }, [filters]);

    const onFiltersUpdate = update => {
        setFilters({ ...filters, ...update });
    };

    return (
        <Fragment>
            <FinanceTableView
                filters={filters}
                onFiltersUpdate={onFiltersUpdate}
                source={source}
            />
        </Fragment>
    );
};
