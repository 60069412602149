import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { WebinarsList } from '../view/WebinarsList';

export enum WebinarType {
    ONLINE = 'online',
    PLANNED = 'planned',
    ENDED = 'ended',
}

interface IWebinar {
    readonly activeTab?: string;
    readonly setExtraButton?: (Element) => void;
    readonly extraButton?: Element;
}
export const WebinarContainer: React.FC<IWebinar> = props => {
    const { activeTab, setExtraButton } = props;
    const navigate = useNavigate();

    useEffect(() => {
        if (activeTab === 'webinars') {
            setExtraButton(
                <Button
                    disabled={false}
                    type={'primary'}
                    onClick={() => navigate(`webinar-create`)}
                >
                    Создать вебинар
                </Button>
            );
        }
    }, [activeTab]);

    return (
        <>
            {Object.entries(WebinarType).map(
                (container: [string, WebinarType], index: number) => (
                    <WebinarsList key={index} type={container[1]} />
                )
            )}
        </>
    );
};
