import React from 'react';
import styled from 'styled-components';
import { Card, Col, Row, Space, Typography } from 'antd';

import { useTheme } from '../../../../../hooks/useTheme';
import { rub } from '../../../../../shared/contants/styles';

export const FinanceStatsCard = props => {
    const token = useTheme();
    const { statistic } = props;

    return (
        <StyledCard type='inner' style={token.Card}>
            <Row wrap gutter={[20, 20]}>
                <StyledCol span={12}>
                    <Secondary>Всего</Secondary>
                    <Value>
                        {statistic?.summ === 0
                            ? statistic?.summ
                            : statistic?.summ.toFixed(2) || 0}{' '}
                        {rub}
                    </Value>
                </StyledCol>

                <StyledCol span={12}>
                    <Secondary>Донаты</Secondary>
                    <Value>
                        {statistic?.donate === 0
                            ? statistic?.donate
                            : statistic?.donate.toFixed(2) || 0}{' '}
                        {rub}
                    </Value>
                </StyledCol>

                <StyledCol span={12}>
                    <Secondary>Тарифы</Secondary>
                    <Value>
                        {statistic?.tariff === 0
                            ? statistic?.tariff
                            : statistic?.tariff.toFixed(2) || 0}{' '}
                        {rub}
                    </Value>
                </StyledCol>

                <StyledCol span={12}>
                    <Secondary>Медиатовары</Secondary>
                    <Value>
                        {statistic?.course === 0
                            ? statistic?.course
                            : statistic?.course.toFixed(2) || 0}{' '}
                        {rub}
                    </Value>
                </StyledCol>
            </Row>
        </StyledCard>
    );
};

const StyledCard = styled(Card)`
    width: 100%;
`;

const Secondary = styled(Typography.Text)`
    font-size: 0.75rem;
    font-weight: 400;
    color: rgba(37, 33, 41, 0.45);
    line-height: 1.25rem;
`;

const Value = styled(Typography.Text)`
    font-size: 1.125rem;
    color: #2f54eb;
    font-weight: 500;
`;

const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
`;
