import React, { FC, Fragment, ReactNode, useState } from 'react';
import { Button, Checkbox, Divider, Space } from 'antd';

type Props = {
    readonly children: ReactNode;
    readonly loading?: boolean;
    readonly disabled?: boolean;
    readonly onSave: () => void;
    readonly onCancel: () => void;
};

export const ConfirmBlock: FC<Props> = props => {
    const [checkBoxStatus, setCheckboxStatus] = useState(false);

    return (
        <Fragment>
            <Divider style={{ margin: '33px 0' }} />
            <Space size='large' direction='vertical'>
                <div>{props.children}</div>
                <div>
                    <Checkbox
                        checked={checkBoxStatus}
                        onChange={() => setCheckboxStatus(!checkBoxStatus)}
                    >
                        Я понимаю последствия
                    </Checkbox>
                </div>
                <Space size='large'>
                    <Button
                        loading={props.loading}
                        onClick={() => props.onSave()}
                        disabled={!checkBoxStatus || props.disabled}
                        htmlType='submit'
                    >
                        Сохранить
                    </Button>
                    <Button onClick={() => props.onCancel()}>Отмена</Button>
                </Space>
            </Space>
        </Fragment>
    );
};
