import React from 'react';
import styled from 'styled-components';

import { getSrc } from '../../../../../../../utils/common/getSrc';

type TProps = {
    src: string;
};
export const ImageView: React.FC<TProps> = props => {
    return <StyledImage src={getSrc(`/storage/${props.src}`)} alt='img' />;
};

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
