import React from 'react';
import { Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

type TProps = {
    readonly id: string;
    readonly level: number;
    readonly onExpressionAdd: (id: string) => void;
    readonly onExpressionGroupAdd: (id: string) => void;
};

const MAX_LEVEL = 3;

export const NodeActions: React.FC<TProps> = props => {
    const canCreateGroup = props.level < MAX_LEVEL;

    return (
        <StyledWrap>
            <Button
                onClick={() => {
                    props.onExpressionAdd(props.id);
                }}
                icon={<PlusOutlined />}
                type='dashed'
            >
                Добавить условие
            </Button>
            {canCreateGroup && (
                <Button
                    onClick={() => {
                        props.onExpressionGroupAdd(props.id);
                    }}
                    icon={<PlusOutlined />}
                    type='dashed'
                >
                    Добавить группу условий
                </Button>
            )}
        </StyledWrap>
    );
};

const StyledWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;
