import React from 'react';
import { Button, Empty, Table } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { formatExtended } from '../../../../../lib/date';
import { TKnowledgeTableProps } from '../../../interfaces/knowledge.interface';
import getEnvConfig from '../../../../../utils/config/env';

export const KnowledgeTable: React.FC<TKnowledgeTableProps> = props => {
    const navigate = useNavigate();
    const rowSelection = {
        selectedRows: props.selectedRows,
        onChange: props.setSelectedRows,
    };
    const columns = [
        {
            title: 'Название базы знаний',
            dataIndex: 'name',
            ellipsis: true,
            width: 250,
            render: (source, knowledge) => (
                <Button
                    style={{ paddingLeft: 0 }}
                    type='link'
                    onClick={() => {
                        navigate(
                            `${
                                getEnvConfig().PUBLIC_PATH
                            }/communities/knowledge/${knowledge.id}`
                        );
                    }}
                >
                    {knowledge.name}
                </Button>
            ),
        },
        {
            title: 'Дата добавления',
            dataIndex: 'updated_at',
            render: (updated_at: string) => formatExtended(updated_at),
            sorter: (a, b) => {
                const startDate = Math.floor(new Date(a.updated_at).getTime());
                const endDate = Math.floor(new Date(b.updated_at).getTime());
                return startDate - endDate;
            },
        },
        {
            title: 'Статус',
            render: () => 'Опубликована',
        },
        {
            title: 'Вопросов',
            dataIndex: 'questions_count',
            sorter: (a, b) => a.questions_count - b.questions_count,
        },
        {
            title: 'Действия',
            render: knowledge => (
                <Button
                    style={{ paddingLeft: 0 }}
                    type='link'
                    onClick={() => props.deleteKnowledge(knowledge.id)}
                >
                    Удалить
                </Button>
            ),
        },
    ];

    return (
        <StyledTable
            scroll={{ x: 900 }}
            loading={props.loading}
            rowSelection={rowSelection}
            locale={{ emptyText: <Empty description='Нет базы знаний' /> }}
            dataSource={props.source?.items}
            columns={columns}
            rowKey={(knowledge: { id: number }) => knowledge.id}
            pagination={{
                total: props.source.total,
                showSizeChanger: true,
                showTotal: () => `Баз знаний: ${props.source.total}`,
                onChange: (page, pageSize) => {
                    props.onFiltersUpdate({
                        offset: (page - 1) * pageSize,
                        limit: pageSize,
                    });
                },
                onShowSizeChange: (current, size) => {
                    props.onFiltersUpdate({
                        offset: (current - 1) * size,
                        limit: size,
                    });
                },
            }}
        />
    );
};

const StyledTable = styled(Table)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;
