import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';

import { QuestionForm } from '../views/QuestionForm';
import { createFormData } from '../../../../../utils/common/createFormData';
import {
    useReadQuestionByIdQuery,
    useUpdateQuestionMutation,
} from '../../../services';
import { getSrc } from '../../../../../utils/common/getSrc';
import {
    IQuestionCreate,
    QuestionStatus,
} from '../../../interfaces/questions.interface';

export const AiQuestionEditContainer: FC = () => {
    const { id } = useParams();
    const { notification } = App.useApp();

    const navigate = useNavigate();
    const [updateQuestion, { isSuccess, error, isLoading }] =
        useUpdateQuestionMutation();

    const [initialValues, setInitialValues] = useState<IQuestionCreate | any>(
        null
    );
    const {
        data,
        isFetching,
        isSuccess: isSuccessRead,
    } = useReadQuestionByIdQuery(id);
    useEffect(() => {
        if (isSuccessRead) {
            setInitialValues({
                knowledge_id: data.items.knowledge_id,
                category_id: data.items.category_id,
                category_name: data.items.category_name,
                question_status: data.items.status,
                question_text: data.items.context,
                question_image: getSrc(data.items?.image),

                answer_text: data.items.answer.context,
                answer_image: getSrc(data.items.answer?.image),
            });
        }
    }, [isSuccessRead]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Вопрос успешно изменен',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    const onSave = async (values: IQuestionCreate) => {
        const requestObject = async () => {
            const data = {
                knowledge_id: values.knowledge_id,
                question_status: QuestionStatus.PUBLISHED,
                category_id: values.category_id,
                question_text: values.question_text,
                answer_text: values.answer_text,

                question_image: values.question_image as File,
                answer_image: values.answer_image as File,
            };

            if (!(values.question_image instanceof File)) {
                delete data.question_image;
            }
            if (!(values.answer_image instanceof File)) {
                delete data.answer_image;
            }
            if (!values.category_id) delete data.category_id;

            await updateQuestion({
                id: Number(id),
                body: createFormData(data),
            });
        };
        await requestObject();
    };

    if (!id && !data) {
        return <></>;
    }

    const isAi = true;

    return (
        initialValues && (
            <QuestionForm
                isAi={isAi}
                initialValues={initialValues}
                onSave={onSave}
                loading={isLoading}
            />
        )
    );
};
