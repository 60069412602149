import styled from 'styled-components';
import { useState } from 'react';

import { ICommunityQueryParams } from 'features/communities/interfaces';
import { useReadChatsQuery } from 'features/communities/services';

import { initialFilters } from './utils/filters';
import { ChatsContainer } from './containers/ChatsContainer';
import { AddCommunity } from './components';

export const Chats = () => {
    const [filters, setFilters] =
        useState<ICommunityQueryParams>(initialFilters);

    const {
        data: source = { items: [], total: 0 },
        isFetching,
        refetch,
    } = useReadChatsQuery(filters);

    const handleFiltersUpdate = (params?: ICommunityQueryParams | null) => {
        if (params) {
            setFilters({
                ...filters,
                ...params,
            });
        } else {
            setFilters(initialFilters);
        }
    };

    return (
        <StyledWrapper>
            <AddCommunity refetch={refetch} loading={isFetching} />
            <ChatsContainer
                setFilters={setFilters}
                onFiltersUpdate={handleFiltersUpdate}
                filters={filters}
                source={source}
                loading={isFetching}
            />
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
