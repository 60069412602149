import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Divider, Select, Space, Typography } from 'antd';

import { RulesDict } from 'features/communities/interfaces/conditional-rules.interface';

import { RuleTree } from './Tree/renderTree';
import { ITree, Tree } from './Tree/tree';

type TProps = {
    readonly tree?: ITree;
    readonly dict: RulesDict;
    readonly onChage?: (tree: ITree) => void;
};

const useForceRender = () => {
    const [k, i] = useState(0);
    const rerender = () => {
        i(k + 1);
    };
    return [k, rerender] as const;
};

export const RuleEditor: React.FC<TProps> = props => {
    const tree = useRef(new Tree(props.tree));

    useEffect(() => {
        tree.current = new Tree(props.tree);
        rerender();
    }, [props.tree]);

    const [key, rerender] = useForceRender();

    const onChange = (): void => {
        const result = JSON.parse(
            JSON.stringify(tree.current, (key, value) => {
                if (key === 'id') return undefined;
                if (key === 'root') return undefined;
                if (key === 'children' && value.length === 0) return undefined;
                return value;
            })
        );
        props.onChage(result);
    };

    const onExpressionAdd = (rootId: string) => {
        const rootNode = tree.current.findNode(rootId);
        rootNode.attachNode({ type: 'EXPRESSION' });
        onChange();
    };

    const onExpressionGroupAdd = (rootId: string) => {
        const rootNode = tree.current.findNode(rootId);
        rootNode.attachNode({
            type: 'AND',
            children: [
                {
                    type: 'EXPRESSION',
                },
                {
                    type: 'EXPRESSION',
                },
            ],
        });
        onChange();
    };

    const onDelete = (node_id: string) => {
        tree.current.deleteNode(node_id);
        onChange();
    };

    const onConditionChange = (args: ITree) => {
        tree.current.toggleCondition(args.root);
        onChange();
    };

    const onExpressionUpdate = (
        node_id: string,
        args: {
            subject: string;
            action: string;
            value: string;
        }
    ) => {
        tree.current.updateNode(node_id, args);
        onChange();
    };

    const onDublicate = (node_id: string) => {
        tree.current.dublicate(node_id);
        onChange();
    };

    const toGroup = (node_id: string) => {
        tree.current.toGroup(node_id);
        onChange();
    };

    if (!props.dict) return null;

    return (
        <Fragment>
            <RuleTree
                key={key}
                level={1}
                onExpressionAdd={onExpressionAdd}
                onExpressionGroupAdd={onExpressionGroupAdd}
                onConditionChange={onConditionChange}
                onExpressionUpdate={onExpressionUpdate}
                tree={tree.current}
                dict={props.dict}
                onDelete={onDelete}
                onDublicate={onDublicate}
                toGroup={toGroup}
            />
        </Fragment>
    );
};
