import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { App } from 'antd';

import { WebinarForm } from '../view/WebinarForm';
import {
    useUpdateWebinarMutation,
    useReadWebinarByIdQuery,
} from '../../../../../services/webinars';
import { getSrc } from '../../../../../../../utils/common/getSrc';
import { createFormData } from '../../../../../../../utils/common/createFormData';

export const WebinarEditContainer = () => {
    const { notification } = App.useApp();
    const { id } = useParams();
    const navigate = useNavigate();

    const [initial, setInitial] = useState<any>();

    const [updateWebinar, { isSuccess: isSuccessUpdate }] =
        useUpdateWebinarMutation();
    const { data: webinar, isSuccess } = useReadWebinarByIdQuery(id);

    useEffect(() => {
        if (isSuccess) {
            setInitial({
                title: webinar.title,
                description: webinar.description,
                background_image:
                    !!webinar.background_image &&
                    getSrc(`storage/${webinar.background_image}`),
                start_at: webinar.start_at,
                end_at: webinar.end_at,
                price: webinar.price,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccessUpdate) {
            notification.success({
                message: 'Вебинар успешно изменен',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccessUpdate]);

    const onSave = async values => {
        const data = {
            title: values.title,
            description: values.description,
            background_image: values.background_image,
            start_at: values.start_at,
            end_at: values.end_at,
            price: values.price,
        };
        if (!(values.background_image instanceof File)) {
            delete data.background_image;
        }
        if (!values.description) delete data.description;

        await updateWebinar({ id: Number(id), body: createFormData(data) });
    };

    const formik = useFormik({
        onSubmit: onSave,
        initialValues: initial,
    });

    if (!id && !webinar) {
        return <></>;
    }

    return (
        initial && (
            <WebinarForm
                formik={formik}
                onSave={onSave}
                initialValues={initial}
            />
        )
    );
};
