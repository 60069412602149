import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Card, Typography, Button, App, Row } from 'antd';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import { useToggle } from '../../../../../../hooks/useToggle';
import { AuthorForm } from '../../view/AuthorForm';
import { createFormData } from '../../../../../../utils/common/createFormData';
import { useCreateAuthorMutation } from '../../../../services/author';
import { useReadUserQuery } from '../../../../../user/services';

export const AuthorCreateContainer = () => {
    const { notification } = App.useApp();
    const navigate = useNavigate();
    const { id } = useParams();

    const { open, setOpen } = useToggle(false);
    const toggle = () => setOpen((x: boolean) => !x);

    const [createAuthor, { isSuccess, isLoading, data, isError }] =
        useCreateAuthorMutation();
    const { refetch } = useReadUserQuery();

    const onSave = async values => {
        await createAuthor(createFormData(values));
    };

    const initialValues = {
        name: '',
        about: '',
        photo: null,
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSave,
    });

    useEffect(() => {
        if (isSuccess && data?.author_id) {
            setOpen(false);
            navigate(`/courses/author/${data.author_id}`);
            notification.success({
                message: `Автор  успешно создан`,
                placement: 'top',
            });
            refetch();
        }
    }, [isSuccess, data]);

    return (
        <>
            <Card hoverable>
                <StyledWrapper>
                    <StyledDescription>
                        <StyledTitle level={2}>Страница автора</StyledTitle>
                        <Typography.Text>
                            Вы еще не создали страницу автора
                        </Typography.Text>
                        <Button
                            disabled={isLoading}
                            type={'primary'}
                            style={{
                                maxWidth: 'max-content',
                                marginTop: '1em',
                            }}
                            onClick={toggle}
                        >
                            Создать страницу автора
                        </Button>
                    </StyledDescription>
                </StyledWrapper>
            </Card>

            <AuthorForm
                formik={formik}
                initialValues={initialValues}
                isCreate={id === undefined}
                open={open}
                onSave={onSave}
                setOpen={setOpen}
            />
        </>
    );
};

const StyledWrapper = styled(Row)`
    display: flex;
    flex-direction: column;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 0;
`;

const StyledDescription = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
