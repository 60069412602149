import React, { FC, useState } from 'react';
import { Space, Switch, Typography } from 'antd';
import styled from 'styled-components';

import { TimePickerFormik } from 'shared/ui/Form/Fields/TimePickerFormik';

import { ReputationSwitchesEnum } from '../../ReputationMapper';

interface IProps {
    formik: any;
}

export const RestrictRatePeriodSwitch: FC<IProps> = props => {
    return (
        <StyledSpace align='start' size='small'>
            <StyledSwitch
                checked={
                    props.formik.values.switches[
                        ReputationSwitchesEnum.restrictRatePeriodSwitch
                    ].isChecked
                }
                onChange={value => {
                    props.formik.setFieldValue(
                        `switches.${ReputationSwitchesEnum.restrictRatePeriodSwitch}.isChecked`,
                        value
                    );
                }}
            />
            <Space direction='vertical'>
                <StyledTitle level={5}>
                    Ограничить время влияния на репутацию
                </StyledTitle>
                {props.formik.values.switches[
                    ReputationSwitchesEnum.restrictRatePeriodSwitch
                ].isChecked && (
                    <>
                        <Typography.Text>
                            Пользователь может влиять на репутацию другого
                            пользователя раз в
                        </Typography.Text>
                        <div>
                            <TimePickerFormik
                                formik={props.formik}
                                fieldName={`switches.${ReputationSwitchesEnum.restrictRatePeriodSwitch}.restrict_rate_member_period`}
                            />
                        </div>
                    </>
                )}
            </Space>
        </StyledSpace>
    );
};

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledSpace = styled(Space)`
    margin-bottom: 32px;
`;
