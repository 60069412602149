import React, { Fragment } from 'react';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { Dropdown } from 'shared/ui/Dropdown';

import { conditionMapper } from './dict';

const getReveredVerb = (action: string): string =>
    Object.entries(conditionMapper)
        .filter(([key, _]) => key !== action)
        .map(([_, value]) => value)[0];

type TProps = {
    readonly type: string;
    readonly index: number;
    readonly onConditionChange: () => void;
};

export const NodeDescription: React.FC<TProps> = props => {
    return (
        <StyledCondition>
            {props.index === 1 && (
                <Dropdown
                    dropdownContent={() => (
                        <Button
                            onClick={() => props.onConditionChange()}
                            type='ghost'
                        >
                            {getReveredVerb(props.type)}
                        </Button>
                    )}
                >
                    <Fragment>
                        <span style={{ marginRight: '6px' }}>
                            {conditionMapper[props.type]}
                        </span>
                        <DownOutlined />
                    </Fragment>
                </Dropdown>
            )}
            {props.index > 1 && <span>{conditionMapper[props.type]}</span>}
            {props.index === 0 && <span>Если</span>}
        </StyledCondition>
    );
};

const StyledCondition = styled.span`
    display: inline-block;
    margin-right: 8px;
    text-align: right;
    min-width: 55px;
`;
