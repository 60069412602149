import React, { Fragment } from 'react';

import { useReadChatsQuery } from 'features/communities/services';

import { ChatListTable } from '../views/ChatListTable';

type Props = {
    readonly filters: any;
    readonly onFiltersUpdate: (update) => void;
};

export const ChatListContainer: React.FC<Props> = props => {
    const { data: chats = { items: [], total: 0 }, isFetching } =
        useReadChatsQuery({ tags_names: props.filters.tags_names });

    return (
        <Fragment>
            <ChatListTable
                loading={isFetching}
                filters={props.filters}
                onFiltersUpdate={props.onFiltersUpdate}
                source={chats}
                onSelect={() => {}}
            />
        </Fragment>
    );
};
