import { Avatar, Space, Table } from 'antd';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { IStatisticsMembers } from 'features/analytics/services/interfaces';
import { TableFilter } from 'shared/ui/TableFilter';

type Props = {
    readonly source: IStatisticsMembers['members'];
    readonly filters: any;
    readonly onFiltersUpdate: (update) => void;
};

export const UsersTableView: React.FC<Props> = props => {
    return (
        <Fragment>
            <StyledTable
                dataSource={props.source}
                rowKey={user => user.nick_name}
                pagination={{
                    total: props.source?.length,
                    showTotal: () => `Участников: ${props.source?.length}`,
                }}
            >
                <Table.Column
                    title={
                        <TableFilter<string>
                            title='Имя'
                            onChange={first_name => {
                                props.onFiltersUpdate({ first_name });
                            }}
                            value={props.filters.first_name}
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    render={member => (
                        <Space>
                            {member.image && <Avatar src={member.image} />}
                            {!member.image && (
                                <Avatar>{member.name.charAt(0)}</Avatar>
                            )}
                            <span>{member.name}</span>
                        </Space>
                    )}
                />
                <Table.Column
                    title={
                        <TableFilter<string>
                            title='Юзренейм'
                            onChange={nick_name => {
                                props.onFiltersUpdate({ nick_name });
                            }}
                            value={props.filters.nick_name}
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    dataIndex={'nick_name'}
                    render={name => name ?? `@${name}`}
                />
                <Table.Column
                    title='Дата и время вступления'
                    defaultSortOrder='descend'
                    sortDirections={['ascend', 'descend']}
                    sorter={(a: any, b: any) =>
                        a.accession_date - b.accession_date
                    }
                    render={user =>
                        dayjs
                            .unix(user.accession_date)
                            .format('DD.MM.YY, HH:mm')
                    }
                />
            </StyledTable>
        </Fragment>
    );
};

const StyledTable = styled(Table<any>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;
