import React, { Fragment, useState } from 'react';
import { Button, Typography, Upload } from 'antd';
import { PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { IPartProps } from '../../../../../interfaces/publications.interface';
import { VideoView } from './VideoView';

export const Video: React.FC<IPartProps> = props => {
    const { order, setPostContent, postContent, content } = props;

    const [value, setValue] = useState(content);

    const handleChange = (source, index) => {
        setValue(source.file);

        setPostContent(
            postContent.map(elem =>
                elem.order === index
                    ? { ...elem, file: source.file }
                    : { ...elem }
            )
        );
    };

    return (
        <StyledWrapper>
            {value?.name ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <PaperClipOutlined />
                    <Upload
                        accept={'mp4'}
                        maxCount={1}
                        listType='picture'
                        showUploadList={false}
                        beforeUpload={() => false}
                        onChange={value => {
                            handleChange(value, order);
                        }}
                    >
                        {value.name}
                    </Upload>
                </div>
            ) : (
                <Fragment>
                    {content ? (
                        <div>
                            <PaperClipOutlined />
                            <Button type={'text'}>
                                <Upload
                                    accept={'mp4'}
                                    maxCount={1}
                                    listType='picture'
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    onChange={value => {
                                        handleChange(value, order);
                                    }}
                                >
                                    {content}
                                </Upload>
                            </Button>
                        </div>
                    ) : (
                        <>
                            <Upload
                                accept={'mp4'}
                                maxCount={1}
                                listType='picture'
                                showUploadList={false}
                                beforeUpload={() => false}
                                onChange={value => {
                                    handleChange(value, order);
                                }}
                            >
                                <Button icon={<UploadOutlined />}>
                                    Загрузить видео
                                </Button>
                            </Upload>

                            <StyledHint type={'secondary'}>
                                Загрузите видео в формате MP4
                            </StyledHint>
                        </>
                    )}
                </Fragment>
            )}
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
`;

const StyledHint = styled(Typography.Text)`
    margin-top: 0.5rem;
`;
