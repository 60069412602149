import { notification, UploadFile } from 'antd';
export const validateImages = (blob: File | UploadFile<any>): boolean => {
    const isJpgOrPng = blob.type === 'image/jpeg' || blob.type === 'image/png';
    if (!isJpgOrPng) {
        notification.error({
            message: 'Можно загрузить только формат JPG/PNG',
            placement: 'top',
        });
        return false;
    }
    const isLt2M = blob.size / 1024 / 1024 < 6;
    if (!isLt2M) {
        notification.error({
            message: 'Изображение должно весить не более 6MB',
            placement: 'top',
        });
        return false;
    }
    return true;
};
