import React, { Fragment, useEffect, useState } from 'react';
import { App, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
    useReadChatMembersQuery,
    useAddMemberToListMutation,
    useRemoveUserFromChatMutation,
    useRemoveMemberFromListMutation,
} from 'features/communities/services';
import type {
    IChatMember,
    IChatMemberQueryParams,
} from 'features/communities/interfaces';

import { ChatMemberDetailsTable } from '../views/ChatMemberDetailsTable';
import getEnvConfig from '../../../../../utils/config/env';

export const ChatMemberDetailsPage: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<IChatMemberQueryParams>({});
    const [source, setSource] = useState<IChatMember>({
        communities: [],
    } as IChatMember);
    const { notification } = App.useApp();

    const [addMemberToList, { error, isError, isLoading }] =
        useAddMemberToListMutation();
    const [removeMemberFromList] = useRemoveMemberFromListMutation();

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Ошибка',
                description: (error as any).data.message,
                placement: 'top',
            });
        }
    }, [isError, error]);

    const { data: members = { items: [], total: 0 } } = useReadChatMembersQuery(
        {
            telegram_id: params.id,
        }
    );

    const [deleteUserFromChat] = useRemoveUserFromChatMutation();

    const handleFiltersUpdate = (update: IChatMemberQueryParams | null) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({});
        }
    };

    const onBulkRemove = async (chats: number[]) => {
        await Promise.all(
            chats.map(community_id =>
                deleteUserFromChat({
                    community_id,
                    telegram_id: source.telegram_id,
                })
            )
        );
        if (source.communities.length === 1) {
            navigate(-1);
        }
    };

    useEffect(() => {
        if (members.items.length > 0) {
            setSource(members.items[0]);
        }
    }, [members.items]);

    const handleListChange = args => {
        if (args.list === 'kick' && source.communities.length === 1) {
            navigate(
                `${getEnvConfig().PUBLIC_PATH}/communities/members/all-members`
            );
        }

        if (args.list === 'none') {
            const payload = Object.assign(
                {},
                {
                    community_ids: args.community_ids,
                    telegram_id: members.items[0].telegram_id,
                    whitelisted: false,
                    muted: false,
                    banned: false,
                }
            );
            if (args.list === 'whitelisted') {
                payload.whitelisted = true;
            }
            if (args.list === 'muted') {
                payload.muted = true;
            }
            if (args.list === 'banned') {
                payload.banned = true;
            }
            removeMemberFromList(payload);
            notification.success({
                message: 'Успешно удален из списка',
            });
            navigate(-1);
        } else {
            const payload = Object.assign(
                {},
                {
                    community_ids: args.community_ids,
                    telegram_id: members.items[0].telegram_id,
                    whitelisted: false,
                    muted: false,
                    banned: false,
                },
                {
                    [args.list]: true,
                }
            );
            addMemberToList(payload);
            notification.success({
                message: 'Успешно добавлен в список',
            });
            navigate(-1);
        }
    };

    return (
        <Fragment>
            <SectionHeader>
                <ButtonBack onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined />
                </ButtonBack>
                <StyledTitle level={4}>{members.items[0]?.name}</StyledTitle>
            </SectionHeader>
            <ChatMemberDetailsTable
                handleListChange={handleListChange}
                onBulkRemove={onBulkRemove}
                filters={filters}
                onFiltersUpdate={handleFiltersUpdate}
                loading={isLoading}
                source={source}
            />
        </Fragment>
    );
};

const SectionHeader = styled.div`
    display: flex;
    margin: 32px 0;
`;

const ButtonBack = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
    cursor: pointer;
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;
