import { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import { CoursesLayout } from '../../shared/layouts';
import { AuthorContainer } from './components/Author/containers/author/AuthorContainer';
import { PostContainer } from './components/Author/containers/post/PostContainer';
import { PostCreateContainer } from './components/Author/containers/post/PostCreateContainer';
import { WebinarContainer } from './components/Author/containers/webinar/containers/WebinarContainer';
import { WebinarCreateContainer } from './components/Author/containers/webinar/containers/WebinarCreateContainer';
import { WebinarInfo } from './components/Author/containers/webinar/view/WebinarInfo';
import { WebinarEditContainer } from './components/Author/containers/webinar/containers/WebinarEditContainer';
import { MemberContainer } from './components/Member/containers/MemberContainer';
import { AuthorCreateContainer } from './components/Author/containers/author/AuthorCreateContainer';
import { PostEditContainer } from './components/Author/containers/post/PostEditContainer';
import { AuthorPublicContainer } from './components/Author/containers/author/public/AuthorPublicContainer';
import { PostMemberContainer } from './components/Member/containers/PostMemberContainer';
import { WebinarPublicInfo } from './components/Author/containers/webinar/containers/public/WebinarPublicInfo';
import analytics from '../courses/components/Analytics/routes';
import recommendation from './components/Recommendation';

const routes: RouteObject[] = [
    {
        path: 'author',
        element: <CoursesLayout />,
        children: [
            {
                path: ':id',
                element: <AuthorPublicContainer />,
                children: [],
            },
            /*{
                path: 'webinar-preview/:uuid',
                element: <WebinarPublicInfo />,
            },*/
        ],
    },
    {
        path: 'courses',
        element: <CoursesLayout />,
        children: [
            {
                path: 'member',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <MemberContainer />,
                    },
                    {
                        path: 'post/:uuid',
                        element: <PostMemberContainer />,
                    },
                    {
                        path: 'webinar-preview/:uuid',
                        element: <WebinarPublicInfo />,
                    },
                ],
            },
            {
                path: 'webinars',
                element: <WebinarContainer />,
            },
            ...analytics,
            ...recommendation.routes,
            {
                path: 'author/create',
                element: <AuthorCreateContainer />,
            },

            {
                path: 'author/:id',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <AuthorContainer />,
                    },
                    {
                        path: 'post/:id',
                        element: <PostContainer />,
                    },
                    {
                        path: 'post-create/:id',
                        element: <PostCreateContainer />,
                    },
                    {
                        path: 'post-edit/:id',
                        element: <PostEditContainer />,
                    },
                    {
                        path: 'webinar-create',
                        element: <WebinarCreateContainer />,
                    },
                    {
                        path: 'webinar-edit/:id',
                        element: <WebinarEditContainer />,
                    },
                    {
                        path: 'webinar-preview/:id',
                        element: <WebinarInfo />,
                    },
                    {
                        path: ':id/webinar-edit/:id',
                        element: <WebinarEditContainer />,
                    },
                    /* {
                        path: 'webinar-preview:id/webinar-edit/:id',

                        children: [
                            {
                                path: ':id',
                                element: <WebinarInfo />,
                            },
                            {
                                path: ':id/webinar-edit/:id',
                                element: <WebinarEditContainer />,
                            },
                        ],
                    },*/
                ],
            },
        ],
    },
];

export default routes;
