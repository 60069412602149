export const allOk = [
    { name: 'Всё устраивает', isOk: true },
    { name: 'Не устраивает', isOk: false },
];
export const whatToDelete = [
    {
        name: 'Лучше вообще не публиковать ничего на эту тему. (Неинтересно)',
        value: 'not_interesting',
    },
    { name: 'Меньше аудио', value: 'less_audio' },
    { name: 'Меньше видео', value: 'less_video' },
    { name: 'Меньше текста', value: 'less_text' },
    { name: 'Меньше картинок', value: 'less_images' },
    { name: 'Меньше вебинаров на эту тему', value: 'less_webinars' },
];

export const whatToAdd = [
    { name: 'Добавить аудио/видео', value: 'add_audio_video' },
    { name: 'Добавить картино', value: 'add_images' },
    { name: 'Добавить текстовых описаний', value: 'add_text' },
    { name: 'Провести вебинар на эту тему', value: 'make_webinar' },
];
