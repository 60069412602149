export interface Filters {
    readonly limit: number;
    readonly offset: number;
    readonly page: number;
    search?: string;
    sort_field?: string;
    sort_direction?: string;
}
export const initialFilters: Filters = {
    limit: 10,
    offset: 0,
    page: 1,
};
