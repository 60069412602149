import { useEffect, useState } from 'react';

import {
    mediaQueryBreakpoints,
    orientations,
    Orientations,
} from 'shared/contants/orientations';

export const useOrientations = (): Orientations => {
    const [orientation, setOrientation] = useState(orientations.desktop);

    const onResize = () => {
        const windowWidth = document.documentElement.clientWidth;
        if (windowWidth <= mediaQueryBreakpoints.tablet) {
            setOrientation(orientations.mobile);
        }
        if (
            windowWidth >= mediaQueryBreakpoints.tablet &&
            windowWidth <= mediaQueryBreakpoints.desktop
        ) {
            setOrientation(orientations.tablet);
        }
        if (windowWidth > mediaQueryBreakpoints.desktop) {
            setOrientation(orientations.desktop);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        if (typeof window !== 'undefined') {
            onResize();
        }
        return () => window.removeEventListener('resize', onResize);
    }, []);

    return orientation;
};
