import React, { Fragment, useEffect, useState } from 'react';
import { App, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
    useReadChatMembersQuery,
    useAddMemberToListMutation,
    useRemoveMemberFromListMutation,
} from 'features/communities/services';
import type {
    IChatMember,
    IChatMemberQueryParams,
} from 'features/communities/interfaces';

import { ChatMemberBlacklistDetailsTable } from '../views/ChatMemberBlacklistDetailsTable';

export const ChatMemberBlackListDetailsPage: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<IChatMemberQueryParams>({
        banned: true,
    });
    const [source, setSource] = useState<IChatMember>({
        communities: [],
    } as IChatMember);
    const { notification } = App.useApp();

    const [addMemberToList, { error, isError, isLoading: isAddLoading }] =
        useAddMemberToListMutation();

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Ошибка',
                description: (error as any).data.message,
                placement: 'top',
            });
        }
    }, [isError, error]);

    const {
        data: members = { items: [], total: 0 },
        isFetching: isReadLoading,
    } = useReadChatMembersQuery({
        ...filters,
        telegram_id: params.id,
    });

    const [removeFromlist, { isLoading: isRemoveLoading }] =
        useRemoveMemberFromListMutation();

    const handleFiltersUpdate = (update: IChatMemberQueryParams | null) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({});
        }
    };

    const onBulkRemove = async (chats: number[]) => {
        console.log(chats);
        const options = {
            banned: true,
            muted: false,
            whitelisted: false,
            is_spammer: 0,
        };

        const payload = {
            telegram_id: source.telegram_id,
            community_ids: chats,
            ...options,
        };

        await removeFromlist(payload);
    };

    useEffect(() => {
        if (members.items.length > 0) {
            setSource(members.items[0]);
        }
    }, [members.items.length]);

    return (
        <Fragment>
            <SectionHeader>
                <ButtonBack onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined />
                </ButtonBack>
                <StyledTitle level={4}>{members.items[0]?.name}</StyledTitle>
            </SectionHeader>
            <StyledTableTitle>ЧС в Чатах</StyledTableTitle>
            <ChatMemberBlacklistDetailsTable
                onBulkRemove={onBulkRemove}
                filters={filters}
                onFiltersUpdate={handleFiltersUpdate}
                loading={isAddLoading || isReadLoading || isRemoveLoading}
                source={source}
            />
        </Fragment>
    );
};

const SectionHeader = styled.div`
    display: flex;
    margin: 32px 0;
`;

const ButtonBack = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
    cursor: pointer;
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;
const StyledTableTitle = styled.div`
    font-weight: 600;
    font-size: 20px;
`;
