import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { App } from 'antd';

import { WebinarForm } from '../view/WebinarForm';
import { useCreateWebinarMutation } from '../../../../../services/webinars';
import { createFormData } from '../../../../../../../utils/common/createFormData';
import { IWebinarCreate } from '../../../../../interfaces/webinars.interface';

export const WebinarCreateContainer = () => {
    const { notification } = App.useApp();

    const [initial, setInitial] = useState<any>();

    const navigate = useNavigate();

    const [createWebinar, { isError, error, data, isSuccess, isLoading }] =
        useCreateWebinarMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: `Вебинар ` + data.title + ' успешно создан',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            notification.error({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                message: error?.data?.message,
            });
        }
    }, [isError]);

    useEffect(() => {
        setInitial({
            title: 'Новый вебинар',
            description: '',
            start_at: null,
            end_at: null,
            background_image: null,
            price: null,
        });
    }, []);

    const onSave = async (values: IWebinarCreate) => {
        if (!values.title || values.title === '') {
            return notification.error({
                message: 'Нужно заполнить название вебинара',
            });
        }

        const data = {
            title: values.title,
            description:
                !values.description || values.description === 'null'
                    ? null
                    : values.description,
            start_at: values.start_at,
            end_at: values.end_at,
            background_image: values.background_image,
            price: values.price,
        };
        if (!values.description) delete data.description;
        if (!values.background_image) delete data.background_image;
        if (!values.price) delete data.price;

        await createWebinar(createFormData(data));
    };

    const formik = useFormik({
        onSubmit: onSave,
        initialValues: initial,
    });

    return (
        initial && (
            <WebinarForm
                formik={formik}
                onSave={onSave}
                initialValues={initial}
                loading={isLoading}
            />
        )
    );
};
