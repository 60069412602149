import React, { FC, Fragment, ReactNode, useState } from 'react';
import { Button, Checkbox, Divider, Space } from 'antd';

type Props = {
    readonly children: ReactNode;
    readonly checkBoxStatus: boolean;
    readonly setCheckboxStatus: (boolean) => void;
};

export const Confirm: FC<Props> = props => {
    return (
        <Fragment>
            <Divider style={{ margin: '33px 0' }} />
            <Space size='large' direction='vertical'>
                <div>{props.children}</div>
                <div>
                    <Checkbox
                        checked={props.checkBoxStatus}
                        onChange={() =>
                            props.setCheckboxStatus(!props.checkBoxStatus)
                        }
                    >
                        Я понимаю последствия
                    </Checkbox>
                </div>
            </Space>
        </Fragment>
    );
};
