import React from 'react';
import Chart, { Props as ApexProps } from 'react-apexcharts';

import { useGetModerationQuery } from 'features/analytics/services';
import { IStatisticFilters } from 'features/analytics/services/interfaces';

import { ChartWrapper } from '../ChartWrapper';

type Props = {
    readonly filters: IStatisticFilters;
    readonly height?: number;
};

export const ModerationChart: React.FC<Props> = props => {
    const {
        data: moderation = {
            banned: 0,
            kicked: 0,
            muted: 0,
            violations: 0,
        },
        isFetching,
    } = useGetModerationQuery(
        {
            community_id: Number(props.filters.chat) || null,
            period: props.filters.period,
        },
        { refetchOnMountOrArgChange: true }
    );

    const options: ApexProps['options'] = {
        labels: ['Нарушение', 'Мьют', 'Бан', 'Кик'],
        legend: {
            show: true,
            position: 'left',
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            },
        },
        theme: {
            monochrome: {
                enabled: true,
                color: '#597EF7',
            },
        },
    };

    return (
        <ChartWrapper isLoading={isFetching} title='Модерация'>
            <Chart
                height={props.height}
                type='donut'
                options={options}
                series={[
                    moderation.violations,
                    moderation.muted,
                    moderation.banned,
                    moderation.kicked,
                ]}
            />
        </ChartWrapper>
    );
};
