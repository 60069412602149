import React, { Fragment, useState } from 'react';
import { Button, Upload, Typography } from 'antd';
import { UploadOutlined, PaperClipOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { IPartProps } from '../../../../../interfaces/publications.interface';

export const Audio: React.FC<IPartProps> = props => {
    const { order, setPostContent, postContent, content } = props;

    const [value, setValue] = useState(content);
    const handleChange = (source, index) => {
        setValue(source.file);
        setPostContent(
            postContent.map(elem =>
                elem.order === index
                    ? { ...elem, file: source.file }
                    : { ...elem }
            )
        );
    };

    return (
        <StyledWrapper>
            {value?.name ? (
                <div>
                    <PaperClipOutlined />
                    <Button type='text'>{value.name}</Button>
                </div>
            ) : (
                <Fragment>
                    {content ? (
                        <div>
                            <PaperClipOutlined />
                            <Button type={'text'}>
                                <Upload
                                    accept={'mp4'}
                                    maxCount={1}
                                    listType='picture'
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    onChange={value => {
                                        handleChange(value, order);
                                    }}
                                >
                                    {content}
                                </Upload>
                            </Button>
                        </div>
                    ) : (
                        <>
                            <Upload
                                maxCount={1}
                                listType='picture'
                                showUploadList={false}
                                beforeUpload={() => false}
                                onChange={value => {
                                    handleChange(value, order);
                                }}
                            >
                                <Button icon={<UploadOutlined />}>
                                    Загрузить аудио
                                </Button>
                            </Upload>

                            <StyledHint type={'secondary'}>
                                Аудиофайл не должен превышать 2 МБ и должен быть
                                в формате MP3.
                            </StyledHint>
                        </>
                    )}
                </Fragment>
            )}
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
`;

const StyledHint = styled(Typography.Text)`
    margin-top: 0.5rem;
`;
