import qs from 'query-string';

import getEnvConfig from 'utils/config/env';

import { IStatisticFilters } from '../services/interfaces';

export type AnalyticsQueryExport =
    | 'moderation'
    | 'members'
    | 'messages'
    | 'finance'
    | 'all';

export const downloadAnalytics = async (
    filters: IStatisticFilters,
    type: 'csv' | 'xlsx'
) => {
    const token = localStorage.getItem('accessToken');
    const baseURL = new URL(getEnvConfig().env.API_URL + `/statistic`);
    let url = '';

    switch (filters.viewAnalitycsType) {
        case 'all': {
            url = `${baseURL}/export-payments?export_type=${type}`;
            break;
        }
        case 'members': {
            url = `${baseURL}/members/export?export_type=${type}`;
            break;
        }
        case 'messages': {
            const query = qs.stringify(
                {
                    export_type: type,
                    period: filters.period,
                    community_ids: filters.chat ? [filters.chat] : null,
                },
                { skipNull: true, arrayFormat: 'colon-list-separator' }
            );
            url = `${baseURL}/messages/export?${query}`;
            break;
        }
        case 'moderation': {
            const query = qs.stringify(
                {
                    export_type: type,
                    period: filters.period,
                    community_ids: filters.chat ? [filters.chat] : null,
                },
                { skipNull: true, arrayFormat: 'colon-list-separator' }
            );
            url = `${baseURL}/moderation/export?${query}`;
            break;
        }
        default: {
            throw new Error('Not implemented');
        }
    }

    const data = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const response = await data.json();
    window.open(
        `${getEnvConfig().env.API_URL.replace(/api\/v3/, '')}/${
            response.file_path || response.data.file_path
        }`
    );
};
