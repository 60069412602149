import React, { FC, Fragment, useState } from 'react';
import { Space, Switch, Typography } from 'antd';
import styled from 'styled-components';

import { TimePickerFormik } from 'shared/ui/Form/Fields/TimePickerFormik';
import { OnboardingSwitchEnum } from 'features/communities/components/Onboarding/OnboardingMapper';

interface IProps {
    formik: any;
}

export const RestrictMessageSendingSwitch: FC<IProps> = props => {
    return (
        <Fragment>
            <StyledSpace align='start'>
                <StyledSwitch
                    checked={
                        props.formik.values.switches[
                            OnboardingSwitchEnum.restrictMessageSendingSwitch
                        ].isChecked
                    }
                    onChange={value => {
                        props.formik.setFieldValue(
                            `switches.${OnboardingSwitchEnum.restrictMessageSendingSwitch}.isChecked`,
                            value
                        );
                    }}
                />
                <Space direction='vertical' size='middle'>
                    <StyledTitle level={5}>
                        Запрет на отправку сообщений
                    </StyledTitle>
                    {props.formik.values.switches[
                        OnboardingSwitchEnum.restrictMessageSendingSwitch
                    ].isChecked && (
                        <StyledWrap>
                            <div style={{ maxWidth: 460 }}>
                                <Typography.Text>
                                    Новые участники не могут отправлять
                                    сообщения в течение
                                </Typography.Text>
                            </div>
                            <div>
                                <TimePickerFormik
                                    formik={props.formik}
                                    fieldName={`switches.${OnboardingSwitchEnum.restrictMessageSendingSwitch}.duration`}
                                />
                            </div>
                        </StyledWrap>
                    )}
                </Space>
            </StyledSpace>
        </Fragment>
    );
};

const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;

    @media (max-width: 576px) {
        margin-left: -68px;
    }
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSpace = styled(Space)`
    margin-bottom: 8px;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;
