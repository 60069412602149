import React from 'react';
import { App, Button, Space, Typography } from 'antd';
import styled from 'styled-components';
import { CopyOutlined } from '@ant-design/icons';

type Props = {
    readonly url: string;
};

export const PublicAccessSwitch: React.FC<Props> = props => {
    const { notification } = App.useApp();

    const onShare = url => {
        navigator.clipboard.writeText(url).then(() =>
            notification.info({
                message: `Ссылка ${url} скопирована в буфер обмена`,
            })
        );
    };

    return (
        <Space direction='vertical' size='middle'>
            <StyledTitle level={5}>
                Открыть публичный доступ к базе знаний по ссылке
            </StyledTitle>
            <Space
                size='middle'
                style={{ alignItems: 'start' }}
                direction='vertical'
            >
                <Typography.Text>
                    База знаний будет доступна по ссылке:
                </Typography.Text>
                <div>
                    <Typography.Text>{props.url}</Typography.Text>
                    <Button
                        type='link'
                        style={{ padding: 0 }}
                        onClick={() => onShare(props.url)}
                    >
                        <CopyOutlined style={{ marginInlineStart: 4 }} />
                    </Button>
                </div>
            </Space>
        </Space>
    );
};

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;
