import React, { Fragment, useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Avatar, Table, Tag, Typography } from 'antd';
import styled from 'styled-components';
import { FilterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import {
    ICommunity,
    ICommunityQueryParams,
} from 'features/communities/interfaces';
import { useReadChatsQuery } from 'features/communities/services';
import { TableFilter } from 'shared/ui/TableFilter';

import { getSrc } from '../../../../../../utils/common/getSrc';

interface IProps {
    selectedRows: number[];
    setSelectedRows: (values: number[]) => void;
}

export const TariffChatList: React.FC<IProps> = ({
    selectedRows,
    setSelectedRows,
}) => {
    const [filters, setFilters] = useState<ICommunityQueryParams>({
        tags_names: [],
        rule_names: [],
    });
    const {
        data: source = { items: [], total: 0 },
        refetch,
        isFetching,
    } = useReadChatsQuery(filters);

    useEffect(() => {
        refetch();
    }, [filters]);

    const rowSelection = {
        type: 'radio',
        hideSelectAll: true,
        selectedRowKeys: selectedRows,
        onChange: setSelectedRows,
    };

    const handleFiltersUpdate = (update: ICommunityQueryParams | null) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({ tags_names: [] });
            setFilters({ rule_names: [] });
        }
    };

    const columns: ColumnsType<ICommunity> = [
        {
            title: () => (
                <TableFilter<string>
                    title='Название сообщества'
                    onChange={name => {
                        handleFiltersUpdate({ name });
                    }}
                    value={filters.name}
                >
                    <TableFilter.Text />
                </TableFilter>
            ),
            key: 'title',
            width: '35%',
            render: record => (
                <>
                    <div>
                        <Avatar src={getSrc(record.image)} />
                        <span style={{ marginLeft: 16 }}>
                            <Typography.Text>{record.title}</Typography.Text>
                        </span>
                    </div>
                </>
            ),
        },
        {
            title: () => (
                <TableFilter<string[]>
                    icon={<FilterOutlined />}
                    onChange={tags_names => {
                        handleFiltersUpdate({ tags_names });
                    }}
                    title='Теги'
                    value={filters.tags_names}
                >
                    <TableFilter.Tags />
                </TableFilter>
            ),
            dataIndex: 'tags',
            key: 'tags',
            width: '25%',
            render: (text, record) => (
                <>
                    {record.tags.map(tag => (
                        <Tag color='default' key={tag.id}>
                            {tag.name}
                        </Tag>
                    ))}
                </>
            ),
        },
        {
            title: 'Участники',
            dataIndex: 'followers',
            key: 'id',
            width: '20%',
            render: text => text,
        },
        {
            title: () => (
                <TableFilter<number>
                    icon={<FilterOutlined />}
                    onChange={created_at => {
                        handleFiltersUpdate({ created_at });
                    }}
                    title='Дата добавления'
                    value={filters.created_at}
                >
                    <TableFilter.Tags />
                </TableFilter>
            ),
            dataIndex: 'created_at',
            key: 'created_at',
            width: '20%',
            render: time => dayjs.unix(time).format('DD.MM.YY'),
        },
    ];

    const pagination = {
        showTotal: total => `Чатов ${total}`,
    };

    return (
        <Fragment>
            <StyledTitle level={5}>Применить к чатам</StyledTitle>
            <Table
                loading={isFetching}
                rowSelection={rowSelection}
                columns={columns}
                rowKey={community => community.id}
                dataSource={source.items}
                pagination={pagination}
            />
        </Fragment>
    );
};

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 37px !important;
`;
