import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, Divider } from 'antd';

type TProps = {
    readonly options: Array<{ value: string; label: string }>;
    readonly value?: string[];
    readonly onChange?: (value: string[]) => void;
    readonly onClose?: () => void;
};

export const MultiCheckBoxFilter: React.FC<TProps> = props => {
    const [selectedTypes, setSelectedTypes] = useState<string[]>(props.value);

    const handleSelect = (name: string, checked: boolean) => {
        if (checked) {
            setSelectedTypes([...selectedTypes, name]);
        } else setSelectedTypes(selectedTypes.filter(type => type !== name));
    };

    const handleChange = () => {
        props.onChange(selectedTypes);
        props.onClose();
    };

    const handleClear = () => {
        props.onChange([]);
        props.onClose();
    };

    return (
        <React.Fragment>
            <StyledContainer>
                {props.options.map((option, index) => (
                    <StyledCheckbox
                        checked={selectedTypes.includes(option.value)}
                        onChange={({ target }) => {
                            handleSelect(option.value, target.checked);
                        }}
                        key={index}
                    >
                        {option.label}
                    </StyledCheckbox>
                ))}
            </StyledContainer>

            <Divider style={{ margin: 0 }} />
            <StyledActions>
                <Button size='small' type='text' onClick={handleClear}>
                    Очистить
                </Button>
                <Button size='small' type='primary' onClick={handleChange}>
                    Ок
                </Button>
            </StyledActions>
        </React.Fragment>
    );
};

const StyledCheckbox = styled(Checkbox)`
    margin-inline-start: 0px !important;
`;

const StyledActions = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 330px;
    overflow-y: scroll;
    overflow-x: none;
`;
