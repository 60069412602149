import React from 'react';
import Chart, { Props as ApexProps } from 'react-apexcharts';
import * as ru from 'apexcharts/dist/locales/ru.json';

import { useGetMembersQuery } from 'features/analytics/services';
import { IStatisticFilters } from 'features/analytics/services/interfaces';

import { ChartWrapper } from '../ChartWrapper';

const labelFormat = (props: Partial<Props>): string => {
    switch (props.filters.period) {
        case 'day': {
            return 'HH:mm';
        }
        case 'week': {
            return 'ddd';
        }
        case 'month': {
            return 'dd';
        }
        case 'year': {
            return 'MMM';
        }
    }
};

type Props = {
    readonly filters: IStatisticFilters;
    readonly height?: number;
};

export const MembersMultiLineChart: React.FC<Props> = props => {
    const colors = ['#D6E4FF', '#ADC6FF', '#85A5FF', '#597EF7'];

    const {
        data: members = {
            series: [],
        },
        isFetching,
    } = useGetMembersQuery(
        {
            period: props.filters.period,
            community_id: Number(props.filters.chat) || null,
        },
        { refetchOnMountOrArgChange: true }
    );

    const options: ApexProps['options'] = {
        labels: [],
        chart: {
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            locales: [ru],
            defaultLocale: 'ru',
        },
        legend: {
            show: true,
            position: 'left',
        },
        xaxis: {
            type: 'datetime',
            labels: {
                format: labelFormat(props),
            },
            tickAmount: 6,
        },
        colors: colors,
        markers: {
            colors: colors,
        },
    };

    return (
        <ChartWrapper isLoading={isFetching} title='Участники'>
            <Chart
                height={props.height}
                type='line'
                options={options}
                series={[
                    {
                        type: 'line',
                        name: 'Участников',
                        data: members.series.map(s => ({
                            x: s.timestamp,
                            y: s.users,
                        })),
                    },
                ]}
            />
        </ChartWrapper>
    );
};
