import { IFormikInitialValues } from 'features/monetization/pages/tariff/containers/TariffContainerAdd';

import { getSrc, prepareSrc } from '../../../../utils/common/getSrc';

export enum TariffSwitchesEnum {
    paidAccess = 'paidAccess',
    demoAccess = 'demoAccess',
    messageAfterPay = 'messageAfterPay',
}

export class TariffMapper {
    private static convertSwitchesMap = new Map<
        TariffSwitchesEnum,
        (value) => any
    >([
        [
            TariffSwitchesEnum.paidAccess,
            value => {
                return {
                    price: value?.price,
                    tariff_is_payable: value?.tariff_is_payable,
                    main_description: value?.main_description,
                    main_image: prepareSrc(value?.main_image),
                };
            },
        ],
        [
            TariffSwitchesEnum.demoAccess,
            value => {
                return {
                    test_period_is_active: value?.test_period_is_active,
                };
            },
        ],
        [
            TariffSwitchesEnum.messageAfterPay,
            value => {
                return {
                    thanks_message_is_active: value?.thanks_message_is_active,
                    ...(value?.thanks_message && {
                        thanks_message: value?.thanks_message,
                    }),
                    ...(value?.thanks_image && {
                        thanks_image: prepareSrc(value?.thanks_image),
                    }),
                };
            },
        ],
    ]);
    public static createDto(values: IFormikInitialValues) {
        const arraySwitches = Object.entries(values.switches) as [
            TariffSwitchesEnum,
            any
        ][];
        const arrayCheckedSwitches = arraySwitches.filter(([_, value]) => {
            return value.isChecked;
        });
        const requestObject = {
            title: values.title,
            community_id: values.community_id,
            tariff_is_payable:
                values.switches[TariffSwitchesEnum.paidAccess].isChecked,
            test_period_is_active:
                values.switches[TariffSwitchesEnum.demoAccess].isChecked,
        };
        if (arrayCheckedSwitches.length) {
            arrayCheckedSwitches.forEach(([key, value]) => {
                if (this.convertSwitchesMap.get(key)) {
                    Object.assign(
                        requestObject,
                        this.convertSwitchesMap.get(key)(value)
                    );
                }
            });
        }
        return requestObject;
    }
    public static convertDto(data): IFormikInitialValues {
        return {
            title: data?.title || '',
            switches: {
                [TariffSwitchesEnum.paidAccess]: {
                    isChecked: data?.tariff_is_payable,
                    tariff_is_payable: data?.tariff_is_payable,
                    price: data?.price,
                    main_description: data?.main_description,
                    main_image: getSrc(data?.main_image),
                },
                [TariffSwitchesEnum.demoAccess]: {
                    isChecked: data?.test_period_is_active,
                    test_period_is_active: data?.test_period_is_active,
                },
                [TariffSwitchesEnum.messageAfterPay]: {
                    isChecked: data?.thanks_message_is_active,
                    thanks_message_is_active: data?.thanks_message_is_active,
                    thanks_message: data?.thanks_message,
                    thanks_image: getSrc(data?.thanks_image),
                },
            },
            community_id: data?.community_id,
        };
    }
}
