import React, { useState } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';

import { PostsPublicContainer } from '../../post/public/PostsPublicContainer';
import { WebinarPublicContainer } from '../../webinar/containers/public/WebinarPublicContainer';
import { AuthorInfoContainer } from '../AuthorInfoContainer';

export const AuthorPublicContainer = () => {
    const [activeTab, setActiveTab] = useState<string>('posts');

    const onChangeTab = (key: string) => {
        setActiveTab(key);
    };

    const items: TabsProps['items'] = [
        {
            key: 'posts',
            label: `Посты`,
            children: <PostsPublicContainer />,
        },
        {
            key: 'webinars',
            label: `Вебинары`,
            children: <WebinarPublicContainer />,
        },
    ];

    return (
        <>
            <AuthorInfoContainer />
            <Tabs
                defaultActiveKey={activeTab}
                items={items}
                onChange={onChangeTab}
            />
        </>
    );
};
