import React, { Fragment } from 'react';

import { IStatisticFilters } from 'features/analytics/services/interfaces';
import { useGetModerationQuery } from 'features/analytics/services';

import { ViolationsTableView } from '../views/ViolationsTableView';

type Props = {
    readonly filters: IStatisticFilters;
};

export const ViolationsTableContainer: React.FC<Props> = props => {
    const {
        data: moderation = {
            members: [],
        },
    } = useGetModerationQuery({
        period: props.filters.period,
        community_id: Number(props.filters.chat) || null,
    });

    return (
        <Fragment>
            <ViolationsTableView source={moderation.members} />
        </Fragment>
    );
};
