import { Card, Typography } from 'antd';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useTheme } from 'hooks/useTheme';

type Props<C> = PropsWithChildren<C> & {
    readonly title: string;
    readonly width?: number;
    readonly height?: number;
    readonly children: React.ReactElement;
    readonly isLoading?: boolean;
};

export const ChartWrapper = <C,>(props: Props<C>) => {
    const token = useTheme();
    return (
        <StyledWrapper>
            <StyledCard
                loading={props.isLoading}
                type='inner'
                style={{
                    ...token.Card,
                }}
            >
                <Typography.Text>{props.title}</Typography.Text>
                {React.cloneElement(props.children)}
            </StyledCard>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    position: relative;
    flex: 1 0 49%;
`;

const StyledCard = styled(Card)`
    min-height: 210px;
`;
