import React from 'react';
import styled from 'styled-components';

export const Container = props => {
    return <StyledContainer>{props.children}</StyledContainer>;
};

const StyledContainer = styled.div`
    display: flex;
    // width: 100%;
    // max-width: 1200px;
    margin: 0 auto;
    max-width: 1200px;
    width: calc(100% - 1rem);

    @media (max-width: 992px) {
        display: flex;
        justify-content: space-between;
    }
`;
