import React, { Fragment, useEffect, useState } from 'react';
import { App, Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import {
    useDeleteRuleMutation,
    useReadRulesQuery,
} from 'features/communities/services';
import { SpodialAdapterError } from 'utils/common/error-parser';
import getEnvConfig from 'utils/config/env';
import { RuleType } from 'shared/contants/rule-types';
import { QueryRules } from 'features/communities/interfaces/rules.interface';

import { ScenariosTable } from '../views/ScenariosTable';
import { ScenariosCard } from '../views/ScenariosCard';
import { SearchMobile } from '../../../../../shared/ui/Search/SearchMobile';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { ScenarioCreate } from '../components/ScenarioCreate';

export const ScenariosContainer: React.FC = () => {
    const { size } = useWindowSize();

    const [filters, setFilters] = useState<any>({});
    const [selectedRows, setSelectedRows] = useState<
        [{ id: string; uuid: string; type: string }]
    >([]);

    const navigate = useNavigate();
    const { data: source = { items: [], total: 0 }, isFetching } =
        useReadRulesQuery(filters);

    const { notification } = App.useApp();

    const [deleteRule, { error, isError, isLoading, isSuccess }] =
        useDeleteRuleMutation();

    const onFiltersUpdate = update => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        }
    };

    useEffect(() => {
        if (isError) {
            const parsedError = new SpodialAdapterError(error);
            notification.error({
                message: `Spodial Adapter Error`,
                description: parsedError.toString(),
            });
        }
    }, [isError]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Правило успешно удалено',
                placement: 'top',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccess) {
            setSelectedRows([]);
        }
    }, [isSuccess]);

    const onDelete = (id: string, type: string) => {
        deleteRule({ id, type });
    };

    const onView = (id: string, type: string) => {
        switch (type) {
            case RuleType.Conditional: {
                navigate(
                    `${
                        getEnvConfig().PUBLIC_PATH
                    }/communities/rules/conditional/${id}`
                );
                break;
            }
            case RuleType.Behavioral: {
                navigate(
                    `${
                        getEnvConfig().PUBLIC_PATH
                    }/communities/rules/behavioral/${id}`
                );
                break;
            }
            case RuleType.Onboarding: {
                navigate(
                    `${
                        getEnvConfig().PUBLIC_PATH
                    }/communities/rules/onboarding/${id}`
                );
                break;
            }
            case RuleType.Antispam: {
                navigate(
                    `${
                        getEnvConfig().PUBLIC_PATH
                    }/communities/rules/antispam/${id}`
                );
                break;
            }
            case RuleType.Reputation: {
                navigate(
                    `${
                        getEnvConfig().PUBLIC_PATH
                    }/communities/rules/reputation/${id}`
                );
                break;
            }
        }
    };

    const onBulkRemove = async (
        row: [{ id: string; uuid: string; type: string }]
    ) => {
        await Promise.all(
            row.map(r => deleteRule({ id: r.id || r.uuid, type: r.type }))
        );
    };

    const rowSelection = {
        selectedRows: selectedRows,
        onChange: (key, row) => setSelectedRows(row),
    };

    return size === 'small' ? (
        <>
            <Space direction={'vertical'}>
                <ScenarioCreate />
                <SearchMobile
                    search={filters.rule_title}
                    searchParam={'rule_title'}
                    action={onFiltersUpdate}
                    loading={isLoading || isFetching}
                />
            </Space>

            {!!selectedRows.length && (
                <Fragment>
                    <Button
                        onClick={() => onBulkRemove(selectedRows)}
                        type={'primary'}
                        style={{ marginRight: '20px' }}
                    >
                        Удалить
                    </Button>
                    <span>Выбрано сценариев:{selectedRows?.length}</span>
                </Fragment>
            )}
            <ScenariosCard
                handleRowsChange={setSelectedRows}
                source={source}
                onView={onView}
                selectedRows={selectedRows}
            />
        </>
    ) : (
        <ScenariosTable
            rowSelection={rowSelection}
            selectedRows={selectedRows}
            filters={filters}
            onFiltersUpdate={onFiltersUpdate}
            loading={isLoading || isFetching}
            onDelete={onDelete}
            items={source.items}
            onView={onView}
            onBulkRemove={onBulkRemove}
            isSuccess={isSuccess}
        />
    );
};
