import React, { FC, Fragment, useEffect } from 'react';
import { Badge, Button, Divider, Space, Typography } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { PlusOutlined } from '@ant-design/icons';

import { HeaderScenarios } from '../../HeaderScenarios/HeaderScenarios';
import { ChatList } from '../ChatsList/ChatList';
import { ConfirmBlock } from '../../ConfirmBlock/ConfirmBlock';
import { PublicAccessSwitch } from './components/PublicAccessSwitch';
import { CategoryContainer } from '../../Categories/container/CategoryContainer';
import {
    IKnowledge,
    IKnowledgeCreate,
} from '../../../interfaces/knowledge.interface';
import { QuestionContainer } from '../../Questions/containers/QuestionContainer';
import getEnvConfig from '../../../../../utils/config/env';
import { useToggle } from '../../../../../hooks/useToggle';
import { useUpdateKnowledgeMutation } from '../../../services';

interface IProps {
    readonly knowledge: Partial<IKnowledge>;
    readonly onSave?: (values: IKnowledgeCreate) => Promise<void>;
}

export const KnowledgeForm: FC<IProps> = props => {
    const { knowledge } = props;

    const { open, setOpen } = useToggle(false);
    const toggleCategory = () => setOpen((x: boolean) => !x);
    const [updateKnowledge, { isSuccess }] = useUpdateKnowledgeMutation();

    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            knowledge_id: knowledge.items.id,
            knowledge_name: knowledge.items.name,
            community_ids: [],
        },
        onSubmit: props.onSave,
    });

    useEffect(() => {
        formik.setFieldValue(`community_ids`, props.knowledge.communities);
    }, [props.knowledge.communities]);

    const updateKnowledgeName = () => {
        const data = {
            knowledge_id: knowledge.items.id,
            knowledge_name: formik.values.knowledge_name,
        };

        updateKnowledge(data);
    };

    return (
        <Fragment>
            <ContainerWrapper>
                <HeaderScenarios
                    onBlur={() => updateKnowledgeName()}
                    inputValue={formik.values.knowledge_name}
                    onChangeHeaderInput={value =>
                        formik.setFieldValue(
                            'knowledge_name',
                            value.target.value
                        )
                    }
                />
                <Space direction='vertical' size='large'>
                    <PublicAccessSwitch url={knowledge.items.uri_hash} />
                </Space>
            </ContainerWrapper>

            <Divider orientation={'left'} />
            <ContainerWrapper>
                <StyledTitle level={5}>Вопросы в базе знаний</StyledTitle>

                <Space wrap>
                    <Button
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() =>
                            navigate(
                                `${
                                    getEnvConfig().PUBLIC_PATH
                                }/communities/knowledge/${
                                    knowledge.items.id
                                }/question-create`
                            )
                        }
                    >
                        Добавить вопрос
                    </Button>

                    <Button onClick={toggleCategory}>
                        Редактирвать категории
                    </Button>
                </Space>
                <CategoryContainer
                    setIsOpenCategory={toggleCategory}
                    open={open}
                    knowledgeId={knowledge.items.id}
                />
            </ContainerWrapper>
            <QuestionContainer />
            <Divider orientation={'left'} />

            <ChatList
                selectedRows={
                    formik.values.community_ids || props.knowledge.communities
                }
                setSelectedRows={value => {
                    formik.setFieldValue(`community_ids`, value);
                }}
                tableOptions={{
                    entityType: 'knowledge',
                }}
            />
            <ConfirmBlock
                onSave={formik.handleSubmit}
                onCancel={() => {
                    navigate(-1);
                }}
            >
                <Fragment>
                    <Badge
                        color='orange'
                        text={`Для ${
                            formik.values?.community_ids?.length || 0
                        } чатов текущая база знаний заменит действующую. Это действие нельзя отменить.`}
                    />
                </Fragment>
            </ConfirmBlock>
        </Fragment>
    );
};

const ContainerWrapper = styled.div`
    display: block;
    margin-top: 32px;
    margin-bottom: 33px;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 32px 0;
    margin-bottom: 33px !important;
`;
