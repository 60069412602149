import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, Col, Radio, RadioChangeEvent, Row } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

import { Label } from './Label';
import { allOk, whatToDelete } from '../utils/feedback';

export const DeleteContentSwitch = props => {
    const { formik } = props;

    const [value, setValue] = useState(true);
    const [options, setOptions] = useState([]);
    const [optionsDisable, setOptionsDisable] = useState(false);

    const onChangeCheckbox = (checkedValues: CheckboxValueType[]) => {
        setOptions(checkedValues);
        formik.setFieldValue('what_to_remove', {
            all_ok: value,
            options,
        });
    };

    const onChangeRadio = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    useEffect(() => {
        if (options) {
            formik.setFieldValue('what_to_remove', {
                all_ok: value,
                options,
            });
        }
    }, [options]);

    useEffect(() => {
        if (value) {
            setOptions([]);
            setOptionsDisable(true);
        } else {
            setOptionsDisable(false);
        }
    }, [value]);

    return (
        <StyledCheckWrapper>
            <Label label={'Что автору стоит убрать?'} />

            <Radio.Group onChange={onChangeRadio} value={value}>
                <Row>
                    {allOk.map((item, index) => (
                        <StyledCol key={index} span={24}>
                            <Radio value={item.isOk}>{item.name}</Radio>
                        </StyledCol>
                    ))}
                </Row>
            </Radio.Group>

            <Checkbox.Group
                style={{ width: '100%' }}
                value={options}
                onChange={onChangeCheckbox}
            >
                <Row>
                    {whatToDelete.map((content, index) => (
                        <StyledCol key={index} span={24}>
                            <Checkbox
                                disabled={optionsDisable}
                                value={content.value}
                            >
                                {content.name}
                            </Checkbox>
                        </StyledCol>
                    ))}
                </Row>
            </Checkbox.Group>
        </StyledCheckWrapper>
    );
};

const StyledCheckWrapper = styled.div`
    min-width: 50%;
    display: flex;
    flex-direction: column;
`;

const StyledCol = styled(Col)`
    margin-bottom: 0.5rem;
`;
