import React, { Fragment, useEffect, useState } from 'react';
import { App, Button, Card, List, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from 'shared/ui/Dropdown';

import {
    useCreatePublicationMutation,
    useDeletePublicationMutation,
    useReadPublicationQuery,
} from '../../../../services/publications';
import { getSrc } from '../../../../../../utils/common/getSrc';
import postPlaceholder from '../../../../../../static/images/post_placeholder.png';
import { useTheme } from '../../../../../../hooks/useTheme';
import { initialFilters } from '../../../../../shops/utils/filters';

interface IPosts {
    readonly isOwner: boolean;
    isError?: boolean;
    isFetching?: boolean;
    readonly extraButton: Element;
    readonly setExtraButton: (Element) => void;
    readonly activeTab: string;
}

export const PostsContainer = (props: IPosts) => {
    const [filters, setFilters] = useState(initialFilters);

    const { notification } = App.useApp();
    const navigate = useNavigate();
    const { boxShadow } = useTheme();

    const [currentPage, setCurrentPage] = useState(1);

    const {
        data: posts = { items: [], total: 0 },
        refetch,
        isFetching,
        isError,
        isLoading,
    } = useReadPublicationQuery(filters);

    const [
        createPost,
        { isSuccess: isSuccessCreate, data: post, isLoading: isLoadingCreate },
    ] = useCreatePublicationMutation();

    const [
        deletePublication,
        { isSuccess: isSuccessDelete, isLoading: isLoadingDelete },
    ] = useDeletePublicationMutation();

    useEffect(() => {
        if (props.activeTab === 'posts') {
            props.setExtraButton(
                <Button
                    disabled={isLoadingCreate || isLoadingDelete}
                    type={'primary'}
                    onClick={() => {
                        createPost();
                    }}
                >
                    Создать пост
                </Button>
            );
        }
    }, [props.activeTab]);

    useEffect(() => {
        if (post && isSuccessCreate) navigate(`post-create/${post.id}`);
    }, [post, isSuccessCreate]);

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Ошибка при получении постов',
            });
        }
    }, [isError]);

    useEffect(() => {
        if (isSuccessDelete) {
            notification.success({
                message: 'Пост успешно удалён',
                placement: 'top',
            });
            refetch();
        }
    }, [isSuccessDelete]);

    useEffect(() => {
        if (sessionStorage.postCurrentPage) {
            setCurrentPage(Number(sessionStorage.getItem('postCurrentPage')));
        }
    }, [sessionStorage.productCurrentPage]);
    const handleFiltersUpdate = update => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters(null);
        }
    };

    const handleReadPost = post => {
        if (post.cost > 0) {
            navigate(`/courses/author/${post.author_id}/post/${post.hash}`);
        } else navigate(`/courses/author/${post.author_id}/post/${post.id}`);
    };

    return (
        <List
            grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 3,
                xxl: 3,
            }}
            loading={isLoading || isFetching || isLoadingDelete}
            dataSource={posts?.items?.slice().sort((a, b) => b.id - a.id)}
            renderItem={(post, index) => (
                <List.Item>
                    <StyledCard
                        loading={isFetching}
                        key={index}
                        type='inner'
                        style={{ boxShadow }}
                        cover={
                            <StyledCover
                                src={
                                    !post.background_image
                                        ? postPlaceholder
                                        : getSrc(
                                              `/storage/${post.background_image}`
                                          )
                                }
                                alt='Publication preview'
                            />
                        }
                    >
                        <StyledTitle level={5}>{post.title}</StyledTitle>
                        {!post.description ? (
                            <StyledDescription />
                        ) : (
                            <StyledDescription
                                ellipsis={{
                                    rows: 2,
                                }}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: post?.description,
                                    }}
                                ></span>
                            </StyledDescription>
                        )}

                        <StyledActions>
                            {props.isOwner ? (
                                <Fragment>
                                    <StyledButton
                                        onClick={() =>
                                            navigate(`post/${post.id}`)
                                        }
                                        style={{ width: '100%' }}
                                    >
                                        Читать
                                    </StyledButton>

                                    <Dropdown
                                        disabled={isLoadingDelete}
                                        autoAdjustOverflow={true}
                                        trigger={['hover']}
                                        destroyPopupOnHide={true}
                                        dropdownContent={() => (
                                            <Fragment>
                                                <Button
                                                    type='text'
                                                    onClick={() =>
                                                        navigate(
                                                            `post-edit/${post.id}`
                                                        )
                                                    }
                                                >
                                                    Редактировать
                                                </Button>

                                                <Button
                                                    disabled={isLoadingDelete}
                                                    type='text'
                                                    onClick={() =>
                                                        deletePublication(
                                                            post.id
                                                        )
                                                    }
                                                >
                                                    Удалить
                                                </Button>
                                            </Fragment>
                                        )}
                                    >
                                        <Button type='default'>
                                            <EditOutlined />
                                        </Button>
                                    </Dropdown>
                                </Fragment>
                            ) : (
                                <StyledButton
                                    onClick={() => handleReadPost(post)}
                                >
                                    {post.price > 0
                                        ? `Купить доступ за ${post.price} ₽`
                                        : 'Читать'}
                                </StyledButton>
                            )}
                        </StyledActions>
                    </StyledCard>
                </List.Item>
            )}
            pagination={{
                defaultCurrent: 1,
                current: currentPage,
                showTitle: true,
                onChange: (page, pageSize) => {
                    handleFiltersUpdate({
                        offset: (page - 1) * pageSize,
                        limit: pageSize,
                        page: page,
                    });
                    setCurrentPage(page);
                },
                defaultPageSize: 9,
                showTotal: total => `Постов: ${total}`,
            }}
        />
    );
};

const StyledButton = styled(Button)`
    width: 100%;
`;

const StyledCard = styled(Card)`
    width: 100%;
    margin-top: 1rem;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 1rem 0;
`;

const StyledDescription = styled(Typography.Paragraph)`
    margin: 1rem 0;
    min-height: 44px;
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
`;

const StyledCover = styled.img`
    object-fit: cover;
    width: 150px;
    height: 250px;
`;
