import React, { Fragment, useEffect, useState } from 'react';
import { Card, Skeleton, Space, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useReadWebinarByUuidQuery } from '../../../../../../services/webinars';
import postPlaceholder from '../../../../../../../../static/images/post_placeholder.png';
import { getSrc } from '../../../../../../../../utils/common/getSrc';
import { useTheme } from '../../../../../../../../hooks/useTheme';
import { AuthorPublicWebinar } from '../../../author/public/AuthorPublicWebinarContainer';
import { FeedbackContainer } from '../../../../../Feedback/FeedbackContainer';
import { FavouriteSwitch } from '../../../../components/FavouriteSwitch';
import { ButtonBack } from '../../../post/components/ButtonBack';
import { useProfileData } from '../../../../../../../../hooks/useProfileData';

const { Text } = Typography;

export const WebinarPublicInfo = () => {
    const token = useTheme();
    const { uuid } = useParams();

    const [isOwner, setIsOwner] = useState<boolean>(false);

    const { data: webinar, isSuccess } = useReadWebinarByUuidQuery(uuid);
    const { user } = useProfileData();

    useEffect(() => {
        if (webinar && user) {
            setIsOwner(webinar?.author_id === user?.author_fields?.id);
        } else setIsOwner(false);
    }, [user, webinar]);

    return (
        <>
            {isSuccess ? (
                <>
                    <ButtonBack />
                    <Card
                        type='inner'
                        style={{ ...token.Card, marginBottom: '1rem' }}
                    >
                        <StyledWrapper>
                            <StyledCover
                                src={
                                    !webinar.background_image
                                        ? postPlaceholder
                                        : getSrc(
                                              `/storage/${webinar.background_image}`
                                          )
                                }
                                alt={'webinar'}
                            />
                            <StyledDescription>
                                <Typography.Title
                                    level={1}
                                    style={{ margin: 0 }}
                                >
                                    {webinar.title}
                                </Typography.Title>

                                <Text type='secondary'>{webinar.start_at}</Text>
                            </StyledDescription>
                            <StyledActions>
                                <Space>
                                    <FavouriteSwitch
                                        type={'webinar'}
                                        id={webinar.id}
                                        isFavourite={webinar.is_favourite}
                                    />
                                </Space>
                            </StyledActions>
                        </StyledWrapper>
                    </Card>
                </>
            ) : null}

            {isSuccess && webinar ? (
                <>
                    <AuthorPublicWebinar
                        type={webinar.type}
                        authorId={webinar.author_id}
                        url={webinar.external_url}
                    />

                    {webinar.id && !isOwner && (
                        <FeedbackContainer
                            postId={webinar.id}
                            type={'webinar'}
                        />
                    )}
                </>
            ) : (
                <Skeleton />
            )}
        </>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledDescription = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 60%;
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: auto;
`;

const StyledCover = styled.img`
    margin-right: 1rem;
    object-fit: cover;
    width: 250px;
    height: 150px;
`;
