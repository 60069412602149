import { Avatar, Space, Table, Button, Input } from 'antd';
import React, { Fragment, useRef, useState } from 'react';
import styled from 'styled-components';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import type { ColumnType } from 'antd/es/table';

import { format } from 'lib/date';
import { IStatisticModeration } from 'features/analytics/services/interfaces';

import { getSrc } from '../../../../../utils/common/getSrc';
type DataIndex = keyof DataType;

type Props = {
    readonly source: IStatisticModeration['members'];
};

interface DataType {
    name: string;
    nick_name: string;
    image: string;
    action: string;
    timestamp: number;
}

export const ViolationsTableView: React.FC<Props> = props => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (
        dataIndex: DataIndex
    ): ColumnType<DataType> => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Поиск по названию`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex
                        )
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() =>
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex
                            )
                        }
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Поиск
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters);
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex
                            );
                        }}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Очистить
                    </Button>
                    <Button
                        type='text'
                        size='small'
                        onClick={() => {
                            close();
                        }}
                    >
                        Отмена
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? '#6257d2' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    return (
        <Fragment>
            <StyledTable
                dataSource={props.source}
                rowKey={user => user.action_date}
                pagination={{
                    total: props.source?.length,
                    showTotal: () => `Событий: ${props.source?.length}`,
                }}
            >
                <Table.Column
                    title={'Дата и время'}
                    dataIndex={'action_date'}
                    render={date => format(date)('DD-MM-YYYY, HH:mm')}
                    defaultSortOrder={'descend'}
                    sorter={(
                        a: { action_date: number },
                        b: { action_date: number }
                    ) => a.action_date - b.action_date}
                />

                <Table.Column
                    title={'Событие'}
                    dataIndex={'action'}
                    filters={[
                        {
                            text: 'Нарушение',
                            value: 'Нарушение',
                        },
                        {
                            text: 'Мьют',
                            value: 'Мьют',
                        },
                        {
                            text: 'Бан',
                            value: 'Бан',
                        },
                        {
                            text: 'Кик',
                            value: 'Кик',
                        },
                    ]}
                    onFilter={(value: string, record: any) =>
                        record.action.indexOf(value) === 0
                    }
                />
                <Table.Column
                    title='Имя'
                    render={user => (
                        <Space>
                            {user.photo_url && (
                                <Avatar src={getSrc(user.photo_url)} />
                            )}
                            {!user.photo_url && (
                                <Avatar>{user?.name.charAt(0)}</Avatar>
                            )}
                            <span>{user.name}</span>
                        </Space>
                    )}
                    {...getColumnSearchProps('name')}
                />
                <Table.Column
                    title={'Юзернейм'}
                    dataIndex={'nick_name'}
                    render={name => `@${name}`}
                    {...getColumnSearchProps('nick_name')}
                />
            </StyledTable>
        </Fragment>
    );
};

const StyledTable = styled(Table<any>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;
