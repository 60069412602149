import { Button, Dropdown, MenuProps, Select, Space } from 'antd';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import filterOptions from './filterOptions';

const { ANALYTICS_TYPES, ANALYTICS_PERIODS, DOWNLOAD_ACTIONS } = filterOptions;

type Props = {
    readonly filters: any;
    readonly onFiltersUpdate: (filters) => void;
    readonly onDownload: (type: 'csv' | 'xlsx') => void;
};
export const AnalyticsFilters: React.FC<Props> = props => {
    const items = [
        {
            key: '1',
            label: 'Скачать в CSV',
        },
    ];

    return (
        <Fragment>
            <Space>
                <Select
                    style={{ minWidth: '142px' }}
                    defaultValue={ANALYTICS_TYPES[0].value}
                    options={ANALYTICS_TYPES}
                    value={props.filters.viewAnalitycsType}
                    onChange={value =>
                        props.onFiltersUpdate({
                            viewAnalitycsType: value,
                        })
                    }
                />
                <StyledSelect
                    defaultValue={ANALYTICS_PERIODS[0].value}
                    value={props.filters.period}
                    options={ANALYTICS_PERIODS}
                    onChange={value =>
                        props.onFiltersUpdate({
                            period: value,
                        })
                    }
                />
                <Dropdown.Button
                    onClick={() => props.onDownload('xlsx')}
                    menu={{ items, onClick: () => props.onDownload('csv') }}
                >
                    Скачать в XLSX
                </Dropdown.Button>
            </Space>
        </Fragment>
    );
};

const StyledSelect = styled(Select)`
    min-width: 120px;
`;
