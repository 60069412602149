import React from 'react';
import { Col, List, Row, Space, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';

import Secondary from '../../../../../shared/ui/SecondaryText';
import { IEventCard } from '../../../interfaces/events.interface';

interface IEventsCard {
    readonly source: {
        items: Array<IEventCard>;
    };
}
export const EventsCard: React.FC<IEventsCard> = props => {
    const { source } = props;

    return (
        <List
            dataSource={source.items}
            renderItem={event => (
                <StyledListItem>
                    <Space
                        direction={'vertical'}
                        style={{
                            width: '100%',
                        }}
                    >
                        <Row>
                            <Col span={12}>
                                <Typography.Text>
                                    {event.telegram_user}
                                </Typography.Text>
                            </Col>

                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Tooltip title={event.event}>
                                    <Typography.Paragraph
                                        style={{
                                            marginTop: '0',
                                            marginBottom: '0',
                                        }}
                                        ellipsis={{
                                            rows: 1,
                                        }}
                                    >
                                        <Secondary>{event.event}</Secondary>
                                    </Typography.Paragraph>
                                </Tooltip>
                            </Col>
                        </Row>

                        <Typography.Paragraph
                            strong
                            style={{ marginTop: '0', marginBottom: '0' }}
                            ellipsis={{
                                rows: 2,
                            }}
                        >
                            {event.community}
                        </Typography.Paragraph>

                        {event.community_tags.length === 0 ? null : (
                            <Secondary>
                                {event.community_tags.map((tag, index) => (
                                    <Tag key={index}>{tag.name}</Tag>
                                ))}
                            </Secondary>
                        )}

                        <Col span={24}>
                            <Secondary>
                                {dayjs(event.done_date).format(
                                    'DD.MM.YYYY, HH:mm'
                                )}
                            </Secondary>
                        </Col>
                    </Space>
                </StyledListItem>
            )}
        />
    );
};

const StyledListItem = styled(List.Item)``;
