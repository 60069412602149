import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';

import { AIKnowledgeForm } from '../views/AIKnowledgeForm';
import {
    useCreateQuestionAiMutation,
    useDeleteQuestionAiMutation,
    useReadQuestionAIByIdQuery,
} from '../../../services';
import { QuestionStatus } from '../../../interfaces/questions.interface';
import { createFormData } from '../../../../../utils/common/createFormData';

type TValues = {
    readonly id: number;
    readonly category: {
        id: number;
    };
    readonly knowledge: {
        id: number;
    };
    readonly context: string;
    readonly answer_text: string;
    readonly question_image: File | null;
    readonly answer_image: File | null;
};
export const AIKnowledgeEditContainer = () => {
    const { id } = useParams();
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [extendedCommunityId, setExtendedCommunityId] = useState<
        number | null
    >(null);
    const [initial, setInitial] = useState(null);
    const { data, isSuccess: isSuccessRead } = useReadQuestionAIByIdQuery(id);
    const [
        createQuestionAi,
        { isSuccess: isSuccessCreate, isLoading: isLoadingCreate },
    ] = useCreateQuestionAiMutation();

    const [deleteQuestionAi, { isSuccess: isSuccessDelete }] =
        useDeleteQuestionAiMutation();

    useEffect(() => {
        if (isSuccessRead) {
            setInitial({
                id: +id,
                context: data?.context,
                knowledge: null,
                category: null,
                community: data?.communities,
            });
            setExtendedCommunityId(data?.community_id);
        }
    }, [isSuccessRead]);

    useEffect(() => {
        if (isSuccessCreate) {
            navigate(-1);
            notification.success({
                message: 'Вопрос успешно сохранен',
            });
        }
    }, [isSuccessCreate]);

    useEffect(() => {
        if (isSuccessDelete) {
            notification.success({
                message: 'Вопрос успешно удален',
            });
        }
    }, [isSuccessDelete]);

    const onSave = async (values: TValues): Promise<void> => {
        if (!values.knowledge || !values.knowledge.id) {
            return notification.error({
                message: 'Нужно выбрать базу знаний',
            });
        }

        if (!values.answer_text) {
            return notification.error({
                message: 'Нужно ввести ответ на вопрос',
            });
        }

        const requestObject = async () => {
            const data = {
                id: +id,
                knowledge_id: values.knowledge?.id,
                category_id: values.category?.id,
                community_id: extendedCommunityId,
                question_status: QuestionStatus.PUBLISHED,

                question_text: values.context,
                answer_text: values.answer_text,

                question_image: values.question_image as File,
                answer_image: values.answer_image as File,
            };

            if (!(values.question_image instanceof File)) {
                delete data.question_image;
            }
            if (!(values.answer_image instanceof File)) {
                delete data.answer_image;
            }
            if (values.answer_text === undefined) {
                delete data.answer_text;
            }
            if (!values.category) delete data.category_id;

            await createQuestionAi({
                id: Number(id),
                body: createFormData(data),
            });
        };
        await requestObject();
    };

    if (!id && !data) {
        return <></>;
    }

    const onDelete = async id => {
        await deleteQuestionAi(id);
    };

    return (
        initial && (
            <AIKnowledgeForm
                loading={isLoadingCreate}
                initialValues={initial}
                onSave={onSave}
                onDelete={onDelete}
            />
        )
    );
};
