import { IFormikInitialValues } from './containers/ReputationContainerCreate';
import {
    ICreateReputationRequest,
    IReputation,
} from '../../interfaces/reputation.interface';
import { getSrc } from '../../../../utils/common/getSrc';

export enum ReputationSwitchesEnum {
    whoCanRateSwitch = 'whoCanRateSwitch',
    restrictRatePeriodSwitch = 'restrictRatePeriodSwitch',
    delayStartRulesTimeSwitch = 'delayStartRulesTimeSwitch',
    userRateSwitch = 'userRateSwitch',
    notifyChangeRateSwitch = 'notifyChangeRateSwitch',
    restrictAccumulateRateSwitch = 'restrictAccumulateRateSwitch',
}

export class ReputationMapper {
    private static convertSwitchesMap = new Map<
        ReputationSwitchesEnum,
        (value) => any
    >([
        [
            ReputationSwitchesEnum.whoCanRateSwitch,
            value => {
                return {
                    ...(value?.who_can_rate && {
                        who_can_rate: value.who_can_rate,
                    }),
                };
            },
        ],
        [
            ReputationSwitchesEnum.restrictRatePeriodSwitch,
            value => {
                return {
                    ...(value?.restrict_rate_member_period && {
                        restrict_rate_member_period:
                            value.restrict_rate_member_period,
                    }),
                };
            },
        ],
        [
            ReputationSwitchesEnum.delayStartRulesTimeSwitch,
            value => {
                return {
                    ...(value?.delay_start_rules_seconds && {
                        delay_start_rules_seconds:
                            value.delay_start_rules_seconds,
                    }),
                    ...(value?.delay_start_rules_total_messages && {
                        delay_start_rules_total_messages:
                            value.delay_start_rules_total_messages,
                    }),
                };
            },
        ],
        [
            ReputationSwitchesEnum.userRateSwitch,
            value => {
                return {
                    ...(value?.show_rating_tables && {
                        show_rating_tables: value.show_rating_tables,
                    }),
                    ...(value?.show_rating_tables_period && {
                        show_rating_tables_period:
                            value.show_rating_tables_period,
                    }),
                    ...(value?.show_rating_tables_time && {
                        show_rating_tables_time: value.show_rating_tables_time,
                    }),
                    ...(value?.show_rating_tables_number_of_users && {
                        show_rating_tables_number_of_users:
                            value.show_rating_tables_number_of_users,
                    }),
                    ...(value?.show_rating_tables_image && {
                        show_rating_tables_image:
                            value.show_rating_tables_image,
                    }),
                    ...(value?.show_rating_tables_message && {
                        show_rating_tables_message:
                            value.show_rating_tables_message,
                    }),
                };
            },
        ],
        [
            ReputationSwitchesEnum.notifyChangeRateSwitch,
            value => {
                return {
                    ...(value?.notify_about_rate_change && {
                        notify_about_rate_change:
                            value.notify_about_rate_change,
                    }),
                    ...(value?.notify_about_rate_change_points && {
                        notify_about_rate_change_points:
                            value.notify_about_rate_change_points,
                    }),
                    ...(value?.notify_about_rate_change_image && {
                        notify_about_rate_change_image:
                            value.notify_about_rate_change_image,
                    }),
                    ...(value?.notify_about_rate_change_message && {
                        notify_about_rate_change_message:
                            value.notify_about_rate_change_message,
                    }),
                };
            },
        ],
        [
            ReputationSwitchesEnum.restrictAccumulateRateSwitch,
            value => {
                return {
                    ...(value?.restrict_accumulate_rate && {
                        restrict_accumulate_rate:
                            value.restrict_accumulate_rate,
                    }),
                    ...(value?.restrict_accumulate_rate_time && {
                        restrict_accumulate_rate_time:
                            value.restrict_accumulate_rate_time,
                    }),
                    ...(value?.restrict_accumulate_rate_period && {
                        restrict_accumulate_rate_period:
                            value.restrict_accumulate_rate_period,
                    }),
                    ...(value?.restrict_accumulate_rate_image && {
                        restrict_accumulate_rate_image:
                            value.restrict_accumulate_rate_image,
                    }),
                    ...(value?.restrict_accumulate_rate_message && {
                        restrict_accumulate_rate_message:
                            value.restrict_accumulate_rate_message,
                    }),
                };
            },
        ],
    ]);
    public static createDto(
        values: IFormikInitialValues
    ): ICreateReputationRequest {
        const arraySwitches = Object.entries(values.switches) as [
            ReputationSwitchesEnum,
            any
        ][];
        const arrayCheckedSwitches = arraySwitches.filter(([_, value]) => {
            return value.isChecked;
        });
        const requestObject: ICreateReputationRequest = {
            title: values.title,
            keyword_rate_up: values.keyword_rate_up,
            keyword_rate_down: values.keyword_rate_down,
            community_ids: values.selectedChatIds,
        };
        if (arrayCheckedSwitches.length) {
            arrayCheckedSwitches.forEach(([key, value]) => {
                if (this.convertSwitchesMap.get(key)) {
                    Object.assign(
                        requestObject,
                        this.convertSwitchesMap.get(key)(value)
                    );
                }
            });
        }
        return requestObject;
    }
    public static convertDto(data: IReputation): IFormikInitialValues {
        return {
            title: data?.title || '',
            keyword_rate_up: data?.keywords_up || [],
            keyword_rate_down: data?.keywords_down || [],
            selectedChatIds: data?.communities || [],
            switches: {
                [ReputationSwitchesEnum.whoCanRateSwitch]: {
                    isChecked: Boolean(data?.who_can_rate),
                    ...(data?.who_can_rate && {
                        who_can_rate: data.who_can_rate,
                    }),
                },
                [ReputationSwitchesEnum.restrictRatePeriodSwitch]: {
                    isChecked: Boolean(data?.restrict_rate_member_period),
                    ...(data?.restrict_rate_member_period && {
                        restrict_rate_member_period:
                            data.restrict_rate_member_period,
                    }),
                },
                [ReputationSwitchesEnum.delayStartRulesTimeSwitch]: {
                    isChecked:
                        Boolean(data?.delay_start_rules_seconds) ||
                        Boolean(data?.delay_start_rules_total_messages),
                    ...(data?.delay_start_rules_seconds && {
                        delay_start_rules_seconds:
                            data.delay_start_rules_seconds,
                    }),
                    ...(data?.delay_start_rules_total_messages && {
                        delay_start_rules_total_messages:
                            data.delay_start_rules_total_messages,
                    }),
                },
                [ReputationSwitchesEnum.userRateSwitch]: {
                    isChecked: Boolean(data?.show_rating_tables),
                    ...(data?.show_rating_tables && {
                        show_rating_tables: data.show_rating_tables,
                    }),
                    ...(data?.show_rating_tables_period && {
                        show_rating_tables_period:
                            data.show_rating_tables_period,
                    }),
                    ...(data?.show_rating_tables_time && {
                        show_rating_tables_time: data.show_rating_tables_time,
                    }),
                    ...(data?.show_rating_tables_number_of_users && {
                        show_rating_tables_number_of_users:
                            data.show_rating_tables_number_of_users,
                    }),
                    ...(data?.show_rating_tables_image && {
                        show_rating_tables_image: getSrc(
                            data.show_rating_tables_image as string
                        ),
                    }),
                    ...(data?.show_rating_tables_message && {
                        show_rating_tables_message:
                            data.show_rating_tables_message,
                    }),
                },
                [ReputationSwitchesEnum.notifyChangeRateSwitch]: {
                    isChecked: Boolean(data?.notify_about_rate_change),
                    ...(data?.notify_about_rate_change && {
                        notify_about_rate_change: data.notify_about_rate_change,
                    }),
                    ...(data?.notify_about_rate_change_points && {
                        notify_about_rate_change_points:
                            data.notify_about_rate_change_points,
                    }),
                    ...(data?.notify_about_rate_change_image && {
                        notify_about_rate_change_image: getSrc(
                            data.notify_about_rate_change_image as string
                        ),
                    }),
                    ...(data?.notify_about_rate_change_message && {
                        notify_about_rate_change_message:
                            data.notify_about_rate_change_message,
                    }),
                },
                [ReputationSwitchesEnum.restrictAccumulateRateSwitch]: {
                    isChecked: Boolean(data?.restrict_accumulate_rate),
                    ...(data?.restrict_accumulate_rate && {
                        restrict_accumulate_rate: data.restrict_accumulate_rate,
                    }),
                    ...(data?.restrict_accumulate_rate_time && {
                        restrict_accumulate_rate_time:
                            data.restrict_accumulate_rate_time,
                    }),
                    ...(data?.restrict_accumulate_rate_period && {
                        restrict_accumulate_rate_period:
                            data.restrict_accumulate_rate_period,
                    }),
                    ...(data?.restrict_accumulate_rate_image && {
                        restrict_accumulate_rate_image: getSrc(
                            data.restrict_accumulate_rate_image as string
                        ),
                    }),
                    ...(data?.restrict_accumulate_rate_message && {
                        restrict_accumulate_rate_message:
                            data.restrict_accumulate_rate_message,
                    }),
                },
            },
        };
    }
}
