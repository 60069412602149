import React, { useEffect, useState } from 'react';
import { App } from 'antd';
import { useParams } from 'react-router-dom';

import {
    useReadQuestionQuery,
    useDeleteQuestionMutation,
} from '../../../services';
import { QuestionTable } from './QuestionTableContainer';

export const QuestionContainer = () => {
    const { id } = useParams();
    const [filters, setFilters] = useState({});
    const { notification } = App.useApp();

    const { data: source = { items: [], total: 0 }, isFetching } =
        useReadQuestionQuery(id);
    const [deleteQuestion, { isSuccess }] = useDeleteQuestionMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Вопрос удален успешно',
                placement: 'top',
            });
        }
    }, [isSuccess]);

    const handleFiltersUpdate = update => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({});
        }
    };

    return (
        <QuestionTable
            source={source}
            loading={isFetching}
            handleFiltersUpdate={handleFiltersUpdate}
            deleteQuestion={deleteQuestion}
        />
    );
};
