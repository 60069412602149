import { FilterOutlined } from '@ant-design/icons';
import { Badge, Tag, Typography } from 'antd';
import React from 'react';
import { ColumnsType } from 'antd/es/table';

import { TableFilter } from '../../../../shared/ui/TableFilter';
import { ICommunity, ICommunityQueryParams } from '../../interfaces';
import { filterRules, ITableOptions } from './ChatList';
import { RuleType } from '../../../../shared/contants/rule-types';

export const getColumnsOptions: (
    filters: ICommunityQueryParams,
    handleFiltersUpdate: (update: ICommunityQueryParams | null) => void,
    tableOptions: Partial<ITableOptions>,
    selectedRows: number[]
) => ColumnsType<ICommunity> = (
    filters,
    handleFiltersUpdate,
    tableOptions,
    selectedRows
) => {
    const titleMap: Record<RuleType, string> = {
        if_then_rule: 'Правила Если-То',
        moderation_rule: 'Правило поведения',
        antispam_rule: 'Антиспам правило',
        onboarding_rule: 'Онбординг',
        reputation_rule: 'Правило репутации',
    };
    return [
        {
            title: () => (
                <TableFilter<string>
                    title='Название сообщества'
                    onChange={name => {
                        handleFiltersUpdate({ name });
                    }}
                    value={filters.name}
                >
                    <TableFilter.Text />
                </TableFilter>
            ),
            dataIndex: 'title',
            key: 'title',
            width: '40%',
            render: text => text,
        },
        {
            title: () => (
                <TableFilter<string[]>
                    icon={<FilterOutlined />}
                    onChange={rule_names => {
                        handleFiltersUpdate({ rule_names });
                    }}
                    title={titleMap[tableOptions.entityType]}
                    value={filters.rule_names}
                >
                    <TableFilter.Tags />
                </TableFilter>
            ),
            dataIndex: 'rules',
            key: 'rules',
            width: '25%',
            render: (text, record) => {
                const filteredRules = filterRules(
                    record.rules,
                    tableOptions.entityType
                );
                const isSelected = selectedRows.find(el => el === record.id);
                return (
                    <>
                        {filteredRules?.length ? (
                            <>
                                {tableOptions?.rowExpandable ? (
                                    <Typography.Text>
                                        {filteredRules.length}
                                    </Typography.Text>
                                ) : (
                                    <>
                                        {isSelected ? (
                                            <>
                                                {filteredRules.map(
                                                    (rule, index) => (
                                                        <div key={index}>
                                                            <Badge
                                                                color='orange'
                                                                offset={[-8, 0]}
                                                                title='Текущее правило заменит действующее'
                                                            />
                                                            <Typography.Text>
                                                                {rule.name ||
                                                                    rule.title}
                                                            </Typography.Text>
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {filteredRules.map(
                                                    (rule, index) => (
                                                        <div key={index}>
                                                            <Typography.Text>
                                                                {rule.name ||
                                                                    rule.title}
                                                            </Typography.Text>
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <Typography.Text>Не назначено</Typography.Text>
                        )}
                    </>
                );
            },
        },
        {
            title: () => (
                <TableFilter<string[]>
                    icon={<FilterOutlined />}
                    onChange={tags_names => {
                        handleFiltersUpdate({ tags_names });
                    }}
                    title='Теги'
                    value={filters.tags_names}
                >
                    <TableFilter.Tags />
                </TableFilter>
            ),
            dataIndex: 'tags',
            key: 'tags',
            render: (text, record) => (
                <>
                    {record.tags.map(tag => (
                        <Tag color='default' key={tag.id}>
                            {tag.name}
                        </Tag>
                    ))}
                </>
            ),
        },
    ];
};
