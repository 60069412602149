import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';

import {
    useCreateKnowledgeBindCommunitiesMutation,
    useReadKnowledgeByIdQuery,
    useUpdateKnowledgeMutation,
} from '../../../services';
import { KnowledgeForm } from '../views/KnowledgeForm';
import { IKnowledgeCreate } from '../../../interfaces/knowledge.interface';

export const KnowledgeCreateContainer: FC = () => {
    const { notification } = App.useApp();
    const { id } = useParams();

    const navigate = useNavigate();

    const [bindCommunities] = useCreateKnowledgeBindCommunitiesMutation();
    const [updateKnowledge, { isSuccess: isSuccessUpdate }] =
        useUpdateKnowledgeMutation();
    const { data, isSuccess, isError } = useReadKnowledgeByIdQuery(id);
    useEffect(() => {
        if (isError) {
            navigate(-1);
        }
    }, [isError]);

    useEffect(() => {
        if (isSuccessUpdate) {
            navigate(-1);
        }
    }, [isSuccessUpdate]);

    const onSave = async (values: IKnowledgeCreate) => {
        if (values?.community_ids?.length > 0) {
            const data = {
                knowledge_id: values.knowledge_id,
                community_ids: values.community_ids,
            };
            await bindCommunities(data);
        }
        await updateKnowledge(values);
    };

    return data && <KnowledgeForm knowledge={data} onSave={onSave} />;
};
