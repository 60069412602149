import React, { useEffect, useState } from 'react';
import { App, Col, Space } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import { useCreateChatTagsMutation } from '../../../services';
import { PaginatedResponse } from '../../../interfaces/PaginatedResponse';
import { ICommunity, ICommunityQueryParams } from '../../../interfaces';
import { ChatsTable } from '../components';
import { SearchMobile } from '../../../../../shared/ui/Search/SearchMobile';
import { MobileFilter } from '../../../../../shared/ui/MobileFilter';
import { TableFilter } from '../../../../../shared/ui/TableFilter';
import { ChatsCard } from '../views/ChatsCard';
import { FetchButton } from '../../../../../shared/ui/FetchButton';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { TagsEditor } from '../components/TagsEditor';
import { ClearFilters } from '../../../../../shared/ui/ClearFilters';
import { initialFilters } from '../utils/filters';

const getRowTags = (row_id: number, items: Array<any>): string[] => {
    return items.find(i => i.id === row_id).tags.map(t => t.name);
};

type TProps = {
    readonly source: PaginatedResponse<ICommunity>;
    readonly loading: boolean;
    readonly filters: ICommunityQueryParams;
    readonly onFiltersUpdate: (filters: ICommunityQueryParams) => void;
    readonly setFilters: (filters: ICommunityQueryParams) => void;
};
export const ChatsContainer: React.FC<TProps> = props => {
    const { notification } = App.useApp();
    const { size } = useWindowSize();

    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [commonTags, setCommonTags] = useState<string[]>([]);

    const [assignTags, { isSuccess, data }] = useCreateChatTagsMutation();

    useEffect(() => {
        if (isSuccess) {
            setSelectedRows([]);
            setCommonTags([]);
            notification.success({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                message: data?.message,
            });
        }
    }, [isSuccess]);

    const handleRowsChange = (rows: number[]) => {
        const rowTags: string[][] = [];
        for (let i = 0; i < rows.length; i++) {
            const currentRowTags = getRowTags(
                Number(rows[i]),
                props.source.items
            );
            rowTags.push(currentRowTags);
        }

        if (rowTags.length) {
            setCommonTags(
                rowTags.reduce((acc, current) =>
                    acc.filter(i => current.includes(i))
                )
            );
        } else {
            setCommonTags([]);
        }
        setSelectedRows(rows);
    };

    const rowSelection = {
        selectedRows: selectedRows,
        onChange: handleRowsChange,
    };

    const handleTagsChange = async (tags: string[]) => {
        try {
            await Promise.all(
                selectedRows.map(community_id =>
                    assignTags({
                        community_id: Number(community_id),
                        tags: Array.from(
                            new Set([
                                ...getRowTags(
                                    Number(community_id),
                                    props.source.items
                                ).filter(t => !commonTags.includes(t)),
                                ...tags,
                            ])
                        ),
                    })
                )
            );
            setCommonTags([...tags]);
            setSelectedRows([]);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div>
            {size === 'small' ? (
                <Space
                    direction={'vertical'}
                    size={'large'}
                    style={{ width: '100%' }}
                >
                    <SearchMobile
                        searchParam={'name'}
                        search={props.filters.name}
                        action={props.onFiltersUpdate}
                        loading={props.loading}
                    />

                    <Space direction={'horizontal'} size={'small'} wrap>
                        <MobileFilter
                            icon={<FilterOutlined />}
                            title='Дата добавления'
                            setFilters={props.onFiltersUpdate}
                            filters={props.filters}
                        >
                            <TableFilter.Date />
                        </MobileFilter>

                        <MobileFilter
                            icon={<FilterOutlined />}
                            title='Все теги'
                            onChange={tag_names => {
                                props.onFiltersUpdate({ tag_names });
                            }}
                            value={props.filters.tag_names}
                        >
                            <TableFilter.Tags />
                        </MobileFilter>
                    </Space>

                    {!!selectedRows.length && (
                        <Col xs={24}>
                            <TagsEditor
                                tags={commonTags}
                                count={selectedRows.length}
                                onChange={handleTagsChange}
                            />
                        </Col>
                    )}

                    <ClearFilters
                        filters={props.filters}
                        initialFilters={initialFilters}
                        setFilters={props.setFilters}
                    />

                    <ChatsCard
                        source={props.source}
                        selectedRows={selectedRows}
                        handleRowsChange={handleRowsChange}
                    />

                    {/* <FetchButton
                        filters={props.filters}
                        setFilters={props.setFilters}
                        total={props.source.total}
                        loading={props.loading}
                    />*/}
                </Space>
            ) : (
                <ChatsTable
                    rowSelection={rowSelection}
                    commonTags={commonTags}
                    handleTagsChange={handleTagsChange}
                    selectedRows={selectedRows}
                    setFilters={props.setFilters}
                    onFiltersUpdate={props.onFiltersUpdate}
                    filters={props.filters}
                    source={props.source}
                    loading={props.loading}
                />
            )}
        </div>
    );
};
