import React from 'react';
import { Col, Row } from 'antd';

import { StatsWidget } from '../../../../analytics/components/Charts/StatsWidget';

export const FinanceStatsContainer = props => {
    const { statistic } = props;
    return (
        <Row wrap gutter={[20, 20]}>
            <Col xs={24} sm={12} md={6} lg={6}>
                <StatsWidget
                    width={'auto'}
                    isLoading={props.isStatisticFetch}
                    title='Всего'
                    value={`${
                        statistic?.summ === 0
                            ? statistic?.summ
                            : statistic?.summ.toFixed(2) || 0
                    } ₽`}
                />
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <StatsWidget
                    width={'auto'}
                    isLoading={props.isStatisticFetch}
                    title='Донаты'
                    value={`${
                        statistic?.donate === 0
                            ? statistic?.donate
                            : statistic?.donate.toFixed(2) || 0
                    } ₽`}
                />
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <StatsWidget
                    width={'auto'}
                    isLoading={props.isStatisticFetch}
                    title='Тарифы'
                    value={`${
                        statistic?.tariff === 0
                            ? statistic?.tariff
                            : statistic?.tariff.toFixed(2) || 0
                    } ₽`}
                />
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <StatsWidget
                    width={'auto'}
                    isLoading={props.isStatisticFetch}
                    title='Медиатовары'
                    value={`${
                        statistic?.course === 0
                            ? statistic?.course
                            : statistic?.course.toFixed(2) || 0
                    } ₽`}
                />
            </Col>
        </Row>
    );
};
