import { IFormikInitialValues } from './containers/BehavioralContainerCreate';
import {
    IBehavioral,
    ICreateBehavioralRequest,
} from '../../interfaces/behavioral.interface';
import { getCommunitiesIds } from '../../../../utils/common/getCommunitiesIds';
import { getSrc } from '../../../../utils/common/getSrc';

export enum BehavioralSwitchesEnum {
    setOfRulesSwitch = 'setOfRulesSwitch',
    restrictedWordsSwitch = 'restrictedWordsSwitch',
    userComplaintSwitch = 'userComplaintSwitch',
}

export class BehavioralMapper {
    private static convertSwitchesMap = new Map<
        BehavioralSwitchesEnum,
        (value) => any
    >([
        [
            BehavioralSwitchesEnum.setOfRulesSwitch,
            value => {
                return {
                    ...(value?.content && { content: value.content }),
                    ...(value?.content_image && {
                        content_image: value.content_image,
                    }),
                };
            },
        ],
        [
            BehavioralSwitchesEnum.restrictedWordsSwitch,
            value => {
                return {
                    ...(value?.restricted_words && {
                        restricted_words: value.restricted_words,
                    }),
                    ...(value?.max_violation_times && {
                        max_violation_times: value.max_violation_times,
                    }),
                    ...(value?.warning && { warning: value.warning }),
                    ...(value?.warning_image && {
                        warning_image: value.warning_image,
                    }),
                    ...(value?.quiet_on_restricted_words && {
                        quiet_on_restricted_words:
                            value.quiet_on_restricted_words,
                    }),
                    ...(value?.action && { action: value.action }),
                };
            },
        ],
        [
            BehavioralSwitchesEnum.userComplaintSwitch,
            value => {
                return {
                    ...(value?.user_complaint_image && {
                        user_complaint_image: value.user_complaint_image,
                    }),
                    ...(value?.complaint_text && {
                        complaint_text: value.complaint_text,
                    }),
                    ...(value?.quiet_on_complaint && {
                        quiet_on_complaint: value.quiet_on_complaint,
                    }),
                };
            },
        ],
    ]);
    public static createDto(
        values: IFormikInitialValues
    ): ICreateBehavioralRequest {
        const arraySwitches: [BehavioralSwitchesEnum, any][] = Object.entries(
            values.switches
        ) as [BehavioralSwitchesEnum, any][];
        const arrayCheckedSwitches = arraySwitches.filter(([_, value]) => {
            return value.isChecked;
        });
        const requestObject: ICreateBehavioralRequest = {
            name: values.name,
            community_ids: values.selectedChatIds,
        };
        if (arrayCheckedSwitches.length) {
            arrayCheckedSwitches.forEach(([key, value]) => {
                if (this.convertSwitchesMap.get(key)) {
                    Object.assign(
                        requestObject,
                        this.convertSwitchesMap.get(key)(value)
                    );
                }
            });
        }
        return requestObject;
    }
    public static convertDto(data: IBehavioral): IFormikInitialValues {
        return {
            name: data?.name || '',
            selectedChatIds: data?.communities || [],
            switches: {
                [BehavioralSwitchesEnum.setOfRulesSwitch]: {
                    isChecked:
                        Boolean(data?.content) ||
                        Boolean(data?.content_image_path),
                    ...(data?.content && { content: data.content }),
                    ...(data?.content_image_path && {
                        content_image: getSrc(data.content_image_path),
                    }),
                },
                [BehavioralSwitchesEnum.restrictedWordsSwitch]: {
                    isChecked:
                        Boolean(data?.restricted_words?.length) ||
                        Boolean(data?.max_violation_times) ||
                        Boolean(data?.warning) ||
                        Boolean(data?.warning_image_path) ||
                        Boolean(data?.quiet_on_restricted_words) ||
                        Boolean(data?.action),
                    ...(data?.restricted_words && {
                        restricted_words: data.restricted_words,
                    }),
                    ...(data?.max_violation_times && {
                        max_violation_times: data.max_violation_times,
                    }),
                    ...(data?.warning && { warning: data.warning }),
                    ...(data?.warning_image_path && {
                        warning_image: getSrc(data.warning_image_path),
                    }),
                    ...(data?.quiet_on_restricted_words && {
                        quiet_on_restricted_words:
                            data.quiet_on_restricted_words,
                    }),
                    ...(data?.action && { action: data.action }),
                },
                [BehavioralSwitchesEnum.userComplaintSwitch]: {
                    isChecked:
                        Boolean(data?.user_complaint_image_path) ||
                        Boolean(data?.complaint_text) ||
                        Boolean(data?.quiet_on_complaint),
                    ...(data?.user_complaint_image_path && {
                        user_complaint_image: getSrc(
                            data.user_complaint_image_path
                        ),
                    }),
                    ...(data?.complaint_text && {
                        complaint_text: data.complaint_text,
                    }),
                    ...(data?.quiet_on_complaint && {
                        quiet_on_complaint: data.quiet_on_complaint,
                    }),
                },
            },
        };
    }
}
