import React, { Fragment, useState } from 'react';
import { Button, Card, Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';

import { useTheme } from 'hooks/useTheme';
import { getDeclension } from 'utils/common/getDeclension';

import { QuestionContainer } from '../../../Questions/containers/public/QuestionContainer';

interface IProps {
    loading?: boolean;
    setValue?: (questions: string) => void;
    searchWords?: string[];
    source?: any | [];
    questionsWOCategory?: any | [];
}
export const CategoryListContainer: React.FC<IProps> = props => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [questionData, setQuestionData] = useState([]);
    const token = useTheme();

    return (
        <Fragment>
            <Row gutter={[20, 20]}>
                {props.source?.map((category, index) => (
                    <Col xs={24} sm={24} md={20} lg={12} key={index}>
                        <Card
                            key={index}
                            style={{
                                ...token.Card,
                                width: 'auto',
                                height: '245px',
                            }}
                            type='inner'
                            loading={props?.loading}
                        >
                            <StyledQuestion>
                                <StyledTitle>{category.name}</StyledTitle>
                            </StyledQuestion>
                            <StyledDescription>
                                {category.questions
                                    .slice(0, 3)
                                    .map((question, index) => (
                                        <StyledQuestion key={index}>
                                            <Typography.Link
                                                style={{
                                                    color: '#6257d2',
                                                    paddingLeft: '15px',
                                                    marginBottom: '.5rem',
                                                }}
                                                ellipsis={true}
                                                onClick={() => {
                                                    setQuestionData(question);
                                                    setIsOpen(true);
                                                }}
                                            >
                                                {/*<Highlighter
                                                    highlightClassName='YourHighlightClass'
                                                    searchWords={
                                                        props.searchWords
                                                    }
                                                    autoEscape={true}
                                                    textToHighlight={
                                                        question.context
                                                    }
                                                />*/}
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: question.context,
                                                    }}
                                                ></span>
                                            </Typography.Link>
                                        </StyledQuestion>
                                    ))}
                                <div>
                                    {category.questions.length > 3 ? (
                                        <Button
                                            onClick={() =>
                                                props.setValue('questions')
                                            }
                                            type={'link'}
                                        >
                                            Еще {category.questions.length - 3}
                                            {getDeclension(
                                                category.questions.length - 3,
                                                ' вопрос',
                                                ' вопроса',
                                                ' вопросов'
                                            )}
                                        </Button>
                                    ) : null}
                                </div>
                            </StyledDescription>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Row style={{ marginTop: 20 }}>
                {props?.questionsWOCategory?.length > 0 ? (
                    <Col xs={24} sm={24} md={20} lg={12}>
                        <Card
                            style={{
                                ...token.Card,
                                width: 'auto',
                                height: '245px',
                            }}
                            type='inner'
                            loading={props?.loading}
                        >
                            <StyledQuestion>
                                <StyledTitle>-</StyledTitle>
                            </StyledQuestion>

                            {props.questionsWOCategory
                                .slice(0, 3)
                                .map((question, index) => (
                                    <StyledDescription key={index}>
                                        <StyledQuestion>
                                            <StyledButton
                                                type={'link'}
                                                onClick={() => {
                                                    setQuestionData(question);
                                                    setIsOpen(true);
                                                }}
                                            >
                                                {/*<Highlighter
                                                    searchWords={
                                                        props.searchWords
                                                    }
                                                    autoEscape={true}
                                                    textToHighlight={
                                                        question.context
                                                    }
                                                />*/}
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: question.context,
                                                    }}
                                                ></span>
                                            </StyledButton>
                                        </StyledQuestion>
                                    </StyledDescription>
                                ))}
                            <Fragment>
                                {props.questionsWOCategory?.length > 3 ? (
                                    <Button
                                        onClick={() =>
                                            props.setValue('questions')
                                        }
                                        type={'link'}
                                    >
                                        Еще{' '}
                                        {props.questionsWOCategory?.length - 3}
                                        {getDeclension(
                                            props.questionsWOCategory?.length -
                                                3,
                                            ' вопрос',
                                            ' вопроса',
                                            ' вопросов'
                                        )}
                                    </Button>
                                ) : null}
                            </Fragment>
                        </Card>
                    </Col>
                ) : null}
            </Row>

            {questionData ? (
                <QuestionContainer
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    data={questionData}
                />
            ) : null}
        </Fragment>
    );
};

const StyledButton = styled(Button)`
    text-overflow: ellipsis;
    white-space: nowrap;
    // overflow: hidden;
    width: inherit;
`;

const StyledTitle = styled.h3`
    padding: 0 15px;
    font-weight: 600;
    font-size: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const StyledDescription = styled.div`
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const StyledQuestion = styled.div`
    max-width: 100%;
    display: flex;
`;
