import { FormikErrors } from 'formik';
import React from 'react';

export interface ICreateQuestionProps {
    imgRef: React.MutableRefObject<any>;
    formik: {
        setFieldValue: (string, any) => Promise<void | FormikErrors<any>>;
        values: IQuestionCreate & IQuestionResponse;
    };
    img: any;
}

export interface IQuestionFormProps {
    onSave: (values) => void;
    initialValues: any;
    knowledgeId?: number;
    loading: boolean;
    readonly isAi?: boolean;
}
//
export interface IQuestionCreate {
    knowledge_id: number | any;
    question_status: QuestionStatus.PUBLISHED;
    category_id: number | null;
    category_name?: string;
    question_text: string;
    question_image: File | string;
    answer_text: string;
    answer_image: File | string;
}

export interface IQuestionResponse {
    answer: {
        context: string;
        image: string;
    };
    image: string;
    context: string;
}

export enum QuestionStatus {
    DRAFT = 'draft',
    DRAFT_AUTO = 'draft_auto',
    PUBLISHED = 'published',
}

export interface IQuestionTableProps {
    deleteQuestion: (number) => void;
    handleFiltersUpdate: (update) => void;
    loading: boolean;
    source: {
        items: [];
        total: number;
    };
}
