import React, { Fragment, useState } from 'react';
import { App, Button, Card, List, Skeleton, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import postPlaceholder from '../../../../../../../../static/images/post_placeholder.png';
import { getSrc } from '../../../../../../../../utils/common/getSrc';
import { useTheme } from '../../../../../../../../hooks/useTheme';
import { useReadWebinarsPublicQuery } from '../../../../../../services/webinars';
import { WebinarType } from '../WebinarContainer';
import { ListTitle } from '../../components/ListTitle';
import { OnlineDot } from '../../components/OnlineDot';
import { RegisterModal } from '../../../pay/containers/RegisterModal';
import { iSource, PayModal } from '../../../pay/containers/PayModal';

interface IWebinars {
    readonly type: WebinarType;
}

export const WebinarsPublicList: React.FC<IWebinars> = props => {
    const { id } = useParams();
    const token = useTheme();
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [filters, setFilters] = useState({
        limit: 3,
        offset: 0,
        type: props.type,
    });
    const [webinarData, setWebinarData] = useState<iSource>({
        title: '',
        price: 0,
        uuid: '',
    });
    const [openRegister, setOpenRegister] = useState(false);
    const [openPay, setOpenPay] = useState(false);

    const { data, isSuccess, isFetching } = useReadWebinarsPublicQuery({
        id,
        ...filters,
    });

    const handleFiltersUpdate = update => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        }
    };

    const handleWebinarLink = link => {
        if (link) {
            navigator.clipboard.writeText(link);
            notification.info({
                message: 'Ссылка скопирована',
                placement: 'top',
                duration: 1,
            });
        } else {
            notification.error({
                message: 'Ссылка не найдена',
                placement: 'top',
                duration: 1.5,
            });
        }
    };

    const handleReadWebinar = webinar => {
        const isAuthenticated = !!localStorage.getItem('accessToken');

        if (webinar.price > 0) {
            setWebinarData(webinar);
            return setOpenPay(true);
        }

        if (!isAuthenticated) {
            setWebinarData(webinar);
            return setOpenRegister(true);
        }
        navigate(`/courses/member/webinar-preview/${webinar.uuid}`);
    };

    return (
        <Fragment>
            <RegisterModal
                source={webinarData || []}
                setOpen={setOpenRegister}
                open={openRegister}
                type={'webinar'}
            />
            <PayModal
                open={openPay}
                setOpen={setOpenPay}
                source={webinarData}
                type={'webinar'}
            />
            <ListTitle type={props.type} />
            {isSuccess && data ? (
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 3,
                        xl: 3,
                        xxl: 3,
                    }}
                    loading={isFetching}
                    dataSource={data || []}
                    renderItem={(webinar, index) => (
                        <List.Item>
                            <StyledCard
                                key={index}
                                type='inner'
                                style={token.Card}
                                /* cover={
                                    <StyledCover
                                        src={
                                            !webinar.background_image
                                                ? postPlaceholder
                                                : getSrc(
                                                      `/storage/${webinar.background_image}`
                                                  )
                                        }
                                        alt='Publication preview'
                                    />
                                }*/
                                cover={
                                    webinar.price > 0 ? (
                                        <StyledCoverHidden
                                            src={
                                                !webinar.background_image
                                                    ? postPlaceholder
                                                    : getSrc(
                                                          `/storage/${webinar.background_image}`
                                                      )
                                            }
                                        />
                                    ) : (
                                        <StyledCover
                                            src={
                                                !webinar.background_image
                                                    ? postPlaceholder
                                                    : getSrc(
                                                          `/storage/${webinar.background_image}`
                                                      )
                                            }
                                            alt='Webinar preview'
                                        />
                                    )
                                }
                            >
                                <StyledType>
                                    {webinar.type === 'online' && (
                                        <StyledDescription
                                            style={{ marginLeft: 0 }}
                                            type={'secondary'}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <OnlineDot /> в эфире
                                            </div>
                                        </StyledDescription>
                                    )}
                                    <StyledDescription type={'secondary'}>
                                        {webinar.start_at}
                                    </StyledDescription>
                                </StyledType>

                                <StyledTitle level={5}>
                                    {webinar.title}
                                </StyledTitle>
                                <StyledDescription
                                    ellipsis={{
                                        rows: 2,
                                    }}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: webinar.description,
                                        }}
                                    ></span>
                                </StyledDescription>
                                <StyledActions>
                                    <Fragment>
                                        <Button
                                            onClick={() =>
                                                handleReadWebinar(webinar)
                                            }
                                            style={{ width: '100%' }}
                                        >
                                            {webinar.price > 0
                                                ? `Купить доступ за ${webinar.price} ₽`
                                                : 'Смотреть'}
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                handleWebinarLink(
                                                    webinar.external_url
                                                )
                                            }
                                        >
                                            <LinkOutlined />
                                        </Button>
                                    </Fragment>
                                </StyledActions>
                            </StyledCard>
                        </List.Item>
                    )}
                    pagination={{
                        hideOnSinglePage: true,
                        showLessItems: true,
                        current: filters.offset / filters.limit + 1,
                        total: data?.total,
                        defaultPageSize: 3,
                        showTotal: () => `Вебинаров: ${data?.total}`,
                        onChange: (page, pageSize) => {
                            handleFiltersUpdate({
                                limit: pageSize,
                                offset: (page - 1) * pageSize,
                            });
                        },
                    }}
                />
            ) : (
                <Skeleton />
            )}
        </Fragment>
    );
};

const StyledCard = styled(Card)`
    //flex: 0 0 33.333333%;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 0.25rem 0;
`;

const StyledDescription = styled(Typography.Paragraph)`
    margin: 0.25rem 0 0.15rem auto;
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
`;

const StyledCover = styled.img`
    object-fit: cover;
    width: 150px;
    height: 250px;
`;

const StyledType = styled.div`
    justify-content: space-between;
    display: flex;
`;

const StyledCoverHidden = styled.img`
    filter: blur(0.25rem);
    object-fit: cover;
    width: 150px;
    height: 250px;
`;
