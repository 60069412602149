import React, { FC, Fragment } from 'react';
import { Typography } from 'antd';

export const DonateThanks: FC = () => {
    return (
        <Fragment>
            <div>
                <p>
                    <Typography.Title level={1}>Спасибо!</Typography.Title>
                </p>
                <p>
                    <Typography.Text>
                        Вы успешно оплатили донат.
                    </Typography.Text>
                </p>
            </div>
        </Fragment>
    );
};
