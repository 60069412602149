import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { BackButton } from 'shared/ui/BackButton';
import { useAddMemberToListMutation } from 'features/communities/services';

import { MembersTableSelectorContainer } from './MembersTableSelectorContainer';
import { ChatsTableSelectorContainer } from './ChatsTableSelectorContainer';
import { ConfirmBlock } from '../../ConfirmBlock/ConfirmBlock';

type Props = unknown;
export const AddToBlackListPage: React.FC<Props> = props => {
    const [selectedMemberIds, setSelectedMemberIds] = useState<number[]>([]);
    const [selectedChatIds, setSelectedChatIds] = useState<number[]>([]);

    const navigate = useNavigate();

    const [addToBlackList] = useAddMemberToListMutation();

    const canConfirm = !!selectedChatIds.length && !!selectedMemberIds.length;

    const handleConfirm = async () => {
        await Promise.all(
            selectedMemberIds.map(member => {
                return addToBlackList({
                    telegram_id: member,
                    community_ids: selectedChatIds,
                    banned: true,
                    muted: false,
                    whitelisted: false,
                    is_spammer: 0,
                    kick: false,
                });
            })
        );
        navigate(-1);
    };

    return (
        <StyledWrapper>
            <BackButton text='Добавить в ЧС' />
            {selectedMemberIds.length > 0 && (
                <StyledSelectedCounterText>
                    Выбрано пользователей: {selectedMemberIds.length}
                </StyledSelectedCounterText>
            )}
            <StyledTableWrapper>
                <MembersTableSelectorContainer
                    value={selectedMemberIds}
                    onChange={setSelectedMemberIds}
                />
            </StyledTableWrapper>
            {selectedChatIds.length > 0 && (
                <StyledSelectedCounterText>
                    Выбрано чатов: {selectedChatIds.length}
                </StyledSelectedCounterText>
            )}
            <ChatsTableSelectorContainer
                onChange={setSelectedChatIds}
                value={selectedChatIds}
            />
            <ConfirmBlock
                disabled={!canConfirm}
                onSave={handleConfirm}
                onCancel={() => {
                    navigate(-1);
                }}
            >
                <Fragment>
                    Выбранные пользователи будут добавлены в ЧС в{' '}
                    {selectedChatIds.length} чатах
                </Fragment>
            </ConfirmBlock>
        </StyledWrapper>
    );
};
const StyledWrapper = styled.div`
    margin-top: 48px;
`;
const StyledTableWrapper = styled.div`
    margin-top: 16px;
`;
const StyledSelectedCounterText = styled.div`
    margin-top: 40px;
`;
