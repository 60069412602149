import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';

import { KnowledgeForm } from '../views/KnowledgeForm';
import {
    useReadKnowledgeByIdQuery,
    useUpdateKnowledgeMutation,
    useCreateKnowledgeBindCommunitiesMutation,
} from '../../../services';
import { IKnowledgeCreate } from '../../../interfaces/knowledge.interface';

export const KnowledgeEditContainer: FC = () => {
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const { id } = useParams();

    const [knowledge, setKnowledge] = useState(null);

    const [updateKnowledge, { isSuccess }] = useUpdateKnowledgeMutation();

    const { data: source, isFetching } = useReadKnowledgeByIdQuery(Number(id));
    const [bindCommunities] = useCreateKnowledgeBindCommunitiesMutation();

    useEffect(() => {
        if (!isFetching) {
            setKnowledge(source);
        }
    }, [source]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'База знаний успешно изменена',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    const onSave = async (values: IKnowledgeCreate) => {
        if (values?.community_ids?.length > 0) {
            const data = {
                knowledge_id: values.knowledge_id,
                community_ids: values.community_ids,
                knowledge_name: values.knowledge_name,
            };
            await bindCommunities(data);
            await updateKnowledge(data);
        } else {
            const data = {
                knowledge_id: values.knowledge_id,
                knowledge_name: values.knowledge_name,
            };
            await bindCommunities(data);
            await updateKnowledge(data);
        }
    };

    return knowledge && <KnowledgeForm knowledge={knowledge} onSave={onSave} />;
};
