import React, { useEffect } from 'react';
import { App, Card, Typography } from 'antd';
import styled from 'styled-components';
import { useFormik } from 'formik';

import { FeedbackForm } from './FeedbackForm';
import { useTheme } from '../../../../hooks/useTheme';
import {
    useCreatePublicationFeedbackMutation,
    useReadPublicationFeedbackQuery,
} from '../../services/publications';

type TProps = {
    readonly postId: number;
    readonly type: string;
};
export const FeedbackContainer: React.FC<TProps> = props => {
    const { notification } = App.useApp();
    const token = useTheme();

    const [createFeedback, { isLoading, isSuccess }] =
        useCreatePublicationFeedbackMutation();

    const { data: feedback, isSuccess: isSuccessRead } =
        useReadPublicationFeedbackQuery({
            id: props.postId,
            type: props.type === 'post' ? 'publication' : props.type,
        });

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Благодарим за обратную связь!',
                duration: 1,
            });
        }
    }, [isSuccess]);

    const onSave = values => {
        createFeedback({ id: props.postId, ...values });
    };

    const initial = {
        type: props.type,
        like_material: 'yes',
        enough_material: 'enough',
        what_to_add: {},
        what_to_remove: {},
    };

    const formik = useFormik({
        initialValues: initial,
        onSubmit: onSave,
    });

    return (
        isSuccessRead &&
        feedback?.result === 'not_made_feedback' && (
            <StyledCard type='inner' style={{ ...token.Card }}>
                <StyledTitle level={4}>Обратная связь</StyledTitle>
                <FeedbackForm
                    formik={formik}
                    onSave={onSave}
                    loading={isLoading}
                />
            </StyledCard>
        )
    );
};

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
`;

const StyledCard = styled(Card)`
    width: 100%;
    margin-top: 1rem;
`;
