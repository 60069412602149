export const options = props => {
    return {
        chart: {
            height: 150,

            type: props.type,
            animations: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        noData: {
            text: 'За указанный период нет данных',
            style: {
                color: '#25212973',
                fontSize: '14px',
            },
        },
        xaxis: {
            categories: props.labels,
        },
        colors: ['#597EF7', '#D6E4FF'],
        labels: props.labels,
        title: {
            text: props.title,
            offsetY: 0,
            offsetX: -1,
            style: {
                color: '#25212973',
            },
        },
    };
};
