import React, { useState } from 'react';
import { Radio, RadioChangeEvent, Space } from 'antd';
import styled from 'styled-components';

import { Label } from './Label';

export const LikeSwitch = props => {
    const [value, setValue] = useState<string>(
        props.formik.initialValues.like_material
    );

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
        props.formik.setFieldValue('like_material', e.target.value);
    };
    return (
        <StyledCheckWrapper>
            <Label label={'Понравился ли вам материал автора?'} />
            <Radio.Group onChange={onChange} value={value}>
                <Space direction='vertical'>
                    <Radio value={'yes'}>Да</Radio>
                    <Radio value={'neutral'}>Нейтально</Radio>
                    <Radio value={'no'}>Нет</Radio>
                </Space>
            </Radio.Group>
        </StyledCheckWrapper>
    );
};

const StyledCheckWrapper = styled.div`
    min-width: 50%;
    display: flex;
    flex-direction: column;
`;
