import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { FloatButton } from 'antd';

import { SectionFirst } from 'shared/layouts/LandingLayout/components/SectionFirst';
import { SectionSecond } from 'shared/layouts/LandingLayout/components/SectionSecond';
import { SectionThird } from 'shared/layouts/LandingLayout/components/SectionThird';
import { SectionFourth } from 'shared/layouts/LandingLayout/components/SectionFourth';
import { SectionSixth } from 'shared/layouts/LandingLayout/components/SectionSixth';
import Bg1 from 'static/landing/bg1.png';
import Bg2 from 'static/landing/bg2.png';
import Bg3 from 'static/landing/bg3.png';
import Bg4 from 'static/landing/bg4.png';
import Bg5 from 'static/landing/bg5.png';

import { CreateSpodialStoreBlock } from './CreateSpodialStoreBlock';

/* eslint-disable */
interface IProps {
    aboutRef: React.MutableRefObject<any>;
    skillsRef: React.MutableRefObject<any>;
    faqRef: React.MutableRefObject<any>;
    demoRef: React.MutableRefObject<any>;
    createStoreRef: React.MutableRefObject<any>;
}
/* eslint-enable */
export const Content: FC<IProps> = ({
    aboutRef,
    skillsRef,
    faqRef,
    demoRef,
    createStoreRef,
}) => {
    return (
        <Fragment>
            <StyledContainer>
                <BackgroundContainer>
                    <ContainerBg1>
                        <img src={Bg1} />
                    </ContainerBg1>
                    <ContainerBg2>
                        <img src={Bg2} />
                    </ContainerBg2>
                    <ContainerBg3>
                        <img src={Bg3} />
                    </ContainerBg3>
                    <ContainerBg4>
                        <img src={Bg4} />
                    </ContainerBg4>
                    <ContainerBg5>
                        <img src={Bg5} />
                    </ContainerBg5>
                </BackgroundContainer>

                <SectionFirst innerRef={demoRef} />

                <SectionSecond aboutRef={aboutRef} />

                <CreateSpodialStoreBlock innerRef={createStoreRef} />

                <SectionFourth demoRef={demoRef} />

                <SectionThird skillsRef={skillsRef} />

                <SectionSixth faqRef={faqRef} />
            </StyledContainer>
            <FloatButton.BackTop />
        </Fragment>
    );
};

const ContainerBg5 = styled.div`
    position: absolute;
    bottom: 80px;
    left: 0;

    img {
        width: 100%;
    }
`;

const ContainerBg4 = styled.div`
    position: absolute;
    bottom: 1260px;
    right: 0;

    @media (max-width: 992px) {
        border: 1200px;
    }

    img {
        width: 100%;
    }
`;

const ContainerBg3 = styled.div`
    position: absolute;
    top: 1360px;
    left: 0;

    @media (max-width: 1400px) {
        top: 1000px;
    }

    @media (max-width: 992px) {
        display: none;
    }

    img {
        width: 100%;
    }
`;

const ContainerBg2 = styled.div`
    position: absolute;
    top: 970px;
    right: 0;

    @media (max-width: 1400px) {
        top: 840px;
    }

    @media (max-width: 992px) {
        top: 600px;
    }

    @media (max-width: 768px) {
        top: 600px;
    }

    img {
        width: 100%;
    }
`;

const ContainerBg1 = styled.div`
    position: absolute;
    top: 250px;

    img {
        width: 100%;
    }
`;

const BackgroundContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
`;

const StyledContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 1600px;
    height: auto;
    padding: 0 50px;
    background: #ffffff;
    margin: 0 auto;
    z-index: 1;

    @media (max-width: 992px) {
        padding: 0 16px;
    }
`;
