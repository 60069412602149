import React from 'react';
import styled from 'styled-components';
import Chart from 'react-apexcharts';
import { Space } from 'antd';

import { useTheme } from '../../../../../hooks/useTheme';
import { PostView } from '../services/interfaces';

type TProps = {
    readonly title: string;
    readonly data: number[] | any;
    readonly labels?: string[];
    readonly type:
        | 'area'
        | 'line'
        | 'bar'
        | 'histogram'
        | 'pie'
        | 'donut'
        | 'radialBar'
        | 'scatter'
        | 'bubble'
        | 'heatmap'
        | 'treemap'
        | 'boxPlot'
        | 'candlestick'
        | 'radar'
        | 'polarArea'
        | 'rangeBar';
    readonly options: any;
    readonly postView?: PostView;
};

export const Card: React.FC<TProps> = props => {
    const token = useTheme();
    return (
        <CardWrapper
            key={'3'}
            style={{
                ...token.Card,
            }}
        >
            <Space direction={'vertical'} style={{ width: '100%' }}>
                <Chart
                    options={props.options(props)}
                    series={props.data}
                    type={props.type}
                />
            </Space>
        </CardWrapper>
    );
};

const CardWrapper = styled.div`
    height: 100%;
    min-height: 300px;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    color: rgba(37, 33, 41, 0.88);

    padding: 16px 24px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
`;
