import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { TariffThanks } from '../views/TariffThanks';
import { useGetByIDQuery } from '../../../services/tariff.service';

export const TariffContainerThanks: FC = () => {
    const params = useParams();
    const { data: tariff } = useGetByIDQuery({ hash: params.id });
    if (!params.id) {
        return <></>;
    }
    if (!tariff) {
        return <></>;
    }
    return <TariffThanks tariff={tariff} />;
};
