import React, { FC, Fragment } from 'react';
import { Badge, Checkbox, Divider, Space, Switch, Typography } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { ChatList } from 'features/communities/components/ChatsList/ChatList';
import { ConfirmBlock } from 'features/communities/components/ConfirmBlock/ConfirmBlock';
import { AntispamSwitchesEnum } from 'features/communities/components/Antispam/AntispamMapper';

import { HeaderScenarios } from '../../HeaderScenarios/HeaderScenarios';
import { TimePickerFormik } from '../../../../../shared/ui/Form/Fields/TimePickerFormik';
import { IFormikInitialValues } from '../containers/AntispamContainerCreate';
import { RuleType } from '../../../../../shared/contants/rule-types';

interface IProps {
    initialValues: IFormikInitialValues;
    onSave: (values) => void;
}

export const AntispamForm: FC<IProps> = props => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: props.onSave,
    });

    return (
        <Fragment>
            <ContainerWrapper>
                <HeaderScenarios
                    inputValue={formik.values.name}
                    onChangeHeaderInput={event => {
                        formik.setFieldValue('name', event.target.value);
                    }}
                />
                <Space direction='vertical' size='large'>
                    <StyledSpace align='start' size='small'>
                        <StyledEmptyPlaceSwitch />
                        <Space direction='vertical'>
                            <StyledTitle level={5}>
                                Ограничить правила по времени действия
                            </StyledTitle>
                            <Typography.Text>
                                Правила действуют на каждого участника в течение
                            </Typography.Text>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <TimePickerFormik
                                    formik={formik}
                                    fieldName='work_period'
                                />
                                <div style={{ marginLeft: 12 }}>
                                    после вступления
                                </div>
                            </div>
                        </Space>
                    </StyledSpace>

                    <StyledSpace align='start' size='small'>
                        <StyledSwitch
                            checked={
                                formik.values.switches[
                                    AntispamSwitchesEnum.messageWithLink
                                ].isChecked
                            }
                            onChange={value => {
                                formik.setFieldValue(
                                    `switches.${AntispamSwitchesEnum.messageWithLink}.isChecked`,
                                    value
                                );
                                formik.setFieldValue(
                                    `switches.${AntispamSwitchesEnum.messageWithLink}.del_message_with_link`,
                                    value
                                );
                            }}
                        />
                        <Space direction='vertical'>
                            <StyledTitle level={5}>
                                Удалять первое сообщение, если оно содержит
                                ссылку
                            </StyledTitle>
                            {formik.values.switches[
                                AntispamSwitchesEnum.messageWithLink
                            ].isChecked && (
                                <StyledWrap>
                                    <Typography.Text>
                                        Новые участники не могут отправлять
                                        ссылку в первом сообщении
                                    </Typography.Text>
                                    <Checkbox
                                        checked={
                                            formik.values.switches[
                                                AntispamSwitchesEnum
                                                    .messageWithLink
                                            ]?.ban_user_contain_link
                                        }
                                        onChange={event => {
                                            formik.setFieldValue(
                                                `switches.${AntispamSwitchesEnum.messageWithLink}.ban_user_contain_link`,
                                                event.target.checked
                                            );
                                        }}
                                    >
                                        Забанить отправителя
                                    </Checkbox>
                                </StyledWrap>
                            )}
                        </Space>
                    </StyledSpace>

                    <StyledSpace align='start' size='small'>
                        <StyledSwitch
                            checked={
                                formik.values.switches[
                                    AntispamSwitchesEnum.messageWithForward
                                ].isChecked
                            }
                            onChange={value => {
                                formik.setFieldValue(
                                    `switches.${AntispamSwitchesEnum.messageWithForward}.isChecked`,
                                    value
                                );
                                formik.setFieldValue(
                                    `switches.${AntispamSwitchesEnum.messageWithForward}.del_message_with_forward`,
                                    value
                                );
                            }}
                        />
                        <Space direction='vertical'>
                            <StyledTitle level={5}>
                                Удалять первое сообщение, если оно пересланное
                            </StyledTitle>
                            {formik.values.switches[
                                AntispamSwitchesEnum.messageWithForward
                            ].isChecked && (
                                <StyledWrap>
                                    <Typography.Text>
                                        Новые участники не могут отправлять
                                        пересланные сообщения
                                    </Typography.Text>
                                    <Checkbox
                                        checked={
                                            formik.values.switches[
                                                AntispamSwitchesEnum
                                                    .messageWithForward
                                            ]?.ban_user_contain_forward
                                        }
                                        onChange={event => {
                                            formik.setFieldValue(
                                                `switches.${AntispamSwitchesEnum.messageWithForward}.ban_user_contain_forward`,
                                                event.target.checked
                                            );
                                        }}
                                    >
                                        Забанить отправителя
                                    </Checkbox>
                                </StyledWrap>
                            )}
                        </Space>
                    </StyledSpace>
                </Space>
                <Divider />
            </ContainerWrapper>
            <ChatList
                selectedRows={formik.values.selectedChatIds}
                setSelectedRows={value => {
                    formik.setFieldValue('selectedChatIds', value);
                }}
                tableOptions={{
                    entityType: RuleType.Antispam,
                }}
            />
            <ConfirmBlock
                onSave={formik.handleSubmit}
                onCancel={() => {
                    navigate(-1);
                }}
            >
                <Fragment>
                    <Badge
                        color='orange'
                        text={`Для ${formik.values.selectedChatIds.length} чатов текущие антиспам правила заменят действующие. Это действие нельзя отменить.`}
                    />
                </Fragment>
            </ConfirmBlock>
        </Fragment>
    );
};

const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;

    @media (max-width: 576px) {
        margin-left: -68px;
    }
`;

const StyledEmptyPlaceSwitch = styled.div`
    width: 60px;

    @media (max-width: 576px) {
        width: 0;
    }
`;

const ContainerWrapper = styled.div`
    display: block;
    margin-top: 32px;
    margin-bottom: 33px;
`;

const StyledTitle = styled(Typography.Title)<{ contentEditable?: boolean }>`
    border: ${props => (props.contentEditable ? '1px solid black' : 'unset')};
    border-radius: ${props => (props.contentEditable ? '5px' : 'unset')};
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
    &:focus-visible {
        outline: unset;
    }
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledSpace = styled(Space)`
    margin-bottom: 8px;
`;
