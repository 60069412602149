/**
 * Chats table initial filters
 */
export const initialFilters = {
    community_title: '',
    date_from: null,
    date_to: null,
    events: [],
    //limit: 10,
    offset: 0,
    tag_names: [],
    user_name: '',
    name: '',
};
