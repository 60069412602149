import {
    Table,
    Space,
    Typography,
    Button,
    Input,
    Avatar,
    Pagination,
} from 'antd';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/es/table/interface';

import { ConfirmationDialog } from 'shared/ui/ConfirmationDialog';

import { Dropdown } from '../../../../../shared/ui/Dropdown';
import { getSrc } from '../../../../../utils/common/getSrc';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { Paginator } from '../../../../../shared/ui/Paginator';

type DataIndex = keyof DataType;

type Props = {
    readonly source;
    readonly loading: boolean;
    readonly isSuccess: boolean;
    readonly onDelete: (id) => void;
    readonly onBulkRemove: (id) => void;
    readonly selectedRows: any;
    readonly setSelectedRowKeys: any;
};

interface DataType {
    context: string;
    question_category: string;
    knowledge: string;
    communities: string;
    status: string | number;
    id?: number;
}

export const AIKnowledgeTable: React.FC<Props> = props => {
    const { windowSize } = useWindowSize();

    const [currentPage, setCurrentPage] = useState(1);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const { selectedRows, setSelectedRowKeys } = props;

    const navigate = useNavigate();

    useEffect(() => {
        if (sessionStorage.aiKnowledgeCurrentPage) {
            setCurrentPage(
                Number(sessionStorage.getItem('aiKnowledgeCurrentPage'))
            );
        }
    }, [sessionStorage.aiKnowledgeCurrentPage]);

    const onChange = page => {
        sessionStorage.setItem('aiKnowledgeCurrentPage', page);
        setCurrentPage(page);
    };

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const pagination = {
        showLessItems: true,
        defaultCurrent: 1,
        current: currentPage,
        onChange: page => onChange(page),
        showTotal: total => `Вопросов: ${total}`,
    };

    const getColumnSearchProps = (
        dataIndex: DataIndex
    ): ColumnType<DataType> => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Поиск по названию`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex
                        )
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() =>
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex
                            )
                        }
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Поиск
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters);
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex
                            );
                        }}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Очистить
                    </Button>
                    <Button
                        type='text'
                        size='small'
                        onClick={() => {
                            close();
                        }}
                    >
                        Отмена
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? '#6257d2' : undefined }}
            />
        ),
        onFilter: (value, record) => {
            if (dataIndex === 'context') {
                return record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase());
            }
            if (record.communities !== null && dataIndex === 'communities') {
                if (dataIndex in record) {
                    return record[dataIndex]['title']
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase());
                }
            }
            if (dataIndex === 'question_category') {
                if (dataIndex in record && record.question_category !== null) {
                    return record[dataIndex]['name']
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase());
                } else return;
            }
            if (dataIndex in record && dataIndex !== 'communities') {
                return record[dataIndex]['name']
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase());
            }
        },

        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const rowSelection = {
        selectedRows: selectedRows,
        onChange: (_, record) => setSelectedRowKeys(record),
    };

    return (
        <StyledWrapper>
            <StyledActions>
                {!!selectedRows.length && (
                    <Fragment>
                        <ConfirmationDialog
                            activator={
                                <Button danger type='primary'>
                                    Удалить
                                </Button>
                            }
                            onConfirm={() => props.onBulkRemove(selectedRows)}
                            title='Удалить?'
                            description={`Вы уверены что хотите удалить?`}
                        />

                        <div>Выбрано: {selectedRows.length}</div>
                    </Fragment>
                )}
                {/*<StyledResetButton
                    type='link'
                    onClick={() => console.log('clear')}
                >
                    Очистить фильтры
                </StyledResetButton>*/}
            </StyledActions>
            <StyledTable
                scroll={{ x: 900 }}
                loading={props.loading}
                dataSource={props.source}
                rowSelection={rowSelection}
                rowKey={(record: { id: number }) => record.id}
                pagination={pagination}
            >
                <Table.Column
                    dataIndex={'context'}
                    ellipsis={true}
                    width={250}
                    title={'Вопрос'}
                    render={(context, record: DataType) => (
                        <Typography.Link
                            onClick={() => {
                                if (record.status === 1) {
                                    return navigate(`ai-question/${record.id}`);
                                } else navigate(`question/${record.id}`);
                            }}
                            style={{ color: '#6257d2', paddingLeft: 0 }}
                        >
                            {context}
                        </Typography.Link>
                    )}
                    {...getColumnSearchProps('context')}
                />

                <Table.Column
                    dataIndex={'communities'}
                    width={155}
                    title={'Сообщество'}
                    render={communities => (
                        <Space>
                            {!communities ? (
                                <Typography.Text type={'secondary'}>
                                    Не определено
                                </Typography.Text>
                            ) : (
                                <>
                                    {communities?.image && (
                                        <Avatar
                                            src={getSrc(communities?.image)}
                                        />
                                    )}
                                    {communities?.title}
                                </>
                            )}
                        </Space>
                    )}
                    {...getColumnSearchProps('communities')}
                />

                <Table.Column
                    dataIndex={'knowledge'}
                    width={150}
                    ellipsis={true}
                    title={'База знаний'}
                    render={(knowledge, record: DataType) =>
                        record.status !== 'published' ? (
                            <Typography.Text type={'secondary'}>
                                Не определено
                            </Typography.Text>
                        ) : (
                            knowledge.name
                        )
                    }
                    {...getColumnSearchProps('knowledge')}
                />
                <Table.Column
                    dataIndex={'question_category'}
                    width={150}
                    title={'Категория'}
                    ellipsis={true}
                    render={(question_category, record: any) =>
                        record.status !== 'published' ? (
                            <Typography.Text type={'secondary'}>
                                Не определено
                            </Typography.Text>
                        ) : (
                            question_category?.name
                        )
                    }
                    {...getColumnSearchProps('question_category')}
                />

                <Table.Column
                    dataIndex={'status'}
                    filters={[
                        {
                            text: 'Опубликован',
                            value: 'published',
                        },
                        {
                            text: 'Черновик ИИ',
                            value: 1,
                        },
                    ]}
                    onFilter={(
                        value: string | number,
                        record: { status: string }
                    ) => record.status === value}
                    width={150}
                    title={'Статус'}
                    render={status => (
                        <>
                            {status === 1 && 'Черновик ИИ'}
                            {status === 'published' && 'Опубликован'}
                        </>
                    )}
                />

                <Table.Column
                    width={125}
                    title={'Действие'}
                    render={record => (
                        <StyledDropdown
                            disabled={selectedRows.length > 0}
                            trigger={['hover']}
                            dropdownContent={() => (
                                <Fragment>
                                    <Button
                                        onClick={() => {
                                            if (record.status === 1) {
                                                return navigate(
                                                    `ai-question/${record.id}`
                                                );
                                            } else
                                                navigate(
                                                    `question/${record.id}`
                                                );
                                        }}
                                        type='text'
                                    >
                                        Редактировать
                                    </Button>
                                    <Button
                                        onClick={() => props.onDelete(record)}
                                        danger
                                        type='text'
                                    >
                                        Удалить
                                    </Button>
                                </Fragment>
                            )}
                        >
                            <Button
                                type='default'
                                disabled={selectedRows.length > 0}
                            >
                                <StyledAction>
                                    Действие <DownOutlined />
                                </StyledAction>
                            </Button>
                        </StyledDropdown>
                    )}
                />
            </StyledTable>

            {/*<Paginator
                pageSize={10}
                isReady={!props.loading}
                listName={'Вопросов'}
                total={props?.source.length}
                showLessItems={true}
                defaultCurrent={1}
                current={currentPage}
                onChange={onChange}
                showTotal={total => `Вопросов: ${total}`}
            />*/}
        </StyledWrapper>
    );
};

const StyledTable = styled(Table)`
    margin-top: 56px;
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
`;

const StyledActions = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    z-index: 10;
`;

const StyledAction = styled.span`
    color: #6257d2;
`;

const StyledDropdown = styled(Dropdown)`
    cursor: pointer;

    button {
        text-align: start;
        padding-left: 0;
        padding-right: 0;
        width: auto;
        border: none;
        box-shadow: none;
    }
`;
