import React, { FC, Fragment, useState } from 'react';
import { Col, InputNumber, Row, Space, Switch, Typography, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { CopyWithTooltip } from 'shared/ui/CopyWithTooltip/CopyWithTooltip';

import { TextEditor } from '../../../../../../shared/ui/TextEditor/TextEditor';
import { validateImages } from '../../../../../../utils/common/validateImages';
import { TariffSwitchesEnum } from '../../TariffMappter';
import { blobToBase64 } from '../../../../../../utils/common/blobToBase64';
import { GenericModal } from '../../../../../../shared/ui/GenericModal';

interface IProps {
    control: TariffSwitchesEnum.paidAccess;
    formik: any;
    tariffHref?: string;
    command?: string;
}

export const PaidAccessSwitch: FC<IProps> = props => {
    const { formik } = props;

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [img, setImg] = useState<undefined | string | ArrayBuffer>(
        formik.values.switches[props.control]?.main_image
    );

    return (
        <Fragment>
            <GenericModal
                title={'Внимание!'}
                width={380}
                open={isOpenModal}
                okText={'Отключить'}
                okButtonProps={{ danger: true }}
                onCancel={() => {
                    setIsOpenModal(false);
                }}
                onConfirm={() => {
                    formik.setFieldValue(
                        `switches.${props.control}.isChecked`,
                        false
                    );
                    formik.setFieldValue(
                        `switches.${props.control}.tariff_is_payable`,
                        false
                    );
                    setIsOpenModal(false);
                }}
            >
                <Space direction={'vertical'}>
                    <Typography.Text>
                        Вы собираетесь выключить платный доступ.
                    </Typography.Text>

                    <Typography.Text>
                        Со всех участников, перестанет взиматься плата за
                        нахождение в сообществе.
                    </Typography.Text>
                </Space>
            </GenericModal>
            <StyledSpace style={{ width: '100%' }}>
                <StyledSwitch
                    checked={formik.values.switches[props.control].isChecked}
                    onChange={value => {
                        if (!value) {
                            setIsOpenModal(true);
                        } else {
                            formik.setFieldValue(
                                `switches.${props.control}.isChecked`,
                                true
                            );
                            formik.setFieldValue(
                                `switches.${props.control}.tariff_is_payable`,
                                true
                            );
                        }
                    }}
                />
                <Space
                    direction='vertical'
                    size='middle'
                    style={{ width: '90%' }}
                >
                    <StyledTitle level={5}>Активировать тариф</StyledTitle>
                    {formik.values.switches[props.control].isChecked && (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography.Text>
                                    Стоимость за месяц (₽):
                                </Typography.Text>
                                <InputNumber
                                    min={50}
                                    status={
                                        formik.values.switches[props.control]
                                            ?.price < 50
                                            ? 'error'
                                            : null
                                    }
                                    controls={false}
                                    value={
                                        formik.values.switches[props.control]
                                            ?.price
                                    }
                                    onChange={value => {
                                        formik.setFieldValue(
                                            `switches.${props.control}.price`,
                                            value
                                        );
                                    }}
                                />
                            </div>

                            <Row>
                                <div style={{ marginTop: 10 }}>
                                    <Upload
                                        maxCount={1}
                                        listType='picture-card'
                                        className='avatar-uploader'
                                        showUploadList={false}
                                        beforeUpload={() => false}
                                        onChange={value => {
                                            if (validateImages(value.file)) {
                                                blobToBase64(value.file).then(
                                                    val => {
                                                        formik.setFieldValue(
                                                            `switches.${props.control}.main_image`,
                                                            val
                                                        );
                                                        setImg(val);
                                                    }
                                                );
                                            }
                                        }}
                                    >
                                        {img ? (
                                            <StyledImage
                                                src={img as string}
                                                alt={'alt'}
                                            />
                                        ) : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>
                                                    Картинка
                                                </div>
                                            </div>
                                        )}
                                    </Upload>
                                </div>

                                <Col xs={24} sm={18} md={12}>
                                    <TextEditor
                                        containerHeight={84}
                                        value={
                                            formik.values.switches[
                                                props.control
                                            ]?.main_description
                                        }
                                        onChange={value => {
                                            formik.setFieldValue(
                                                `switches.${props.control}.main_description`,
                                                value
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                            {props?.command && (
                                <>
                                    <div>Команда для Telegram:</div>
                                    <CopyWithTooltip text={props.command} />
                                </>
                            )}
                            {props?.tariffHref && (
                                <>
                                    <div>Страница тарифа:</div>
                                    <CopyWithTooltip text={props.tariffHref} />
                                </>
                            )}
                        </div>
                    )}
                </Space>
            </StyledSpace>
        </Fragment>
    );
};

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledSpace = styled(Row)`
    margin-bottom: 8px;
`;

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
