const humanizeDuration = seconds => {
    if (seconds < 60) {
        return seconds + ' сек';
    } else return (seconds / 60).toFixed(1) + ' м';
};
export const options = props => {
    return {
        chart: {
            type: 'bar',
            animations: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        noData: {
            text: 'За указанный период нет данных',
            style: {
                color: '#25212973',
                fontSize: '14px',
            },
        },
        colors: ['#2F54EB', '#597EF7', '#85A5FF'],
        labels: props.labels,
        legend: {
            width: 120,
            position: 'left',
            formatter: function (val, opts) {
                return val + ' --- ' + opts.w.globals.series[opts.seriesIndex];
            },
            offsetY: 14,
            itemMargin: {
                horizontal: 0,
                vertical: 7,
            },
        },
        title: {
            text: props.title,
            offsetY: 0,
            offsetX: -1,
            style: {
                color: '#25212973',
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            },
        },
        tooltip: {
            enabled: false,
        },
        dataLabels: {
            enabled: true,
            dropShadow: false,
            formatter: function (val, opts) {
                return props.postView === 'time' ? humanizeDuration(val) : val;
            },
        },
    };
};
