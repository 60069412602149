import React from 'react';
import { Button, Tooltip } from 'antd';

type TProps = {
    readonly type: string;
};
export const DownloadButton: React.FC<TProps> = props => {
    const onDownload = async () => {
        console.log(props.type);
    };

    return (
        <Tooltip title={'Скоро'}>
            <Button disabled={true} onClick={() => onDownload()}>
                Скачать
            </Button>
        </Tooltip>
    );
};
