import React, { Fragment, useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Table, Typography } from 'antd';
import styled from 'styled-components';

import {
    ICommunity,
    ICommunityQueryParams,
} from 'features/communities/interfaces';
import { useReadChatsQuery } from 'features/communities/services';

import { getColumnsOptions } from './getColumnsOptions';
import { RuleType } from '../../../../shared/contants/rule-types';

export interface ITableOptions {
    entityType: RuleType;
    rowExpandable: boolean;
}

interface IProps {
    selectedRows: number[];
    setSelectedRows: React.Dispatch<React.SetStateAction<React.Key[]>>;
    tableOptions: Partial<ITableOptions>;
}

export const filterRules = (rules, type: RuleType): any[] => {
    return rules.filter(el => el.type === type);
};

export const ChatList: React.FC<IProps> = ({
    selectedRows,
    setSelectedRows,
    tableOptions,
}) => {
    const [filters, setFilters] = useState<ICommunityQueryParams>({
        tags_names: [],
        rule_names: [],
    });
    const {
        data: source = { items: [], total: 0 },
        refetch,
        isFetching,
    } = useReadChatsQuery(filters);

    useEffect(() => {
        refetch();
    }, [filters]);

    const rowSelection = {
        selectedRowKeys: selectedRows,
        onChange: setSelectedRows,
        selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    };

    const handleFiltersUpdate = (update: ICommunityQueryParams | null) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({ tags_names: [] });
            setFilters({ rule_names: [] });
        }
    };

    const columns: ColumnsType<ICommunity> = getColumnsOptions(
        filters,
        handleFiltersUpdate,
        tableOptions,
        selectedRows
    );

    const pagination = {
        showTotal: total => `Чатов ${total}`,
    };

    return (
        <Fragment>
            <StyledTitle level={5}>Применить к чатам</StyledTitle>
            <SelectedText>
                <Typography.Text>
                    Выбрано чатов: {selectedRows?.length || 0}
                </Typography.Text>
            </SelectedText>
            <Table
                loading={isFetching}
                scroll={{ x: 900 }}
                rowSelection={rowSelection}
                columns={columns}
                rowKey={community => community.id}
                dataSource={source.items}
                pagination={pagination}
                expandable={{
                    expandedRowRender: record =>
                        filterRules(record.rules, tableOptions.entityType).map(
                            (rule, index) => (
                                <div key={index}>
                                    <Typography.Text>
                                        {rule.name || rule.title}
                                    </Typography.Text>
                                </div>
                            )
                        ),
                    rowExpandable: record =>
                        tableOptions?.rowExpandable &&
                        filterRules(record.rules, tableOptions.entityType)
                            .length > 0,
                }}
            />
        </Fragment>
    );
};

const SelectedText = styled.div`
    margin-bottom: 21px !important;
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 37px !important;
`;
