import React from 'react';
import Chart, { Props as ApexProps } from 'react-apexcharts';

import { useGetMembersQuery } from 'features/analytics/services';
import { IStatisticFilters } from 'features/analytics/services/interfaces';

import { ChartWrapper } from '../ChartWrapper';

type Props = {
    readonly filters: IStatisticFilters;
    readonly height?: number;
    readonly width?: number;
};

export const ActiveMembersChart: React.FC<Props> = props => {
    const {
        data: members = {
            totalMembers: 0,
            activeMembers: {
                value: 0,
                delta: 0,
            },
            joinMembers: {
                value: 0,
                delta: 0,
            },
            leftMembers: {
                value: 0,
                delta: 0,
            },
        },
        isFetching,
    } = useGetMembersQuery({
        period: props.filters.period,
        community_id: Number(props.filters.chat) || null,
    });

    const options: ApexProps['options'] = {
        labels: ['Все', 'Активные'],
        legend: {
            show: true,
            position: 'left',
        },
        theme: {
            monochrome: {
                enabled: true,
                color: '#597EF7',
            },
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            },
        },
    };

    return (
        <ChartWrapper isLoading={isFetching} title='Активные участники'>
            <Chart
                height={props.height}
                type='pie'
                options={options}
                series={[members.totalMembers, members.activeMembers.value]}
            />
        </ChartWrapper>
    );
};
