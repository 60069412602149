import React, { FC, useEffect, useState } from 'react';
import { InputNumber, notification, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { GenericModal } from '../../../../shared/ui/GenericModal';
import { useArbitraryAmountPayMutation } from '../../services/donate.service';
import getEnvConfig from '../../../../utils/config/env';

interface IProps {
    isModalOpen?: boolean;
}
export const ArbitraryAmountPage: FC<IProps> = props => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [arbitraryAmount, setArbitraryAmount] = useState<
        undefined | number
    >();
    const [arbitraryAmountPay, { error: errorArbitrary }] =
        useArbitraryAmountPayMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (errorArbitrary) {
            notification.error({
                message:
                    (errorArbitrary as any).data.message ||
                    'Что-то пошло не так',
                placement: 'top',
            });
        }
    }, [errorArbitrary]);

    const onConfirmArbitrary = async () => {
        const redirectUrl = await arbitraryAmountPay({
            amount: arbitraryAmount,
            telegram_user_id: searchParams.get('telegram_user_id'),
            donate_id: +searchParams.get('donate_id'),
        }).unwrap();
        window.location.replace(redirectUrl.data.redirect);
    };

    return (
        <div>
            <GenericModal
                title={'Произвольная сумма'}
                width={380}
                open={props?.isModalOpen}
                okText={'Оплатить'}
                onCancel={() => {
                    navigate(`${getEnvConfig().PUBLIC_PATH}`);
                }}
                onConfirm={onConfirmArbitrary}
            >
                <div style={{ marginBottom: 8 }}>
                    <Typography.Text>
                        Введите сумму доната от{' '}
                        {searchParams.get('min_price') || '150'} ₽ до{' '}
                        {searchParams.get('max_price') || '100 000'} ₽
                    </Typography.Text>
                </div>
                <InputNumber
                    type='number'
                    style={{ marginBottom: 24, width: '100%' }}
                    min={Number(searchParams.get('min_price')) || 150}
                    max={Number(searchParams.get('max_price')) || 100000}
                    onChange={value => setArbitraryAmount(value)}
                    value={arbitraryAmount}
                />
                <div>
                    <Typography.Text>
                        Вы будете перенаправлены на страницу платёжной системы.
                    </Typography.Text>
                </div>
            </GenericModal>
        </div>
    );
};
