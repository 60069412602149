import React from 'react';
import { Button } from 'antd';
import { DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from '../../../../../../shared/ui/Dropdown';

export const ActionSwitch = props => {
    const navigate = useNavigate();

    const { id, deleteById, size } = props;

    return (
        <StyledDropdown
            dropdownContent={() => (
                <>
                    <Button type='text' onClick={() => navigate(`${id}`)}>
                        Редактировать
                    </Button>
                    <Button danger type='text' onClick={() => deleteById(id)}>
                        Удалить
                    </Button>
                </>
            )}
        >
            {size === 'small' ? (
                <StyledMenu />
            ) : (
                <Button type='default'>
                    Действие <DownOutlined />
                </Button>
            )}
        </StyledDropdown>
    );
};

const StyledDropdown = styled(Dropdown)`
    cursor: pointer;

    button {
        text-align: start;
        padding-left: 0;
        padding-right: 0;
        width: auto;
        border: none;
        box-shadow: none;
    }
`;

const StyledMenu = styled(EllipsisOutlined)`
    transform: rotate(90deg);
`;
