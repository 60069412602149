import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { App, Input, Space, Typography } from 'antd';
import styled from 'styled-components';

import { GenericModal } from '../../../../../../../shared/ui/GenericModal';
import { useCreatePublicationPayMutation } from '../../../../../services/publications';
import { Confirm } from '../../../components/Confirm';
import { useCreateWebinarPayMutation } from '../../../../../services/webinars';

type TPayModal = {
    readonly source: iSource;
    readonly setOpen: Dispatch<SetStateAction<boolean>>;
    readonly open: boolean;
    readonly type: string;
};

export interface iSource {
    readonly title: string;
    readonly price: number;
    readonly uuid: string;
}
export const PayModal: React.FC<TPayModal> = props => {
    const { notification } = App.useApp();

    const { setOpen } = props;
    const { title, price, uuid } = props.source;

    const [email, setEmail] = useState('');
    const [checkBoxStatus, setCheckboxStatus] = useState(false);

    const [pay, { isSuccess, data, isError }] =
        props.type === 'webinar'
            ? useCreateWebinarPayMutation()
            : useCreatePublicationPayMutation();

    useEffect(() => {
        if (isSuccess) {
            setOpen(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Произошла ошибка при оплате',
            });
        }
    }, [isError]);

    const onSubmit = async () => {
        if (uuid) {
            const redirectUrl = await pay({ uuid, email }).unwrap();
            window.location.replace((redirectUrl as any).data.redirect);
            /* notification.success({
                message: 'Мы получили вашу оплату',
                placement: 'top',
            });
*/
            setOpen(false);
        }
    };

    return (
        <GenericModal
            title={'Оплата медиатовара'}
            destroyOnClose
            width={360}
            open={props.open}
            okText={'Оплатить'}
            onCancel={() => {
                setEmail(null);
                setCheckboxStatus(false);
                setOpen(false);
            }}
            onConfirm={onSubmit}
            okButtonProps={{
                danger: false,
                disabled: !checkBoxStatus || !email,
            }}
        >
            <Space direction={'vertical'} size={'large'}>
                <Typography.Text>{title}</Typography.Text>
                <Typography.Title level={4}>{price} &#8381;</Typography.Title>
            </Space>

            <StyledItem>
                <StyledLabel>Почта</StyledLabel>
                <Input
                    autoFocus
                    value={email}
                    placeholder='john@example.com'
                    onChange={event => {
                        setEmail(event.target.value);
                    }}
                />
            </StyledItem>

            <Confirm
                checkBoxStatus={checkBoxStatus}
                setCheckboxStatus={setCheckboxStatus}
            >
                Подтверждаю ознакомление с правилами. Вы будете перенаправлены
                на страницу платёжной системы.
            </Confirm>
        </GenericModal>
    );
};

const StyledItem = styled.div`
    margin: 1rem 0;
`;

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 0.5rem;
`;
