import React, { FC, Fragment } from 'react';
import { Space, Switch, Typography, Upload } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

import { TextEditor } from '../../../../../../shared/ui/TextEditor/TextEditor';
import { validateImages } from '../../../../../../utils/common/validateImages';
import { BehavioralSwitchesEnum } from '../../BehavioralMapper';

interface IProps {
    formik: any;
    img1Ref: React.MutableRefObject<any>;
    img1: any;
}

export const SetOfRulesSwitch: FC<IProps> = props => {
    return (
        <Fragment>
            <StyledSpace align='start'>
                <StyledSwitch
                    checked={
                        props.formik.values.switches[
                            BehavioralSwitchesEnum.setOfRulesSwitch
                        ].isChecked
                    }
                    onChange={value => {
                        props.formik.setFieldValue(
                            `switches.${BehavioralSwitchesEnum.setOfRulesSwitch}.isChecked`,
                            value
                        );
                    }}
                />
                <Space direction='vertical' size='middle'>
                    <StyledTitle level={5}>Свод правил</StyledTitle>
                    {props.formik.values.switches[
                        BehavioralSwitchesEnum.setOfRulesSwitch
                    ].isChecked && (
                        <StyledWrap>
                            <div style={{ marginTop: 10 }}>
                                <Upload
                                    maxCount={1}
                                    listType='picture-card'
                                    className='avatar-uploader'
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    onChange={value => {
                                        if (validateImages(value.file)) {
                                            props.img1Ref.current = value.file;
                                            props.formik.setFieldValue(
                                                `switches.${BehavioralSwitchesEnum.setOfRulesSwitch}.content_image`,
                                                value.file
                                            );
                                        }
                                    }}
                                >
                                    {props.formik.values.switches[
                                        BehavioralSwitchesEnum.setOfRulesSwitch
                                    ].content_image ? (
                                        <StyledImage
                                            src={props.img1}
                                            alt='first'
                                        />
                                    ) : (
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>
                                                Картинка
                                            </div>
                                        </div>
                                    )}
                                </Upload>
                            </div>
                            <TextEditor
                                value={
                                    props.formik.values.switches[
                                        BehavioralSwitchesEnum.setOfRulesSwitch
                                    ].content
                                }
                                onChange={value => {
                                    props.formik.setFieldValue(
                                        `switches.${BehavioralSwitchesEnum.setOfRulesSwitch}.content`,
                                        value
                                    );
                                }}
                            />
                        </StyledWrap>
                    )}
                </Space>
            </StyledSpace>
        </Fragment>
    );
};

const StyledWrap = styled.div`
    display: flex;
    gap: 16px;
    align-items: start;

    @media (max-width: 992px) {
        flex-direction: column;
    }

    @media (max-width: 576px) {
        margin-left: -68px;
    }
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSpace = styled(Space)`
    margin-bottom: 8px;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
