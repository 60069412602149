import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import getEnvConfig from 'utils/config/env';

import {
    IPublicationResponse,
    IPublications,
} from '../interfaces/publications.interface';
import { PaginatedResponse } from '../../communities/interfaces/PaginatedResponse';
import {
    IProduct,
    IProductFilters,
} from '../../shops/interfaces/products.interface';

export const publicationsApi = createApi({
    reducerPath: 'publications',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['Publications'],
    endpoints: builder => ({
        createPublication: builder.mutation<any, void>({
            query: body => ({
                url: '/publications',
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            invalidatesTags: ['Publications'],
        }),

        createPublicationFavourite: builder.mutation({
            query: id => ({
                url: '/favourite/publications',
                method: 'POST',
                body: { publication_id: id },
            }),
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            invalidatesTags: ['Publications'],
        }),

        createPublicationVisited: builder.mutation({
            query: id => ({
                url: '/visited/publications',
                method: 'POST',
                body: { publication_id: id },
            }),
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            invalidatesTags: ['Publications'],
        }),

        deletePublicationFavourite: builder.mutation({
            query: id => ({
                url: `/favourite/publications/${id}`,
                method: 'DELETE',
            }),
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            invalidatesTags: ['Publications'],
        }),

        updatePublication: builder.mutation({
            query: ({ id, body }) => ({
                url: `/publications/${id}`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Publications'],
        }),

        readPublicationById: builder.query({
            query: id => ({
                url: `/publications/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: IPublicationResponse }) => {
                return response.data;
            },
            providesTags: ['Publications'],
        }),
        readPublicationByUuid: builder.query({
            query: uuid => ({
                url: `/publication/${uuid}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: IPublicationResponse }) => {
                return response.data;
            },
            // providesTags: ['Publications'],
        }),

        readPublicationVisited: builder.query({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/visited/publications?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: [] }, meta) => {
                return {
                    posts: response.data,
                    total: Number(meta.response.headers.get('Items-Count')),
                };
            },
            providesTags: ['Publications'],
        }),

        readPublicationFavourite: builder.query({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/favourite/publications?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: [] }, meta) => {
                return {
                    posts: response.data,
                    total: Number(meta.response.headers.get('Items-Count')),
                };
            },
            providesTags: ['Publications'],
        }),

        readPublication: builder.query<
            PaginatedResponse<IPublications>,
            IProductFilters
        >({
            query: () => ({
                url: `/publications`,
                method: 'GET',
            }),

            transformResponse: (res: { data: Array<IPublications> }, meta) => {
                return {
                    items: res.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },

            /* transformResponse: (response: { data: Array<IPublications> }) => {
                return response.data;
            },*/
            providesTags: ['Publications'],
        }),

        createPublicationPay: builder.mutation({
            query: ({ uuid, ...body }) => ({
                url: `/publication/pay/${uuid}`,
                method: 'POST',
                body: body,
            }),
            transformResponse: response => {
                return response;
            },
        }),

        readPublicationPublic: builder.query({
            query: id => ({
                url: `/public/publications/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: IPublicationResponse }) => {
                return response.data;
            },
            providesTags: ['Publications'],
        }),

        deletePublication: builder.mutation({
            query: id => ({
                url: `/publications/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Publications'],
        }),

        createPublicationPart: builder.mutation({
            query: body => ({
                url: '/publication-parts',
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            invalidatesTags: ['Publications'],
        }),
        updatePublicationPart: builder.mutation({
            query: ({ id, body }) => ({
                url: `/publication-parts/${id}`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Publications'],
        }),
        deletePublicationPart: builder.mutation({
            query: id => ({
                url: `/publication-parts/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Publications'],
        }),
        createPublicationFeedback: builder.mutation({
            query: ({ id, ...body }) => ({
                url: `/lms_feedback/${id}`,
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            invalidatesTags: ['Publications'],
        }),
        readPublicationFeedback: builder.query({
            query: ({ id, ...args }) => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/publications/check_feedback/${id}?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            providesTags: ['Publications'],
        }),

        createPublicationTime: builder.mutation({
            query: ({ id, seconds }) => ({
                url: '/statistic/publication-time',
                method: 'POST',
                body: { publication_id: id, seconds: seconds },
            }),
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            invalidatesTags: ['Publications'],
        }),
    }),
});
export const {
    useCreatePublicationMutation,
    useUpdatePublicationMutation,
    useReadPublicationByIdQuery,
    useReadPublicationQuery,
    useDeletePublicationMutation,
    useReadPublicationByUuidQuery,

    useReadPublicationPublicQuery,
    useCreatePublicationPartMutation,
    useUpdatePublicationPartMutation,
    useDeletePublicationPartMutation,

    useCreatePublicationFavouriteMutation,
    useDeletePublicationFavouriteMutation,
    useReadPublicationFavouriteQuery,

    useReadPublicationVisitedQuery,
    useCreatePublicationVisitedMutation,

    useCreatePublicationPayMutation,
    useCreatePublicationFeedbackMutation,
    useReadPublicationFeedbackQuery,
    useCreatePublicationTimeMutation,
} = publicationsApi;
