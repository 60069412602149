import { RouteObject } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';

import { TariffContainerPreview } from 'features/monetization/pages/tariff/containers/TariffContainerPreview';
import { TariffContainerAdd } from 'features/monetization/pages/tariff/containers/TariffContainerAdd';
import { FinancesContainer } from 'features/monetization/pages/finances/containers/FinancesContainer';
import { DonateContainerThanks } from 'features/monetization/pages/donate/containers/DonateContainerThanks';

import { DonateContainerEdit } from './pages/donate/containers/DonateContainerEdit';
import { ArbitraryAmountPage } from './pages/arbitraryAmountPage/ArbitraryAmountPage';
import { DonateContainer } from './pages/donate/containers/DonateContainer';
import { TariffContainer } from './pages/tariff/containers/TariffContainer';
import { TariffContainerEdit } from './pages/tariff/containers/TariffContainerEdit';
import { TariffContainerThanks } from './pages/tariff/containers/TariffContainerThanks';

export const MonetizationRoutes: RouteObject[] = [
    {
        path: 'monetization',
        element: <Outlet />,
        children: [
            {
                path: '',
                element: <Navigate to='donate' />,
            },
            {
                path: 'donate',
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        path: '',
                        element: <DonateContainer />,
                    },
                    {
                        path: ':id',
                        element: <DonateContainerEdit />,
                    },
                ],
            },
            {
                path: 'tariff',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <TariffContainer />,
                    },
                    {
                        path: 'add',
                        element: <TariffContainerAdd />,
                    },
                    {
                        path: ':id',
                        element: <TariffContainerEdit />,
                    },
                ],
            },
            {
                path: 'finances',
                element: <FinancesContainer />,
            },
        ],
    },
];

export const MonetizationPublicRoutes: RouteObject[] = [
    {
        path: 'monetization/arbitrary',
        element: <ArbitraryAmountPage isModalOpen />,
    },
    {
        path: 'tariff/:id',
        element: <TariffContainerPreview isModalOpen={false} />,
    },
    {
        path: 'tariff/:id/confirm',
        element: <TariffContainerPreview isModalOpen={true} />,
    },
    {
        path: 'tariff/:id/pay',
        element: <TariffContainerPreview isModalOpen={true} />,
    },
    {
        path: 'tariff/:id/thanks',
        element: <TariffContainerThanks />,
    },
    {
        path: 'donate/thanks',
        element: <DonateContainerThanks />,
    },
];
