import React, { Fragment, useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import styled from 'styled-components';

import { useReadChatsQuery } from 'features/communities/services';

import { getColumnsOptions } from './getColumnsOptions';

export interface ITableOptions {
    entityType: string;
    rowExpandable: boolean;
}

interface IProps {
    selectedRows: React.Key[];
    setSelectedRows: React.Dispatch<React.SetStateAction<React.Key[]>>;
    tableOptions: Partial<ITableOptions>;
}

export const ChatList: React.FC<IProps> = ({
    selectedRows,
    setSelectedRows,
    tableOptions,
}) => {
    const [filters, setFilters] = useState<any>({
        tags_names: [],
    });
    const {
        data: source = { items: [], total: 0 },
        refetch,
        isFetching,
    } = useReadChatsQuery(filters);

    useEffect(() => {
        refetch();
    }, [filters]);

    const rowSelection = {
        selectedRowKeys: selectedRows,
        onChange: setSelectedRows,
        selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    };

    const handleFiltersUpdate = update => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({});
        }
    };

    const columns = getColumnsOptions(filters, handleFiltersUpdate);

    const pagination = {
        showTotal: total => `Чатов ${total}`,
    };

    return (
        <Fragment>
            <StyledTitle level={5}>Применить к чатам</StyledTitle>
            <SelectedText>
                <Typography.Text>
                    Выбрано чатов: {selectedRows?.length || 0}
                </Typography.Text>
            </SelectedText>
            <Table
                scroll={{ x: 900 }}
                loading={isFetching}
                rowSelection={rowSelection}
                columns={columns}
                rowKey={community => community.id}
                dataSource={source.items}
                pagination={pagination}
            />
        </Fragment>
    );
};

const SelectedText = styled.div`
    margin-bottom: 21px !important;
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 37px !important;
`;
