import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';

interface DataType {
    title: string;
    dataIndex: string;
    end_at: number;
    start_at: number;
}

const handleDurability = (start, end) => {
    const seconds = (dayjs(end).unix() - dayjs(start).unix()) / 60;
    return new Date(seconds * 1000).toISOString().slice(11, 19);
};

export const columns: ColumnsType<DataType> = [
    {
        title: 'Название',
        dataIndex: 'title',
    },

    {
        title: 'Длительность',
        render: record => handleDurability(record.start_at, record.end_at),
    },

    {
        title: 'Дата и время начала',
        dataIndex: 'start_at',
        render: date => dayjs(date).format('DD.MM.YYYY HH:mm'),
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.start_at - b.start_at,
    },
];
