import React, { Fragment, useEffect, useState } from 'react';
import { Button, Space, Typography } from 'antd';
import styled from 'styled-components';

import { TagsSelector } from 'shared/ui/Form';
import { GenericModal } from 'shared/ui/GenericModal';
import { useReadChatTagsQuery } from 'features/communities/services';

type TProps = {
    readonly count: number;
    readonly onChange: (tags: string[]) => void;
    readonly tags: Array<string>;
};

export const TagsEditor: React.FC<TProps> = props => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [tags, setTags] = useState<string[]>([]);

    const { data: tagsData = [], refetch } = useReadChatTagsQuery();

    useEffect(() => {
        setTags(Array.from(new Set(props.tags)));
    }, [props.tags]);

    const handleCancel = () => {
        setTags(props.tags);
        setIsOpen(false);
    };

    const handleConfirm = () => {
        props.onChange(tags);
        refetch();
        setIsOpen(false);
    };

    const hasError = tags.some(t => t.length >= 50);

    return (
        <Fragment>
            <Space wrap style={{ width: '100%' }}>
                <Button
                    type='primary'
                    onClick={() => {
                        setIsOpen(true);
                    }}
                >
                    Изменить теги
                </Button>
                <Typography.Text>{`Выбрано чатов: ${props.count}`}</Typography.Text>
            </Space>

            <GenericModal
                title={`Изменить теги ${props.count} чатов`}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                okButtonProps={{
                    disabled: hasError,
                }}
                destroyOnClose
                width={380}
                open={isOpen}
            >
                <p>Видны только теги, относящиеся ко всем выбранным чатам</p>
                <TagsSelector
                    options={tagsData.map(t => t.name)}
                    value={tags}
                    onChange={setTags}
                />
                {hasError && (
                    <StyledError>
                        Длина не должна превышать 50 символов
                    </StyledError>
                )}
            </GenericModal>
        </Fragment>
    );
};

const StyledError = styled.p`
    color: #f06e69;
    font-size: 14px;
`;
