import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Badge, Space } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { GreetingsSwitch } from 'features/communities/components/Onboarding/views/components/GreetingsSwitch';
import { BotJoinLimitSwitch } from 'features/communities/components/Onboarding/views/components/BotJoinLimitSwitch';
import { JoinLimitSwitch } from 'features/communities/components/Onboarding/views/components/JoinLimitSwitch';
import { RtlJoinLimitSwitch } from 'features/communities/components/Onboarding/views/components/RtlJoinLimitSwitch';
import { InviteBotLimitSwitch } from 'features/communities/components/Onboarding/views/components/InviteBotLimitSwitch';
import { ChatJoinActionSwitch } from 'features/communities/components/Onboarding/views/components/ChatJoinActionSwitch';
import { IFormikInitialValues } from 'features/communities/components/Onboarding/containers/OnboardingContainerCreate';
import { RestrictMessageSendingSwitch } from 'features/communities/components/Onboarding/views/components/RestrictMessageSendingSwitch';
import { DeleteGreetingsSwitch } from 'features/communities/components/Onboarding/views/components/DeleteGreetingsSwitch';
import { OnboardingSwitchEnum } from 'features/communities/components/Onboarding/OnboardingMapper';

import { blobToBase64 } from '../../../../../utils/common/blobToBase64';
import { HeaderScenarios } from '../../HeaderScenarios/HeaderScenarios';
import { ChatList } from '../../ChatsList/ChatList';
import { ConfirmBlock } from '../../ConfirmBlock/ConfirmBlock';
import { RuleType } from '../../../../../shared/contants/rule-types';

interface IProps {
    initialValues: Partial<IFormikInitialValues>;
    onSave: (values) => void;
}

export const OnboardingForm: FC<IProps> = props => {
    const [img1, setImg1] = useState<ArrayBuffer | string | null>(
        props.initialValues.switches[OnboardingSwitchEnum.greetingsSwitch]
            ?.greetingImage || null
    );
    const [img2, setImg2] = useState<ArrayBuffer | string | null>(
        props.initialValues.switches[OnboardingSwitchEnum.chatJoinSwitch]
            ?.questionImage || null
    );
    const img1Ref = useRef(null);
    const img2Ref = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (img1Ref.current) {
            blobToBase64(img1Ref.current).then(val => setImg1(val));
        }
        if (img2Ref.current) {
            blobToBase64(img2Ref.current).then(val => setImg2(val));
        }
    }, [img1Ref.current, img2Ref.current]);

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: props.onSave,
    });

    return (
        <Fragment>
            <ContainerWrapper>
                <HeaderScenarios
                    inputValue={formik.values.title}
                    onChangeHeaderInput={event => {
                        formik.setFieldValue('title', event.target.value);
                    }}
                />
                <Space direction='vertical' size='large'>
                    <GreetingsSwitch
                        formik={formik}
                        imgRef={img1Ref}
                        img1={img1}
                    />

                    <DeleteGreetingsSwitch formik={formik} />

                    <RestrictMessageSendingSwitch formik={formik} />

                    <BotJoinLimitSwitch formik={formik} />

                    <JoinLimitSwitch formik={formik} />

                    <RtlJoinLimitSwitch formik={formik} />

                    <InviteBotLimitSwitch formik={formik} />

                    {/*<ChatJoinActionSwitch
                        formik={formik}
                        imgRef={img2Ref}
                        img2={img2}
                    />*/}
                </Space>
            </ContainerWrapper>
            <ChatList
                selectedRows={formik.values.selectedChatIds}
                setSelectedRows={value => {
                    formik.setFieldValue('selectedChatIds', value);
                }}
                tableOptions={{
                    entityType: RuleType.Onboarding,
                }}
            />
            <ConfirmBlock
                onSave={formik.handleSubmit}
                onCancel={() => {
                    navigate(-1);
                }}
            >
                <Fragment>
                    <Badge
                        color='orange'
                        text={`Для ${formik.values.selectedChatIds.length} чатов текущий онбординг заменит действующий. Это действие нельзя отменить.`}
                    />
                </Fragment>
            </ConfirmBlock>
        </Fragment>
    );
};

const ContainerWrapper = styled.div`
    display: block;
    margin-top: 32px;
    margin-bottom: 33px;
`;
