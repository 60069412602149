import React, { FC, Fragment, useEffect } from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import {
    TariffMapper,
    TariffSwitchesEnum,
} from 'features/monetization/pages/tariff/TariffMappter';
import { useCreateMutation } from 'features/monetization/services/tariff.service';

import { Page } from '../../../../../shared/ui/Page';
import { TariffForm } from '../views/TariffForm';

export interface IFormikInitialValues {
    title: string;
    switches: {
        [TariffSwitchesEnum.paidAccess]: {
            isChecked: boolean;
            price?: number;
            tariff_is_payable?: boolean;
            main_description?: string;
            main_image?: string;
            command?: string;
        };
        [TariffSwitchesEnum.demoAccess]: {
            isChecked: boolean;
            test_period_is_active?: boolean;
        };
        [TariffSwitchesEnum.messageAfterPay]: {
            isChecked: boolean;
            thanks_message_is_active?: boolean;
            thanks_message?: string;
            thanks_image?: string;
        };
    };
    community_id?: number;
}

export const TariffContainerAdd: FC = () => {
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [create, { isSuccess, error }] = useCreateMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Тариф создан успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any).data.message || 'Что-то пошло не так',
                placement: 'top',
            });
        }
    }, [error]);

    const initialValues: IFormikInitialValues = {
        title: '001 Тариф',
        switches: {
            [TariffSwitchesEnum.paidAccess]: {
                isChecked: false,
            },
            [TariffSwitchesEnum.demoAccess]: {
                isChecked: false,
            },
            [TariffSwitchesEnum.messageAfterPay]: {
                isChecked: false,
            },
        },
    };

    const onSave = async (values: IFormikInitialValues) => {
        const price = values.switches[TariffSwitchesEnum.paidAccess].price;
        const paidAccess =
            values.switches[TariffSwitchesEnum.paidAccess].isChecked;

        if (paidAccess) {
            if (!price) {
                return notification.info({
                    message: 'Укажите стоимость за месяц',
                });
            }
            if (price < 50) {
                return notification.info({
                    message:
                        'Минимальная стоимость тарифа должна быть 50 рублей',
                });
            }
        }

        if (!values.community_id) {
            return notification.error({
                message: 'Нужно выбрать сообщество',
            });
        }

        await create(TariffMapper.createDto(values));
    };

    return (
        <Fragment>
            <Page title='Тарифы'>
                <TariffForm
                    initialValues={initialValues}
                    onSave={onSave}
                    isCreate={true}
                />
            </Page>
        </Fragment>
    );
};
