import React, { useEffect, useState } from 'react';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

type TProps = {
    readonly value?: string;
    readonly onChange?: (value: string) => void;
    readonly onClear?: () => void;
    readonly onClose?: () => void;
};

export const TextFilter: React.FC<TProps> = props => {
    const [value, setValue] = useState<string>(props.value);

    useEffect(() => {
        setValue(props.value || '');
    }, [props.value]);

    const handleChange = () => {
        props.onChange(value.toLowerCase());
        props.onClose();
    };

    const handleClear = () => {
        setValue('');
        props.onClear && props.onClear();
    };

    const isEmpty = value === '' || !value;

    return (
        <>
            <Input
                value={value}
                placeholder={`Поиск по названию`}
                onChange={({ currentTarget: { value } }) => {
                    setValue(value);
                }}
            />
            <Space>
                <Button
                    disabled={isEmpty}
                    size='small'
                    type='primary'
                    icon={<SearchOutlined />}
                    onClick={handleChange}
                >
                    Поиск
                </Button>
                <Button disabled={isEmpty} size='small' onClick={handleClear}>
                    Очистить
                </Button>
                <Button size='small' type='text' onClick={props.onClose}>
                    Отмена
                </Button>
            </Space>
        </>
    );
};
