import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { authApi } from 'features/auth/services';
import { userApi } from 'features/user/services';
import { subscriptionsApi } from 'features/subscriptions/services';
import { communitiesAPi } from 'features/communities/services';
import { adminApi } from 'features/admin/services';
import { publicationsApi } from 'features/courses/services/publications';
import { analyticsApi } from 'features/analytics/services';
import { analyticsAdapterApi } from 'features/analytics/services/adapter';

import rootReducer from './rootReducer';
import { rtkQueryErrorLogger } from './errorMiddleware';
import { monetizationApi } from '../features/monetization/services';
import { webinarsApi } from '../features/courses/services/webinars';
import { authorApi } from '../features/courses/services/author';
import { analyticsReadersApi } from '../features/courses/components/Analytics/services/readers';
import { recommendationApi } from '../features/courses/components/Recommendation/services';
import { analyticsPostsApi } from '../features/courses/components/Analytics/services/posts';
import { analyticsWebinarsApi } from '../features/courses/components/Analytics/services/webinars';
import { productsApi } from '../features/shops/services/products';
import { shopsApi } from '../features/shops/services/shops';
import { productsCategoriesApi } from '../features/shops/services/categories';
import { legalEntityApi } from '../features/user/services/legalEntity';

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            rtkQueryErrorLogger,
            authApi.middleware,
            userApi.middleware,
            subscriptionsApi.middleware,
            communitiesAPi.middleware,
            publicationsApi.middleware,
            webinarsApi.middleware,
            analyticsApi.middleware,
            analyticsAdapterApi.middleware,
            analyticsReadersApi.middleware,
            analyticsPostsApi.middleware,
            recommendationApi.middleware,
            ...adminApi,
            ...monetizationApi,
            authorApi.middleware,
            analyticsWebinarsApi.middleware,
            productsApi.middleware,
            shopsApi.middleware,
            productsCategoriesApi.middleware,
            legalEntityApi.middleware,
        ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
