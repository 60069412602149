import { Avatar, Space, Table } from 'antd';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { IStatisticFinanceHistory } from 'features/analytics/services/interfaces';
import { currencyFormat } from 'features/analytics/utils/currency';
import { TableFilter } from 'shared/ui/TableFilter';

type Props = {
    readonly source: IStatisticFinanceHistory[];
    readonly filters: any;
    readonly onFiltersUpdate: (update) => void;
};

export const FinanceTableView: React.FC<Props> = props => {
    return (
        <Fragment>
            <StyledTable
                dataSource={props.source}
                rowKey={payment => payment.id}
                pagination={{
                    total: props.source?.length,
                    showTotal: () => `Платежей: ${props.source?.length}`,
                }}
            >
                <Table.Column
                    title={'Дата и время'}
                    defaultSortOrder='descend'
                    sortDirections={['ascend', 'descend']}
                    sorter={(a: any, b: any) => a.buy_date - b.buy_date}
                    render={payment =>
                        dayjs(payment.buy_date).format('MM.DD.YY, HH:mm')
                    }
                />
                <Table.Column
                    sortDirections={['ascend', 'descend']}
                    title='Сумма'
                    sorter={(a: any, b: any) => a.amount - b.amount}
                    render={payment => `${currencyFormat(payment.amount)}`}
                />
                <Table.Column title={'Тип'} dataIndex={'type'} />
                <Table.Column
                    title={'Назначение'}
                    dataIndex={'payable_title'}
                />
                <Table.Column
                    title={
                        <TableFilter<string>
                            title='Имя'
                            onChange={first_name => {
                                props.onFiltersUpdate({ first_name });
                            }}
                            value={props.filters.first_name}
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    render={payment => (
                        <Space>
                            <Avatar src={payment.photo_url} />
                            {payment.first_name}
                        </Space>
                    )}
                />
                <Table.Column
                    title={
                        <TableFilter<string>
                            title='Юзернейм'
                            onChange={user_name => {
                                props.onFiltersUpdate({ user_name });
                            }}
                            value={props.filters.user_name}
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    render={payment =>
                        payment.user_name && (
                            <Link
                                target='_blank'
                                to={`https://t.me/${payment.user_name}`}
                            >
                                @{payment.user_name}
                            </Link>
                        )
                    }
                />
                <Table.Column
                    title={
                        <TableFilter<string>
                            title='Почта'
                            onChange={email => {
                                props.onFiltersUpdate({ email });
                            }}
                            value={props.filters.email}
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    dataIndex={'email'}
                />
            </StyledTable>
        </Fragment>
    );
};

const StyledTable = styled(Table<IStatisticFinanceHistory>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;
