import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import { useLazyReadOrdersExportQuery } from '../../services/shops';

const { Text } = Typography;
const OrdersContainer = () => {
    const { id } = useParams();
    const [getOrdersExport, { data }] = useLazyReadOrdersExportQuery();

    const handleGetOrders = async () => {
        getOrdersExport(+id);
    };
    return (
        <Flex gap={24} vertical>
            <Flex justify={'flex-end'}>
                <Button
                    onClick={handleGetOrders}
                    type={'default'}
                    icon={<UploadOutlined />}
                >
                    Выгрузить заказы
                </Button>
            </Flex>
            <Text>
                Раздел находится в разработке, но пока вы можете выгрузить все
                ваши заказы.
            </Text>
        </Flex>
    );
};

export default OrdersContainer;
