import React from 'react';
import Chart, { Props as ApexProps } from 'react-apexcharts';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { IStatisticFilters } from 'features/analytics/services/interfaces';
import { useGetFinanceQuery } from 'features/analytics/services';

import { ChartWrapper } from '../ChartWrapper';
import { ListBagde } from '../../ListBadge';

type Props = {
    readonly height?: number;
    readonly width?: number;
    readonly filters: IStatisticFilters;
};

const currencyFormat = (value: number): string => {
    return new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
    }).format(value / 100);
};

export const FinanceChart: React.FC<Props> = props => {
    const colors = ['#85A5FF', '#597EF7'];

    const {
        data = {
            balance: [],
            course: [],
            donate: [],
            labels: [],
            rate: [],
            all: 0,
        },
    } = useGetFinanceQuery(
        {
            period: props.filters.period,
            community_id: Number(props.filters.chat) || null,
        },
        { refetchOnMountOrArgChange: true }
    );

    const options: ApexProps['options'] = {
        chart: {
            stacked: true,
            type: 'bar',
            toolbar: {
                show: false,
            },
        },
        labels: data.labels,
        legend: {
            show: false,
            position: 'left',
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            labels: {
                show: props.filters.period !== 'day',
                formatter(value, timestamp, opts) {
                    switch (props.filters.period) {
                        case 'year': {
                            return dayjs(timestamp).format('MMMM');
                        }
                        case 'month': {
                            return dayjs(timestamp).format('DD MMMM');
                        }
                        case 'week': {
                            return dayjs(timestamp).format('DD MMMM');
                        }
                    }
                },
            },
            type: 'datetime',
        },
        yaxis: {
            labels: {
                show: false,
            },
        },
        plotOptions: {},
        colors: colors,
        markers: {
            colors: colors,
        },
        tooltip: {
            custom: function (options) {
                return `${
                    options.w.config.series[options.seriesIndex].name
                } ${currencyFormat(
                    options.series[options.seriesIndex][options.dataPointIndex]
                )}`;
            },
        },
    };

    const series = [];

    if (data.donate.some(d => d > 0))
        series.push({
            name: 'Донаты',
            data: data.donate,
        });
    if (data.rate.some(d => d > 0))
        series.push({
            name: 'Тариф',
            data: data.rate,
        });
    if (data.course.some(d => d > 0))
        series.push({
            name: 'Медиатовары',
            data: data.course,
        });

    return (
        <ChartWrapper isLoading={false} title='Финансы'>
            <StyledContainer>
                <StyledLegendContainer>
                    <div className='counter'>{currencyFormat(data.all)}</div>
                    {data.donate.some(d => d > 0) && (
                        <ListBagde text='Донаты' color='#D6E4FF' />
                    )}
                    {data.rate.some(r => r > 0) && (
                        <ListBagde text='Тариф' color='#ADC6FF' />
                    )}
                    {data.course.some(c => c > 0) && (
                        <ListBagde text='Медиатовары' color='#597EF7' />
                    )}
                </StyledLegendContainer>
                <StyledChart
                    height={props.height}
                    type='bar'
                    options={options}
                    series={series}
                />
            </StyledContainer>
        </ChartWrapper>
    );
};

const StyledChart = styled(Chart)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`;
const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const StyledLegendContainer = styled.div`
    height: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .counter {
        color: #2f54eb;
        font-size: 36px;
        font-weight: 300;
    }

    .result {
        color: #139c55;
        font-size: 24px;
        font-weight: 300;
    }

    .description {
        font-size: 12px;
        font-weight: 400;
        color: rgba(37, 33, 41, 0.45);
    }
`;
