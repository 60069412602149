import React from 'react';
import styled from 'styled-components';
import { Button, Col, Collapse, Space, Typography } from 'antd';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';

type TProps = {
    readonly loading: boolean;
    readonly refetch: () => void;
};

export const AddCommunity: React.FC<TProps> = props => {
    const botName = window.ENV.TELEGRAM_BOT;
    const botInviteLink = `tg://resolve?domain=${botName}`;

    return (
        <Collapse defaultActiveKey={1}>
            <Collapse.Panel header='Как добавлять сообщества' key={1}>
                <Space size={'large'} direction={'vertical'}>
                    <Col span={24}>
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            <Typography.Text>
                                Для добавления сообщества(группы или канала)
                                Telegram в личный кабинет Spodial перейдите{' '}
                                <Link
                                    style={{ color: '#6257d2' }}
                                    target={'_blank'}
                                    to={botInviteLink}
                                >
                                    по ссылке
                                </Link>
                            </Typography.Text>
                            <Typography.Text>
                                или отсканируйте QR-code
                            </Typography.Text>
                        </Space>
                    </Col>

                    <Col span={24}>
                        <StyledQr size={200} value={botInviteLink} />
                    </Col>

                    <Col span={24}>
                        <Button
                            disabled={props.loading}
                            onClick={() => props.refetch()}
                        >
                            Обновить список сообществ
                        </Button>
                    </Col>
                </Space>
            </Collapse.Panel>
        </Collapse>
    );
};

const StyledQr = styled(QRCode)`
    display: block;
`;
