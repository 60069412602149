import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Dropdown } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useTheme } from 'hooks/useTheme';

import { DateFilter } from './DateFilter';
import { TagFilter } from './TagFilter';
import { TextFilter } from './TextFilter';
import { SelectorFilter } from './SelectorFilter';
import { MultiCheckBoxFilter } from './MultiCheckBoxFilter';

type Children<T> = {
    readonly onChange: (value: T | '') => void;
    readonly onClose: () => void;
    readonly onClear?: () => void;
    readonly value: T | '';
};

type TProps<T> = {
    readonly title: string;
    readonly value?: T | '';
    readonly onChange?: (value: T | '') => void;
    readonly icon?: React.ReactElement;
    readonly children:
        | ((renderProp: Children<T>) => React.ReactNode)
        | React.ReactElement;
    readonly setFilters?: (filters) => void;
    readonly filters?: {
        date_to?: number | string;
        date_from?: number | string;
    };
    readonly closeOnSelect?: boolean;
};

export const TableFilter = <T,>(props: TProps<T>) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<T | ''>();
    const [showBadge, setShowBadge] = useState(false);
    const [filterTitle, setFilterTitle] = useState(props.title);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onChange = (value: T) => {
        props.onChange && props.onChange(value);
        props.closeOnSelect ? setOpen(false) : null;
    };

    const onClose = () => {
        setOpen(false);
    };

    const onClear = () => {
        props.onChange && props.onChange('');
        props.closeOnSelect ? setOpen(false) : null;
    };

    const token = useTheme();

    return (
        <StyledWrapper>
            <StyledTitle>{props.title}</StyledTitle>
            <Dropdown
                open={isOpen}
                onOpenChange={setOpen}
                trigger={['click']}
                placement={'bottomRight'}
                dropdownRender={() => (
                    <StyledDropDownCard style={{ ...token.Card }}>
                        {typeof props.children === 'function'
                            ? props.children({
                                  onChange: onChange,
                                  onClear: onClear,
                                  onClose: onClose,
                                  value: props.value,
                              })
                            : React.cloneElement(props.children, {
                                  onChange,
                                  onClear,
                                  onClose,
                                  value,
                                  setFilters: props.setFilters,
                                  filters: props.filters,
                                  setShowBadge,
                                  setFilterTitle,
                              })}
                    </StyledDropDownCard>
                )}
            >
                <StyledFilterButton>
                    {props.icon || <SearchOutlined />}
                </StyledFilterButton>
            </Dropdown>
        </StyledWrapper>
    );
};

TableFilter.Tags = TagFilter;
TableFilter.Text = TextFilter;
TableFilter.Date = DateFilter;
TableFilter.Selector = SelectorFilter;
TableFilter.MultiCheckBox = MultiCheckBoxFilter;

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledTitle = styled.div`
    font-weight: bold;
`;

const StyledFilterButton = styled.span`
    cursor: pointer;
`;

const StyledDropDownCard = styled(Card)`
    max-width: 300px;

    .ant-card-body {
        padding: 0 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;
