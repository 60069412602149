import React, { Fragment, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { App, Button, Divider, Input, Select, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';

import {
    useCreateCategoryMutation,
    useReadCategoryQuery,
} from '../../../../services';

export const CategorySelect = props => {
    const { notification } = App.useApp();

    const [name, setName] = useState('');
    const [list, setList] = useState([]);

    const inputRef = useRef<InputRef>(null);

    const { data: categories, isSuccess } = useReadCategoryQuery({
        knowledge_id: props.formik.values.knowledge?.id,
    });

    const [createCategory, { isLoading, isSuccess: isSuccessCreate }] =
        useCreateCategoryMutation();

    useEffect(() => {
        if (isSuccess && categories) {
            const categoriesList = categories?.items.map((c, index) => ({
                id: c.id,
                key: index,
                value: c.name,
                text: c.name,
            }));
            setList(categoriesList);
        }
    }, [isSuccess, categories, isSuccessCreate]);

    useEffect(() => {
        if (isSuccessCreate) {
            setName('');
            notification.success({
                message: 'Категория успешно добавлена',
            });
        }
    }, [isSuccessCreate]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const addItem = async (
        e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
    ) => {
        e.preventDefault();

        const checkCategory = categories.items.some(elem => elem.name === name);

        if (checkCategory) {
            return notification.error({
                message: `Категория ${name} уже существует`,
            });
        }

        await createCategory({
            knowledge_id: props.formik.values.knowledge?.id,
            name: name,
        });
    };

    return (
        <Fragment>
            <StrongText>Категория</StrongText>
            <StyledSelect
                showSearch
                onChange={(e, category: { id: number }) => {
                    props.formik.setFieldValue('category', category);
                }}
                disabled={!props.formik.values.knowledge}
                defaultValue={'Выберите категорию'}
                options={list}
                dropdownRender={menu => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                                placeholder='Название категории'
                                ref={inputRef}
                                value={name}
                                onChange={onNameChange}
                            />
                            <Button
                                disabled={isLoading || name === ''}
                                type='text'
                                icon={<PlusOutlined />}
                                onClick={addItem}
                            >
                                Добавить
                            </Button>
                        </Space>
                    </>
                )}
            />
        </Fragment>
    );
};

const StrongText = styled(Typography.Text)`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
`;

const StyledSelect = styled(Select)`
    max-width: 300px;
`;
