import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { App, Skeleton } from 'antd';
import dayjs from 'dayjs';

import {
    useCreatePublicationTimeMutation,
    useCreatePublicationVisitedMutation,
    useReadPublicationByUuidQuery,
} from '../../../services/publications';
import { FeedbackContainer } from '../../Feedback/FeedbackContainer';
import { ButtonBack } from '../../Author/containers/post/components/ButtonBack';
import { PartsCard } from '../../Author/view/PartsCard';
import { PostMainInfo } from '../../Author/view/PostMainInfo';
import { useProfileData } from '../../../../../hooks/useProfileData';

export const PostMemberContainer = () => {
    const { notification } = App.useApp();
    const { uuid } = useParams();
    const { user } = useProfileData();

    const [isOwner, setIsOwner] = useState<boolean>(false);

    const [createTime] = useCreatePublicationTimeMutation();

    const {
        data: post,
        isError,
        isSuccess,
    } = useReadPublicationByUuidQuery(uuid);

    useEffect(() => {
        if (post && user) {
            setIsOwner(post?.author_id === user?.author_fields?.id);
        } else setIsOwner(false);
    }, [user, post]);

    const [createVisited] = useCreatePublicationVisitedMutation();

    useEffect(() => {
        if (isSuccess) {
            createVisited(post?.id);
            sessionStorage.setItem('postId', String(post.id));
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Произошла ошибка при получении поста',
            });
        }
    }, [isError]);

    const now = dayjs().unix();

    useEffect(() => {
        if (now) {
            sessionStorage.setItem('start', String(now));
        }
    }, [now]);

    useEffect(() => {
        window.addEventListener('unload', handlePostTime);
        return () => {
            window.removeEventListener('unload', handlePostTime);
            void handlePostTime();
        };
    }, []);

    const handlePostTime = async () => {
        const end = dayjs().unix();
        const start = sessionStorage.getItem('start');
        const postId = sessionStorage.getItem('postId');
        const secondOnPage = end - Number(start);
        await createTime({ id: Number(postId), seconds: secondOnPage });
    };

    return !isSuccess ? (
        <Skeleton />
    ) : (
        <>
            <ButtonBack />
            {post && <PostMainInfo post={post} />}
            {post?.parts?.length > 0 && <PartsCard parts={post.parts} />}
            {post.id && !isOwner && (
                <FeedbackContainer postId={post?.id} type={'post'} />
            )}
        </>
    );
};
