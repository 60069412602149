import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Space, Typography } from 'antd';
import styled from 'styled-components';

export const ButtonBack = () => {
    const navigate = useNavigate();

    return (
        <StyledWrapper onClick={() => navigate(-1)} align={'center'}>
            <ArrowLeftOutlined />
            <Typography.Title level={4} style={{ margin: 0 }}>
                Назад
            </Typography.Title>
        </StyledWrapper>
    );
};

const StyledWrapper = styled(Space)`
    margin: 1rem 0;
    cursor: pointer;
`;
