import { Dispatch, SetStateAction } from 'react';

export interface IReadersResponse {
    original: {
        agesRanges: TAges[];
        countries: TCountries[];
        genders: TGenders[];
    };
}

export type TAges = {
    age_range: string;
    users_count: number;
};

export type TCountries = {
    country: string;
    users_count: number;
};

export type TGenders = {
    gender_range: string;
    users_count: number;
};

export enum Periods {
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
}

export interface IPageProps {
    readonly filters?: TFilters;
    readonly setFilters?: Dispatch<SetStateAction<TFilters>>;
}

export type TFilters = {
    period: Periods;
    sort?: string;
};

export interface IPostsResponse {
    readonly host: THost[];
    readonly time: TTime[];
    readonly view: TView[];
}

export type THost = {
    publication_id: number;
    host: number;
    title: string;
};
export type TTime = {
    publication_id: number;
    title: string;
    seconds: string;
};
export type TView = {
    publication_id: number;
    title: string;
    view: number;
};

export enum PostView {
    time = 'time',
    view = 'view',
    host = 'host',
}

export interface IWebinarsResponse {
    data_low: number[];
    data_max: number[];
    titles: string[];
    webinars: Array<IWebinarsForChart>;
}

export interface IWebinarsForChart {
    start_at: string;
    end_at: string;
    title: string;
}
