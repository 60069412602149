import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Badge, Input, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { WhoCanRateSwitch } from 'features/communities/components/Reputation/views/components/WhoCanRateSwitch';
import { RestrictRatePeriodSwitch } from 'features/communities/components/Reputation/views/components/RestrictRatePeriodSwitch';
import { DelayStartRulesTimeSwitch } from 'features/communities/components/Reputation/views/components/DelayStartRulesTimeSwitch';
import { UserRateSwitch } from 'features/communities/components/Reputation/views/components/UserRateSwitch';
import { blobToBase64 } from 'utils/common/blobToBase64';
import { NotifyChangeRateSwitch } from 'features/communities/components/Reputation/views/components/NotifyChangeRateSwitch';
import { RestrictAccumulateRateSwitch } from 'features/communities/components/Reputation/views/components/RestrictAccumulateRateSwitch';

import { HeaderScenarios } from '../../HeaderScenarios/HeaderScenarios';
import { ConfirmBlock } from '../../ConfirmBlock/ConfirmBlock';
import { ChatList } from '../../ChatsList/ChatList';
import { RuleType } from '../../../../../shared/contants/rule-types';
import { IFormikInitialValues } from '../containers/ReputationContainerCreate';
import { ReputationSwitchesEnum } from '../ReputationMapper';

interface IProps {
    onSave: (values) => void;
    initialValues: Partial<IFormikInitialValues>;
}

export const ReputationForm: FC<IProps> = props => {
    const [userRateImg, setUserRateImg] = useState<ArrayBuffer | string | null>(
        (props.initialValues.switches[ReputationSwitchesEnum.userRateSwitch]
            .show_rating_tables_image as string) || null
    );
    const [notifyChangeRateImg, setNotifyChangeRateImg] = useState<
        ArrayBuffer | string | null
    >(
        (props.initialValues.switches[
            ReputationSwitchesEnum.notifyChangeRateSwitch
        ].notify_about_rate_change_image as string) || null
    );
    const [accumulateRateImg, setAccumulateRateImg] = useState<
        ArrayBuffer | string | null
    >(
        (props.initialValues.switches[
            ReputationSwitchesEnum.restrictAccumulateRateSwitch
        ].restrict_accumulate_rate_image as string) || null
    );
    const userRateImgRef = useRef(null);
    const notifyChangeRateRef = useRef(null);
    const accumulateRateRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (userRateImgRef.current) {
            blobToBase64(userRateImgRef.current).then(val =>
                setUserRateImg(val)
            );
        }
        if (notifyChangeRateRef.current) {
            blobToBase64(notifyChangeRateRef.current).then(val =>
                setNotifyChangeRateImg(val)
            );
        }
        if (accumulateRateRef.current) {
            blobToBase64(accumulateRateRef.current).then(val =>
                setAccumulateRateImg(val)
            );
        }
    }, [
        userRateImgRef.current,
        notifyChangeRateRef.current,
        accumulateRateRef.current,
    ]);

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: props.onSave,
    });

    return (
        <Fragment>
            <ContainerWrapper>
                <HeaderScenarios
                    inputValue={formik.values.title}
                    onChangeHeaderInput={event => {
                        formik.setFieldValue('title', event.target.value);
                    }}
                />

                <SwitchersWrap>
                    <StyledTitle level={5}>
                        Ключевые слова, влияюшие на репутацию
                    </StyledTitle>
                    <div style={{ marginBottom: 8 }}>
                        <Typography.Text>
                            Репутация повышается, если в ответе на сообщения
                            имеются следующие слова, выражения и эмоджи
                            (перечислите по одному на строке):
                        </Typography.Text>
                    </div>
                    <Input.TextArea
                        rows={4}
                        style={{ marginBottom: 8 }}
                        value={formik.values.keyword_rate_up?.join('\n')}
                        onChange={event => {
                            formik.setFieldValue(
                                'keyword_rate_up',
                                event.target.value.split('\n')
                            );
                        }}
                    />
                    <div style={{ marginBottom: 8 }}>
                        <Typography.Text>
                            Репутация понижается, если в ответе на сообщения
                            имеются следующие слова, выражения и эмоджи
                            (перечислите по одному на строке):
                        </Typography.Text>
                    </div>
                    <Input.TextArea
                        rows={4}
                        style={{ marginBottom: 32 }}
                        value={formik.values.keyword_rate_down?.join('\n')}
                        onChange={event => {
                            formik.setFieldValue(
                                'keyword_rate_down',
                                event.target.value.split('\n')
                            );
                        }}
                    />

                    <WhoCanRateSwitch formik={formik} />

                    <RestrictRatePeriodSwitch formik={formik} />

                    <DelayStartRulesTimeSwitch formik={formik} />

                    <UserRateSwitch
                        formik={formik}
                        imgRef={userRateImgRef}
                        img={userRateImg}
                    />

                    <NotifyChangeRateSwitch
                        formik={formik}
                        imgRef={notifyChangeRateRef}
                        img={notifyChangeRateImg}
                    />

                    <RestrictAccumulateRateSwitch
                        formik={formik}
                        imgRef={accumulateRateRef}
                        img={accumulateRateImg}
                    />
                </SwitchersWrap>

                <ChatList
                    selectedRows={formik.values.selectedChatIds}
                    setSelectedRows={value => {
                        formik.setFieldValue('selectedChatIds', value);
                    }}
                    tableOptions={{
                        entityType: RuleType.Reputation,
                    }}
                />
                <ConfirmBlock
                    onSave={formik.handleSubmit}
                    onCancel={() => {
                        navigate(-1);
                    }}
                >
                    <Fragment>
                        <Badge
                            color='orange'
                            text={`Для ${formik.values.selectedChatIds.length} чатов текущие правила репутации заменят действующие. Это действие нельзя отменить.`}
                        />
                    </Fragment>
                </ConfirmBlock>
            </ContainerWrapper>
        </Fragment>
    );
};

const SwitchersWrap = styled.div`
    max-width: 776px;
    display: flex;
    flex-direction: column;
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const ContainerWrapper = styled.div`
    display: block;
    margin-top: 32px;
    margin-bottom: 33px;
`;
