import React, { Fragment, useState } from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import { IChatMemberQueryParams } from 'features/communities/interfaces';
import {
    useReadChatMembersQuery,
    useRemoveMemberFromListMutation,
} from 'features/communities/services';

import { WhiteListedMembersTable } from '../views/WhiteListedMembersTable';

export const WhiteListedMembersPage: React.FC = () => {
    const navigate = useNavigate();
    const { notification } = App.useApp();
    const [filters, setFilters] = useState<IChatMemberQueryParams>({
        whitelisted: true,
        community_id: null,
    });

    const { data: source = { items: [], total: 0 }, isFetching } =
        useReadChatMembersQuery(filters);

    const [removeFromlist] = useRemoveMemberFromListMutation();

    const handleFiltersUpdate = (update: IChatMemberQueryParams | null) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({});
        }
    };

    const onBulkRemove = async args => {
        const options = {
            banned: false,
            muted: false,
            whitelisted: true,
            is_spammer: 0,
        };

        const payloads = args.map(arg => ({
            telegram_id: arg.telegram_id,
            community_ids: arg.communities.map(c => c.id),
            ...options,
        }));

        await Promise.all(payloads.map(removeFromlist));
        navigate(-1);

        if (args.length === 1) {
            return notification.success({
                message: 'Участник успешно удален',
                placement: 'top',
            });
        } else {
            return notification.success({
                message: 'Участники успешно удалены',
                placement: 'top',
            });
        }
    };

    return (
        <Fragment>
            <WhiteListedMembersTable
                onBulkRemove={onBulkRemove}
                filters={filters}
                onFiltersUpdate={handleFiltersUpdate}
                loading={isFetching}
                source={source}
            />
        </Fragment>
    );
};
