import React, { Fragment, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Col, Radio, Row, Typography } from 'antd';
import styled from 'styled-components';

import { useReadKnowledgeByHashQuery } from '../../../../services';
import { Search } from '../../view/components/Search';
import { CategoryListContainer } from './CategoryListContainer';
import { QuestionTableContainer } from '../../../Questions/containers/public/QuestionTableContainer';

export const CategoryContainer = () => {
    const { id } = useParams();

    const [value, setValue] = useState('categories');
    const [title, setTitle] = useState('');
    const [questionTotal, setQuestionTotal] = useState(0);
    const [categoryTotal, setCategoryTotal] = useState('');
    const [questionData, setQuestionData] = useState([]);

    const [searchWords, setSearchWords] = useState([]);
    const [textToHighlight, setTextToHighlight] = useState('');

    const [questionsExtended, setQuestionsExtended] = useState([]);
    const [questionsWOCategory, setQuestionsWOCategory] = useState([]);

    const {
        data: source,
        isFetching,
        isSuccess,
    } = useReadKnowledgeByHashQuery(id);

    useEffect(() => {
        if (!isFetching) {
            setQuestionData(source?.items?.categories);
            setQuestionTotal(source?.items?.questions?.length);
            setCategoryTotal(source?.items?.categories?.length);
            setQuestionsExtended(source?.items?.questions);
            setQuestionsWOCategory(
                source?.items?.questions.filter(el => el.category_id === null)
            );
        }
    }, [source]);

    useEffect(() => {
        if (isSuccess && source) {
            setTitle(source?.items?.knowledge_name);
        }
    }, [isSuccess, source]);

    const handleSearch = e => {
        setSearchWords([e]);

        const result = source.items.questions.filter(question => {
            setTextToHighlight(question.context);
            return question?.context.toLowerCase().match(e.toLowerCase());
        });
        setQuestionTotal(result.length);
    };

    return (
        <Fragment>
            <Typography.Title level={1}>{title || ''}</Typography.Title>

            <StyledHeader
                gutter={[20, 10]}
                justify={'space-between'}
                align={'middle'}
            >
                <Col xs={24} lg={12} xl={12}>
                    <Search onSearch={handleSearch} />
                </Col>

                <Col xs={24} lg={7} xl={6}>
                    <Typography.Text>
                        Поиск по <strong>{questionTotal || 0}</strong> вопросам
                        в <strong>{categoryTotal}</strong> категориях
                    </Typography.Text>
                </Col>

                <Col xs={24} lg={24} xl={6}>
                    <StyledButtonRadioGroup
                        value={value}
                        defaultValue={'categories'}
                        onChange={e => {
                            setValue(e.target.value);
                        }}
                    >
                        <Radio.Button type={'link'} value='categories'>
                            Категории
                        </Radio.Button>
                        <Radio.Button type={'link'} value='questions'>
                            Вопросы
                        </Radio.Button>
                    </StyledButtonRadioGroup>
                </Col>
            </StyledHeader>

            {value === 'categories' ? (
                <CategoryListContainer
                    searchWords={searchWords}
                    loading={isFetching}
                    source={questionData || []}
                    questionsWOCategory={questionsWOCategory}
                    setValue={setValue}
                />
            ) : (
                <QuestionTableContainer
                    questionTotal={questionTotal}
                    searchWords={searchWords}
                    loading={isFetching}
                    source={questionsExtended || []}
                />
            )}

            <Outlet />
        </Fragment>
    );
};

const StyledHeader = styled(Row)`
    margin: 1rem 0;
`;

const StyledButtonRadioGroup = styled(Radio.Group)`
    display: flex;
    justify-content: flex-end;
`;
