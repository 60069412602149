import React, { useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { Card } from '../../components/Card';
import { options } from '../../utils/postsChartOptions';
import { PostSort } from '../../components/PostSort';
import { IPageProps, PostView } from '../../services/interfaces';
import { useLazyReadPostsQuery } from '../../services/posts';

export const PostsContainer: React.FC<IPageProps> = props => {
    dayjs.extend(duration);

    const { pathname } = useLocation();
    const { filters, setFilters } = props;

    const [getPosts, { data: series, isSuccess }] = useLazyReadPostsQuery();

    useEffect(() => {
        if (pathname === '/courses/analytics/posts') {
            getPosts(filters);
        }
    }, [pathname, filters]);

    const hostLabels = series?.host?.map(h => h.title);
    const hostSeries = series?.host?.map(h => h.host);
    const hostData = [{ data: hostSeries }];

    const viewLabels = series?.view?.map(v => v.title);
    const viewSeries = series?.view?.map(v => v.view);
    const viewData = [{ data: viewSeries }];

    const timeLabels = series?.time?.map(t => t.title);
    const timeSeries = series?.time?.map(t => t.seconds);
    const timeData = [{ data: timeSeries }];

    return (
        isSuccess && (
            <>
                <Row
                    align={'middle'}
                    justify='space-between'
                    style={{ marginBottom: '1.5rem' }}
                >
                    <Col sm={12} md={12}>
                        <Typography.Title level={4}>
                            Топ публикаций
                        </Typography.Title>
                    </Col>
                    <Col
                        sm={12}
                        md={12}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <PostSort filters={filters} setFilters={setFilters} />
                    </Col>
                </Row>

                <Row wrap gutter={[30, 30]}>
                    <Col span={12}>
                        <Card
                            options={options}
                            data={viewData}
                            labels={viewLabels}
                            title={'По просмотрам'}
                            type={'bar'}
                            postView={PostView.view}
                        />
                    </Col>

                    <Col span={12}>
                        <Card
                            options={options}
                            data={timeData}
                            labels={timeLabels}
                            title={'По средней длительности просмотров'}
                            type={'bar'}
                            postView={PostView.time}
                        />
                    </Col>

                    <Col span={12}>
                        <Card
                            options={options}
                            data={hostData}
                            labels={hostLabels}
                            title={'По количеству зрителей/читателей'}
                            type={'bar'}
                            postView={PostView.host}
                        />
                    </Col>
                </Row>
            </>
        )
    );
};
