import dayjs from 'dayjs';

export const buildWeeklySeries = data => {
    const accounts = data.message_members_statistic?.map(m => ({
        id: m.telegram_id,
        name: m.name,
    }));

    const labels = data.user_messages_chart?.marks.map(d =>
        dayjs(d).format('DD.MM')
    );

    try {
        const series = Object.entries(
            data.user_messages_chart?.messages || {}
        )?.map(([tg, messages]) => {
            const acc = accounts.find(a => a.id == tg.replace(/[a-z_]+/, ''));

            return {
                name: acc.name,
                messages,
            };
        });
        return {
            series,
            labels,
            accounts,
        };
    } catch (e) {
        console.error(e);
    }

    return data;
};
