import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Card } from '../../components/Card';
import { options } from '../../utils/webinarsChartOptions';
import { WebinarList } from '../../components/WebinarList';
import { useLazyReadWebinarsQuery } from '../../services/webinars';

export const WebinarsContainer = props => {
    const { pathname } = useLocation();
    const [dataMax, setDataMax] = useState([]);
    const [dataLow, setDataLow] = useState([]);
    const [labels, setLabels] = useState<string[]>([]);

    const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

    const { filters, setFilters } = props;

    const [getWebinars, { data, isSuccess }] = useLazyReadWebinarsQuery();

    const location = '/courses/analytics/webinars';

    useEffect(() => {
        if (pathname === location) {
            getWebinars(filters);
        }
    }, [pathname, filters]);

    useEffect(() => {
        if (data) {
            setDataMax(data.data_max.slice(0, 3));
            setDataLow(data.data_low.slice(0, 3));
            setLabels(data.titles.slice(0, 3));
        }
    }, [data]);

    const series = [
        {
            name: 'Посмотрели',
            data: dataMax,
        },
        {
            name: 'Не Посмотрели',
            data: dataLow,
        },
    ];

    const onRowChange = (rowKey, record) => {
        setSelectedRows(rowKey);

        const max = data.data_max.filter((value, index) =>
            rowKey.includes(index)
        );
        setDataMax(max);

        const low = data.data_low.filter((value, index) =>
            rowKey.includes(index)
        );
        setDataLow(low);

        const lab = data.titles.filter((value, index) => {
            return ~rowKey.indexOf(index);
        });
        setLabels(lab);
    };

    return (
        <>
            <Card
                title={'Зрители'}
                data={series}
                type={'bar'}
                options={options}
                labels={labels}
            />

            {isSuccess && (
                <WebinarList
                    data={data.webinars}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    onRowChange={onRowChange}
                />
            )}
        </>
    );
};
