import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Row,
    Space,
    Typography,
    Upload,
} from 'antd';
import { useFormik } from 'formik';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { HeaderScenarios } from '../../../../../../communities/components/HeaderScenarios/HeaderScenarios';
import { TextEditor } from '../../../../../../../shared/ui/TextEditor/TextEditor';
import { validateImages } from '../../../../../../../utils/common/validateImages';
import { blobToBase64 } from '../../../../../../../utils/common/blobToBase64';
import { formatWithTime } from '../../../../../../../lib/date';
import { PaySwitch } from '../containers/components/PaySwitch';

export const WebinarForm = props => {
    const navigate = useNavigate();

    const [webinarImg, setWebinarImg] = useState(
        props.initialValues?.background_image || null
    );
    const webinarImgRef = useRef(null);

    useEffect(() => {
        if (webinarImgRef.current) {
            blobToBase64(webinarImgRef.current).then(val => setWebinarImg(val));
        }
    }, [webinarImgRef.current]);

    const formik = useFormik({
        onSubmit: props.onSave,
        initialValues: props.initialValues,
    });

    const isDateEmpty = !formik.values.start_at || !formik.values.end_at;
    const isDescriptionEmpty =
        !formik.values.description || formik.values.description === '';

    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    return (
        <Fragment>
            <Typography.Title
                level={1}
                style={{ margin: 0, marginBottom: '40px' }}
            >
                Вебинар
            </Typography.Title>

            <HeaderScenarios
                inputValue={formik?.values?.title}
                onChangeHeaderInput={event => {
                    formik.setFieldValue('title', event.target.value);
                }}
            />

            <StyledRow justify={'start'} wrap>
                <div style={{ marginTop: 10, marginRight: 14 }}>
                    <Upload
                        maxCount={1}
                        listType='picture-card'
                        className='avatar-uploader'
                        showUploadList={false}
                        beforeUpload={() => false}
                        onChange={value => {
                            if (validateImages(value?.file)) {
                                webinarImgRef.current = value?.file;
                                formik.setFieldValue(
                                    `background_image`,
                                    value.file
                                );
                            }
                        }}
                    >
                        {formik.values?.background_image ? (
                            <StyledImage
                                src={webinarImg}
                                alt='image for question'
                            />
                        ) : (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Картинка</div>
                            </div>
                        )}
                    </Upload>
                </div>

                <Col xs={24} sm={18} md={12}>
                    <TextEditor
                        containerHeight={78}
                        value={formik?.values?.description || ''}
                        placeholder={'О вебинаре (обязательно)'}
                        onChange={(value: string) => {
                            formik.setFieldValue('description', value);
                        }}
                    />
                </Col>
            </StyledRow>

            <Space wrap size={'large'} style={{ marginBottom: '2rem' }}>
                <StyledDate>
                    <Typography.Text>Дата и время начала</Typography.Text>
                    <DatePicker
                        defaultValue={
                            !formik?.values?.start_at
                                ? null
                                : dayjs(formik?.values?.start_at, dateFormat)
                        }
                        disabledDate={current => {
                            return current && current <= dayjs().add(-1, 'day');
                        }}
                        placeholder={'Выберите дату и время'}
                        format={dateFormat}
                        showTime={true}
                        style={{ width: '14rem', marginTop: '.5rem' }}
                        onChange={value => {
                            formik.setFieldValue(
                                'start_at',
                                formatWithTime(value)
                            );
                        }}
                    />
                </StyledDate>

                <StyledDate>
                    <Typography.Text>Дата и время окончания</Typography.Text>
                    <DatePicker
                        defaultValue={
                            !formik?.values?.end_at
                                ? null
                                : dayjs(formik?.values?.end_at, dateFormat)
                        }
                        format={dateFormat}
                        disabledDate={current => {
                            return current && current <= dayjs().add(-1, 'day');
                        }}
                        placeholder={'Выберите дату и время'}
                        showTime={true}
                        style={{ width: '14rem', marginTop: '.5rem' }}
                        onChange={value => {
                            formik.setFieldValue(
                                'end_at',
                                formatWithTime(value)
                            );
                        }}
                    />
                </StyledDate>
            </Space>

            <PaySwitch formik={formik} />

            <Divider />
            <Space wrap>
                <Button
                    disabled={
                        props.loading || isDateEmpty || isDescriptionEmpty
                    }
                    type={'primary'}
                    onClick={() => formik.handleSubmit()}
                >
                    Сохранить
                </Button>
                <Button disabled={props.loading} onClick={() => navigate(-1)}>
                    Отмена
                </Button>
            </Space>
        </Fragment>
    );
};

const StyledRow = styled(Row)`
    margin: 2rem 0;
`;

const StyledDate = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
