import React from 'react';

import { WebinarType } from '../WebinarContainer';
import { WebinarsPublicList } from './WebinarPublicList';

export const WebinarPublicContainer = () => {
    return (
        <>
            {Object.entries(WebinarType).map(
                (container: [string, WebinarType], index: number) => (
                    <WebinarsPublicList key={index} type={container[1]} />
                )
            )}
        </>
    );
};
