import React from 'react';
import { Select } from 'antd';

type Props = {
    readonly value: string;
    readonly onChange: (status: string) => void;
    readonly width?: string;
};

export const MemberStatusSelector: React.FC<Props> = props => {
    return (
        <Select
            style={{ width: props.width || '100%' }}
            defaultValue={'none'}
            value={props.value}
            onChange={props.onChange}
            options={[
                {
                    label: 'Нет',
                    value: 'none',
                },

                {
                    label: 'Белый список',
                    value: 'whitelisted',
                },
                {
                    label: 'Бан',
                    value: 'banned',
                },
                {
                    label: 'Мьют',
                    value: 'muted',
                },
                {
                    label: 'Кик',
                    value: 'kick',
                },
            ]}
        />
    );
};
