import React, { Fragment } from 'react';
import {
    Avatar,
    Button,
    Checkbox,
    Divider,
    Select,
    Skeleton,
    Space,
    Typography,
} from 'antd';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '../../../../../hooks/useTheme';
import { getSrc, prepareSrc } from '../../../../../utils/common/getSrc';
import { getDeclension } from '../../../../../utils/common/getDeclension';
import { Dropdown } from '../../../../../shared/ui/Dropdown';
import { rub } from '../../../../../shared/contants/styles';

interface IProps {
    source: any;
    onSetStatus: any;
    setSelectedRows: any;
    selectedRows: any;
}
export const AdaptiveCard: React.FC<IProps> = props => {
    const {
        source: tariff,
        onSetStatus,
        setSelectedRows,
        selectedRows,
    } = props;
    const token = useTheme();
    const navigate = useNavigate();

    const selectTariff = (e, item) => {
        const x = props.selectedRows.concat(...[item]);
        props.setSelectedRows(x);
    };

    return tariff === undefined ? (
        <Skeleton />
    ) : (
        tariff?.map((item, index) => (
            <div key={index}>
                <Space
                    style={{ justifyContent: 'space-between', width: '100%' }}
                >
                    <Typography.Paragraph
                        onClick={() => navigate(`${item.id}`)}
                    >
                        <StyledTitle style={{ color: token.colorPrimary }}>
                            {item.title}
                        </StyledTitle>
                    </Typography.Paragraph>

                    {/* <Checkbox
                        onChange={e => selectTariff(e, item)}
                    />*/}
                </Space>

                <Typography.Paragraph>
                    {item.price} {rub}
                </Typography.Paragraph>

                <Typography.Paragraph>
                    <Space>
                        <Avatar src={getSrc(item.community_image)} />
                        <Typography.Text>{item.chat_name}</Typography.Text>
                    </Space>
                </Typography.Paragraph>

                <Typography.Paragraph>
                    <StyledCount>
                        {item.followers}
                        {getDeclension(
                            item.followers,
                            ' участник',
                            ' участника',
                            ' участников'
                        )}
                    </StyledCount>
                </Typography.Paragraph>

                <StyledDropdownWithBorder
                    dropdownContent={() => (
                        <Button
                            size={'small'}
                            type='text'
                            onClick={() =>
                                onSetStatus(item.id, !item.tariff_is_payable)
                            }
                        >
                            {!item.tariff_is_payable
                                ? 'Активировать'
                                : 'Отключить'}
                        </Button>
                    )}
                >
                    <Button size={'small'}>
                        {item.tariff_is_payable ? 'Активен' : 'Отключен'}
                        <DownOutlined />
                    </Button>
                </StyledDropdownWithBorder>
                <StyledDivider />
            </div>
        ))
    );
};

const StyledTitle = styled(Typography.Link)`
    font-weight: 600;
`;

const StyledDivider = styled(Divider)`
    margin: 0.75rem 0;
`;

const StyledCount = styled(Typography.Text)`
    color: rgba(37, 33, 41, 0.45);
`;

const StyledDropdownWithBorder = styled(Dropdown)`
    cursor: pointer;
    width: fit-content;
`;
