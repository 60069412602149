import { FilterOutlined } from '@ant-design/icons';
import { Tag, Typography } from 'antd';
import React from 'react';

import { TableFilter } from '../../../../../shared/ui/TableFilter';

export const getColumnsOptions = (filters, handleFiltersUpdate) => {
    return [
        {
            title: () => 'Название сообщества',
            dataIndex: 'title',
            key: 'title',
            width: '25%',
            render: text => text,
        },
        {
            title: () => 'База знаний',
            dataIndex: 'rules',
            key: 'rules',
            width: '25%',
            render: (text, record) => {
                return record?.knowledge.name !== null ? (
                    <Typography.Text>{record.knowledge?.name}</Typography.Text>
                ) : (
                    <Typography.Text>Не назначено</Typography.Text>
                );
            },
        },
        {
            title: () => (
                <TableFilter<string[]>
                    icon={<FilterOutlined />}
                    onChange={tags_names => {
                        handleFiltersUpdate({ tags_names });
                    }}
                    title={'Теги'}
                    value={filters.tags_names}
                >
                    <TableFilter.Tags />
                </TableFilter>
            ),
            dataIndex: 'tags',
            key: 'tags',
            render: (text, record) => (
                <>
                    {record.tags.map(tag => (
                        <Tag color='default' key={tag.id}>
                            {tag.name}
                        </Tag>
                    ))}
                </>
            ),
        },
    ];
};
