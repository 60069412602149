import React, { FC, Fragment, useState } from 'react';
import { Space, Switch, Typography, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { validateImages } from 'utils/common/validateImages';
import { TextEditor } from 'shared/ui/TextEditor/TextEditor';
import { OnboardingSwitchEnum } from 'features/communities/components/Onboarding/OnboardingMapper';

interface IProps {
    imgRef: React.MutableRefObject<any>;
    formik: any;
    img1: any;
}

export const GreetingsSwitch: FC<IProps> = props => {
    return (
        <Fragment>
            <StyledSpace align='start'>
                <StyledSwitch
                    checked={
                        props.formik.values.switches[
                            OnboardingSwitchEnum.greetingsSwitch
                        ].isChecked
                    }
                    onChange={value => {
                        props.formik.setFieldValue(
                            `switches.${OnboardingSwitchEnum.greetingsSwitch}.isChecked`,
                            value
                        );
                    }}
                />
                <Space direction='vertical' size='middle'>
                    <StyledTitle level={5}>
                        Приветствие для новых участников
                    </StyledTitle>
                    {props.formik.values.switches[
                        OnboardingSwitchEnum.greetingsSwitch
                    ].isChecked && (
                        <StyledWrap>
                            <div style={{ marginTop: 10 }}>
                                <Upload
                                    maxCount={1}
                                    listType='picture-card'
                                    className='avatar-uploader'
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    onChange={value => {
                                        if (validateImages(value.file)) {
                                            props.imgRef.current = value.file;
                                            props.formik.setFieldValue(
                                                `switches.${OnboardingSwitchEnum.greetingsSwitch}.greetingImage`,
                                                value.file
                                            );
                                        }
                                    }}
                                >
                                    {props.formik.values.switches[
                                        OnboardingSwitchEnum.greetingsSwitch
                                    ].greetingImage ? (
                                        <StyledImage
                                            src={props.img1}
                                            alt={'alt'}
                                        />
                                    ) : (
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>
                                                Картинка
                                            </div>
                                        </div>
                                    )}
                                </Upload>
                            </div>
                            <TextEditor
                                value={
                                    props.formik.values.switches[
                                        OnboardingSwitchEnum.greetingsSwitch
                                    ]?.content
                                }
                                onChange={value => {
                                    props.formik.setFieldValue(
                                        `switches.${OnboardingSwitchEnum.greetingsSwitch}.content`,
                                        value
                                    );
                                }}
                            />
                        </StyledWrap>
                    )}
                </Space>
            </StyledSpace>
        </Fragment>
    );
};

const StyledWrap = styled.div`
    display: flex;
    gap: 16px;
    align-items: start;

    @media (max-width: 992px) {
        flex-direction: column;
    }

    @media (max-width: 576px) {
        margin-left: -68px;
    }
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSpace = styled(Space)`
    margin-bottom: 8px;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
