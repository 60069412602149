import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

import { Periods } from '../services/interfaces';

type TFilter = {
    setFilters: (filters) => void;
};
export const PeriodFilter: React.FC<TFilter> = props => {
    const { setFilters } = props;

    return (
        <StyledSelect
            defaultValue={'За месяц'}
            options={[
                {
                    value: Periods.day,
                    label: 'За день',
                },
                {
                    value: Periods.week,
                    label: 'За неделю',
                },
                {
                    value: Periods.month,
                    label: 'За месяц',
                },
                {
                    value: Periods.year,
                    label: 'За год',
                },
            ]}
            onChange={value => setFilters({ period: value })}
        />
    );
};

const StyledSelect = styled(Select)`
    min-width: 115px;
`;
