import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Typography } from 'antd';
import Highlighter from 'react-highlight-words';

const { Text } = Typography;
import { QuestionInTable } from './QuestionInTable';

type TQuestion = {
    readonly id: number;
    readonly status: string;
    readonly knowledge_id: number;
    readonly category_name: string | null;
    readonly category_id: number | null;
    readonly source: [] | any;
};

export interface IQuestionProps {
    readonly loading?: boolean;
    source?: TQuestion[];
    searchWords?: string[];
    questionTotal?: number;
}
export const QuestionTableContainer: React.FC<IQuestionProps> = (
    props
): ReactElement => {
    const { loading, source } = props;
    const [category, setCategory] = useState([]);

    useEffect(() => {
        if (source) {
            const categoryNames = source.map(element => element.category_name);
            const uniqCategory = [...new Set(categoryNames)].map(
                (elem, key) => ({
                    key: key,
                    value: !elem ? null : elem,
                    text: !elem ? '-' : elem,
                    category_name: !elem ? '-' : elem,
                })
            );
            setCategory(uniqCategory);
        }
    }, [source]);

    const update = (value, question) => {
        return question.category_name === value;
    };

    const columns = [
        {
            title: 'Категория',
            dataIndex: 'category_name',
            filters: category,
            onFilter: (value: string, question) => update(value, question),
            render: category => <Text>{!category ? '-' : category}</Text>,
        },
        {
            title: 'Вопрос',
            dataIndex: 'context',
            render: question => (
                /*<Highlighter
                    highlightClassName='YourHighlightClass'
                    searchWords={props.searchWords}
                    autoEscape={true}
                    textToHighlight={question}
                />*/
                <span
                    dangerouslySetInnerHTML={{
                        __html: question,
                    }}
                ></span>
            ),
        },
    ];

    return (
        <StyledTable
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
                total: props?.questionTotal,
                showTotal: () => `Вопросов: ${props?.questionTotal}`,
            }}
            expandable={{
                expandedRowRender: question => (
                    <QuestionInTable data={question} />
                ),
            }}
            dataSource={source}
            rowKey={(question: { id: number }) => question.id}
            columns={columns}
        />
    );
};

const StyledTable = styled(Table)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
    .ant-table-wrapper {
        width: 100%;
    }
`;
