import React, { useEffect, useState } from 'react';
import { App } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { AntispamMapper } from 'features/communities/components/Antispam/AntispamMapper';

import {
    useReadAntispamQuery,
    useUpdateAntispamMutation,
} from '../../../services';
import { AntispamForm } from '../views/AntispamForm';
import { IFormikInitialValues } from './AntispamContainerCreate';

export const AntispamContainerEdit: React.FC = () => {
    const { notification } = App.useApp();
    const params = useParams();
    const { data } = useReadAntispamQuery(params.id);
    const [initialValues, setInitialValues] =
        useState<IFormikInitialValues | null>(null);

    const navigate = useNavigate();

    const [updateOnboarding, { isSuccess, error }] =
        useUpdateAntispamMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Сценарий обновлен успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any).data.message || 'Что-то пошло не так',
                placement: 'top',
            });
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            setInitialValues(AntispamMapper.convertDto(data));
        }
    }, [data]);

    const onSave = async (values: IFormikInitialValues) => {
        await updateOnboarding({
            uuid: data.uuid,
            body: AntispamMapper.createDto(values),
        });
    };

    if (!params.id && !data) {
        return <></>;
    }

    return (
        <>
            {initialValues && (
                <AntispamForm initialValues={initialValues} onSave={onSave} />
            )}
        </>
    );
};
