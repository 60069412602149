import React, { FC } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';

import { IFinanceResponse } from '../../../services/finance.interface';

interface IProps {
    source: Array<IFinanceResponse>;
    loading: boolean;
    total: number;
    setFilters: any;
}

export const FinancesTable: FC<IProps> = props => {
    const { source, loading } = props;

    const columns = [
        {
            title: 'Дата и время',
            dataIndex: 'date',
            key: 'date',
            render: text => dayjs(text).format('DD.MM.YYYY, HH:mm'),
        },
        {
            title: 'Сумма',
            dataIndex: 'amount',
            key: 'amount',
            render: text => `${text} ₽`,
        },
        {
            title: 'Карта',
            dataIndex: 'card',
            key: 'card',
            render: text => `* ${text}`,
        },
        {
            title: 'Статус',
            dataIndex: 'date',
            key: 'id',
            render: () => 'Выполнено',
        },
    ];

    const pagination = {
        total: props.total,
        showTotal: () => `Заявок: ${props.total}`,
        onChange: (page, pageSize) => {
            props.setFilters({
                offset: (page - 1) * pageSize,
                limit: pageSize,
            });
        },
        showQuickJumper: false,
        showSizeChanger: false,
    };

    return (
        <Table
            loading={loading}
            columns={columns}
            rowKey={record => record.paymentId}
            dataSource={source || []}
            pagination={pagination}
        />
    );
};
