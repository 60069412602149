import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Button, Empty, Table, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import {
    IChatMember,
    IChatMemberQueryParams,
} from 'features/communities/interfaces';
import { TableFilter } from 'shared/ui/TableFilter';
import { PaginatedResponse } from 'features/communities/interfaces/PaginatedResponse';
import { ConfirmationDialog } from 'shared/ui/ConfirmationDialog';
import getEnvConfig from 'utils/config/env';

import { ChatsFilter } from '../components/ChatsFilter';
import { useTheme } from '../../../../../hooks/useTheme';

type TProps = {
    readonly source: PaginatedResponse<IChatMember>;
    readonly loading?: boolean;
    readonly filters: IChatMemberQueryParams;
    readonly onFiltersUpdate: (filters: IChatMemberQueryParams) => void;
    readonly onBulkRemove: (args) => void;
};

export const WhiteListedMembersTable: React.FC<TProps> = props => {
    const token = useTheme();

    const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

    const navigate = useNavigate();

    return (
        <StyledWrapper>
            <StyledActions>
                {!!selectedRows.length && (
                    <Fragment>
                        <ConfirmationDialog
                            activator={
                                <Button type='primary'>Удалить из БС</Button>
                            }
                            onConfirm={() => {
                                props.onBulkRemove(
                                    props.source.items.filter(member =>
                                        selectedRows
                                            .map(Number)
                                            .some(n => n === member.telegram_id)
                                    )
                                );
                            }}
                            title='Удалить?'
                            description={`Вы уверены что хотите удалить пользователей из черного списка?`}
                        />

                        <div>Выбрано пользователей: {selectedRows.length}</div>
                    </Fragment>
                )}
                {!selectedRows.length && (
                    <Button
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() =>
                            navigate(
                                `${
                                    getEnvConfig().PUBLIC_PATH
                                }/communities/members/add-to-whitelist`
                            )
                        }
                    >
                        Добавить пользователя в БС
                    </Button>
                )}
            </StyledActions>
            <StyledTable
                loading={props.loading}
                rowSelection={{
                    selectedRowKeys: selectedRows,
                    onChange: setSelectedRows,
                }}
                locale={{ emptyText: <Empty description='Нет участников' /> }}
                dataSource={props.source.items}
                rowKey={member => member.telegram_id}
                pagination={{
                    total: props.source.total,
                    showSizeChanger: true,
                    showTotal: () => `Пользователей: ${props.source.total}`,
                    onChange: (page, pageSize) => {
                        props.onFiltersUpdate({
                            offset: (page - 1) * pageSize,
                            limit: pageSize,
                        });
                    },
                    onShowSizeChange: (current, size) => {
                        props.onFiltersUpdate({
                            offset: (current - 1) * size,
                            limit: size,
                        });
                    },
                }}
                scroll={{ x: 600 }}
            >
                <Table.Column
                    title={
                        <TableFilter<string>
                            title='Имя'
                            onChange={name => props.onFiltersUpdate({ name })}
                            value={props.filters.name}
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    fixed='left'
                    width={200}
                    render={member => (
                        <Typography.Link
                            style={{ color: token.colorPrimary }}
                            onClick={() => {
                                navigate(
                                    `${
                                        getEnvConfig().PUBLIC_PATH
                                    }/communities/members/view-member-whitelist/${
                                        member.telegram_id
                                    }`
                                );
                            }}
                        >
                            {member.name}
                        </Typography.Link>
                    )}
                />
                <Table.Column
                    title={
                        <TableFilter<string>
                            onChange={user_name =>
                                props.onFiltersUpdate({ user_name })
                            }
                            value={props.filters.user_name}
                            title='Юзернейм'
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    width={200}
                    dataIndex={'user_name'}
                />
                <Table.Column
                    title={
                        <ChatsFilter
                            title={'Чаты в которых в БС'}
                            filters={props.filters}
                            onChange={props.onFiltersUpdate}
                        />
                    }
                    render={communities =>
                        communities.map(c => c.title).join(', ')
                    }
                    dataIndex={'communities'}
                    width={400}
                />

                <Table.Column
                    title='Действие'
                    render={chat => (
                        <Typography.Link
                            style={{ color: token.colorPrimary }}
                            onClick={() => {
                                props.onBulkRemove([chat]);
                            }}
                        >
                            Удалить из БС
                        </Typography.Link>
                    )}
                    width={140}
                />
            </StyledTable>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
`;

const StyledTable = styled(Table<IChatMember>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;

const StyledActions = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
    top: -56px;
    z-index: 10;
`;
