import React, { useEffect, useState } from 'react';
import { App, Card, List, Typography, Button } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { getSrc } from '../../../../../../../utils/common/getSrc';
import postPlaceholder from '../../../../../../../static/images/post_placeholder.png';
import { useReadPublicationPublicQuery } from '../../../../../services/publications';
import { iSource, PayModal } from '../../pay/containers/PayModal';
import { RegisterModal } from '../../pay/containers/RegisterModal';

export const PostsPublicContainer = () => {
    const { id } = useParams();
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [openPay, setOpenPay] = useState<boolean>(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [postData, setPostData] = useState<iSource>({
        title: '',
        price: 0,
        uuid: '',
    });

    const { data, isFetching, isError, isSuccess } =
        useReadPublicationPublicQuery(id);

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Произошла ошибка при получении постов автора',
            });
        }
    }, [isError]);

    const handleReadPost = post => {
        const isAuthenticated = !!localStorage.getItem('accessToken');
        sessionStorage.setItem('guestPostId', post.uuid);

        if (post.price > 0) {
            setPostData(post);
            return setOpenPay(true);
        }

        if (!isAuthenticated) {
            setPostData(post);
            return setOpenRegister(true);
        }

        navigate(`/courses/member/post/${post.uuid}`);
        sessionStorage.removeItem('guestPostId');
    };

    return (
        <>
            <PayModal
                open={openPay}
                setOpen={setOpenPay}
                source={postData}
                type={'post'}
            />
            <RegisterModal
                source={postData || []}
                setOpen={setOpenRegister}
                open={openRegister}
                type={'post'}
            />

            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                }}
                loading={isFetching}
                dataSource={data?.slice().sort((a, b) => b.id - a.id)}
                renderItem={(post, index) => (
                    <List.Item>
                        <StyledCard
                            loading={isFetching}
                            key={index}
                            hoverable
                            cover={
                                post.price > 0 ? (
                                    <StyledCoverHidden
                                        src={
                                            !post.background_image
                                                ? postPlaceholder
                                                : getSrc(
                                                      `/storage/${post.background_image}`
                                                  )
                                        }
                                    />
                                ) : (
                                    <StyledCover
                                        src={
                                            !post.background_image
                                                ? postPlaceholder
                                                : getSrc(
                                                      `/storage/${post.background_image}`
                                                  )
                                        }
                                        alt='Publication preview'
                                    />
                                )
                            }
                        >
                            <StyledTitle level={5}>{post.title}</StyledTitle>
                            {!post.description ? (
                                <StyledDescription />
                            ) : (
                                <StyledDescription
                                    ellipsis={{
                                        rows: 2,
                                    }}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: post?.description,
                                        }}
                                    ></span>
                                </StyledDescription>
                            )}

                            <StyledActions>
                                <StyledButton
                                    onClick={() => handleReadPost(post)}
                                >
                                    {post.price > 0
                                        ? `Купить доступ за ${post.price} ₽`
                                        : 'Читать'}
                                </StyledButton>
                            </StyledActions>
                        </StyledCard>
                    </List.Item>
                )}
                pagination={{
                    showTitle: true,
                    defaultPageSize: 9,
                    showTotal: total => `Постов: ${total}`,
                }}
            />
        </>
    );
};

const StyledButton = styled(Button)`
    width: 100%;
`;

const StyledCard = styled(Card)`
    //flex: 0 0 33.333333%;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 1rem 0;
`;

const StyledDescription = styled(Typography.Paragraph)`
    margin: 1rem 0;
    min-height: 44px;
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
`;

const StyledCover = styled.img`
    object-fit: cover;
    width: 150px;
    height: 250px;
`;

const StyledCoverHidden = styled.img`
    filter: blur(0.25rem);
    object-fit: cover;
    width: 150px;
    height: 250px;
`;
