import React, { useState } from 'react';
import { Skeleton } from 'antd';

import { useReadPublicationFavouriteQuery } from '../../../services/publications';
import { PostList } from '../view/PostList';
import { WebinarList } from '../view/WebinarList';
import { useReadWebinarFavouriteQuery } from '../../../services/webinars';

export const FavoritesContainer = () => {
    const [postsFilters, setPostsFilters] = useState({
        limit: 3,
        offset: 0,
    });

    const [webinarsFilters, setWebinarsFilters] = useState({
        limit: 3,
        offset: 0,
    });

    const {
        data: posts,
        isFetching: isFetchingPosts,
        isError: isErrorPosts,
        isSuccess: isSuccessPosts,
    } = useReadPublicationFavouriteQuery(postsFilters);

    const {
        data: webinars,
        isSuccess: isSuccessWebinars,
        isFetching: isFetchingWebinars,
        isError: isErrorWebinars,
    } = useReadWebinarFavouriteQuery(webinarsFilters);

    const handleFiltersUpdate = (update, type) => {
        if (update && type === 'post') {
            return setPostsFilters({
                ...postsFilters,
                ...update,
            });
        }
        if (update && type === 'webinar') {
            return setWebinarsFilters({
                ...webinarsFilters,
                ...update,
            });
        }
    };

    return (
        <>
            {posts && isSuccessPosts ? (
                <PostList
                    data={posts}
                    title={'Посты'}
                    isFetching={isFetchingPosts}
                    isError={isErrorPosts}
                    onFiltersUpdate={handleFiltersUpdate}
                    filters={postsFilters}
                />
            ) : (
                <Skeleton />
            )}

            {webinars && isSuccessWebinars ? (
                <WebinarList
                    data={webinars}
                    title={'Вебинары'}
                    onFiltersUpdate={handleFiltersUpdate}
                    filters={webinarsFilters}
                    isFetching={isFetchingWebinars}
                    isError={isErrorWebinars}
                />
            ) : (
                <Skeleton />
            )}
        </>
    );
};
