import { IFormikInitialValues } from 'features/communities/components/Onboarding/containers/OnboardingContainerCreate';
import {
    ICreateOnboardingRequest,
    IOnboarding,
} from 'features/communities/interfaces/onboarding.interface';
import { getSrc } from 'utils/common/getSrc';

export enum OnboardingSwitchEnum {
    greetingsSwitch = 'greetingsSwitch',
    deleteGreetingsSwitch = 'deleteGreetingsSwitch',
    restrictMessageSendingSwitch = 'restrictMessageSendingSwitch',
    botJoinLimitSwitch = 'botJoinLimitSwitch',
    joinLimitSwitch = 'joinLimitSwitch',
    rtlNameJoinLimitSwitch = 'rtlNameJoinLimitSwitch',
    inviteBotLimitSwitch = 'inviteBotLimitSwitch',
    chatJoinSwitch = 'chatJoinSwitch',
}

export class OnboardingMapper {
    private static convertSwitchesMap = new Map<
        OnboardingSwitchEnum,
        (value) => any
    >([
        [
            OnboardingSwitchEnum.greetingsSwitch,
            value => {
                return {
                    ...(value?.content && {
                        greetings: {
                            content: value.content,
                        },
                    }),
                };
            },
        ],
        [
            OnboardingSwitchEnum.deleteGreetingsSwitch,
            value => {
                return {
                    ...(value?.duration && {
                        deleteGreetings: {
                            duration: value.duration,
                        },
                    }),
                };
            },
        ],
        [
            OnboardingSwitchEnum.restrictMessageSendingSwitch,
            value => {
                return {
                    ...(value?.duration && {
                        restrictMessageSending: {
                            duration: value.duration,
                        },
                    }),
                };
            },
        ],
        [
            OnboardingSwitchEnum.botJoinLimitSwitch,
            value => {
                return {
                    ...(value?.action && {
                        botJoinLimitation: {
                            action: value.action,
                        },
                    }),
                };
            },
        ],
        [
            OnboardingSwitchEnum.joinLimitSwitch,
            value => {
                return {
                    ...((value.count || value.duration || value.action) && {
                        joinLimitation: {
                            ...(value.count && {
                                count: value.count,
                            }),
                            ...(value.duration && {
                                duration: value.duration,
                            }),
                            ...(value.action && {
                                action: value.action,
                            }),
                        },
                    }),
                };
            },
        ],
        [
            OnboardingSwitchEnum.rtlNameJoinLimitSwitch,
            value => {
                return {
                    ...(value.action && {
                        rtlNameJoinLimitation: {
                            action: value.action,
                        },
                    }),
                };
            },
        ],
        [
            OnboardingSwitchEnum.inviteBotLimitSwitch,
            value => {
                return {
                    ...(value.action && {
                        inviteBotLimitation: {
                            action: value.action,
                        },
                    }),
                };
            },
        ],
        [
            OnboardingSwitchEnum.chatJoinSwitch,
            value => {
                return {
                    ...(value.actionType && {
                        chatJoinAction: {
                            type: value.actionType,
                            ...(value.actionType === 'question' && {
                                question: {
                                    ...(value.content && {
                                        content: value.content,
                                    }),
                                    ...(value.method && {
                                        method: value.method,
                                    }),
                                    ...(value.timeout && {
                                        timeout: value.timeout,
                                    }),
                                },
                            }),
                        },
                    }),
                };
            },
        ],
    ]);
    public static createDto(
        values: IFormikInitialValues
    ): ICreateOnboardingRequest {
        const arraySwitches = Object.entries(values.switches) as [
            OnboardingSwitchEnum,
            any
        ][];
        const arrayCheckedSwitches = arraySwitches.filter(([_, value]) => {
            return value.isChecked;
        });
        const requestObject: ICreateOnboardingRequest = {
            title: values.title,
            rules: {},
            communities_ids: values.selectedChatIds,
        };
        if (
            values.switches[OnboardingSwitchEnum.greetingsSwitch].isChecked &&
            Boolean(
                values.switches[OnboardingSwitchEnum.greetingsSwitch]
                    .greetingImage
            )
        ) {
            requestObject.greeting_image =
                values.switches[
                    OnboardingSwitchEnum.greetingsSwitch
                ].greetingImage;
        }
        if (
            values.switches[OnboardingSwitchEnum.chatJoinSwitch].isChecked &&
            Boolean(
                values.switches[OnboardingSwitchEnum.chatJoinSwitch]
                    .questionImage
            )
        ) {
            requestObject.question_image =
                values.switches[
                    OnboardingSwitchEnum.chatJoinSwitch
                ].questionImage;
        }
        if (arraySwitches.length) {
            arrayCheckedSwitches.forEach(([key, value]) => {
                if (this.convertSwitchesMap.get(key)) {
                    Object.assign(
                        requestObject.rules,
                        this.convertSwitchesMap.get(key)(value)
                    );
                }
            });
        }
        return requestObject;
    }
    public static convertDto(data: IOnboarding): IFormikInitialValues {
        const rules = JSON.parse(data.rules as string);
        return {
            ...(data.title && { title: data.title }),
            switches: {
                [OnboardingSwitchEnum.greetingsSwitch]: {
                    isChecked:
                        Boolean(data?.greeting_image) ||
                        Boolean(rules?.greetings?.content),
                    ...(data?.greeting_image && {
                        greetingImage: getSrc(data.greeting_image as string),
                    }),
                    ...(rules?.greetings?.content && {
                        content: rules?.greetings?.content,
                    }),
                },
                [OnboardingSwitchEnum.deleteGreetingsSwitch]: {
                    isChecked: Boolean(rules?.deleteGreetings),
                    ...(rules?.deleteGreetings && {
                        duration: rules?.deleteGreetings.duration,
                    }),
                },
                [OnboardingSwitchEnum.restrictMessageSendingSwitch]: {
                    isChecked: Boolean(rules?.restrictMessageSending?.duration),
                    ...(rules?.restrictMessageSending?.duration && {
                        duration: rules?.restrictMessageSending?.duration,
                    }),
                },
                [OnboardingSwitchEnum.botJoinLimitSwitch]: {
                    isChecked: Boolean(rules?.botJoinLimitation?.action),
                    ...(rules?.botJoinLimitation?.action && {
                        action: rules?.botJoinLimitation?.action,
                    }),
                },
                [OnboardingSwitchEnum.joinLimitSwitch]: {
                    isChecked:
                        Boolean(rules?.joinLimitation?.count) ||
                        Boolean(rules?.joinLimitation?.duration) ||
                        Boolean(rules?.joinLimitation?.action),
                    ...(rules?.joinLimitation?.count && {
                        count: rules?.joinLimitation?.count,
                    }),
                    ...(rules?.joinLimitation?.duration && {
                        duration: rules?.joinLimitation?.duration,
                    }),
                    ...(rules?.joinLimitation?.action && {
                        action: rules?.joinLimitation?.action,
                    }),
                },
                [OnboardingSwitchEnum.rtlNameJoinLimitSwitch]: {
                    isChecked: Boolean(rules?.rtlNameJoinLimitation?.action),
                    ...(rules?.rtlNameJoinLimitation?.action && {
                        action: rules?.rtlNameJoinLimitation?.action,
                    }),
                },
                [OnboardingSwitchEnum.inviteBotLimitSwitch]: {
                    isChecked: Boolean(rules?.inviteBotLimitation?.action),
                    ...(rules?.inviteBotLimitation?.action && {
                        action: rules?.inviteBotLimitation?.action,
                    }),
                },
                [OnboardingSwitchEnum.chatJoinSwitch]: {
                    isChecked: Boolean(rules?.chatJoinAction?.type),
                    ...(rules?.chatJoinAction?.type && {
                        actionType: rules?.chatJoinAction?.type,
                    }),
                    ...(rules?.chatJoinAction?.question?.content && {
                        content: rules?.chatJoinAction?.question?.content,
                    }),
                    ...(rules?.chatJoinAction?.question?.method && {
                        method: rules?.chatJoinAction?.question?.method,
                    }),
                    ...(rules?.chatJoinAction?.question?.timeout && {
                        timeout: rules?.chatJoinAction?.question?.timeout,
                    }),
                    ...(data?.question_image && {
                        questionImage: getSrc(data.question_image as string),
                    }),
                },
            },
            selectedChatIds: data?.communities_ids || [],
        };
    }
}
