import React, { FC } from 'react';
import { Flex, Spin, Typography } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useTariffPayedQuery } from 'features/monetization/services/tariff.service';

interface IProps {
    tariff: any;
}

export const TariffThanks: FC<IProps> = props => {
    const location = useLocation();
    const params = useParams();
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.get('paymentId')) {
        return <></>;
    }
    const { data: tariffPayedInfo, isLoading } = useTariffPayedQuery({
        tariffHash: params.id,
        paymentHash: searchParams.get('paymentId'),
    });

    return (
        <Flex gap={24} vertical>
            <Typography.Title style={{ marginBottom: 0 }} level={1}>
                Спасибо!
            </Typography.Title>

            <Flex vertical>
                <Typography.Text>
                    Вы успешно оплатили тафриф{' '}
                    {isLoading ? (
                        <Spin />
                    ) : (
                        <Link to={props.tariff.tariff_page}>
                            «{props.tariff.title}»
                        </Link>
                    )}
                </Typography.Text>
                <Typography.Text>
                    Также вам на{' '}
                    {isLoading ? <Spin /> : tariffPayedInfo?.payer?.email}{' '}
                    выслана информация по покупке и инструкция по доступу в
                    группу.
                </Typography.Text>
            </Flex>
        </Flex>
    );
};
