import React from 'react';
import { Checkbox, Col, List, Row, Space, Typography } from 'antd';
import styled from 'styled-components';

import Secondary from '../../../../../shared/ui/SecondaryText';
import { getDeclension } from '../../../../../utils/common/getDeclension';

export const ScenariosCard = props => {
    const { source, selectedRows } = props;

    const handleScenarioTypeTranslate = type => {
        switch (type) {
            case 'moderation_rule':
                return 'Правила модерации';
            case 'onboarding_rule':
                return 'Онбординг';
            case 'antispam_rule':
                return 'Антиспам';
            case 'reputation_rule':
                return 'Репутация';
            case 'if_then_rule':
                return 'Если-То';
            default:
                return 'Правило';
        }
    };

    const handleRowSelect = id => {
        const isSelected = selectedRows.indexOf(id) !== -1;

        if (isSelected) {
            const filtered = selectedRows.filter(elem => elem !== id);
            props.handleRowsChange(filtered);
        } else {
            props.handleRowsChange([...selectedRows, id]);
        }
    };

    return (
        <List
            dataSource={source.items}
            rowKey={scenario => scenario.id}
            renderItem={scenario => (
                <StyledListItem
                    isSelected={selectedRows.includes(
                        scenario.id || scenario.uuid
                    )}
                >
                    <Space direction={'vertical'} style={{ width: '100%' }}>
                        <Row>
                            <Col span={3}>
                                <Checkbox
                                    checked={selectedRows.includes(
                                        scenario.id || scenario.uuid
                                    )}
                                    onChange={() => {
                                        handleRowSelect(
                                            scenario.id || scenario.uuid
                                        );
                                    }}
                                />
                            </Col>

                            <Col span={21}>
                                <Typography.Paragraph
                                    style={{ width: '100%' }}
                                    ellipsis={{
                                        rows: 2,
                                    }}
                                >
                                    <Typography.Link
                                        strong
                                        onClick={() => {
                                            props.onView(
                                                scenario.id || scenario.uuid,
                                                scenario.type
                                            );
                                        }}
                                    >
                                        {scenario.name || scenario.title}
                                    </Typography.Link>
                                </Typography.Paragraph>
                            </Col>

                            <Col
                                span={21}
                                offset={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Secondary>
                                    {handleScenarioTypeTranslate(scenario.type)}
                                </Secondary>
                                <Secondary>
                                    {scenario.communities?.length}
                                    {getDeclension(
                                        scenario.communities?.length,
                                        ' чат',
                                        ' чата',
                                        ' чатов'
                                    )}
                                </Secondary>
                            </Col>
                        </Row>
                    </Space>
                </StyledListItem>
            )}
        />
    );
};

const StyledListItem = styled(List.Item)<{ isSelected: boolean }>`
    background: ${props => (props.isSelected ? '#F4F0FF' : 'white')};
`;

const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
`;

const StyledLink = styled(Typography.Link)``;
