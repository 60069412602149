import React from 'react';
import styled from 'styled-components';
import { Card, Typography, Button, Skeleton, App, Space, Row, Col } from 'antd';
import { EditOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useLocation, useParams } from 'react-router-dom';

import { useToggle } from '../../../../../../hooks/useToggle';
import { AuthorEditContainer } from './AuthorEditContainer';
import { getSrc } from '../../../../../../utils/common/getSrc';
import { useTheme } from '../../../../../../hooks/useTheme';
import {
    useReadAuthorByIdQuery,
    useReadAuthorPublicByIdQuery,
} from '../../../../services/author';

export const AuthorInfoContainer = () => {
    const { boxShadow } = useTheme();
    const { id } = useParams();
    const { notification } = App.useApp();
    const location = useLocation();

    const { open, setOpen } = useToggle(false);
    const toggle = () => setOpen((x: boolean) => !x);

    const isOwner = location.pathname.includes('courses');

    const {
        data: author,
        isSuccess,
        isError,
        isFetching,
    } = isOwner
        ? useReadAuthorByIdQuery(Number(id))
        : useReadAuthorPublicByIdQuery(Number(id));

    const onShare = () => {
        navigator.clipboard.writeText(`${window.location.host}/author/${id}`);
        notification.success({
            message: 'Ссылка скопирована',
            duration: 1,
        });
    };

    return (
        <StyledContent>
            <Card type='inner' style={{ boxShadow }}>
                {isFetching || isError ? (
                    <Skeleton />
                ) : (
                    <Row
                        justify={!isOwner ? 'start' : 'space-between'}
                        gutter={[15, 15]}
                    >
                        <Col xs={24} sm={20} md={8} lg={4}>
                            <StyledImg
                                alt={'author'}
                                width={'100%'}
                                src={getSrc(`/storage/${author?.photo}`)}
                            />
                        </Col>

                        <Col xs={24} sm={20} md={14} lg={14}>
                            <StyledTitle level={2}>{author?.name}</StyledTitle>
                            <Typography.Text
                                style={{ display: 'block', minWidth: '100%' }}
                            >
                                {author?.about}
                            </Typography.Text>
                        </Col>

                        {isOwner && (
                            <StyledActions xs={24} sm={24} md={24} lg={4}>
                                <Space
                                    align={'center'}
                                    direction={'horizontal'}
                                    wrap
                                >
                                    <Button onClick={() => onShare()}>
                                        <ShareAltOutlined />
                                    </Button>
                                    <Button
                                        disabled={
                                            !isSuccess || isError || isFetching
                                        }
                                        onClick={toggle}
                                    >
                                        <EditOutlined />
                                    </Button>
                                </Space>
                            </StyledActions>
                        )}
                    </Row>
                )}
            </Card>
            {isOwner && <AuthorEditContainer open={open} setOpen={toggle} />}
        </StyledContent>
    );
};

const StyledContent = styled.div`
    margin-bottom: 2rem;
`;

const StyledImg = styled.img`
    object-fit: cover;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 0;
    margin-block-end: 0;
`;

const StyledActions = styled(Col)`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
`;
