import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';

import { StatsWidget } from '../components/StatsWidget';
import { Card } from '../components/Card';
import { useReadRecommendationQuery } from '../services';
import { PublicationSelector } from '../components/PublicationSelector';
import { chartBarOptions, chartDonutOptions } from '../services/options';

export const RecommendationPage = () => {
    const [likeMaterialCategories, setLikeMaterialCategories] = useState([]);
    const [likeMaterialSeries, setLikeMaterialSeries] = useState([]);
    const [enoughMaterialCategories, setEnoughMaterialCategories] = useState(
        []
    );
    const [enoughMaterialSeries, setEnoughMaterialSeries] = useState([]);
    const [addSeries, setAddSeries] = useState([]);
    const [addCategories, setAddCategories] = useState([]);
    const [removeSeries, setRemoveSeries] = useState([]);
    const [removeCategories, setRemoveCategories] = useState([]);

    const [filters, setFilters] = useState({});

    const { data, isFetching } = useReadRecommendationQuery(filters);

    useEffect(() => {
        if (data) {
            setLikeMaterialCategories(Object.keys(data.likeMaterial));
            setLikeMaterialSeries([{ data: Object.values(data.likeMaterial) }]);
            setEnoughMaterialCategories(Object.keys(data.enoughMaterial));
            setEnoughMaterialSeries([
                { data: Object.values(data.enoughMaterial) },
            ]);

            setAddCategories(Object.keys(data.whatAdd));
            setAddSeries(Object.values(data.whatAdd));

            setRemoveCategories(Object.keys(data.whatRemove));
            setRemoveSeries(Object.values(data.whatRemove));
        }
    }, [data]);

    return (
        <>
            <Row
                wrap
                gutter={[10, 30]}
                align={'middle'}
                justify='space-between'
                style={{ marginBottom: '1.5rem' }}
            >
                <Col sm={12} md={12}>
                    <Typography.Title level={1}>Рекомендации</Typography.Title>
                </Col>

                <Col
                    sm={12}
                    md={12}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    <PublicationSelector
                        filters={filters}
                        setFilters={setFilters}
                    />
                </Col>
            </Row>

            <Row wrap gutter={[10, 30]} style={{ marginBottom: '1.5rem' }}>
                <Col sm={6} md={6}>
                    <StatsWidget
                        isLoading={isFetching}
                        title={'Всего читателей'}
                        value={data?.readers}
                    />
                </Col>

                <Col sm={6} md={6}>
                    <StatsWidget
                        isLoading={isFetching}
                        title={'Активных читателей'}
                        value={!data ? '' : data?.activeReaders + ' %'}
                    />
                </Col>
            </Row>

            <Row wrap gutter={[10, 30]}>
                <Col sm={12} md={12}>
                    <Card
                        title={'Оценка качества'}
                        data={likeMaterialSeries}
                        categories={likeMaterialCategories}
                        options={chartBarOptions}
                        type={'bar'}
                    />
                </Col>

                <Col sm={12} md={12}>
                    <Card
                        title={'Удовлетворенность объемом'}
                        data={enoughMaterialSeries}
                        categories={enoughMaterialCategories}
                        options={chartBarOptions}
                        type={'bar'}
                    />
                </Col>

                <Col sm={12} md={12}>
                    <Card
                        title={
                            'Рекомендации по добавлению  дополнительных/новых сведений'
                        }
                        data={addSeries}
                        labels={addCategories}
                        options={chartDonutOptions}
                        type={'donut'}
                    />
                </Col>
                <Col sm={12} md={12}>
                    <Card
                        title={'Советы по уменьшению объемов контента'}
                        data={removeSeries}
                        labels={removeCategories}
                        options={chartDonutOptions}
                        type={'donut'}
                    />
                </Col>
            </Row>
        </>
    );
};
