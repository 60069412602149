import React, { FC } from 'react';
import { Button, Col, Row, Space, Typography } from 'antd';
import styled from 'styled-components';

import { getSrc } from '../../../../../utils/common/getSrc';
import { useWindowSize } from '../../../../../hooks/useWindowSize';

interface IProps {
    readonly tariff: {
        main_image: undefined | string;
        title: string;
        main_description: string;
        price: number;
        test_period_is_active: boolean;
    };
    readonly onPay: () => void;
    readonly onTrial: () => void;
}

export const TariffPreview: FC<IProps> = props => {
    const { tariff, onPay, onTrial } = props;
    const { size } = useWindowSize();

    return (
        <Card>
            {size === 'small' ? (
                <Row>
                    <Col xs={24}>
                        {!tariff.main_image ? null : (
                            <StyledImage
                                style={{ height: 176, marginBottom: '1rem' }}
                                src={getSrc(tariff?.main_image)}
                                alt={'Тариф'}
                            />
                        )}
                    </Col>

                    <Col xs={24}>
                        <StyledDescription>
                            <StyledTitle level={1}>{tariff?.title}</StyledTitle>
                            <Typography.Text>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: tariff?.main_description,
                                    }}
                                ></span>
                            </Typography.Text>

                            <StyledActions>
                                <Space wrap style={{ marginTop: '1rem' }}>
                                    <Button type={'primary'} onClick={onPay}>
                                        {tariff.price === 0
                                            ? 'Бесплатно'
                                            : tariff.price + ' ₽'}
                                    </Button>
                                    {tariff?.test_period_is_active && (
                                        <Button onClick={onTrial}>
                                            Пробный доступ
                                        </Button>
                                    )}
                                </Space>
                            </StyledActions>
                        </StyledDescription>
                    </Col>
                </Row>
            ) : (
                <>
                    {!tariff.main_image ? null : (
                        <StyledImage
                            style={{ height: 176 }}
                            src={getSrc(tariff?.main_image)}
                            alt={'Тариф'}
                        />
                    )}

                    <StyledDescription>
                        <StyledTitle level={1}>{tariff?.title}</StyledTitle>
                        <Typography.Text>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: tariff?.main_description,
                                }}
                            ></span>
                        </Typography.Text>

                        <StyledActions>
                            <Space wrap>
                                <Button type={'primary'} onClick={onPay}>
                                    {tariff.price === 0
                                        ? 'Бесплатно'
                                        : tariff.price + ' ₽'}
                                </Button>
                                {tariff?.test_period_is_active && (
                                    <Button onClick={onTrial}>
                                        Пробный доступ
                                    </Button>
                                )}
                            </Space>
                        </StyledActions>
                    </StyledDescription>
                </>
            )}
        </Card>
    );
};

const Card = styled.div`
    width: 100%;
    display: flex;
    color: rgba(37, 33, 41, 0.88);
    padding: 16px 24px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 0;
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const StyledImage = styled.img`
    margin-right: 1rem;
`;
