import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { App, Skeleton } from 'antd';

import {
    useCreatePublicationVisitedMutation,
    useReadPublicationByIdQuery,
} from '../../../../services/publications';
import { ButtonBack } from './components/ButtonBack';
import { PartsCard } from '../../view/PartsCard';
import { PostMainInfo } from '../../view/PostMainInfo';

export const PostContainer = () => {
    const { notification } = App.useApp();
    const { id } = useParams();

    const {
        data: post,
        isFetching,
        isError,
        isSuccess,
    } = useReadPublicationByIdQuery(id);

    const [createVisited] = useCreatePublicationVisitedMutation();

    useEffect(() => {
        if (id) {
            createVisited(id);
        }
    }, []);

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Произошла ошибка при получении поста',
            });
        }
    }, [isError]);

    return !isSuccess ? (
        <Skeleton />
    ) : (
        <>
            <ButtonBack />
            {post && <PostMainInfo post={post} id={+id} />}
            {post?.parts?.length > 0 && <PartsCard parts={post.parts} />}
        </>
    );
};
