import React, { FC, Fragment, useEffect, useState } from 'react';
import { App } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import {
    useReadReputationQuery,
    useUpdateReputationMutation,
} from 'features/communities/services';
import { IFormikInitialValues } from 'features/communities/components/Reputation/containers/ReputationContainerCreate';

import { ReputationForm } from '../views/ReputationForm';
import { createFormData } from '../../../../../utils/common/createFormData';
import { IReputation } from '../../../interfaces/reputation.interface';
import { ReputationMapper } from '../ReputationMapper';

export const ReputationContainerEdit: FC = () => {
    const { notification } = App.useApp();
    const params = useParams();
    const { data } = useReadReputationQuery(params.id);
    const [initialValues, setInitialValues] =
        useState<Partial<IFormikInitialValues> | null>(null);

    const navigate = useNavigate();

    const [updateReputation, { isSuccess, error }] =
        useUpdateReputationMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Сценарий обновлен успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any)?.data?.message || 'Что-то не так',
                placement: 'top',
            });
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            const response = ReputationMapper.convertDto(data);
            setInitialValues(response);
        }
    }, [data]);

    const onSave = async (values: IFormikInitialValues) => {
        const requestObject: Partial<IReputation> =
            ReputationMapper.createDto(values);
        requestObject.uuid = params.id;
        if (!(requestObject.restrict_accumulate_rate_image instanceof File)) {
            delete requestObject.restrict_accumulate_rate_image;
        }
        if (!(requestObject.notify_about_rate_change_image instanceof File)) {
            delete requestObject.notify_about_rate_change_image;
        }
        if (!(requestObject.show_rating_tables_image instanceof File)) {
            delete requestObject.show_rating_tables_image;
        }
        await updateReputation({
            uuid: params.id,
            body: createFormData(requestObject),
        });
    };

    if (!params.id && !data) {
        return <></>;
    }

    return (
        <Fragment>
            {initialValues && (
                <ReputationForm initialValues={initialValues} onSave={onSave} />
            )}
        </Fragment>
    );
};
