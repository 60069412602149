import React, { useEffect } from 'react';
import { App, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useDeleteWebinarMutation } from '../../../../../services/webinars';
import getEnvConfig from '../../../../../../../utils/config/env';

export const DeleteButton = props => {
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [deleteWebinar, { isError, isLoading, isSuccess: isSuccessDelete }] =
        useDeleteWebinarMutation();

    const handleDelete = () => {
        if (isError) {
            return notification.error({
                duration: 1,
                message: 'Ошибка при удалении вебинара',
            });
        }

        deleteWebinar(props.id).then(() =>
            navigate(`${getEnvConfig().COURSES_PATH}/author/${props.authorId}`)
        );
    };

    useEffect(() => {
        if (isSuccessDelete) {
            notification.success({
                message: 'Вебинар успешно удалён',
                placement: 'top',
            });
        }
    }, [isSuccessDelete]);

    return (
        <Button
            disabled={isLoading}
            type={'text'}
            danger
            onClick={() => handleDelete()}
        >
            Удалить
        </Button>
    );
};
