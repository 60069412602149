export interface ICreateReputationRequest {
    title: string;
    keyword_rate_up: string[];
    keyword_rate_down: string[];
    community_ids: number[];
    who_can_rate?: WhoCanRateEnum;
    restrict_rate_member_period?: number;
    delay_start_rules_seconds?: string;
    delay_start_rules_total_messages?: number;
    show_rating_tables?: boolean;
    show_rating_tables_period?: RatePeriodEnum;
    show_rating_tables_time?: string;
    show_rating_tables_number_of_users?: number;
    show_rating_tables_image?: File;
    show_rating_tables_message?: string;
    notify_about_rate_change?: boolean;
    notify_about_rate_change_points?: number;
    notify_about_rate_change_image?: File;
    notify_about_rate_change_message?: string;
    restrict_accumulate_rate?: boolean;
    restrict_accumulate_rate_period?: RatePeriodEnum;
    restrict_accumulate_rate_image?: File;
    restrict_accumulate_rate_message?: string;
}

export enum RatePeriodEnum {
    first_day_of_week = 'first_day_of_week',
    first_day_of_month = 'first_day_of_month',
    first_day_of_year = 'first_day_of_year',
}

export enum WhoCanRateEnum {
    all = 'all',
    owner = 'owner',
    owner_and_admin = 'owner_and_admin',
}

export interface IReputation {
    title: string;
    who_can_rate: WhoCanRateEnum;
    restrict_rate_member_period: number;
    delay_start_rules_seconds: string;
    delay_start_rules_total_messages: number;
    show_rating_tables: boolean;
    show_rating_tables_period: RatePeriodEnum;
    show_rating_tables_time: string;
    show_rating_tables_number_of_users: number;
    show_rating_tables_image: string | File;
    show_rating_tables_message: string;
    notify_about_rate_change: boolean;
    notify_about_rate_change_points: number;
    notify_about_rate_change_image: string | File;
    notify_about_rate_change_message: string;
    restrict_accumulate_rate: boolean;
    restrict_accumulate_rate_time: string;
    restrict_accumulate_rate_period: RatePeriodEnum;
    restrict_accumulate_rate_image: string | File;
    restrict_accumulate_rate_message: string;
    communities: number[];
    keywords_up: string[];
    keywords_down: string[];
    uuid: string;
}

export interface IUpdateReputationResponse {
    title: string;
    who_can_rate: WhoCanRateEnum;
    restrict_rate_member_period: number;
    delay_start_rules_seconds: string;
    delay_start_rules_total_messages: number;
    show_rating_tables: boolean;
    show_rating_tables_period: RatePeriodEnum;
    show_rating_tables_time: string;
    show_rating_tables_number_of_users: number;
    show_rating_tables_image: string;
    show_rating_tables_message: string;
    notify_about_rate_change: boolean;
    notify_about_rate_change_points: number;
    notify_about_rate_change_image: string;
    notify_about_rate_change_message: string;
    restrict_accumulate_rate: boolean;
    restrict_accumulate_rate_time: string;
    restrict_accumulate_rate_period: RatePeriodEnum;
    restrict_accumulate_rate_image: string;
    restrict_accumulate_rate_message: string;
    communities: number[];
    keywords_up: string[];
    keywords_down: string[];
    uuid: string;
}

export interface IUpdateReputationRequest {
    uuid: string;
    body: FormData;
}
