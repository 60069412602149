import React, { ReactNode } from 'react';
import { Button, Card, Col, Flex, Row, Space, Tooltip, Typography } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { getSrc } from '../../../../utils/common/getSrc';
import { CopyWithTooltip } from '../../../../shared/ui/CopyWithTooltip/CopyWithTooltip';
import { ConfirmationDialog } from '../../../../shared/ui/ConfirmationDialog';
import { useTheme } from '../../../../hooks/useTheme';
import { IShop } from '../../interfaces/shop.interface';
import { ProductImage } from '../../components/Product/ProductImage';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { useToggle } from '../../../../hooks/useToggle';
import { Copy } from '../../components/Copy';

type ShopCard = {
    readonly source: IShop;
    readonly loading: boolean;
    readonly toggle?: () => void;
    readonly deleteShop?: (id: number) => void;
    readonly actions?: ReactNode;
};
export const ShopCard: React.FC<ShopCard> = props => {
    const { source, loading, toggle, deleteShop } = props;
    const { size } = useWindowSize();

    const { boxShadow, colorPrimary } = useTheme();

    return (
        <Card type='inner' style={{ boxShadow, marginBottom: '1.5rem' }}>
            <Flex wrap={'wrap'} justify={'space-between'} gap={'1.5rem'}>
                <Col md={4} xs={24}>
                    <ProductImage
                        gutter={0}
                        src={getSrc(`/storage/${source?.photo}`)}
                    />
                </Col>

                <Col
                    md={19}
                    xs={24}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                >
                    <Flex
                        vertical
                        style={{
                            marginBottom: size === Size.small ? '1.5rem' : 0,
                        }}
                    >
                        <Typography.Title style={{ margin: 0 }} level={2}>
                            {source.name}
                        </Typography.Title>
                        <Typography.Text>{source.about}</Typography.Text>
                    </Flex>

                    <Flex
                        vertical={false}
                        justify={'space-between'}
                        align={'center'}
                        wrap={'wrap'}
                    >
                        <div
                            style={{
                                marginBottom:
                                    size === Size.small ? '1.5rem' : 0,
                            }}
                        >
                            <Flex align={'center'} gap={5}>
                                <Typography.Text
                                    style={{
                                        minWidth: 'fit-content',
                                    }}
                                    strong
                                >
                                    Общая ссылка:
                                </Typography.Text>
                                <Copy text={source.shop_link} />
                            </Flex>

                            <Flex vertical={false} align={'center'} gap={5}>
                                <Typography.Text
                                    style={{
                                        minWidth: 'fit-content',
                                    }}
                                    strong
                                >
                                    Ссылка на магазин:
                                </Typography.Text>
                                <Copy text={source.shop_inline} />
                            </Flex>
                        </div>

                        <StyledActions>
                            {props.actions ? (
                                props.actions
                            ) : (
                                <Space
                                    align={'center'}
                                    direction={'horizontal'}
                                    wrap
                                >
                                    <ConfirmationDialog
                                        activator={
                                            <StyledButton
                                                disabled={loading}
                                                danger
                                            >
                                                <DeleteOutlined />
                                            </StyledButton>
                                        }
                                        onConfirm={() => deleteShop(source.id)}
                                        title='Удалить магазин?'
                                        description={`Внимание! Восстановить магазин ${source.name} после удаления будет невозможно. `}
                                        withCheckbox
                                    />
                                    <StyledButton
                                        disabled={loading}
                                        onClick={toggle}
                                    >
                                        <EditOutlined />
                                    </StyledButton>
                                </Space>
                            )}
                        </StyledActions>
                    </Flex>
                </Col>
            </Flex>
        </Card>
    );
};

const StyledButton = styled(Button)`
    padding: 0 0.5rem;
`;

const StyledActions = styled(Col)`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
`;
