import React, { FC, Fragment } from 'react';
import { Radio, Space, Switch, Typography } from 'antd';
import styled from 'styled-components';

import { OnboardingSwitchEnum } from 'features/communities/components/Onboarding/OnboardingMapper';
import { useOrientations } from 'hooks/useOrientation/useOrientation';

interface IProps {
    formik: any;
}

export const InviteBotLimitSwitch: FC<IProps> = props => {
    const orientation = useOrientations();
    return (
        <Fragment>
            <StyledSpace align='start'>
                <StyledSwitch
                    checked={
                        props.formik.values.switches[
                            OnboardingSwitchEnum.inviteBotLimitSwitch
                        ].isChecked
                    }
                    onChange={value => {
                        props.formik.setFieldValue(
                            `switches.${OnboardingSwitchEnum.inviteBotLimitSwitch}.isChecked`,
                            value
                        );
                    }}
                />
                <Space direction='vertical' size='middle'>
                    <StyledTitle level={5}>
                        Ограничить добавление ботов участниками
                    </StyledTitle>
                    {props.formik.values.switches[
                        OnboardingSwitchEnum.inviteBotLimitSwitch
                    ].isChecked && (
                        <StyledWrap>
                            <div style={{ maxWidth: 460 }}>
                                <Typography.Text>
                                    Если бот добавлен участником, участника
                                    следует
                                </Typography.Text>
                            </div>
                            <Radio.Group
                                buttonStyle='solid'
                                value={props.formik.values.switches[
                                    OnboardingSwitchEnum.inviteBotLimitSwitch
                                ]?.action?.toString()}
                                onChange={event => {
                                    props.formik.setFieldValue(
                                        `switches.${OnboardingSwitchEnum.inviteBotLimitSwitch}.action`,
                                        +event.target.value
                                    );
                                }}
                            >
                                <StyledRadioWrap>
                                    {orientation === 'mobile' ? (
                                        <>
                                            <Radio value='4'>Забанить</Radio>
                                            <Radio value='10'>Выгнать</Radio>
                                        </>
                                    ) : (
                                        <>
                                            <Radio.Button value='4'>
                                                Забанить
                                            </Radio.Button>
                                            <Radio.Button value='10'>
                                                Выгнать
                                            </Radio.Button>
                                        </>
                                    )}
                                </StyledRadioWrap>
                            </Radio.Group>
                        </StyledWrap>
                    )}
                </Space>
            </StyledSpace>
        </Fragment>
    );
};

const StyledRadioWrap = styled.div`
    display: flex;

    @media (max-width: 576px) {
        gap: 16px;
        flex-direction: column;
    }
`;

const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;

    @media (max-width: 576px) {
        margin-left: -68px;
    }
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSpace = styled(Space)`
    margin-bottom: 8px;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;
