import React from 'react';
import styled from 'styled-components';
import { Empty, Space, Table, Tag } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import type { PaginationProps } from 'antd';

import {
    ICommunityTag,
    IEvent,
    IEventQueryParams,
} from 'features/communities/interfaces';
import { TableFilter } from 'shared/ui/TableFilter';
import { PaginatedResponse } from 'features/communities/interfaces/PaginatedResponse';
import { format } from 'lib/date';

import eventTypes from '../event-types';

type TProps = {
    readonly source: PaginatedResponse<IEvent>;
    readonly loading?: boolean;
    readonly filters: IEventQueryParams;
    readonly onFiltersUpdate: (filters: IEventQueryParams) => void;
    readonly setFilters: (filters: IEventQueryParams) => void;
};

export const EventsTable: React.FC<TProps> = props => {
    const { onFiltersUpdate, filters, source, loading } = props;

    const pagination: PaginationProps = {
        showLessItems: true,
        total: source.total,
        showSizeChanger: true,
        current: filters.offset / filters.limit + 1,
        pageSize: filters.limit,
        onChange: (page, pageSize) => {
            onFiltersUpdate({
                offset: (page - 1) * pageSize,
                limit: pageSize,
            });
        },
        onShowSizeChange: (current, size) => {
            onFiltersUpdate({
                offset: (current - 1) * size,
                limit: size,
            });
        },
    };

    return (
        <StyledTable
            scroll={{ x: 1000 }}
            loading={loading}
            locale={{ emptyText: <Empty description='Нет событий' /> }}
            rowKey={event => JSON.stringify(event)}
            dataSource={source.items}
            pagination={pagination}
        >
            <Table.Column
                fixed={'left'}
                width={150}
                title={
                    <TableFilter
                        icon={<FilterOutlined />}
                        title='Дата добавления'
                        setFilters={onFiltersUpdate}
                        filters={filters}
                    >
                        <TableFilter.Date />
                    </TableFilter>
                }
                render={(createdAt: number) =>
                    format(createdAt)('DD.MM.YY, HH:mm')
                }
                dataIndex={'done_date'}
            />
            <Table.Column
                width={'200px'}
                title={
                    <TableFilter<string[]>
                        icon={<FilterOutlined />}
                        onChange={(event: string[]) => {
                            onFiltersUpdate({ events: event });
                        }}
                        title='Событие'
                        value={filters.events}
                    >
                        <TableFilter.MultiCheckBox
                            options={eventTypes.map(e => ({
                                label: e,
                                value: e,
                            }))}
                        />
                    </TableFilter>
                }
                render={event => <React.Fragment>{event.event}</React.Fragment>}
            />
            <Table.Column
                title={
                    <TableFilter<string>
                        onChange={(username: string) => {
                            onFiltersUpdate({ user_name: username });
                        }}
                        value={filters.user_name}
                        title='Юзернейм'
                    >
                        <TableFilter.Text />
                    </TableFilter>
                }
                dataIndex={'telegram_user'}
            />
            <Table.Column
                title={
                    <TableFilter
                        title='Чаты в которых'
                        onChange={(community: string) => {
                            onFiltersUpdate({
                                community_title: community,
                            });
                        }}
                    >
                        <TableFilter.Text />
                    </TableFilter>
                }
                dataIndex={'community'}
            />
            <Table.Column
                width={'200px'}
                title={
                    <TableFilter<string[]>
                        icon={<FilterOutlined />}
                        onChange={(tag_names: string[]) => {
                            onFiltersUpdate({
                                offset: 0,
                                tag_names,
                            });
                        }}
                        title='Теги'
                        value={filters.tag_names}
                    >
                        <TableFilter.Tags />
                    </TableFilter>
                }
                dataIndex={'community_tags'}
                render={(tags: ICommunityTag[]) => (
                    <StyledSpace>
                        {tags.map((tag, index) => (
                            <Tag key={index}>{tag.name}</Tag>
                        ))}
                    </StyledSpace>
                )}
            />
        </StyledTable>
    );
};

const StyledTable = styled(Table<IEvent>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;

const StyledSpace = styled(Space)`
    width: 100%;
    flex-wrap: wrap;
`;
