import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Button, Empty, Space, Table, Tag } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import {
    IChatMember,
    IChatMemberQueryParams,
} from 'features/communities/interfaces';
import { TableFilter } from 'shared/ui/TableFilter';
import { ConfirmationDialog } from 'shared/ui/ConfirmationDialog';
import { format } from 'lib/date';

type TProps = {
    readonly source: IChatMember;
    readonly loading?: boolean;
    readonly filters: IChatMemberQueryParams;
    readonly onFiltersUpdate: (filters: IChatMemberQueryParams) => void;
    readonly onBulkRemove: (accounts: number[]) => void;
};

export const ChatMemberWhiteListDetailsTable: React.FC<TProps> = props => {
    const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

    const rowSelection = {
        selectedRows: selectedRows,
        onChange: setSelectedRows,
    };

    return (
        <StyledWrapper>
            <StyledActions>
                {!!selectedRows.length && (
                    <Fragment>
                        <ConfirmationDialog
                            activator={<Button type='primary'>Удалить</Button>}
                            onConfirm={() => {
                                props.onBulkRemove(
                                    selectedRows.map(r => Number(r))
                                );
                            }}
                            title='Удалить?'
                            description={`Вы уверены что хотите удалить пользователей из ${selectedRows.length} сообществ?`}
                        />

                        <div>Выбрано чатов: {selectedRows.length}</div>
                    </Fragment>
                )}
            </StyledActions>
            <StyledTable
                loading={props.loading}
                rowSelection={rowSelection}
                locale={{ emptyText: <Empty description='Нет чатов' /> }}
                dataSource={props.source.communities}
                rowKey={chat => chat.id}
                pagination={{
                    total: props.source.communities.length,
                    showSizeChanger: true,
                    showTotal: () =>
                        `Чатов: ${props.source.communities.length}`,
                    onChange: (page, pageSize) => {
                        props.onFiltersUpdate({
                            offset: (page - 1) * pageSize,
                            limit: pageSize,
                        });
                    },
                    onShowSizeChange: (current, size) => {
                        props.onFiltersUpdate({
                            offset: (current - 1) * size,
                            limit: size,
                        });
                    },
                }}
                scroll={{ x: 600 }}
            >
                <Table.Column
                    title={
                        <TableFilter<string>
                            title='Название сообщества'
                            onChange={name => props.onFiltersUpdate({ name })}
                            value={props.filters.name}
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    dataIndex={'title'}
                    fixed='left'
                    width={220}
                />
                <Table.Column
                    title={
                        <TableFilter<string>
                            onChange={user_name =>
                                props.onFiltersUpdate({ user_name })
                            }
                            value={props.filters.user_name}
                            title='Дата добавления'
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    width={200}
                    render={chat => format(chat.accession_date)('MM.DD.YYYY')}
                />
                <Table.Column
                    width={400}
                    title={
                        <TableFilter<string[]>
                            icon={<FilterOutlined />}
                            title='Теги'
                        >
                            <TableFilter.Tags />
                        </TableFilter>
                    }
                    render={chat => (
                        <React.Fragment>
                            <StyledSpace>
                                {chat.chat_tags.map((tag, index) => (
                                    <Tag key={index}>{tag.name}</Tag>
                                ))}
                            </StyledSpace>
                        </React.Fragment>
                    )}
                />
                <Table.Column
                    title='Действие'
                    width={160}
                    render={chat => (
                        <Button
                            type='link'
                            onClick={() => {
                                props.onBulkRemove([chat.id]);
                            }}
                        >
                            Удалить из БС
                        </Button>
                    )}
                />
            </StyledTable>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
`;

const StyledTable = styled(Table<IChatMember['communities'][0]>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;

const StyledActions = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;
const StyledSpace = styled(Space)`
    width: 100%;
    flex-wrap: wrap;
`;
