import React, { useState } from 'react';

import {
    IChatMemberQueryParams,
    ICommunityQueryParams,
} from 'features/communities/interfaces';
import { useReadChatsQuery } from 'features/communities/services';

import { ChatsTableSelector } from '../views/ChatsTableSelector';

type Props = {
    readonly value: number[];
    readonly onChange: (ids: number[]) => void;
};
export const ChatsTableSelectorContainer: React.FC<Props> = props => {
    const [filters, setFilters] = useState<ICommunityQueryParams>({});
    const { data: source = { items: [], total: 0 }, isFetching } =
        useReadChatsQuery(filters);

    const handleFiltersUpdate = (update: IChatMemberQueryParams | null) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({});
        }
    };

    return (
        <ChatsTableSelector
            value={props.value}
            onSelect={props.onChange}
            filters={filters}
            onFiltersUpdate={handleFiltersUpdate}
            source={source}
            loading={isFetching}
        />
    );
};
