import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select, Typography } from 'antd';

import { useReadKnowledgeQuery } from '../../../../services';

export const KnowledgeSelect = props => {
    const [list, setList] = useState([]);

    const { data: knowledge, isSuccess } = useReadKnowledgeQuery(null);

    useEffect(() => {
        if (isSuccess) {
            const knowledgeList = knowledge?.items.map((k, index) => ({
                id: k.id,
                key: index,
                value: k.name,
                text: k.name,
            }));
            setList(knowledgeList);
        }
    }, [isSuccess]);

    return (
        <Fragment>
            <StrongText>База знаний</StrongText>
            <StyledSelect
                showSearch
                onChange={(e, knowledge) => {
                    props.formik.setFieldValue('knowledge', knowledge);
                }}
                defaultValue={'Выберите базу знаний'}
                options={list}
            />
        </Fragment>
    );
};

const StrongText = styled(Typography.Text)`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
`;

const StyledSelect = styled(Select)`
    max-width: 300px;
`;
