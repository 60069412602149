import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

const { Search } = Input;

interface ISearch {
    readonly search: string;
    readonly searchParam: string;
    readonly action: (search) => void;
    readonly loading: boolean;
}
export const SearchMobile: React.FC<ISearch> = props => {
    const [value, setValue] = useState<string | null>(props.search || null);
    const handleSearch = () => {
        const s = {
            [props.searchParam]: value,
        };
        props.action(s);
    };

    useEffect(() => {
        if (props.search === '') {
            setValue('');
        }
    }, [props.search]);

    return (
        <Search
            loading={props.loading}
            size={'large'}
            placeholder={'Поиск по названию'}
            value={value}
            onChange={e => setValue(e.target.value)}
            onSearch={handleSearch}
            onPressEnter={handleSearch}
        />
    );
};
