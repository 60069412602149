import React, { useEffect, useState } from 'react';
import { InputNumber, Space, Switch, Typography } from 'antd';
import styled from 'styled-components';

interface IProps {
    readonly formik: {
        values: {
            price: number | null;
        };
        initialValues: {
            price: number | null;
        };
        setFieldValue: (string, number) => void;
    };
}

export const PaySwitch: React.FC<IProps> = props => {
    const { formik } = props;

    const [isChecked, setIsChecked] = useState<boolean>(
        formik.initialValues !== null || false
    );

    useEffect(() => {
        if (!isChecked) {
            formik.setFieldValue('price', null);
        }
    }, [isChecked]);

    return (
        <Space align='start' style={{ width: '100%' }}>
            <StyledSwitch
                checked={isChecked}
                onChange={() => {
                    setIsChecked(!isChecked);
                }}
            />
            <Space direction='vertical' size='middle' style={{ width: '100%' }}>
                <StyledTitle level={5}>Платный вебинар</StyledTitle>
                {isChecked && (
                    <Space size='small' direction={'vertical'}>
                        <Typography.Text>
                            Укажите стоимость (₽):
                        </Typography.Text>
                        <InputNumber
                            value={formik.values.price}
                            onChange={(value: null | number) => {
                                formik.setFieldValue(`price`, value);
                            }}
                            controls={false}
                        />
                    </Space>
                )}
            </Space>
        </Space>
    );
};

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;
