import React, { Fragment, useState } from 'react';
import { App, Button, Card, List, Skeleton, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import postPlaceholder from '../../../../../../../static/images/post_placeholder.png';
import { getSrc } from '../../../../../../../utils/common/getSrc';
import { useTheme } from '../../../../../../../hooks/useTheme';
import { useReadWebinarsQuery } from '../../../../../services/webinars';
import { WebinarType } from '../containers/WebinarContainer';
import { ListTitle } from '../components/ListTitle';
import { OnlineDot } from '../components/OnlineDot';

interface IWebinars {
    readonly type: WebinarType;
}

export const WebinarsList: React.FC<IWebinars> = props => {
    const { id } = useParams();
    const token = useTheme();
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [filters, setFilters] = useState({
        limit: 3,
        offset: 0,
        type: props.type,
    });

    const { data, isSuccess, isFetching } = useReadWebinarsQuery(filters);

    const handleFiltersUpdate = update => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        }
    };

    const handleWebinarLink = link => {
        if (link) {
            navigator.clipboard.writeText(link);
            notification.info({
                message: 'Ссылка скопирована',
                placement: 'top',
                duration: 1,
            });
        } else {
            notification.error({
                message: 'Ссылка не найдена',
                placement: 'top',
                duration: 1.5,
            });
        }
    };

    return (
        <Fragment>
            <ListTitle type={props.type} />
            {isSuccess && data ? (
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 3,
                        xl: 3,
                        xxl: 3,
                    }}
                    loading={isFetching}
                    dataSource={data?.webinars || []}
                    renderItem={(webinar, index) => (
                        <List.Item>
                            <StyledCard
                                key={index}
                                type='inner'
                                style={token.Card}
                                cover={
                                    <StyledCover
                                        src={
                                            !webinar.background_image
                                                ? postPlaceholder
                                                : getSrc(
                                                      `/storage/${webinar.background_image}`
                                                  )
                                        }
                                        alt='Publication preview'
                                    />
                                }
                            >
                                <StyledType>
                                    {webinar.type === 'online' && (
                                        <StyledDescription
                                            style={{ marginLeft: 0 }}
                                            type={'secondary'}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <OnlineDot /> в эфире
                                            </div>
                                        </StyledDescription>
                                    )}
                                    <StyledDescription type={'secondary'}>
                                        {webinar.start_at}
                                    </StyledDescription>
                                </StyledType>

                                <StyledTitle level={5}>
                                    {webinar.title}
                                </StyledTitle>
                                <StyledDescription
                                    ellipsis={{
                                        rows: 2,
                                    }}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: webinar.description,
                                        }}
                                    ></span>
                                </StyledDescription>
                                <StyledActions>
                                    <Fragment>
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    `webinar-preview/${webinar.id}`
                                                )
                                            }
                                            style={{ width: '100%' }}
                                        >
                                            {webinar.type === 'online' &&
                                                'Смотреть'}
                                            {webinar.type === 'planned' &&
                                                'Начать'}
                                            {webinar.type === 'ended' &&
                                                'Смотреть'}
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                handleWebinarLink(
                                                    webinar.external_url
                                                )
                                            }
                                        >
                                            <LinkOutlined />
                                        </Button>
                                    </Fragment>
                                </StyledActions>
                            </StyledCard>
                        </List.Item>
                    )}
                    pagination={{
                        hideOnSinglePage: true,
                        showLessItems: true,
                        current: filters.offset / filters.limit + 1,
                        total: data?.total,
                        defaultPageSize: 3,
                        showTotal: () => `Вебинаров: ${data?.total}`,
                        onChange: (page, pageSize) => {
                            handleFiltersUpdate({
                                limit: pageSize,
                                offset: (page - 1) * pageSize,
                            });
                        },
                    }}
                />
            ) : (
                <Skeleton />
            )}
        </Fragment>
    );
};

const StyledCard = styled(Card)`
    //flex: 0 0 33.333333%;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 0.25rem 0;
`;

const StyledDescription = styled(Typography.Paragraph)`
    margin: 0.25rem 0 0.15rem auto;
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
`;

const StyledCover = styled.img`
    object-fit: cover;
    width: 150px;
    height: 250px;
`;

const StyledType = styled.div`
    justify-content: space-between;
    display: flex;
`;
