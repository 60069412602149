import React, { useState } from 'react';
import { Col, Input } from 'antd';
import styled from 'styled-components';

import { IPartProps } from '../../../../../interfaces/publications.interface';

export const Heading: React.FC<IPartProps> = props => {
    const { order, setPostContent, postContent, content } = props;

    const [value, setValue] = useState(content);
    const onChange = (val, order) => {
        setValue(val);
        setPostContent(
            postContent.map(elem =>
                elem.order === order ? { ...elem, text: val } : { ...elem }
            )
        );
    };

    return (
        <StyledWrapper xs={24} sm={20} md={15}>
            <StyledInput
                value={content || value}
                onChange={e => onChange(e.target.value, order)}
                placeholder={'Заголовок'}
            ></StyledInput>
        </StyledWrapper>
    );
};

const StyledInput = styled(Input)`
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    min-width: 50%;
`;

const StyledWrapper = styled(Col)`
    margin: 0.5rem 0;
`;
