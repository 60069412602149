import React, { FC, useState } from 'react';
import { Input, InputNumber, Space, Switch, Typography } from 'antd';
import styled from 'styled-components';

import { TimePickerFormik } from 'shared/ui/Form/Fields/TimePickerFormik';

import { ReputationSwitchesEnum } from '../../ReputationMapper';

interface IProps {
    formik: any;
}

export const DelayStartRulesTimeSwitch: FC<IProps> = props => {
    return (
        <StyledSpace align='start' size='small'>
            <StyledSwitch
                checked={
                    props.formik.values.switches[
                        ReputationSwitchesEnum.delayStartRulesTimeSwitch
                    ].isChecked
                }
                onChange={value => {
                    props.formik.setFieldValue(
                        `switches.${ReputationSwitchesEnum.delayStartRulesTimeSwitch}.isChecked`,
                        value
                    );
                }}
            />
            <Space direction='vertical'>
                <StyledTitle level={5}>
                    Отсрочить начало действия правил репутации
                </StyledTitle>
                {props.formik.values.switches[
                    ReputationSwitchesEnum.delayStartRulesTimeSwitch
                ].isChecked && (
                    <>
                        <Typography.Text>
                            Правила репутации для каждого вступившего участника
                            начинают действовать через
                        </Typography.Text>
                        <div>
                            <TimePickerFormik
                                formik={props.formik}
                                fieldName={`switches.${ReputationSwitchesEnum.delayStartRulesTimeSwitch}.delay_start_rules_seconds`}
                            />
                            <span style={{ marginLeft: 12 }}>
                                после вступления
                            </span>
                        </div>
                        <div>
                            <InputNumber
                                value={
                                    props.formik.values.switches[
                                        ReputationSwitchesEnum
                                            .delayStartRulesTimeSwitch
                                    ]?.delay_start_rules_total_messages
                                }
                                onChange={value => {
                                    props.formik.setFieldValue(
                                        `switches.${ReputationSwitchesEnum.delayStartRulesTimeSwitch}.delay_start_rules_total_messages`,
                                        +value
                                    );
                                }}
                            />
                            <span style={{ marginLeft: 12 }}>
                                сообщений в чате
                            </span>
                        </div>
                    </>
                )}
            </Space>
        </StyledSpace>
    );
};

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledSpace = styled(Space)`
    margin-bottom: 32px;
`;
