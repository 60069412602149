import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

import { useReadPublicationListQuery } from '../services';

export const PublicationSelector = props => {
    const { setFilters } = props;

    const [webinars, setWebinars] = useState([]);
    const [publications, setPublications] = useState([]);

    const { data, isSuccess, isFetching } = useReadPublicationListQuery(null);

    useEffect(() => {
        if (isSuccess && data) {
            const webinars = data.webinars.map((w, index) => ({
                key: 'webinar' + index,
                id: w.id,
                value: w.id,
                label: w.title,
                type: 'webinar',
            }));

            setWebinars(webinars);

            const publications = data.publications.map((p, index) => ({
                key: 'publication' + index,
                id: p.id,
                value: p.id,
                label: p.title,
                type: 'publication',
            }));

            setPublications(publications);
        }
    }, [isSuccess]);

    const onChange = (title, record) => {
        if (record.type === 'webinar') {
            return setFilters({ webinar_id: record.id });
        }
        if (record.type === 'publication') {
            return setFilters({ publication_id: record.id });
        }
    };

    return (
        <StyledSelect
            loading={isFetching}
            showSearch
            allowClear
            placeholder={'Все публикации и вебинары'}
            onClear={() => setFilters(null)}
            options={[
                {
                    label: 'Публикации',
                    options: publications,
                },
                {
                    label: 'Вебинары',
                    options: webinars,
                },
            ]}
            onSelect={(_, record) => onChange(_, record)}
        />
    );
};

const StyledSelect = styled(Select)`
    min-width: 240px;
`;
