export const parseError = (error: unknown): string => {
    if (typeof error === 'object') {
        if ('data' in error && typeof error.data === 'object') {
            if ('message' in error.data) {
                return error.data.message as string;
            }
        }
    }

    return 'Неизвестная ошибка';
};

export class SpodialAdapterError {
    constructor(private readonly error: unknown) {}

    public toString(): string {
        return this.error.data.spodial_php_error.message;
    }
}
