import React from 'react';
import styled from 'styled-components';

type TLabel = {
    readonly label: string;
};
export const Label: React.FC<TLabel> = props => {
    return <StyledLabel>{props.label}</StyledLabel>;
};

const StyledLabel = styled.label`
    margin-bottom: 10px;
`;
