import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col } from 'antd';

import { TextEditor } from '../../../../../../../shared/ui/TextEditor/TextEditor';
import { IPartProps } from '../../../../../interfaces/publications.interface';
export const Text: React.FC<IPartProps> = props => {
    const { order, setPostContent, postContent, content } = props;

    const [value, setValue] = useState(content);

    const onChange = (val, index) => {
        setPostContent(
            postContent.map(elem =>
                elem.order === index ? { ...elem, text: val } : { ...elem }
            )
        );
    };

    useEffect(() => {
        if (value) {
            setPostContent(
                postContent.map(elem =>
                    elem.order === order
                        ? { ...elem, text: value }
                        : { ...elem }
                )
            );
        }
    }, [value]);

    return (
        <StyledWrapper xs={24} sm={20} md={15}>
            <TextEditor
                placeholder={'Напишите что-то'}
                value={content || value}
                onChange={setValue}
            />
        </StyledWrapper>
    );
};

const StyledWrapper = styled(Col)`
    margin: 1rem 0.25rem 1rem 0;
`;
