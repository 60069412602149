import React, { FC, Fragment, useState } from 'react';
import { Button, Drawer, Flex, Menu } from 'antd';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

import Logo from 'static/images/logo.png';
import getEnvConfig from 'utils/config/env';

interface IProps {
    aboutRef: React.MutableRefObject<any>;
    skillsRef: React.MutableRefObject<any>;
    faqRef: React.MutableRefObject<any>;
    demoRef: React.MutableRefObject<any>;
    createStoreRef: React.MutableRefObject<any>;
    contactsRef: React.MutableRefObject<any>;
}

export const Navbar: FC<IProps> = ({
    aboutRef,
    skillsRef,
    faqRef,
    demoRef,
    createStoreRef,
    contactsRef,
}) => {
    const [open, setOpen] = useState(false);

    const menuItems = [
        {
            name: 'О нас',
            goTo: () =>
                aboutRef.current.scrollIntoView({
                    block: 'end',
                    inline: 'end',
                    behavior: 'smooth',
                }),
        },
        {
            name: 'Возможности',
            goTo: () =>
                skillsRef.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth',
                }),
        },
        {
            name: 'FAQ',
            goTo: () =>
                faqRef.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth',
                }),
        },
        {
            name: 'Демо',
            goTo: () =>
                demoRef.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth',
                }),
        },
        {
            name: 'Контакты',
            goTo: () =>
                contactsRef.current.scrollIntoView({
                    block: 'end',
                    inline: 'end',
                    behavior: 'smooth',
                }),
        },
        {
            name: 'Создать Магазин',
            goTo: () =>
                createStoreRef.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth',
                }),
        },
    ];

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Drawer placement='left' onClose={onClose} open={open}>
                <StyledMobileMenu>
                    {menuItems.map((item, index) => (
                        <StyledMobileMenuItem
                            key={index}
                            onClick={() => {
                                item.goTo();
                                onClose();
                            }}
                        >
                            {item.name}
                        </StyledMobileMenuItem>
                    ))}
                </StyledMobileMenu>
            </Drawer>

            <StyledHeader>
                <HeaderContainer align={'center'}>
                    <StyledMobileBurger onClick={() => showDrawer()}>
                        <MenuOutlined />
                    </StyledMobileBurger>
                    <StyledLogo>
                        <NavLink
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            to='/'
                        >
                            <img width={'100%'} alt='logo' src={Logo} />
                        </NavLink>
                    </StyledLogo>
                    <StyledMenu
                        theme='light'
                        mode='horizontal'
                        defaultSelectedKeys={['0']}
                        items={menuItems.map((item, index) => {
                            return {
                                key: index,
                                label: (
                                    <div onClick={item.goTo}>{item.name}</div>
                                ),
                            };
                        })}
                    />
                    <ActionBlock>
                        <Link to={`${getEnvConfig().PUBLIC_PATH}/profile`}>
                            <Button type='primary'>Войти</Button>
                        </Link>
                    </ActionBlock>
                </HeaderContainer>
            </StyledHeader>
        </Fragment>
    );
};

const StyledMobileMenuItem = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(37, 33, 41, 0.88);
    cursor: pointer;
`;

const StyledMobileMenu = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 26px;
`;

const StyledMobileBurger = styled.div`
    display: none;

    @media (max-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
`;

const HeaderContainer = styled(Flex)`
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0.75rem 0;

    @media (max-width: 992px) {
        display: flex;
        justify-content: space-between;
    }
`;

const ActionBlock = styled.div`
    //padding-top: 21px;
`;

const StyledLogo = styled.div`
    width: 200px;
    //padding-top: 24px;

    @media (max-width: 992px) {
        display: none;
    }
`;

const StyledHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 150;
    width: 100%;
    // height: 60px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border-bottom: 1px solid rgba(37, 33, 41, 0.06);
    padding: 0 50px;

    @media (max-width: 992px) {
        padding: 0 40px;
    }
`;

const StyledMenu = styled(Menu)`
    display: flex;
    border-bottom: 0;
    flex: 1;
    align-items: center;
    justify-content: center;

    @media (max-width: 992px) {
        display: none;
    }
`;
