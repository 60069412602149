import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { App } from 'antd';

import { AuthorForm } from '../../view/AuthorForm';
import { createFormData } from '../../../../../../utils/common/createFormData';
import {
    useCreateAuthorMutation,
    useReadAuthorByIdQuery,
} from '../../../../services/author';

export const AuthorEditContainer = props => {
    const { notification } = App.useApp();
    const { id } = useParams();

    const [initial, setInitial] = useState(null);

    const [openDel, setOpenDel] = useState(false);

    const [updateAuthor, { isSuccess }] = useCreateAuthorMutation();

    const {
        data: author,
        isSuccess: isSuccessRead,
        isError: isErrorRead,
        isFetching: isFetchingRead,
    } = useReadAuthorByIdQuery(Number(id));

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: `Данные автора успешно изменены`,
                placement: 'top',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccess) {
            props.setOpen(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccessRead) {
            setInitial({
                name: author.name,
                about: author.about,
                photo: author.photo,
            });
        }
    }, [isSuccessRead]);

    const onSave = async values => {
        const requestObject = async () => {
            const data = {
                name: values.name,
                about: values.about,
                photo: values.photo as File,
            };
            if (!(values.photo instanceof File)) {
                delete data.photo;
            }

            await updateAuthor(createFormData(data));
        };

        await requestObject();
    };

    if (!id && !author) {
        return <></>;
    }

    const isEdit = true;

    return (
        initial &&
        isSuccessRead && (
            <AuthorForm
                open={props.open}
                setOpen={props.setOpen}
                initialValues={initial}
                onSave={onSave}
                openDel={openDel}
                setOpenDel={setOpenDel}
                isEdit={isEdit}
            />
        )
    );
};
