import React, { FC, SetStateAction } from 'react';
import {
    Button,
    Card,
    Col,
    Divider,
    List,
    Row,
    Select,
    Skeleton,
    Space,
    Typography,
} from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { rub } from '../../../../../shared/contants/styles';
import { IFinanceResponse } from '../../../services/finance.interface';

interface IProps {
    source: Array<IFinanceResponse>;
    loading: boolean;
}

export const FinanceCard: FC<IProps> = props => {
    const { source, loading } = props;

    return loading ? (
        <Skeleton />
    ) : (
        <List
            rowKey={finance => finance.paymentId}
            dataSource={source}
            renderItem={finance => (
                <List.Item>
                    <Col span={12}>
                        <Space direction={'vertical'}>
                            <Typography.Text>
                                {finance.amount} {rub}
                            </Typography.Text>
                            <StyledSecondary>
                                {dayjs(finance.date).format(
                                    'DD.MM.YYYY, HH:mm'
                                )}
                            </StyledSecondary>
                        </Space>
                    </Col>
                    <Col
                        span={12}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <Space direction={'vertical'}>
                            <StyledSecondary>{finance.card}</StyledSecondary>
                            <StyledStatus>
                                <Typography.Text>Выполнено</Typography.Text>
                            </StyledStatus>
                        </Space>
                    </Col>
                </List.Item>
            )}
        />
    );
};

const StyledSecondary = styled(Typography.Text)`
    color: rgba(37, 33, 41, 0.45);
`;

const StyledDivider = styled(Divider)`
    margin: 0.75rem 0;
`;

const StyledStatus = styled.div`
    padding: 0 7px;
    border-radius: 4px;
    box-shadow: 0 2px 0 rgba(37, 33, 41, 0, 02);
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    text-align: center;
`;
