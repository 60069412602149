import { theme, ThemeConfig } from 'antd';

export const themeConfig: ThemeConfig = {
    token: {
        colorWarning: '#ff9f43',
        colorPrimary: '#6257d2',
        colorSuccess: '#21c169',
        colorError: '#f06e69',
        colorInfo: '#6257d2',
        colorTextBase: '#252129',
        borderRadius: 5,
        wireframe: false,
        colorBgLayout: '#F0F0F0',
        colorBgContainer: '#FFFFFF',
    },
    components: {
        Card: {
            boxShadow:
                '0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
        },
        Button: {
            colorLink: '#6257d2',
            colorLinkActive: '#6257d2',
            colorLinkHover: '#6257d2',
        },
        Tabs: {
            horizontalItemGutter: 28,
        },
        Form: {
            itemMarginBottom: 0,
        },
    },

    algorithm: theme.defaultAlgorithm,
};

export default themeConfig;
