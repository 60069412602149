import React, { FC, Fragment } from 'react';
import { InputNumber, Radio, Space, Switch, Typography } from 'antd';
import styled from 'styled-components';

import { TimePickerFormik } from 'shared/ui/Form/Fields/TimePickerFormik';
import { OnboardingSwitchEnum } from 'features/communities/components/Onboarding/OnboardingMapper';
import { useOrientations } from 'hooks/useOrientation/useOrientation';

interface IProps {
    formik: any;
}

export const JoinLimitSwitch: FC<IProps> = props => {
    const orientation = useOrientations();
    return (
        <Fragment>
            <StyledSpace align='start'>
                <StyledSwitch
                    checked={
                        props.formik.values.switches[
                            OnboardingSwitchEnum.joinLimitSwitch
                        ].isChecked
                    }
                    onChange={value => {
                        props.formik.setFieldValue(
                            `switches.${OnboardingSwitchEnum.joinLimitSwitch}.isChecked`,
                            value
                        );
                    }}
                />
                <Space direction='vertical' size='middle'>
                    <StyledTitle level={5}>
                        Ограничить массовое вступление
                    </StyledTitle>
                    {props.formik.values.switches[
                        OnboardingSwitchEnum.joinLimitSwitch
                    ].isChecked && (
                        <StyledWrap>
                            <Space size='middle'>
                                <div>
                                    <div>
                                        <Typography.Text>
                                            Если вступают более
                                        </Typography.Text>
                                    </div>
                                    <div>
                                        <InputNumber
                                            value={
                                                props.formik.values.switches[
                                                    OnboardingSwitchEnum
                                                        .joinLimitSwitch
                                                ]?.count
                                            }
                                            onChange={value => {
                                                props.formik.setFieldValue(
                                                    `switches.${OnboardingSwitchEnum.joinLimitSwitch}.count`,
                                                    +value
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Typography.Text>
                                            в течение
                                        </Typography.Text>
                                    </div>
                                    <div>
                                        <TimePickerFormik
                                            formik={props.formik}
                                            fieldName={`switches.${OnboardingSwitchEnum.joinLimitSwitch}.duration`}
                                        />
                                    </div>
                                </div>
                            </Space>
                            <Radio.Group
                                buttonStyle='solid'
                                value={props.formik.values.switches[
                                    OnboardingSwitchEnum.joinLimitSwitch
                                ]?.action?.toString()}
                                onChange={event => {
                                    props.formik.setFieldValue(
                                        `switches.${OnboardingSwitchEnum.joinLimitSwitch}.action`,
                                        +event.target.value
                                    );
                                }}
                            >
                                <StyledRadioWrap>
                                    {orientation === 'mobile' ? (
                                        <>
                                            <Radio value='4'>Забанить</Radio>
                                            <Radio value='10'>Выгнать</Radio>
                                            <Radio value='5'>
                                                Запретить сообщения
                                            </Radio>
                                        </>
                                    ) : (
                                        <>
                                            <Radio.Button value='4'>
                                                Забанить
                                            </Radio.Button>
                                            <Radio.Button value='10'>
                                                Выгнать
                                            </Radio.Button>
                                            <Radio.Button value='5'>
                                                Запретить сообщения
                                            </Radio.Button>
                                        </>
                                    )}
                                </StyledRadioWrap>
                            </Radio.Group>
                        </StyledWrap>
                    )}
                </Space>
            </StyledSpace>
        </Fragment>
    );
};

const StyledRadioWrap = styled.div`
    display: flex;

    @media (max-width: 576px) {
        gap: 16px;
        flex-direction: column;
    }
`;

const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;

    @media (max-width: 576px) {
        margin-left: -68px;
    }
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSpace = styled(Space)`
    margin-bottom: 8px;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;
