import React, { useEffect } from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import {
    AntispamMapper,
    AntispamSwitchesEnum,
} from 'features/communities/components/Antispam/AntispamMapper';

import { useCreateAntispamMutation } from '../../../services';
import { AntispamForm } from '../views/AntispamForm';

export interface IFormikInitialValues {
    name: string;
    switches: {
        [AntispamSwitchesEnum.messageWithLink]: {
            isChecked: boolean;
            del_message_with_link?: boolean;
            ban_user_contain_link?: boolean;
        };
        [AntispamSwitchesEnum.messageWithForward]: {
            isChecked: boolean;
            del_message_with_forward?: boolean;
            ban_user_contain_forward?: boolean;
        };
    };
    work_period?: number;
    selectedChatIds: number[];
}

export const AntispamContainerCreate: React.FC = () => {
    const { notification } = App.useApp();

    const navigate = useNavigate();

    const [createAntispam, { isSuccess, error }] = useCreateAntispamMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Сценарий создан успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any).data.message || 'Что-то пошло не так',
                placement: 'top',
            });
        }
    }, [error]);

    const initialValues: IFormikInitialValues = {
        name: '001 Антиспам правила',
        switches: {
            [AntispamSwitchesEnum.messageWithLink]: {
                isChecked: false,
                del_message_with_link: false,
                ban_user_contain_link: false,
            },
            [AntispamSwitchesEnum.messageWithForward]: {
                isChecked: false,
                del_message_with_forward: false,
                ban_user_contain_forward: false,
            },
        },
        work_period: 10800,
        selectedChatIds: [],
    };

    const onSave = async (values: IFormikInitialValues) => {
        await createAntispam(AntispamMapper.createDto(values));
    };

    return <AntispamForm initialValues={initialValues} onSave={onSave} />;
};
