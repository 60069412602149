import React, { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { App, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    useCreateKnowledgeMutation,
    useDeleteKnowledgeMutation,
    useReadKnowledgeQuery,
} from '../../../services';
import { KnowledgeTable } from '../views/KnowledgeTable';
import getEnvConfig from '../../../../../utils/config/env';
import { QueryKnowledge } from '../../../interfaces/knowledge.interface';

export const KnowledgeContainer: React.FC<PropsWithChildren> = () => {
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [knowledgeName, setKnowledgeName] = useState(null);
    const [id, setId] = useState<null | number>(null);

    const [
        createKnowledge,
        { isSuccess: isSuccessCreated, data: successData },
    ] = useCreateKnowledgeMutation();

    const [selectedRows, setSelectedRows] = useState<React.Key[] | any>([]);

    const [filters, setFilters] = useState<QueryKnowledge>({});

    const { data: source = { items: [], total: 0 }, isFetching } =
        useReadKnowledgeQuery(filters);

    const [deleteKnowledge, { error, isError, isLoading, isSuccess }] =
        useDeleteKnowledgeMutation();

    useEffect(() => {
        if (knowledgeName !== null && id === null) {
            getKnowledge();
        }
    }, [id === null, knowledgeName != null]);

    const handleCreate = (): void => {
        const ids: any = [...new Set(source.items.map(k => k.id))];

        if (ids?.length === 0) {
            setKnowledgeName(`База знаний ${id}`);
        } else {
            const lastId = Math.max(...ids);
            setKnowledgeName(`База знаний ${lastId + 1}`);
        }
    };

    const getKnowledge = async () => {
        const data: { knowledge_name: string } = {
            knowledge_name: knowledgeName,
        };
        const res: any = await createKnowledge(data);

        await setId(res.data.data.id);
        await navigate(
            `${getEnvConfig().PUBLIC_PATH}/communities/knowledge/create/${
                res.data.data.id
            }`
        );
    };

    useEffect(() => {
        if (isSuccessCreated) {
            notification.success({
                message: 'База знаний создана успешно',
                placement: 'top',
            });
        }
    }, [isSuccessCreated]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'База знаний успешно удалена',
                placement: 'top',
            });
            setSelectedRows([]);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: 'Нельзя удалить базу знаний с вопросами',
                placement: 'top',
            });
            setSelectedRows([]);
        }
    }, [error]);

    const onBulkRemove = async (
        id: number[],
        count?: number | undefined
    ): Promise<void> => {
        if (count > 0) {
            setSelectedRows([]);
            return notification.info({
                message: 'Нельзя удалить базу знаний с вопросами',
            });
        } else await Promise.all(id.map(deleteKnowledge));
        setSelectedRows([]);
    };

    const handleFiltersUpdate = update => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({});
        }
    };

    return (
        <StyledWrapper>
            <StyledActions>
                {selectedRows?.length > 0 ? (
                    <Fragment>
                        <Button
                            disabled={isLoading}
                            type={'primary'}
                            onClick={() => onBulkRemove(selectedRows)}
                        >
                            Удалить
                        </Button>

                        <StyledSelectedCounterText>
                            Выбрано баз знаний: {selectedRows?.length}
                        </StyledSelectedCounterText>
                    </Fragment>
                ) : (
                    <Button
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() => {
                            handleCreate();
                        }}
                    >
                        Создать базу знаний
                    </Button>
                )}
            </StyledActions>
            <KnowledgeTable
                source={source}
                loading={isFetching}
                deleteKnowledge={deleteKnowledge}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onFiltersUpdate={handleFiltersUpdate}
            />
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 10;
`;

const StyledSelectedCounterText = styled.div`
    margin: 0 0 0 1rem;
`;
