import { v4 as uuidv4 } from 'uuid';

export interface ITree {
    readonly id?: string;
    readonly root: string | null;
    readonly children: Array<ITree>;

    readonly type: 'AND' | 'OR' | 'EXPRESSION';
    readonly subject?: string;
    readonly action?: string;
    readonly value?: {
        type: string;
        parameter?: string;
    };
}

export class Tree implements ITree {
    public children: Array<Tree> = [];
    public root: string | null = null;
    public id?: string;
    public type: 'AND' | 'OR' | 'EXPRESSION';

    constructor(tree?: ITree) {
        if (tree) {
            this.id = tree.id || uuidv4();
            Object.entries(tree).map(([key, value]) => {
                this[key] = value;
            });
            const { children = [] } = tree;
            this.children = children.map(
                c => new Tree({ ...c, root: this.id })
            );
            if (tree.root) {
                this.root = tree.root;
            }
        } else {
            this.id = uuidv4();
            this.root = null;
        }
    }

    public serialize = (): string => {
        return JSON.stringify(this);
    };

    public attachNode = (treeLike: object) => {
        this.children.push(new Tree({ ...treeLike, root: this.id } as ITree));
    };

    public toggleCondition = (rootId: string) => {
        const rootNode = this.findNode(rootId);
        if (rootNode.type === 'OR') {
            rootNode.type = 'AND';
        } else {
            rootNode.type = 'OR';
        }
    };

    public updateNode = (node_id: string, args: object) => {
        const rootNode_id = this.findNode(node_id).root;
        const rootNode = this.findNode(rootNode_id);
        const index = rootNode.children.findIndex(obj => obj.id === node_id);
        Object.entries(args).forEach(([key, value]) => {
            rootNode.children[index][key] = value;
        });
    };

    public dublicate = (node_id: string) => {
        const node = this.findNode(node_id);
        const parentNode = this.findNode(node.root);
        parentNode.children.push(new Tree(node));
    };

    public deleteNode = (rootId: string) => {
        const removalNode = this.findNode(rootId);
        const parentRoot = this.findNode(removalNode.root);
        parentRoot.children = parentRoot.children.filter(c => c.id !== rootId);
        if (parentRoot.children.length === 0 && !!parentRoot.root) {
            this.deleteNode(parentRoot.id);
        }
    };

    public toGroup = (node_id: string) => {
        const node = this.findNode(node_id);
        const parentNode = this.findNode(node.root);
        parentNode.children = parentNode.children.filter(
            child => child.id !== node_id
        );
        parentNode.children.push(
            new Tree({
                type: 'AND',
                root: parentNode.id,
                children: [node],
            } as ITree)
        );
    };

    public findNode = (
        node_id: string,
        parentNode: Tree = this
    ): Tree | null => {
        let result = null;
        if (node_id === parentNode.id) return parentNode;
        for (let i = 0; i < parentNode.children.length; i++) {
            if ((result = parentNode.children[i].findNode(node_id))) {
                break;
            }
        }
        return result;
    };
}
