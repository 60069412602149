import { Avatar, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';

import getEnvConfig from 'utils/config/env';

type Props = {
    readonly image?: string;
    readonly title?: string;
    readonly chat?: string;
    readonly onClick?: (id: any) => void;
};
export const ChatTitleAvatar: React.FC<Props> = props => {
    return (
        <StyledChatTitle onClick={props.onClick || void null}>
            <Space>
                {props.image && (
                    <Avatar
                        src={
                            <img
                                src={`${getEnvConfig().env.API_URL.replace(
                                    /\/api\/v3/,
                                    ''
                                )}${props.image}`}
                                alt='avatar'
                            />
                        }
                    />
                )}
                {!props.image && <Avatar>{props?.title?.charAt(0)}</Avatar>}

                {props.title}
            </Space>
        </StyledChatTitle>
    );
};

const StyledChatTitle = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
`;
