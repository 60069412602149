import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Row, Space, Typography } from 'antd';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import { HeaderScenarios } from '../../../../../communities/components/HeaderScenarios/HeaderScenarios';
import { Description } from './components/Description';
import { Price } from './components/Price';
import { createButton } from './buttons';
import { Heading } from './components/Heading';
import { Text } from './components/Text';
import { Image } from './components/Image';
import { Video } from './components/Video';
import { Audio } from './components/Audio';
import { blobToBase64 } from '../../../../../../utils/common/blobToBase64';
import { ButtonDelete } from './components/ButtonDelete';

export const PostForm = props => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { onSave, setPostCost, addItem, postContent, open, setPostContent } =
        props;

    const [img1, setImg1] = useState<ArrayBuffer | string | null>(
        props.initialValues?.background_image || null
    );
    const img1Ref = useRef(null);

    useEffect(() => {
        if (img1Ref.current) {
            blobToBase64(img1Ref.current).then(val => {
                console.log(val);
                setImg1(val);
            });
        }
    }, [img1Ref.current]);

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: onSave,
    });

    const userContentProps = {
        open: open,
        postContent: postContent,
        setPostContent: setPostContent,
    };

    const handleDeletePost = () => {
        navigate(-1);
        props.deletePost(id);
    };

    return (
        <Fragment>
            <Typography.Title
                level={1}
                style={{ margin: 0, marginBottom: '40px' }}
            >
                Пост
            </Typography.Title>

            <HeaderScenarios
                goBack={!props.isEdit ? handleDeletePost : null}
                inputValue={formik?.values?.title}
                onChangeHeaderInput={({ target }) => {
                    formik.setFieldValue('title', target.value);
                }}
            />

            <Description
                formik={formik}
                img={img1}
                imgRef={img1Ref}
                type={'background_image'}
            />

            <Price formik={formik} setPostCost={setPostCost} />

            <Space wrap>
                {createButton.map((button, index) => (
                    <Button key={index} onClick={() => addItem(button, index)}>
                        {button.icon}
                        {button.text}
                    </Button>
                ))}
            </Space>

            <div id={'text'} style={{ margin: '2rem 0' }}>
                {userContentProps?.postContent
                    //?.sort((a, b) => Number(a) - Number(b))
                    ?.map((block, index) => (
                        <Fragment key={block.key}>
                            {block.type === 6 && open && (
                                <Row>
                                    <Heading
                                        order={block.order}
                                        content={block.text}
                                        {...userContentProps}
                                    />
                                    <ButtonDelete
                                        order={block.order}
                                        isEdit={props.isEdit}
                                        id={
                                            block.id === 'new'
                                                ? 'new'
                                                : block.id
                                        }
                                        setOrder={props.setOrder}
                                        {...userContentProps}
                                    />
                                </Row>
                            )}
                            {block.type === 1 && open && (
                                <Row>
                                    <Text
                                        order={block.order}
                                        content={block.text}
                                        {...userContentProps}
                                    />
                                    <ButtonDelete
                                        order={block.order}
                                        isEdit={props.isEdit}
                                        id={
                                            block.id === 'new'
                                                ? 'new'
                                                : block.id
                                        }
                                        setOrder={props.setOrder}
                                        {...userContentProps}
                                    />
                                </Row>
                            )}
                            {block.type === 4 && open && (
                                <Row>
                                    <Image
                                        order={block.order}
                                        content={
                                            block.id !== 'new'
                                                ? `/storage/${block.file_path}`
                                                : block.file_path
                                        }
                                        {...userContentProps}
                                        isEdit={props.isEdit}
                                    />
                                    <ButtonDelete
                                        order={block.order}
                                        isEdit={props.isEdit}
                                        id={
                                            block.id === 'new'
                                                ? 'new'
                                                : block.id
                                        }
                                        setOrder={props.setOrder}
                                        {...userContentProps}
                                    />
                                </Row>
                            )}
                            {block.type === 2 && open && (
                                <Row>
                                    <Video
                                        order={block.order}
                                        content={
                                            block.file_path
                                                ? block.file_path
                                                : null
                                        }
                                        isEdit={props.isEdit}
                                        {...userContentProps}
                                    />
                                    <ButtonDelete
                                        order={block.order}
                                        isEdit={props.isEdit}
                                        id={
                                            block.id === 'new'
                                                ? 'new'
                                                : block.id
                                        }
                                        setOrder={props.setOrder}
                                        {...userContentProps}
                                    />
                                </Row>
                            )}
                            {block.type === 3 && open && (
                                <Row>
                                    <Audio
                                        order={block.order}
                                        content={
                                            block.file_path
                                                ? block.file_path
                                                : null
                                        }
                                        isEdit={props.isEdit}
                                        {...userContentProps}
                                    />
                                    <ButtonDelete
                                        order={block.order}
                                        isEdit={props.isEdit}
                                        id={
                                            block.id === 'new'
                                                ? 'new'
                                                : block.id
                                        }
                                        setOrder={props.setOrder}
                                        {...userContentProps}
                                    />
                                </Row>
                            )}
                        </Fragment>
                    ))}
            </div>

            <Space wrap>
                <Button
                    //disabled={props.loading}
                    onClick={() => formik.submitForm()}
                    type={'primary'}
                >
                    Сохранить
                </Button>
                <Button
                    onClick={() =>
                        !props.isEdit ? handleDeletePost() : navigate(-1)
                    }
                >
                    Отмена
                </Button>
            </Space>
        </Fragment>
    );
};
