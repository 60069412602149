import React, { FC, Fragment, useState } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Collapse } from 'antd';

import Rectangle from 'static/landing/Rectangle.png';
import Laptop from 'static/landing/laptop.png';
import PayImg from 'static/landing/payImg.png';
import StatisticImg from 'static/landing/statisticImg.png';
import SellImg from 'static/landing/sellImg.png';

import { SubTitle } from './Title';

export const SectionThird: FC = props => {
    const [activePanel, setActivePanel] = useState<number>(1);
    return (
        <StyledWrapper ref={props.skillsRef}>
            <StyledSectionThird>
                <SectionThirdLeftWrap>
                    <Collapse
                        activeKey={activePanel}
                        ghost
                        accordion
                        expandIcon={({ isActive }) => (
                            <StyledIconWrap>
                                {isActive ? (
                                    <MinusOutlined
                                        style={{
                                            fontSize: 25,
                                            color: '#6257D2',
                                        }}
                                    />
                                ) : (
                                    <PlusOutlined
                                        style={{
                                            fontSize: 25,
                                            color: '#6257D2',
                                        }}
                                    />
                                )}
                            </StyledIconWrap>
                        )}
                        onChange={key => setActivePanel(+key)}
                    >
                        <Collapse.Panel
                            header={
                                <SubTitle
                                    text={'Управляйте сообществами'}
                                    type={'primary'}
                                />
                            }
                            key='1'
                        >
                            <p>
                                Неограниченные возможности группировки чатов из
                                разных Telegram аккаунтов. Автоматизируйте любые
                                последовательности действий
                            </p>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={
                                <SubTitle
                                    text={'Анализируйте запросы'}
                                    type={'primary'}
                                />
                            }
                            key='2'
                        >
                            <p>
                                Посмотрите показатели всего проекта без сведения
                                аналитики по отдельным чатам. Можно
                                проанализировать общие результаты и выделить те,
                                что требуют особого внимания ваших админов.
                            </p>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={
                                <SubTitle
                                    text={'Принимайте оплату'}
                                    type={'primary'}
                                />
                            }
                            key='3'
                        >
                            <p>
                                Достигайте большего, получая финансовую помощь
                                от своих сообществ. Возможность вывода средств в
                                любой момент. Низкая комиссия - от 7%
                            </p>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={
                                <SubTitle
                                    text={'Продавайте товары'}
                                    type={'primary'}
                                />
                            }
                            key='4'
                        >
                            <p>
                                Создавайте, настраивайте и запускайте магазин
                                всего за 3 дня. Полный цикл продаж в Telegram
                                приложении.
                            </p>
                        </Collapse.Panel>
                    </Collapse>
                </SectionThirdLeftWrap>
                <SectionThirdRightWrap>
                    {(activePanel === 1 || activePanel === 0) && (
                        <div style={{ position: 'relative', zIndex: 0 }}>
                            <StyledRectangle style={{ position: 'absolute' }}>
                                <img src={Rectangle} />
                            </StyledRectangle>
                            <img className={'laptop'} src={Laptop} />
                        </div>
                    )}
                    {activePanel === 2 && (
                        <img className={'laptop'} src={StatisticImg} />
                    )}
                    {activePanel === 3 && (
                        <img className={'laptop'} src={PayImg} />
                    )}
                    {activePanel === 4 && (
                        <img className={'laptop'} src={SellImg} />
                    )}
                </SectionThirdRightWrap>
            </StyledSectionThird>
            {/*<ActionBlock>
                    <Link
                        to={`${
                            getEnvConfig().PUBLIC_PATH
                        }/auth/sign-up?reason=free`}
                    >
                        <Button type={'primary'}>Начать работу</Button>
                    </Link>
                </ActionBlock>*/}
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 80px;
`;

const StyledIconWrap = styled.div`
    padding-top: 15px;
`;

const StyledRectangle = styled.div`
    position: absolute;
    top: -30px;
    left: 30px;
    z-index: -1;

    @media (max-width: 1400px) {
        top: -15px;
        left: 15px;
        img {
            height: 28px;
        }
    }
`;

const SectionThirdLeftWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    padding-left: 164px;

    @media (max-width: 992px) {
        padding-left: 130px;
    }

    @media (max-width: 768px) {
        padding-left: 20px;
    }

    @media (max-width: 576px) {
        padding-left: 0;
        width: 100%;
    }
`;

const SectionThirdRightWrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding-right: 164px;
    z-index: 0;

    @media (max-width: 992px) {
        padding-right: 130px;
    }

    @media (max-width: 768px) {
        padding-right: 0;
    }

    @media (max-width: 576px) {
        width: 100%;
    }

    .laptop {
        z-index: 1;
        height: 356px;

        @media (max-width: 1400px) {
            height: 177px;
        }
    }
`;

const StyledSectionThird = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 992px) {
        margin-bottom: 76px;
    }

    @media (max-width: 768px) {
        margin-bottom: 40px;
    }

    @media (max-width: 576px) {
        justify-content: start;
        flex-direction: column;
        gap: 24px;
    }
`;
