import React, { Fragment } from 'react';
import { Divider, Input, InputNumber, Select, Space, Typography } from 'antd';
import styled from 'styled-components';

type CallbackValue = {
    type: string;
    parameter?: string;
};

type Props = {
    readonly options: Array<{
        value: string;
        label: string;
        customValueComponent?: string;
    }>;
    readonly value?: CallbackValue;
    readonly onChange: (value: CallbackValue) => void;
};
export const ConditionalAction: React.FC<Props> = props => {
    const hasCustomComponent = (type: string) => {
        const option = props.options.find(option => option.value === type);
        return !!option?.customValueComponent;
    };

    const renderCustomComponent = (type: string) => {
        const innerProps = {
            placeholder: 'Введите значние',
            value: props.value?.parameter,
            onChange: ({ currentTarget: { value } }) => {
                props.onChange({
                    type: props.value.type,
                    parameter: value,
                });
            },
        };
        const option = props.options.find(option => option.value === type);
        if (!option?.customValueComponent) return null;
        switch (option.customValueComponent) {
            case 'TextInput': {
                return <Input {...innerProps} />;
            }
            case 'TextArea': {
                return <Input.TextArea rows={1} {...innerProps} />;
            }
            case 'NumberInput': {
                return (
                    <InputNumber
                        {...innerProps}
                        onChange={value => {
                            props.onChange({
                                type: props.value.type,
                                parameter: value,
                            });
                        }}
                    />
                );
            }
            case 'Selector': {
                return (
                    <Select
                        {...innerProps}
                        onChange={value => {
                            props.onChange({
                                type: props.value.type,
                                parameter: value,
                            });
                        }}
                        options={[
                            {
                                value: '1',
                                label: '1 день',
                            },
                            {
                                value: '7',
                                label: 'Неделя',
                            },
                            {
                                value: '30',
                                label: 'Месяц',
                            },
                            {
                                value: '180',
                                label: 'Пол года',
                            },
                            {
                                value: '365',
                                label: 'Год',
                            },
                        ]}
                    />
                );
            }
            default: {
                console.error('Not implemented');
            }
        }
    };

    return (
        <Fragment>
            <Typography.Title level={5}>Действие</Typography.Title>
            <StyledWrap>
                <Select
                    style={{ minWidth: '200px' }}
                    value={props.value?.type}
                    onChange={value => props.onChange({ type: value })}
                    defaultValue={props.options[0]?.value}
                    options={props.options}
                />
                {hasCustomComponent && renderCustomComponent(props.value?.type)}
            </StyledWrap>
            <Divider style={{ margin: '33px 0' }} />
        </Fragment>
    );
};

const StyledWrap = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 0;

    @media (max-width: 576px) {
        flex-direction: column;
    }
`;
