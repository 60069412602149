import React from 'react';
import Chart, { Props as ApexProps } from 'react-apexcharts';

import { useGetSemanticsQuery } from 'features/analytics/services';

import { ChartWrapper } from '../ChartWrapper';

type Props = {
    readonly period?: string;
    readonly community_id: string;
    readonly height?: number;
};

export const MessageTopicsChart: React.FC<Props> = props => {
    const { data: semantic = {}, isFetching } = useGetSemanticsQuery(
        {
            period: props.period,
            community_id: props.community_id,
        },
        { refetchOnMountOrArgChange: true }
    );

    const series = Object.entries(semantic)
        .sort((a, b) => (a[1] > b[1] ? 1 : b[1] > a[1] ? -1 : 0))
        .reverse()
        .slice(0, 4);

    const options: ApexProps['options'] = {
        labels: [...series.map(s => s[0])],

        legend: {
            show: true,
            position: 'left',
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            },
        },
        theme: {
            monochrome: {
                enabled: true,
                color: '#597EF7',
            },
        },
    };

    return (
        <ChartWrapper isLoading={isFetching} title='Темы сообщений'>
            <Chart
                type='pie'
                height={props.height}
                options={options}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                series={[...series.map(s => s[1])]}
            />
        </ChartWrapper>
    );
};
