import React, { ReactElement } from 'react';
import { Typography, Image } from 'antd';
import styled from 'styled-components';

import { getSrc } from '../../../../../../utils/common/getSrc';
import { GenericModal } from '../../../../../../shared/ui/GenericModal';

interface IQuestionProps {
    readonly isOpen?: boolean;
    readonly setIsOpen?: (boolean) => void;
    data?: any;
}

export const QuestionContainer: React.FC<IQuestionProps> = (
    props
): ReactElement => {
    const { data, isOpen, setIsOpen } = props;

    return (
        <GenericModal
            width={600}
            open={isOpen}
            onCancel={() => {
                setIsOpen(false);
            }}
            onConfirm={() => setIsOpen(false)}
        >
            <StyledItem>
                <StyledTitle level={4}>Вопрос:</StyledTitle>
                <StyledText>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: data?.context,
                        }}
                    ></span>
                </StyledText>
                {data?.image && (
                    <Image width={'300px'} src={getSrc(data.image)} />
                )}
            </StyledItem>
            <StyledItem>
                <StyledTitle level={4}>Ответ:</StyledTitle>
                <StyledText>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: data?.answer?.context,
                        }}
                    ></span>
                </StyledText>
                {data?.answer?.image && (
                    <Image width={'300px'} src={getSrc(data.answer.image)} />
                )}
            </StyledItem>
        </GenericModal>
    );
};

const StyledItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0.5rem;
`;

const StyledText = styled(Typography.Text)`
    margin-bottom: 0.5rem;
`;
