import React, { Fragment, useState } from 'react';
import { Input, InputNumber, Select } from 'antd';
import styled from 'styled-components';

import { RulesDict } from 'features/communities/interfaces/conditional-rules.interface';

type TProps = {
    readonly subject: string;
    readonly action: string;
    readonly value: {
        type: string;
        parameter?: string;
    };
    readonly dict: RulesDict;
    readonly onUpdate: (args: {
        subject?: string;
        action?: string;
        value?: {
            type: string;
            parameter?: string;
        };
    }) => void;
};

export const ExpressionNode: React.FC<TProps> = props => {
    const { actions = [], subjects = [], values = [] } = props.dict;

    const [customValue, setCustomValue] = useState(
        props.value?.parameter || ''
    );

    const hasCustomSelect = props.value?.type === 'custom';

    const renderCustomInput = (subjectType: string) => {
        const inputProps = {
            value: customValue,
            onChange: ({ currentTarget: { value } }) => {
                setCustomValue(value);
            },
            onBlur: () => {
                props.onUpdate({
                    value: {
                        type: props.value.type as any,
                        parameter: customValue as any,
                    },
                });
            },
            placeholder: 'Введите значение',
        };

        const customValueComponent = props.dict.subjects.find(
            subject => subject.name === subjectType
        )?.customValueComponent;

        switch (customValueComponent) {
            case 'TextInput': {
                return <Input {...inputProps} />;
            }
            case 'NumberInput': {
                return (
                    <InputNumber
                        {...inputProps}
                        onChange={value => {
                            setCustomValue(value);
                        }}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <Fragment>
            <StyledSelect
                value={props.subject}
                options={subjects.map(mapDictToOptions)}
                onChange={(subject: string) => {
                    props.onUpdate({
                        subject,
                        action: null,
                        value: null,
                    });
                }}
                placeholder='Выбрать'
            />
            <StyledSelect
                value={props.action}
                options={actions
                    .map(mapDictToOptions)
                    .filter(a => a.allowedSubjects.includes(props.subject))}
                onChange={(action: string) => {
                    props.onUpdate({
                        action,
                        value: null,
                    });
                }}
                placeholder='Выбрать'
            />
            <StyledSelect
                value={props.value?.type}
                options={values
                    .map(mapDictToOptions)
                    .filter(
                        a =>
                            a.allowedActions.includes(props.action) &&
                            a.allowedSubjects.includes(props.subject)
                    )}
                onChange={(value: string) => {
                    props.onUpdate({
                        value: {
                            type: value,
                        },
                    });
                }}
                placeholder='Выбрать'
            />
            {hasCustomSelect && renderCustomInput(props.subject)}
        </Fragment>
    );
};

const StyledSelect = styled(Select)`
    min-width: 200px;
`;

const mapDictToOptions = option => {
    return {
        ...option,
        label: option.label,
        value: option.name,
    };
};
