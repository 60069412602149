import React, { FC, useEffect } from 'react';
import {
    App,
    Col,
    Input,
    InputNumber,
    Row,
    Space,
    Switch,
    Typography,
} from 'antd';
import styled from 'styled-components';

import { DonateSwitchesEnum } from 'features/monetization/pages/donate/DonateMapper';

interface IProps {
    control:
        | DonateSwitchesEnum.fixedAmountSwitch1
        | DonateSwitchesEnum.fixedAmountSwitch2
        | DonateSwitchesEnum.fixedAmountSwitch3;
    formik: any;
}

export const FixedAmountSwitch: FC<IProps> = props => {
    const { notification } = App.useApp();

    const { formik } = props;

    useEffect(() => {
        if (formik.values.switches[props.control]?.sum < 50) {
            notification.info({
                message: 'Минимальная сумма должны быть больше 50 рублей',
            });
        }
    }, [formik.values.switches[props.control]?.sum]);

    return (
        <Row
            gutter={[20, 20]}
            style={{ marginBottom: '1.5rem', width: '100%' }}
        >
            <Space align='start'>
                <StyledSwitch
                    checked={formik.values.switches[props.control].isChecked}
                    onChange={value => {
                        formik.setFieldValue(
                            `switches.${props.control}.isChecked`,
                            value
                        );
                    }}
                />
                <StyledTitle level={5}>Фиксированная сумма</StyledTitle>
            </Space>

            {formik.values.switches[props.control].isChecked && (
                <Col xs={22} span={24} style={{ marginLeft: 55 }}>
                    <div>
                        <div>Значение (₽):</div>
                        <div>
                            <InputNumber
                                status={
                                    formik.values.switches[props.control]?.sum <
                                    50
                                        ? 'error'
                                        : null
                                }
                                min={50}
                                placeholder='1000'
                                value={
                                    formik.values.switches[props.control]?.sum
                                }
                                onChange={value => {
                                    formik.setFieldValue(
                                        `switches.${props.control}.sum`,
                                        value
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <Row gutter={[10, 10]} style={{ marginTop: '1rem' }}>
                        <Col span={20}>
                            <Typography.Text>Текст на кнопке</Typography.Text>
                        </Col>
                        <Col xs={20}>
                            <Input.TextArea
                                placeholder='Введите текст для отображения на кнопке'
                                style={{ width: 550 }}
                                rows={4}
                                value={
                                    formik.values.switches[props.control]?.text
                                }
                                onChange={event => {
                                    formik.setFieldValue(
                                        `switches.${props.control}.text`,
                                        event.target.value
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
};

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
