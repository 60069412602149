import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { Button, Space, Typography } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { blobToBase64 } from '../../../../../utils/common/blobToBase64';
import { Question } from './components/Question';
import { Answer } from './components/Answer';
import { Dropdown } from '../../../../../shared/ui/Dropdown';
import {
    useReadCategoryQuery,
    useReadKnowledgeByIdQuery,
} from '../../../services';
import { HeaderScenarios } from '../../HeaderScenarios/HeaderScenarios';
import { IQuestionFormProps } from '../../../interfaces/questions.interface';
import { useToggle } from '../../../../../hooks/useToggle';

export const QuestionForm: FC<IQuestionFormProps> = props => {
    const navigate = useNavigate();
    const { open, setOpen } = useToggle(false);

    const [questionImg, setQuestionImg] = useState<
        ArrayBuffer | string | File | null
    >(props.initialValues.question_image);
    const [answerImg, setAnswerImg] = useState<
        ArrayBuffer | string | File | null
    >(props.initialValues.answer_image);
    const questionImgRef = useRef(null);
    const answerImgRef = useRef(null);

    useEffect(() => {
        if (questionImgRef.current) {
            blobToBase64(questionImgRef.current).then(val =>
                setQuestionImg(val)
            );
        }
        if (answerImgRef.current) {
            blobToBase64(answerImgRef.current).then(val => setAnswerImg(val));
        }
    }, [questionImgRef.current, answerImgRef.current]);

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: props.onSave,
    });

    const { data: source = { items: [], total: 0 } } = useReadCategoryQuery({
        knowledge_id: props.initialValues.knowledge_id,
    });

    const { data: knowledge } = useReadKnowledgeByIdQuery(
        props.initialValues.knowledge_id
    );

    return (
        <div style={{ marginTop: 32 }}>
            <HeaderScenarios
                inputValue={props.isAi ? 'Вопрос' : knowledge?.items?.name}
                withoutEdit
            />

            <ContainerWrapper>
                <StyledDropdown
                    destroyPopupOnHide
                    trigger={['click']}
                    overlayStyle={{
                        height: '300px',
                        overflow: 'auto',
                    }}
                    dropdownContent={() =>
                        source.items.length === 0 ? (
                            <Typography.Text>
                                В базе знаний нет категорий
                            </Typography.Text>
                        ) : (
                            source.items.map((item, index) => (
                                <Button
                                    key={index}
                                    type='text'
                                    onClick={() => {
                                        formik.setFieldValue(
                                            `category_name`,
                                            item.name
                                        );
                                        formik.setFieldValue(
                                            `category_id`,
                                            item.id
                                        );
                                        setOpen(false);
                                    }}
                                >
                                    {item.name}
                                </Button>
                            ))
                        )
                    }
                >
                    <Button onClick={() => setOpen(!open)} type='default'>
                        {formik.values?.category_name
                            ? 'Выбрана категория: ' +
                              formik.values?.category_name
                            : 'Укажите категорию'}
                        {open ? <UpOutlined /> : <DownOutlined />}
                    </Button>
                </StyledDropdown>

                <Question
                    formik={formik}
                    imgRef={questionImgRef}
                    img={questionImg}
                />

                <Answer formik={formik} imgRef={answerImgRef} img={answerImg} />

                <ContainerWrapper>
                    <Space size='large'>
                        <Button
                            type={'primary'}
                            loading={props.loading}
                            onClick={() => formik.handleSubmit()}
                            htmlType='submit'
                        >
                            Сохранить
                        </Button>
                        <Button onClick={() => navigate(-1)}>Отмена</Button>
                    </Space>
                </ContainerWrapper>
            </ContainerWrapper>
        </div>
    );
};

const ContainerWrapper = styled.div`
    display: block;
    margin-top: 32px;
    margin-bottom: 33px;
`;

const StyledDropdown = styled(Dropdown)`
    cursor: pointer;
    margin: 1rem 0;
    overflow-y: auto;
    max-height: 300px;
    width: 300px;
`;
