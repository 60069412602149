import React from 'react';
import styled from 'styled-components';

import { getSrc } from '../../../../../../../utils/common/getSrc';

type TProps = {
    readonly src: string;
    readonly edit?: boolean;
};
export const AudioView: React.FC<TProps> = props => {
    const { src, edit } = props;

    return (
        <StyledAudio autoPlay={false} controls={true} preload={'metadata'}>
            <source src={getSrc(edit ? src : `/storage/${src}`)} />
        </StyledAudio>
    );
};

const StyledAudio = styled.audio`
    width: 640px;
`;
