export const Subjects = [
    {
        name: 'message_content',
        label: 'Содержание сообщения',
    },
];

export const Actions = [
    {
        name: 'substr',
        label: 'содержит',
        allowedSubjects: ['message_content'],
    },
];

export const Values = [
    {
        name: 'link',
        label: 'cсылка',
        allowedSubjects: ['message_content'],
        allowedActions: ['substr'],
    },
];

export const conditionMapper = {
    AND: 'И',
    OR: 'ИЛИ',
};
