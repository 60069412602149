import React, { FC, useState } from 'react';
import { Checkbox, Input, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { GenericModal } from '../../../../../../shared/ui/GenericModal';
import { payModalCheckbox } from '../../utils/tariff';

interface IProps {
    tariff: TTariff;
    isOpenModal: boolean;
    setIsOpenModal: (value: boolean) => void;
    onConfirm: (values: { email: string }) => void;
    readonly isTrial: string;
}

type TTariff = {
    readonly created_at: Date;
    readonly title: string;
    readonly price: number;
};

export const TariffConfirmModal: FC<IProps> = props => {
    const { isTrial, tariff } = props;

    const [email, setEmail] = useState('');

    const [checked, setChecked] = useState([]);
    const all = [1, 2, 3, 4, 5, 6];
    const handleExpiredTime = () => {
        return dayjs(tariff.created_at).add(1, 'month').format('DD.MM.YYYY');
    };

    const handleChangeCheckbox = (id: number) => {
        setChecked([...checked, id]);

        if (checked.includes(id)) {
            return setChecked(checked.filter(elem => elem !== id));
        }

        if (id === 0) {
            return setChecked([0, ...all]);
        }
    };

    return (
        <GenericModal
            title={'Подтвердите тариф'}
            width={380}
            open={props.isOpenModal}
            okText={isTrial ? 'Подтвердить' : 'Оплатить'}
            okButtonProps={{
                danger: true,
                disabled: email === '' || !email || checked?.length < 7,
            }}
            onCancel={() => {
                props.setIsOpenModal(false);
            }}
            onConfirm={() =>
                props.onConfirm({
                    email,
                })
            }
        >
            <Space direction={'vertical'} style={{ marginBottom: 8 }}>
                {isTrial ? (
                    <Typography.Text>
                        Пробный доступ на 3 дня к тарифу
                        <div>
                            «{tariff.title}» до {handleExpiredTime()}
                        </div>
                    </Typography.Text>
                ) : (
                    <Typography.Text>
                        Тариф «{tariff.title}» до {handleExpiredTime()}
                    </Typography.Text>
                )}
            </Space>

            {!isTrial && (
                <Typography.Title level={3}>{tariff.price} ₽</Typography.Title>
            )}

            <Space
                direction={'vertical'}
                style={{ width: '100%', margin: '1rem 0' }}
            >
                <Typography.Text>Почта</Typography.Text>
                <Input
                    value={email}
                    placeholder='ivan@example.com'
                    onChange={e => setEmail(e.target.value)}
                />
            </Space>

            <Space direction={'vertical'}>
                <Checkbox
                    style={{ marginBottom: 12 }}
                    onChange={() => handleChangeCheckbox(0)}
                    checked={checked.indexOf(0) !== -1}
                >
                    Подтверждаю ознакомление с правилами
                </Checkbox>

                {payModalCheckbox.map((item, index) => (
                    <StyledCheckbox
                        key={index}
                        checked={checked.indexOf(item.id) !== -1}
                        onChange={() => handleChangeCheckbox(item.id)}
                    >
                        {item.name}
                    </StyledCheckbox>
                ))}

                {!isTrial && (
                    <Typography.Text>
                        Вы будете перенаправлены на страницу платёжной системы.
                    </Typography.Text>
                )}
            </Space>
        </GenericModal>
    );
};

const StyledCheckbox = styled(Checkbox)`
    margin-left: 1rem;
`;
