import React, { useState } from 'react';
import { App } from 'antd';

import { IChatMemberQueryParams } from 'features/communities/interfaces';
import {
    useReadChatMembersQuery,
    useRemoveUserFromChatsMutation,
} from 'features/communities/services';

import { AllMembersTable } from '../views/AllMembersTable';

export const AllMembersPage: React.FC = () => {
    const { notification } = App.useApp();

    const [filters, setFilters] = useState<IChatMemberQueryParams>({
        community_id: null,
    });

    const { data: source = { items: [], total: 0 }, isFetching } =
        useReadChatMembersQuery(filters);

    const [deleteUserFromChats, { isLoading: isDeleteLoading }] =
        useRemoveUserFromChatsMutation();

    const handleFiltersUpdate = (update: IChatMemberQueryParams | null) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({});
        }
    };

    const onBulkRemove = async (accounts: number[]) => {
        await Promise.all(accounts.map(deleteUserFromChats));
        if (accounts?.length === 1) {
            return notification.success({
                message: 'Участник успешно удален',
                placement: 'top',
            });
        } else
            notification.success({
                message: 'Участники успешно удалены',
                placement: 'top',
            });
    };

    return (
        <AllMembersTable
            onBulkRemove={onBulkRemove}
            filters={filters}
            onFiltersUpdate={handleFiltersUpdate}
            loading={isFetching || isDeleteLoading}
            source={source}
        />
    );
};
