import React, { FC, Fragment, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { Button, Checkbox, Divider, Space, Typography } from 'antd';

import { TariffChatList } from 'features/monetization/pages/tariff/views/components/TariffChatsList';

import { HeaderScenarios } from '../../../../communities/components/HeaderScenarios/HeaderScenarios';
import { PaidAccessSwitch } from './components/PaidAccessSwitch';
import { TariffSwitchesEnum } from '../TariffMappter';
import { DemoAccessSwitch } from './components/DemoAccessSwitch';
import { GenericModal } from '../../../../../shared/ui/GenericModal';

interface IProps {
    initialValues: any;
    onSave: (values) => void;
    onDelete?: () => Promise<void>;
    tariffHref?: string;
    command?: string;
    isCreate?: boolean;
}

export const TariffForm: FC<IProps> = props => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [checkBoxStatus, setCheckboxStatus] = useState(false);

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: props.onSave,
    });

    return (
        <Fragment>
            <GenericModal
                title={'Внимание!'}
                width={380}
                open={isOpenModal}
                okText={'Удалить'}
                okButtonProps={{ danger: true }}
                onCancel={() => setIsOpenModal(false)}
                onConfirm={() => {
                    props.onDelete().then(() => {
                        setIsOpenModal(false);
                    });
                }}
            >
                <Space direction={'vertical'}>
                    <Typography.Text>
                        Вы собираетесь удалить тариф.
                    </Typography.Text>

                    <Typography.Text>
                        Со всех участников, перестанет взиматься плата за
                        нахождение в сообществе.
                    </Typography.Text>
                </Space>
            </GenericModal>
            <ContainerWrapper>
                <HeaderScenarios
                    inputValue={formik.values.title}
                    onChangeHeaderInput={event => {
                        formik.setFieldValue('title', event.target.value);
                    }}
                />
                <Space
                    direction='vertical'
                    size='large'
                    style={{ width: '100%' }}
                >
                    <PaidAccessSwitch
                        control={TariffSwitchesEnum.paidAccess}
                        formik={formik}
                        tariffHref={props.tariffHref}
                        command={props.command}
                    />

                    <DemoAccessSwitch
                        control={TariffSwitchesEnum.demoAccess}
                        formik={formik}
                    />

                    {/*<MessageAfterPaySwitch
                        control={TariffSwitchesEnum.messageAfterPay}
                        formik={formik}
                    />*/}

                    <TariffChatList
                        selectedRows={[formik.values.community_id]}
                        setSelectedRows={values => {
                            formik.setFieldValue('community_id', values[0]);
                        }}
                    />
                </Space>
            </ContainerWrapper>
            <WarningText direction={'vertical'}>
                <Typography.Text>Внимание!</Typography.Text>
                <Typography.Text>
                    В сообществе есть участники, которые не будут автоматически
                    удалены. Вопрос оплаты за нахождение в сообществе решается с
                    ними самостоятельно, используя страницу и сообщение с
                    информацией об оплате.
                </Typography.Text>
                <Typography.Text>
                    Платный доступ начинает действовать только для новых
                    участников, которые после включения тарифа добавятся в
                    сообщество через оплату.
                </Typography.Text>
            </WarningText>
            <div style={{ margin: '24px 0' }}>
                <Checkbox
                    checked={checkBoxStatus}
                    onChange={() => setCheckboxStatus(!checkBoxStatus)}
                >
                    Я понимаю последствия
                </Checkbox>
            </div>

            <Divider />

            <Space size='large'>
                <Button
                    type='primary'
                    onClick={formik.submitForm}
                    disabled={!checkBoxStatus}
                    htmlType='submit'
                >
                    Сохранить
                </Button>

                {!props.isCreate && (
                    <Button
                        danger
                        onClick={() => {
                            setIsOpenModal(true);
                        }}
                    >
                        Удалить
                    </Button>
                )}
            </Space>
        </Fragment>
    );
};

const WarningText = styled(Space)``;

const ContainerWrapper = styled.div`
    display: block;
    margin-top: 32px;
    margin-bottom: 33px;
`;
