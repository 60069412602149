import { getSrc, prepareSrc } from '../../../../utils/common/getSrc';
import { IFormikInitialValues } from './containers/DonateContainerEdit';

export enum DonateSwitchesEnum {
    receiveDonationsSwitch = 'receiveDonationsSwitch',
    arbitraryAmountSwitch = 'arbitraryAmountSwitch',
    fixedAmountSwitch1 = 'fixedAmountSwitch1',
    fixedAmountSwitch2 = 'fixedAmountSwitch2',
    fixedAmountSwitch3 = 'fixedAmountSwitch3',
}

export class DonateMapper {
    private static convertSwitchesMap = new Map<
        DonateSwitchesEnum,
        (value) => any
    >([
        [
            DonateSwitchesEnum.receiveDonationsSwitch,
            value => {
                return {
                    donate_is_active: value.isChecked || false,
                    image: prepareSrc(value?.image) || '',
                    description: value?.description || '',
                    command: value?.command || '',
                };
            },
        ],
        [
            DonateSwitchesEnum.arbitraryAmountSwitch,
            value => {
                return {
                    random_sum_is_active: value.isChecked,
                    random_sum_button: value.text || '',
                    random_sum_max: value.max_price,
                    random_sum_min: value.min_price,
                };
            },
        ],
        [
            DonateSwitchesEnum.fixedAmountSwitch1,
            value => {
                return {
                    fix_sum_1_is_active: value.isChecked,
                    fix_sum_1_button: value.text || '',
                    fix_sum_1: value.sum,
                };
            },
        ],
        [
            DonateSwitchesEnum.fixedAmountSwitch2,
            value => {
                return {
                    fix_sum_2_is_active: value.isChecked,
                    fix_sum_2_button: value.text || '',
                    fix_sum_2: value.sum,
                };
            },
        ],
        [
            DonateSwitchesEnum.fixedAmountSwitch3,
            value => {
                return {
                    fix_sum_3_is_active: value.isChecked,
                    fix_sum_3_button: value.text || '',
                    fix_sum_3: value.sum,
                };
            },
        ],
    ]);
    public static createDto(values: IFormikInitialValues) {
        const arraySwitches = Object.entries(values.switches) as [
            DonateSwitchesEnum,
            any
        ][];
        const arrayCheckedSwitches = arraySwitches.filter(([_, value]) => {
            return value.isChecked;
        });
        const requestObject = {
            title: values.title,
        };
        if (arrayCheckedSwitches.length) {
            arrayCheckedSwitches.forEach(([key, value]) => {
                if (this.convertSwitchesMap.get(key)) {
                    Object.assign(
                        requestObject,
                        this.convertSwitchesMap.get(key)(value)
                    );
                }
            });
        }
        Object.assign(
            requestObject,
            this.convertSwitchesMap.get(
                DonateSwitchesEnum.receiveDonationsSwitch
            )(values.switches[DonateSwitchesEnum.receiveDonationsSwitch])
        );
        return requestObject;
    }
    public static convertDto(data): IFormikInitialValues {
        const arbitraryAmount = data.variants.find(
            el => el.variant_name === 'random_sum'
        );
        const fixedAmount1 = data.variants.find(
            el => el.variant_name === 'fix_sum_1'
        );
        const fixedAmount2 = data.variants.find(
            el => el.variant_name === 'fix_sum_2'
        );
        const fixedAmount3 = data.variants.find(
            el => el.variant_name === 'fix_sum_3'
        );
        return {
            title: data.title,
            command: data.command,
            switches: {
                [DonateSwitchesEnum.receiveDonationsSwitch]: {
                    isChecked: data.donate_is_active,
                    ...(data.description && { description: data.description }),
                    ...(data.image && { image: getSrc(data.image) }),
                },
                [DonateSwitchesEnum.arbitraryAmountSwitch]: {
                    isChecked: arbitraryAmount?.random_sum_is_active,
                    ...(arbitraryAmount?.random_sum_button && {
                        text: arbitraryAmount.random_sum_button,
                    }),
                    ...(arbitraryAmount?.max_price && {
                        max_price: arbitraryAmount.max_price,
                    }),
                    ...(arbitraryAmount?.min_price && {
                        min_price: arbitraryAmount.min_price,
                    }),
                },
                [DonateSwitchesEnum.fixedAmountSwitch1]: {
                    isChecked: fixedAmount1?.fix_sum_1_is_active,
                    ...(fixedAmount1?.fix_sum_1_button && {
                        text: fixedAmount1.fix_sum_1_button,
                    }),
                    ...(fixedAmount1?.fix_sum_1 && {
                        sum: fixedAmount1.fix_sum_1,
                    }),
                },
                [DonateSwitchesEnum.fixedAmountSwitch2]: {
                    isChecked: fixedAmount2?.fix_sum_2_is_active,
                    ...(fixedAmount2?.fix_sum_2_button && {
                        text: fixedAmount2.fix_sum_2_button,
                    }),
                    ...(fixedAmount2?.fix_sum_2 && {
                        sum: fixedAmount2.fix_sum_2,
                    }),
                },
                [DonateSwitchesEnum.fixedAmountSwitch3]: {
                    isChecked: fixedAmount3?.fix_sum_3_is_active,
                    ...(fixedAmount3?.fix_sum_3_button && {
                        text: fixedAmount3.fix_sum_3_button,
                    }),
                    ...(fixedAmount3?.fix_sum_3 && {
                        sum: fixedAmount3.fix_sum_3,
                    }),
                },
            },
        };
    }
}
