import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Input, Typography, Upload } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';

import { validateImages } from '../../../../../utils/common/validateImages';
import { getSrc } from '../../../../../utils/common/getSrc';
import { blobToBase64 } from '../../../../../utils/common/blobToBase64';
import { GenericModal } from '../../../../../shared/ui/GenericModal';
import { AuthorDeleteContainer } from '../containers/author/AuthorDeleteContainer';

const { TextArea } = Input;
const { Text } = Typography;

export const AuthorForm = props => {
    const [photoImg, setPhotoImg] = useState(null);

    const photoImgRef = useRef(null);

    useEffect(() => {
        if (photoImgRef.current) {
            blobToBase64(photoImgRef.current).then(val => setPhotoImg(val));
        }
    }, [photoImgRef.current]);

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: props.onSave,
    });

    const disabled = !formik.values.photo || formik.values.name === '';

    return (
        <Fragment>
            <GenericModal
                onCancel={() => props.setOpen(false)}
                title={'Редактировать данные'}
                open={props.open}
                onConfirm={() => formik.handleSubmit()}
                okButtonProps={{
                    disabled: disabled,
                }}
                footer={[
                    props.isCreate ? null : (
                        <StyledFooter key={'del'}>
                            <Text type='secondary'>Устали быть автором?</Text>
                            <Button
                                onClick={() => props.setOpenDel(true)}
                                type={'link'}
                                style={{ paddingLeft: 0 }}
                            >
                                Удалить страницу
                            </Button>
                        </StyledFooter>
                    ),
                ]}
            >
                <StyledItem>
                    <StyledLabel>Фото профиля*</StyledLabel>

                    <Upload
                        maxCount={1}
                        listType='picture-card'
                        className='avatar-uploader'
                        showUploadList={false}
                        beforeUpload={() => false}
                        onChange={value => {
                            if (validateImages(value.file)) {
                                photoImgRef.current = value.file;
                                formik.setFieldValue(
                                    `photo`,
                                    photoImgRef.current
                                );
                            }
                        }}
                    >
                        {formik?.values?.photo ? (
                            <StyledImage
                                src={
                                    photoImg ||
                                    getSrc(`storage/${formik?.values?.photo}`)
                                }
                                alt={'Spodial author avatar'}
                            />
                        ) : (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Загрузить</div>
                            </div>
                        )}
                    </Upload>
                </StyledItem>

                <StyledItem>
                    <StyledLabel>Имя*</StyledLabel>
                    <Input
                        value={formik?.values?.name}
                        placeholder='Ваше имя'
                        onChange={event => {
                            formik.setFieldValue(`name`, event.target.value);
                        }}
                    />
                </StyledItem>

                <StyledItem>
                    <StyledLabel>О себе</StyledLabel>
                    <StyledTextArea
                        rows={5}
                        autoSize={{
                            maxRows: 15,
                        }}
                        showCount
                        maxLength={300}
                        value={formik?.values?.about}
                        onChange={event => {
                            formik.setFieldValue(`about`, event.target.value);
                        }}
                        placeholder='Кратко расскажите о себе'
                    />
                </StyledItem>
            </GenericModal>
            {props.isEdit && props.openDel ? (
                <AuthorDeleteContainer
                    open={props.openDel}
                    setOpenDel={props.setOpenDel}
                    isCreate={props.isCreate}
                />
            ) : null}
        </Fragment>
    );
};

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 0.5rem;
`;

const StyledTextArea = styled(TextArea)`
    min-height: 200px;
    margin: 0 0 20px 0;
`;

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const StyledItem = styled.div`
    margin: 1rem 0;
`;

const StyledFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
