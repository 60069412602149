import React from 'react';
import styled from 'styled-components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Radio } from 'antd';

import getEnvConfig from 'utils/config/env';

const getMemberListType = (location: string): string => {
    return location.split('/').at(-1);
};

const isMemberDetailsView = (location: string): boolean => {
    return location.includes('views-member');
};

export const Members: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const currentListType = getMemberListType(location.pathname);

    // На странице информации о пользователе ( в каких чатах он состоит, не показывать фильтры БС, ЧС)
    const shouldShowRadioGroup = isMemberDetailsView(location.pathname);

    return (
        <StyledWrapper>
            {!shouldShowRadioGroup && (
                <StyledButtonRadioGroup
                    defaultValue={currentListType}
                    value={currentListType}
                    onChange={({ target }) => {
                        navigate(
                            `${
                                getEnvConfig().PUBLIC_PATH
                            }/communities/members/${target.value}`
                        );
                    }}
                >
                    <Radio.Button value='all-members'>
                        Все участники
                    </Radio.Button>
                    <Radio.Button value='blacklisted'>
                        Чёрный список
                    </Radio.Button>
                    <Radio.Button value='whitelisted'>
                        Белый список
                    </Radio.Button>
                </StyledButtonRadioGroup>
            )}

            <Outlet />
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const StyledButtonRadioGroup = styled(Radio.Group)`
    margin-left: auto;
    @media (max-width: 768px) {
        margin-bottom: 3rem;
    }
`;
