import React, { useEffect, useState } from 'react';
import { Space, Tabs, TabsProps } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';

import { Page } from 'shared/ui/Page';

import { WebinarsContainer } from './Webinars/WebinarsContainer';
import { PostsContainer } from './Posts/PostsContainer';
import { ReadersContainer } from './Readers/ReadersContainer';
import { Periods, TFilters } from '../services/interfaces';
import { PeriodFilter } from '../components/PeriodFilter';
import { DownloadButton } from '../components/DownloadButton';

export const MainPage: React.FC = () => {
    const [filters, setFilters] = useState<TFilters>({
        period: Periods.month,
    });
    const [activeTab, sstActiveTab] = useState<string>('readers');
    const navigate = useNavigate();

    useEffect(() => {
        navigate(activeTab);
    }, []);

    const tabs: TabsProps['items'] = [
        {
            label: 'Читатели',
            key: 'readers',
            children: (
                <ReadersContainer filters={filters} setFilters={setFilters} />
            ),
        },
        {
            label: 'Вебинары',
            key: 'webinars',
            children: (
                <WebinarsContainer filters={filters} setFilters={setFilters} />
            ),
        },
        {
            label: 'Посты',
            key: 'posts',
            children: (
                <PostsContainer filters={filters} setFilters={setFilters} />
            ),
        },
    ];
    const onChange = (key: string) => {
        navigate(key);
        sstActiveTab(key);
    };

    const extra: JSX.Element = (
        <Space>
            <PeriodFilter setFilters={setFilters} />
            <DownloadButton type={activeTab} />
        </Space>
    );

    return (
        <Page title='Аналитика'>
            <Tabs
                defaultActiveKey={activeTab}
                onChange={onChange}
                items={tabs}
                tabBarExtraContent={extra}
            />
            <Outlet />
        </Page>
    );
};
