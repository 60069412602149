import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';

type Props = {
    readonly icon?: React.ReactNode;
    readonly text?: string;
    readonly children?: React.ReactNode;
    readonly onClick?: () => void;
};
export const BackButton: React.FC<Props> = props => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (!props.onClick) {
            navigate(-1);
        } else {
            props.onClick();
        }
    };

    return (
        <StyledBackButton onClick={handleClick}>
            <StyledIcon>{props.icon || <ArrowLeftOutlined />}</StyledIcon>
            <StyledText>{props.text}</StyledText>
            {props.children}
        </StyledBackButton>
    );
};

const StyledBackButton = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;
    cursor: pointer;
`;
const StyledIcon = styled.div``;
const StyledText = styled.span`
    font-size: 20px;
    font-weight: bold;
`;
