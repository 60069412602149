import React from 'react';
import styled from 'styled-components';

import { IStatisticFilters } from 'features/analytics/services/interfaces';
import { ChatListContainer } from 'features/analytics/components/ChatList/containers/ChatListContainer';
import { FinanceChart } from 'features/analytics/components/Charts/FinanceChart';

import { FinanceTableContainer } from './containers/FinanceTableContainer';

type Props = {
    readonly filters: IStatisticFilters;
    readonly onFiltersUpdate: (update: Partial<IStatisticFilters>) => void;
};

export const FinanceLayout: React.FC<Props> = props => {
    return (
        <StyledWrapper>
            <StyledCharts>
                <FinanceChart height={150} filters={props.filters} />
                <div style={{ flex: '1 0 49%' }} />
            </StyledCharts>

            <FinanceTableContainer filters={props.filters} />
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const StyledCharts = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
`;
