import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';

import { OnboardingForm } from 'features/communities/components/Onboarding/views/OnboardingForm';
import { IFormikInitialValues } from 'features/communities/components/Onboarding/containers/OnboardingContainerCreate';
import { OnboardingMapper } from 'features/communities/components/Onboarding/OnboardingMapper';

import {
    useReadOnboardingQuery,
    useUpdateOnboardingMutation,
} from '../../../services';
import { IUpdateOnboardingRequest } from '../../../interfaces/onboarding.interface';
import { createFormData } from '../../../../../utils/common/createFormData';

export const OnboardingContainerEdit: FC = () => {
    const { notification } = App.useApp();
    const params = useParams();
    const { data } = useReadOnboardingQuery(params.id);
    const [initialValues, setInitialValues] =
        useState<IFormikInitialValues | null>(null);

    const navigate = useNavigate();

    const [updateOnboarding, { isSuccess, error }] =
        useUpdateOnboardingMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Сценарий обновлен успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any)?.data?.message || 'Что-то не так',
                placement: 'top',
            });
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            setInitialValues(OnboardingMapper.convertDto(data));
        }
    }, [data]);

    const onSave = async (values: IFormikInitialValues) => {
        const requestObject: Partial<IUpdateOnboardingRequest> =
            OnboardingMapper.createDto(values);
        if (!(requestObject?.greeting_image instanceof File))
            delete requestObject.greeting_image;
        if (!(requestObject?.question_image instanceof File))
            delete requestObject.question_image;
        requestObject.onboarding_uuid = data.uuid;
        await updateOnboarding(createFormData(requestObject));
    };

    if (!params.id && !data) {
        return <></>;
    }

    return (
        <>
            {initialValues && (
                <OnboardingForm initialValues={initialValues} onSave={onSave} />
            )}
        </>
    );
};
