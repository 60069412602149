import { Badge, Button, Checkbox, Divider, Row, Space } from 'antd';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { Question } from '../../Questions/views/components/Question';
import { Answer } from '../../Questions/views/components/Answer';
import { KnowledgeSelect } from './components/KnowledgeSelect';
import { CategorySelect } from './components/CategorySelect';
import { HeaderScenarios } from '../../HeaderScenarios/HeaderScenarios';
import { CommunitySelect } from './components/CommunitySelect';
import { blobToBase64 } from '../../../../../utils/common/blobToBase64';
import { useWindowSize } from '../../../../../hooks/useWindowSize';

type Props = {
    readonly loading?: boolean;
    readonly initialValues: TValues;
    readonly onSave: (values) => void;
    readonly onDelete: (id) => void;
};

type TValues = {
    context: string;
    knowledge: null;
    category: null;
    community: null;
};

export const AIKnowledgeForm: React.FC<Props> = props => {
    const navigate = useNavigate();
    const { windowSize } = useWindowSize();

    const [checkboxStatus, setCheckboxStatus] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const questionImgRef = useRef(null);
    const answerImgRef = useRef(null);
    const [questionImg, setQuestionImg] = useState<
        ArrayBuffer | string | File | null
    >();
    const [answerImg, setAnswerImg] = useState<
        ArrayBuffer | string | File | null
    >();

    useEffect(() => {
        if (questionImgRef.current) {
            blobToBase64(questionImgRef.current).then(val =>
                setQuestionImg(val)
            );
        }
        if (answerImgRef.current) {
            blobToBase64(answerImgRef.current).then(val => setAnswerImg(val));
        }
    }, [questionImgRef.current, answerImgRef.current]);

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: props.onSave,
    });

    useEffect(() => {
        if (
            formik.values.answer_text === undefined ||
            formik.values.answer_text === '<p><br></p>' ||
            props.loading
        ) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [formik.values.answer_text]);

    return (
        <StyledWrapper>
            <HeaderScenarios inputValue={'Вопрос'} withoutEdit />

            <CommunitySelect formik={formik} />
            <KnowledgeSelect formik={formik} />
            <CategorySelect formik={formik} />

            <Question
                formik={formik}
                imgRef={questionImgRef}
                img={questionImg}
            />
            <Answer formik={formik} imgRef={answerImgRef} img={answerImg} />

            <Fragment>
                <Divider />
                <Space direction={'vertical'} size={'large'}>
                    {formik.values.knowledge ? (
                        <Badge
                            color='orange'
                            text={`После сохранения вопрос продублируется в базу знаний
                            «${formik.values.knowledge.value}», и поменяет статус
                            на «Опубликовано»`}
                        />
                    ) : (
                        <Badge
                            color='orange'
                            text={`После сохранения вопрос продублируется в базу знаний, и поменяет статус
                            на «Опубликовано»`}
                        />
                    )}
                    <Checkbox
                        onClick={() => setCheckboxStatus(!checkboxStatus)}
                    >
                        Я понимаю последствия
                    </Checkbox>
                </Space>

                <StyledActions>
                    <Button
                        style={{
                            width: windowSize.width < 576 ? '100%' : 'auto',
                        }}
                        disabled={!checkboxStatus || isDisabled}
                        type={'primary'}
                        onClick={() => formik.submitForm()}
                    >
                        Сохранить
                    </Button>

                    <Button
                        style={{
                            width: windowSize.width < 576 ? '100%' : 'auto',
                        }}
                        onClick={() => navigate(-1)}
                    >
                        Отмена
                    </Button>

                    <Button
                        style={{
                            width: windowSize.width < 576 ? '100%' : 'auto',
                        }}
                        onClick={() => {
                            navigate(-1);
                            props.onDelete(formik.values.id);
                        }}
                        danger
                    >
                        Удалить
                    </Button>
                </StyledActions>
            </Fragment>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 24px;
`;

const StyledActions = styled(Row)`
    padding: 12px 0;
    gap: 24px;
`;
