import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useLocation } from 'react-router-dom';

import { Card } from '../../components/Card';
import { options } from '../../utils/readerChartOptions';
import {
    useLazyReadReadersQuery,
    useReadReadersQuery,
} from '../../services/readers';
import { IPageProps } from '../../services/interfaces';

export const ReadersContainer: React.FC<IPageProps> = props => {
    const { pathname } = useLocation();
    const { filters } = props;

    const [getReaders, { data: series, isSuccess }] = useLazyReadReadersQuery();

    useEffect(() => {
        if (pathname === '/courses/analytics/readers') {
            getReaders(filters);
        }
    }, [pathname, filters]);

    const genderLabels = series?.original?.genders.map(g => g.gender_range);
    const genderSeries = series?.original?.genders.map(g => g.users_count);

    const ageLabels = series?.original?.agesRanges.map(a => a.age_range);
    const ageSeries = series?.original?.agesRanges.map(a => a.users_count);

    const countryLabels = series?.original?.countries.map(c => c.country);
    const countrySeries = series?.original?.countries.map(c => c.users_count);

    return (
        isSuccess && (
            <Row wrap gutter={[30, 30]}>
                <Col span={12}>
                    <Card
                        options={options}
                        data={genderSeries}
                        labels={genderLabels}
                        title={'Пол'}
                        type={'donut'}
                    />
                </Col>
                <Col span={12}>
                    <Card
                        options={options}
                        data={ageSeries}
                        labels={ageLabels}
                        title={'Возраст'}
                        type={'donut'}
                    />
                </Col>
                <Col span={12}>
                    <Card
                        options={options}
                        data={countrySeries}
                        labels={countryLabels}
                        title={'Страна'}
                        type={'donut'}
                    />
                </Col>
            </Row>
        )
    );
};
