import React, { useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import { Outlet } from 'react-router-dom';

import { HistoryContainer } from './HistoryContainer';
import { FavoritesContainer } from './FavoritesContainer';
import { Page } from '../../../../../shared/ui/Page';

export const MemberContainer = () => {
    const [active, setActive] = useState<string>('history');

    const items: TabsProps['items'] = [
        {
            key: 'history',
            label: `История`,
            children: <HistoryContainer />,
        },
        {
            key: 'favorites',
            label: `Избранное`,
            children: <FavoritesContainer />,
        },
    ];

    return (
        <Page title={'Вы смотрите'}>
            <Tabs
                animated={true}
                defaultActiveKey={active}
                items={items}
                onChange={(key: string) => setActive(key)}
            />
            <Outlet />
        </Page>
    );
};
