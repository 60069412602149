import React from 'react';
import { Input } from 'antd';

export const Search = props => {
    const { Search } = Input;
    const { onSearch } = props;

    return (
        <Search
            onChange={e => onSearch(e.target.value)}
            onSearch={e => onSearch(e)}
            placeholder='Введите вопрос'
        />
    );
};
