import React from 'react';
import Chart, { Props as ApexProps } from 'react-apexcharts';
import dayjs from 'dayjs';

import { useGetMessagesQuery } from 'features/analytics/services';
import { IStatisticFilters } from 'features/analytics/services/interfaces';
import { capitalize } from 'utils/common/string';
import { format } from 'lib/date';

import { ChartWrapper } from '../ChartWrapper';
import { buildWeeklySeries } from './buildMultilineSeries';

type Props = {
    readonly height?: number;
    readonly filters: IStatisticFilters;
    readonly telegramAccounts: Array<string>;
};

export const MessagesMultiLineChart: React.FC<Props> = props => {
    const colors = ['#D6E4FF', '#ADC6FF', '#85A5FF', '#597EF7'];
    const labelFormat = (timestamp: number) => {
        switch (props.filters.period) {
            case 'week': {
                return capitalize(format(timestamp)('dd.'));
            }
            case 'year': {
                return capitalize(format(timestamp)('MM-DD.'));
            }
            default: {
                return format(timestamp)('MM.DD');
            }
        }
    };
    const {
        data: messages = {
            total_messages: 0,
            messages_tonality: '',
            message_statistic: [],
            message_members_statistic: [],
            user_messages_chart: {
                marks: [],
            },
        },
        isFetching,
    } = useGetMessagesQuery(
        {
            period: props.filters.period,
            community_id: props.filters.chat || null,
            telegram_users_id: props.filters.telegram_accounts.length
                ? props.filters.telegram_accounts
                : null,
        },
        { refetchOnMountOrArgChange: true }
    );

    const chartData = props.filters.telegram_accounts.length
        ? buildWeeklySeries(messages)
        : {
              series: [
                  {
                      name: '',
                      type: 'line',
                      messages:
                          messages.message_statistic?.map(s => ({
                              y: s.messages,
                              x: dayjs(s.scale).format('DD.MM'),
                          })) || [],
                  },
              ],
          };

    const options: ApexProps['options'] = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: true,
            position: 'left',
        },

        plotOptions: {
            heatmap: {
                radius: 5,
            },
        },
        colors: colors,
        markers: {
            colors: colors,
        },
        xaxis: {
            categories: chartData.labels,
        },
    };

    return (
        <ChartWrapper isLoading={isFetching} title='Сообщения'>
            <Chart
                height={props.height}
                type='line'
                options={options}
                series={chartData?.series?.map(user => ({
                    type: 'line',
                    name: user.name,
                    data: user.messages,
                }))}
                // series={[
                //     {
                //         type: 'line',
                //         name: 'Сообщений',
                //         data: messages.message_statistic.map(m => ({
                //             y: m.messages,
                //             x: [''],
                //         })),
                //     },
                // ]}
            />
        </ChartWrapper>
    );
};
