import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { App, Button, Input, Typography } from 'antd';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import { GenericModal } from '../../../../../../../shared/ui/GenericModal';
import { useSignInMutation } from '../../../../../../auth/services';

type TPayModal = {
    readonly source: any | [];
    readonly setOpen: Dispatch<SetStateAction<boolean>>;
    readonly open: boolean;
    readonly type: string;
};
export const RegisterModal: React.FC<TPayModal> = props => {
    const navigate = useNavigate();
    const { notification } = App.useApp();

    const { setOpen, source } = props;

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [signIn, { isSuccess, isError, data }] = useSignInMutation();

    useEffect(() => {
        if (isSuccess) {
            setOpen(false);
            localStorage.setItem('accessToken', data?.accessToken);

            if (props.type === 'webinar') {
                return navigate(
                    `/courses/member/webinar-preview/${source?.uuid}`
                );
            }
            if (props.type === 'post') {
                return navigate(`/courses/member/post/${source?.uuid}`);
            }
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Неверная почта или пароль',
            });
        }
    }, [isError]);

    const onSubmit = async () => {
        if (password == '' || email === '') {
            return notification.error({
                message: 'Нужно заполнить почту и пароль',
            });
        }

        try {
            await signIn({
                password: password,
                email: email.toLowerCase(),
            }).unwrap();
        } catch (e) {
            if (e.status === 401) {
                console.log('error');
            }
        }
    };

    return (
        <GenericModal
            title={'Приветствуем!'}
            destroyOnClose
            width={360}
            open={props.open}
            okText={'Войти'}
            onCancel={() => {
                setEmail(null);
                setOpen(false);
            }}
            onConfirm={onSubmit}
            okButtonProps={{
                danger: false,
                // disabled: !checkBoxStatus || !email,
            }}
            footer={
                <div>
                    <Typography.Text>Нет аккаунта?</Typography.Text>
                    <Button
                        onClick={() => navigate('/app/auth/sign-up')}
                        type={'link'}
                    >
                        Создать аккаунт
                    </Button>
                </div>
            }
        >
            <div>
                Авторизуйтесь для просмотра бесплатных постов и вебинаров.
            </div>

            <StyledForm>
                <Input
                    value={email}
                    autoFocus
                    placeholder='john@example.com'
                    onChange={e => setEmail(e.target.value)}
                />
                <PasswordLabel>
                    <div>Пароль</div>
                    <Link to={'/app/auth/recover'}>Забыли пароль?</Link>
                </PasswordLabel>
                <Input.Password onChange={e => setPassword(e.target.value)} />
            </StyledForm>
        </GenericModal>
    );
};

const PasswordLabel = styled.div`
    margin-top: 12px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
`;

const StyledForm = styled.div`
    margin: 2rem 0;
`;
