import React, { useEffect } from 'react';
import { App, Button, Tooltip } from 'antd';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';

import {
    useCreatePublicationFavouriteMutation,
    useDeletePublicationFavouriteMutation,
} from '../../../services/publications';
import {
    useDeleteWebinarFavouriteMutation,
    useCreateWebinarFavouriteMutation,
} from '../../../services/webinars';

interface IFavourite {
    readonly id: number;
    readonly isFavourite: boolean;
    readonly isDisable?: boolean;
    readonly type?: string;
}
export const FavouriteSwitch: React.FC<IFavourite> = props => {
    const { notification } = App.useApp();

    const [
        createFavourite,
        { isLoading: isLoadingCreate, isSuccess: isSuccessCreate },
    ] =
        props.type === 'webinar'
            ? useCreateWebinarFavouriteMutation()
            : useCreatePublicationFavouriteMutation();
    const [
        deleteFavourite,
        { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
    ] =
        props.type === 'webinar'
            ? useDeleteWebinarFavouriteMutation()
            : useDeletePublicationFavouriteMutation();

    useEffect(() => {
        if (isSuccessCreate) {
            notification.success({
                duration: 1,

                message: 'Добавлено в избранное',
            });
        }
    }, [isSuccessCreate]);

    useEffect(() => {
        if (isSuccessDelete) {
            notification.success({
                duration: 1,
                message: 'Убрано из избранного',
            });
        }
    }, [isSuccessDelete]);

    return !props.isFavourite ? (
        <Tooltip title={'Добавить в изрбранное'}>
            <Button
                loading={isLoadingCreate}
                disabled={isLoadingCreate || isLoadingDelete || props.isDisable}
                onClick={() => createFavourite(+props.id)}
            >
                <HeartOutlined />
            </Button>
        </Tooltip>
    ) : (
        <Tooltip title={'Убрать из изрбранного'}>
            <Button
                loading={isLoadingDelete}
                disabled={isLoadingCreate || isLoadingDelete || props.isDisable}
                onClick={() => deleteFavourite(props.id)}
            >
                <HeartFilled style={{ color: '#6257d2' }} />
            </Button>
        </Tooltip>
    );
};
