import React, { useEffect, useState } from 'react';
import { Button, Empty, Table, Typography } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';

import { formatExtended } from '../../../../../lib/date';
import getEnvConfig from '../../../../../utils/config/env';
import { IQuestionTableProps } from '../../../interfaces/questions.interface';

interface DataType {
    readonly title: string;
    readonly id: number;
    readonly context: null | string;
    readonly updated_at: string;
}
export const QuestionTable = (props: IQuestionTableProps) => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const columns: ColumnsType<DataType> = [
        {
            title: 'Вопрос',
            dataIndex: 'context',
            width: 450,
            ellipsis: true,
            render: (name, question: DataType) => (
                <Link
                    to={`${
                        getEnvConfig().PUBLIC_PATH
                    }/communities/knowledge/question/${question.id}`}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: question.context,
                        }}
                    ></span>
                </Link>
            ),
        },
        {
            title: 'Категория',
            dataIndex: 'category_name',
            width: 150,
            ellipsis: true,
            render: category => (
                <Typography.Text>{!category ? '-' : category}</Typography.Text>
            ),
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updated_at',
            render: (updated_at: string) => formatExtended(updated_at),
            sorter: (a, b) => {
                const startDate = Math.floor(new Date(a.updated_at).getTime());
                const endDate = Math.floor(new Date(b.updated_at).getTime());
                return startDate - endDate;
            },
        },
        {
            title: 'Действия',
            render: question => (
                <Button
                    style={{ paddingLeft: 0 }}
                    type='link'
                    onClick={() => props.deleteQuestion(question.id)}
                >
                    Удалить
                </Button>
            ),
        },
    ];

    const saveCurrentPage = currentPage => {
        sessionStorage.setItem('questionCurrentPage', currentPage);
    };

    useEffect(() => {
        if (sessionStorage.questionCurrentPage) {
            setCurrentPage(
                Number(sessionStorage.getItem('questionCurrentPage'))
            );
        }
    }, [sessionStorage.questionCurrentPage]);

    return (
        <StyledTable
            scroll={{ x: 900 }}
            loading={props.loading}
            //rowSelection={rowSelection}
            locale={{ emptyText: <Empty description='Нет вопросов' /> }}
            columns={columns}
            dataSource={props.source?.items}
            rowKey={(question: { id: number }) => question.id}
            pagination={{
                defaultCurrent: 1,
                current: currentPage,
                //total: source?.total,
                showSizeChanger: true,
                showTotal: total => `Вопросов: ${total}`,
                onChange: (page, pageSize) => {
                    props.handleFiltersUpdate({
                        offset: (page - 1) * pageSize,
                        limit: pageSize,
                        page: page,
                    });
                    setCurrentPage(page);
                    saveCurrentPage(page);
                },
                onShowSizeChange: (current, size) => {
                    props.handleFiltersUpdate({
                        offset: (current - 1) * size,
                        limit: size,
                    });
                },
            }}
        />
    );
};

const StyledTable = styled(Table)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;
