import React from 'react';
import { PlusOutlined } from '@ant-design/icons';

export const createButton = [
    { text: 'Заголовок', type: '6', icon: <PlusOutlined /> },
    { text: 'Текст', type: '1', icon: <PlusOutlined /> },
    { text: 'Изображение', type: '4', icon: <PlusOutlined /> },
    { text: 'Видео', type: '2', icon: <PlusOutlined /> },
    { text: 'Аудио', type: '3', icon: <PlusOutlined /> },
];
