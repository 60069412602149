import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Button, Empty, Table } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import {
    IChatMember,
    IChatMemberQueryParams,
} from 'features/communities/interfaces';
import { TableFilter } from 'shared/ui/TableFilter';
import { ConfirmationDialog } from 'shared/ui/ConfirmationDialog';
import { format } from 'lib/date';

import { MemberStatusSelector } from './MemberStatusSelector';

type TProps = {
    readonly source: IChatMember;
    readonly loading?: boolean;
    readonly filters: IChatMemberQueryParams;
    readonly onFiltersUpdate: (filters: IChatMemberQueryParams) => void;
    readonly onBulkRemove: (accounts: number[]) => void;
    readonly handleListChange: (args) => void;
};

export const ChatMemberDetailsTable: React.FC<TProps> = props => {
    const { loading, source, onBulkRemove, onFiltersUpdate, handleListChange } =
        props;

    const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

    const rowSelection = {
        selectedRows: selectedRows,
        onChange: setSelectedRows,
    };

    return (
        <StyledWrapper>
            <StyledActions>
                {!!selectedRows.length && (
                    <Fragment>
                        <ConfirmationDialog
                            activator={<Button type='primary'>Удалить</Button>}
                            onConfirm={() => {
                                onBulkRemove(selectedRows.map(r => Number(r)));
                            }}
                            title='Удалить?'
                            description={`Вы уверены что хотите удалить пользователей из ${selectedRows.length} сообществ?`}
                        />
                        <MemberStatusSelector
                            width='200px'
                            value={'none'}
                            onChange={value => {
                                handleListChange({
                                    list: value,
                                    community_ids: selectedRows.map(Number),
                                });
                            }}
                        />
                        <div>Выбрано чатов: {selectedRows.length}</div>
                    </Fragment>
                )}
            </StyledActions>
            <StyledTable
                loading={loading}
                rowSelection={rowSelection}
                locale={{ emptyText: <Empty description='Нет чатов' /> }}
                dataSource={source.communities}
                rowKey={chat => chat.id}
                pagination={{
                    total: source.communities.length,
                    showSizeChanger: true,
                    showTotal: () => `Чатов: ${source.communities.length}`,
                    onChange: (page, pageSize) => {
                        onFiltersUpdate({
                            offset: (page - 1) * pageSize,
                            limit: pageSize,
                        });
                    },
                    onShowSizeChange: (current, size) => {
                        onFiltersUpdate({
                            offset: (current - 1) * size,
                            limit: size,
                        });
                    },
                }}
                scroll={{ x: 600 }}
            >
                <Table.Column
                    title={
                        <TableFilter<string>
                            title='Чат'
                            onChange={name => onFiltersUpdate({ name })}
                            value={props.filters.name}
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    fixed='left'
                    width={200}
                    dataIndex={'title'}
                />
                <Table.Column
                    title={
                        <TableFilter<string>
                            onChange={user_name =>
                                onFiltersUpdate({ user_name })
                            }
                            value={props.filters.user_name}
                            title='Дата вступления'
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    width={200}
                    render={chat => format(chat.accession_date)('MM.DD.YYYY')}
                />
                <Table.Column
                    width={200}
                    title={
                        <TableFilter icon={<FilterOutlined />} title='Статус'>
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    render={chat => {
                        return (
                            <MemberStatusSelector
                                value={chat.status || 'none'}
                                onChange={value =>
                                    handleListChange({
                                        community_ids: [chat.id],
                                        list: value,
                                        prev: chat.status,
                                    })
                                }
                            />
                        );
                    }}
                />

                <Table.Column
                    title='Действие'
                    width={100}
                    render={chat => (
                        <Button
                            type='link'
                            onClick={() => {
                                onBulkRemove([chat.id]);
                            }}
                        >
                            Удалить
                        </Button>
                    )}
                />
            </StyledTable>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
`;

const StyledTable = styled(Table<IChatMember['communities'][0]>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;

const StyledActions = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;
