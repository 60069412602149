import React, { FC, useState } from 'react';
import { Radio, Space, Switch, Typography } from 'antd';
import styled from 'styled-components';

import { ReputationSwitchesEnum } from '../../ReputationMapper';
import { WhoCanRateEnum } from '../../../../interfaces/reputation.interface';

interface IProps {
    formik: any;
}

export const WhoCanRateSwitch: FC<IProps> = props => {
    return (
        <StyledSpace align='start' size='small'>
            <StyledSwitch
                checked={
                    props.formik.values.switches[
                        ReputationSwitchesEnum.whoCanRateSwitch
                    ].isChecked
                }
                onChange={value => {
                    props.formik.setFieldValue(
                        `switches.${ReputationSwitchesEnum.whoCanRateSwitch}.isChecked`,
                        value
                    );
                }}
            />
            <Space direction='vertical'>
                <StyledTitle level={5}>
                    Ограничить список участников, влияющих на репутацию
                </StyledTitle>
                {props.formik.values.switches[
                    ReputationSwitchesEnum.whoCanRateSwitch
                ].isChecked && (
                    <>
                        <Typography.Text>
                            Типы участников, которые могут влиять на репутацию
                            других участников
                        </Typography.Text>
                        <Radio.Group
                            buttonStyle='solid'
                            value={
                                props.formik.values.switches[
                                    ReputationSwitchesEnum.whoCanRateSwitch
                                ]?.who_can_rate
                            }
                            onChange={event => {
                                props.formik.setFieldValue(
                                    `switches.${ReputationSwitchesEnum.whoCanRateSwitch}.who_can_rate`,
                                    event.target.value
                                );
                            }}
                        >
                            <Radio.Button
                                value={WhoCanRateEnum.owner_and_admin}
                            >
                                Владелец и администраторы
                            </Radio.Button>
                            <Radio.Button value={WhoCanRateEnum.owner}>
                                Владелец
                            </Radio.Button>
                        </Radio.Group>
                    </>
                )}
            </Space>
        </StyledSpace>
    );
};

const StyledSpace = styled(Space)`
    margin-bottom: 32px;
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;
