import React from 'react';
import styled from 'styled-components';
import { Button, Select } from 'antd';

type TProps = {
    readonly value?: string;
    readonly onChange?: (value: string) => void;
    readonly onClose?: () => void;
    readonly options: Array<{ value: string; label: string }>;
};

export const SelectorFilter: React.FC<TProps> = props => {
    const handleClear = () => {
        props.onChange(null);
        props.onClose();
    };

    const handleChange = (value: string) => {
        props.onChange(value);
        props.onClose();
    };

    return (
        <React.Fragment>
            <Select
                placeholder='Выбрать'
                value={props.value}
                onChange={handleChange}
                options={props.options}
            />

            <StyledActions>
                <Button size='small' onClick={handleClear}>
                    Очистить
                </Button>
                <Button size='small' type='text' onClick={props.onClose}>
                    Отмена
                </Button>
            </StyledActions>
        </React.Fragment>
    );
};

const StyledActions = styled.div`
    display: flex;
    gap: 8px;
    justify-content: space-between;
`;
