import React, { useEffect } from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useCreateBehaviorRuleMutation } from 'features/communities/services';

import { BehavioralForm } from '../views/BehavioralForm';
import { createFormData } from '../../../../../utils/common/createFormData';
import { BehavioralMapper, BehavioralSwitchesEnum } from '../BehavioralMapper';

export interface IFormikInitialValues {
    name: string;
    switches: {
        [BehavioralSwitchesEnum.setOfRulesSwitch]: {
            isChecked: boolean;
            content?: string;
            content_image?: File | string;
        };
        [BehavioralSwitchesEnum.restrictedWordsSwitch]: {
            isChecked: boolean;
            restricted_words?: string[];
            max_violation_times?: number;
            warning?: string;
            warning_image?: File | string;
            quiet_on_restricted_words?: boolean;
            action?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
        };
        [BehavioralSwitchesEnum.userComplaintSwitch]: {
            isChecked: boolean;
            user_complaint_image?: File | string;
            complaint_text?: string;
            quiet_on_complaint?: boolean;
        };
    };
    selectedChatIds: number[];
}

export const BehavioralContainerCreate = () => {
    const { notification } = App.useApp();

    const navigate = useNavigate();

    const [createBehaviorRule, { isSuccess, error }] =
        useCreateBehaviorRuleMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Сценарий создан успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any).data.message || 'Что-то не так',
                placement: 'top',
            });
        }
    }, [error]);

    const initialValues: IFormikInitialValues = {
        name: '001 Правила поведения',
        switches: {
            [BehavioralSwitchesEnum.setOfRulesSwitch]: {
                isChecked: false,
                content:
                    `Дорогой участник!\n` +
                    `В нашем чате запрещено обсуждать политику, а также использовать нецензурную лексику.\n` +
                    `За нарушение правил бот будет удалять ваши сообщения и публиковать предупреждение.\n` +
                    'После третьего предупреждения - вам запретят писать сообщения или забанят. Отменить это сможет только админ.\n',
            },
            [BehavioralSwitchesEnum.restrictedWordsSwitch]: {
                isChecked: false,
                warning:
                    '@пользователь, вы нарушаете правила общения в чате. После третьего предупреждения вам запретят писать сообщения или забанят. Отменить это сможет только админ.',
                action: 4,
                max_violation_times: 3,
            },
            [BehavioralSwitchesEnum.userComplaintSwitch]: {
                isChecked: false,
                complaint_text:
                    '@пользователь, вы нарушаете правила общения в чате. Пожалуйста, измените тон общения или продолжите выяснение отношений в личных сообщениях.',
            },
        },
        selectedChatIds: [],
    };

    const onSave = async (values: IFormikInitialValues) => {
        const requestObject = BehavioralMapper.createDto(values);
        await createBehaviorRule(createFormData(requestObject));
    };

    return <BehavioralForm initialValues={initialValues} onSave={onSave} />;
};
