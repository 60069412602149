import React, { useEffect, useState } from 'react';
import { Space, Table, Typography } from 'antd';
import styled from 'styled-components';

import { columns } from '../utils/webinarsChartMock';

export const WebinarList = props => {
    const { data, selectedRows, setSelectedRows, onRowChange } = props;
    //const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

    useEffect(() => {
        if (data) {
            setSelectedRows(data.slice(0, 3).map((webinar, index) => index));
        }
    }, [data]);

    const count = selectedRows?.length;

    /* const onRowChange = (key, record) => {
        console.log(record);
        console.log(key);
        setSelectedRows(key);
    };*/

    return (
        <StyledWrapper>
            {count <= 0 ? null : (
                <StyledActions>
                    <Typography.Text>
                        Выбрано вебинаров: {count}
                    </Typography.Text>
                </StyledActions>
            )}

            <StyledTable
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedRows,
                    onChange: (key, record) => onRowChange(key, record),
                }}
                rowKey={(record, key) => key}
                columns={columns}
                dataSource={data}
            />
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    margin: 2rem 0;
`;

const StyledTable = styled(Table)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;

const StyledActions = styled(Space)`
    margin-bottom: 1rem;
`;
