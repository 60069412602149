import { IFormikInitialValues } from 'features/communities/components/Antispam/containers/AntispamContainerCreate';
import {
    IAntispam,
    IAntispamCreateRequest,
} from 'features/communities/interfaces/antispam.interface';

export enum AntispamSwitchesEnum {
    messageWithLink = 'messageWithLink',
    messageWithForward = 'messageWithForward',
}

export class AntispamMapper {
    private static convertSwitchesMap = new Map<
        AntispamSwitchesEnum,
        (value) => any
    >([
        [
            AntispamSwitchesEnum.messageWithLink,
            value => {
                return {
                    del_message_with_link: value?.del_message_with_link,
                    ban_user_contain_link: value?.ban_user_contain_link,
                };
            },
        ],
        [
            AntispamSwitchesEnum.messageWithForward,
            value => {
                return {
                    del_message_with_forward: value?.del_message_with_forward,
                    ban_user_contain_forward: value?.ban_user_contain_forward,
                };
            },
        ],
    ]);
    public static createDto(values: IFormikInitialValues) {
        const arraySwitches: [AntispamSwitchesEnum, any][] = Object.entries(
            values.switches
        ) as [AntispamSwitchesEnum, any][];
        const arrayCheckedSwitches = arraySwitches.filter(([_, value]) => {
            return value.isChecked;
        });
        const requestObject: IAntispamCreateRequest = {
            name: values.name,
            work_period: values.work_period || 0,
            community_ids: values.selectedChatIds,
        };
        if (arrayCheckedSwitches.length) {
            arrayCheckedSwitches.forEach(([key, value]) => {
                if (this.convertSwitchesMap.get(key)) {
                    Object.assign(
                        requestObject,
                        this.convertSwitchesMap.get(key)(value)
                    );
                }
            });
        }
        return requestObject;
    }
    public static convertDto(data: IAntispam): IFormikInitialValues {
        return {
            name: data?.name || '',
            ...(data?.work_period && { work_period: data.work_period }),
            switches: {
                [AntispamSwitchesEnum.messageWithLink]: {
                    isChecked: Boolean(data?.del_message_with_link),
                    del_message_with_link: Boolean(data?.del_message_with_link),
                    ban_user_contain_link: Boolean(data?.ban_user_contain_link),
                },
                [AntispamSwitchesEnum.messageWithForward]: {
                    isChecked: Boolean(data?.del_message_with_forward),
                    del_message_with_forward: Boolean(
                        data?.del_message_with_forward
                    ),
                    ban_user_contain_forward: Boolean(
                        data?.ban_user_contain_forward
                    ),
                },
            },
            selectedChatIds: data?.community_ids || [],
        };
    }
}
