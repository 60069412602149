import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { App, Col, Empty, Space, Table, Tag, Typography } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import { format } from 'lib/date';
import {
    ICommunity,
    ICommunityQueryParams,
    ICommunityTag,
} from 'features/communities/interfaces';
import { TableFilter } from 'shared/ui/TableFilter';
import { PaginatedResponse } from 'features/communities/interfaces/PaginatedResponse';
import { useCreateChatTagsMutation } from 'features/communities/services';

import { TagsEditor } from '../TagsEditor';
import { ClearFilters } from '../../../../../../shared/ui/ClearFilters';
import { initialFilters } from '../../utils/filters';

type TProps = {
    readonly source: PaginatedResponse<ICommunity>;
    readonly loading: boolean;
    readonly filters: ICommunityQueryParams;
    readonly onFiltersUpdate: (filters: ICommunityQueryParams) => void;
    readonly setFilters: (filters: ICommunityQueryParams) => void;

    readonly handleTagsChange: any;
    readonly selectedRows: any;
    readonly commonTags: any;
    readonly rowSelection: any;
};
export const ChatsTable: React.FC<TProps> = props => {
    const {
        selectedRows,
        handleTagsChange,
        commonTags,
        rowSelection,
        setFilters,
        filters,
        onFiltersUpdate,
    } = props;

    return (
        <StyledWrapper>
            <StyledActions>
                <Col xs={12}>
                    {!!selectedRows.length && (
                        <TagsEditor
                            tags={commonTags}
                            count={selectedRows.length}
                            onChange={handleTagsChange}
                        />
                    )}
                </Col>

                <ClearFilters
                    filters={filters}
                    initialFilters={initialFilters}
                    setFilters={setFilters}
                />
            </StyledActions>

            <StyledTable
                loading={props.loading}
                rowSelection={rowSelection}
                rowKey={community => community.id}
                locale={{ emptyText: <Empty description='Нет чатов' /> }}
                dataSource={props.source.items}
                pagination={{
                    total: props.source.total,
                    showSizeChanger: true,
                    showTotal: () => `Чатов: ${props.source.total}`,
                }}
            >
                <Table.Column
                    fixed
                    title={
                        <TableFilter<string>
                            title='Название сообщества'
                            onChange={name => {
                                onFiltersUpdate({ name });
                            }}
                            value={filters.name}
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    dataIndex={'title'}
                />
                <Table.Column
                    title={
                        <TableFilter
                            icon={<FilterOutlined />}
                            title='Дата добавления'
                            setFilters={onFiltersUpdate}
                            filters={filters}
                        >
                            <TableFilter.Date />
                        </TableFilter>
                    }
                    dataIndex={'created_at'}
                    render={(createdAt: number) =>
                        format(createdAt)('DD.MM.YY')
                    }
                />
                <Table.Column
                    title={
                        <TableFilter<string[]>
                            icon={<FilterOutlined />}
                            onChange={tag_names => {
                                onFiltersUpdate({ tag_names });
                            }}
                            title='Теги'
                            value={filters.tag_names}
                        >
                            <TableFilter.Tags />
                        </TableFilter>
                    }
                    dataIndex={'tags'}
                    render={(tags: ICommunityTag[]) => (
                        <React.Fragment>
                            <StyledSpace>
                                {tags.map((tag, index) => (
                                    <Tag key={index}>{tag.name}</Tag>
                                ))}
                            </StyledSpace>
                        </React.Fragment>
                    )}
                />
            </StyledTable>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StyledTable = styled(Table<ICommunity>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StyledSpace = styled(Space)`
    width: 100%;
    flex-wrap: wrap;
`;
