import React, { useEffect, useState } from 'react';
import { Button, Empty, Table } from 'antd';
import styled from 'styled-components';
import type { ColumnsType } from 'antd/es/table';

import { ICategoryTable } from '../../../interfaces/category.interface';

interface DataType {
    key: React.Key;
    name: string;
    question_count: number;
    title: string;
    defaultFilteredValue: string[];
}
export const CategoryTable: React.FC<ICategoryTable> = props => {
    const [filters, setFilters] = useState([]);

    const { items } = props.source;

    useEffect(() => {
        setFilters(
            items.map((elem, index) => ({
                key: index,
                value: elem.name,
                text: elem.name,
            }))
        );
    }, [items]);

    const rowSelection = {
        selectedRows: props.selectedRows,
        onChange: props.setSelectedRows,
    };

    const columns: ColumnsType<DataType> = [
        {
            title: 'Имя',
            dataIndex: 'name',
            filters: filters,
            onFilter: (value: string, record) =>
                record.name.indexOf(value) === 0,
        },
        {
            title: 'Вопросы',
            dataIndex: 'question_count',
            sorter: (a, b) => a.question_count - b.question_count,
        },
        {
            title: 'Действия',
            render: (category: ICategoryTable) => (
                <>
                    <Button
                        style={{ paddingLeft: 0 }}
                        type='link'
                        onClick={() => {
                            props.deleteCategory([category.id]);
                        }}
                    >
                        Удалить
                    </Button>
                    <Button
                        style={{ paddingLeft: 0 }}
                        type='link'
                        onClick={() => props.renameCategory(category)}
                    >
                        Переименовать
                    </Button>
                </>
            ),
        },
    ];

    return (
        <StyledTable
            columns={columns}
            //defaultFilteredValue={'descend'}
            loading={props.loading}
            rowSelection={rowSelection}
            locale={{ emptyText: <Empty description='Нет категорий' /> }}
            dataSource={items}
            rowKey={(category: { id: string }) => category.id}
            pagination={{
                total: props?.source.total,
                showSizeChanger: true,
                showTotal: () => `Категорий: ${props?.source.total}`,
                onChange: (page, pageSize) => {
                    props.onFiltersUpdate({
                        offset: (page - 1) * pageSize,
                        limit: pageSize,
                    });
                },
                onShowSizeChange: (current, size) => {
                    props.onFiltersUpdate({
                        offset: (current - 1) * size,
                        limit: size,
                    });
                },
            }}
        />
    );
};

const StyledTable = styled(Table)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;
