import React, { useEffect, useState } from 'react';
import { App } from 'antd';

import { AIKnowledgeTable } from '../views/AIKnowledgeTable';
import {
    useDeleteQuestionAiMutation,
    useDeleteQuestionMutation,
    useReadQuestionAIQuery,
} from '../../../services';

export const AIKnowledgeContainer: React.FC = () => {
    const { notification } = App.useApp();

    const [questionList, setQuestionList] = useState([]);
    const [selectedRows, setSelectedRowKeys] = useState<React.Key[]>([]);

    const { data, isFetching, isSuccess } = useReadQuestionAIQuery(null);
    const [deleteQuestion, { isSuccess: isSuccessDelete }] =
        useDeleteQuestionMutation();

    const [deleteQuestionAi, { isSuccess: isSuccessDeleteAi }] =
        useDeleteQuestionAiMutation();

    useEffect(() => {
        if (isSuccess && data) {
            setQuestionList([
                ...(data && data.items ? data.items.questions : undefined),
                ...(data && data.items ? data.items.questions_ai : undefined),
            ]);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (isSuccessDelete || isSuccessDeleteAi) {
            setSelectedRowKeys([]);
            notification.success({
                duration: 1,
                message: 'Успешно удалено',
            });
        }
    }, [isSuccessDelete, isSuccessDeleteAi]);

    const onDelete = async selected => {
        if (selected.status === 1) {
            await deleteQuestionAi(selected.id);
        }
        if (selected.status === 'published') {
            await deleteQuestion(selected.id);
        }
    };

    const onBulkRemove = async selected => {
        const questionsAi = selected
            .filter(elem => elem.status === 1)
            .map(q => q.id);

        const questionsCommon = selected
            .filter(elem => elem.status === 'published')
            .map(q => q.id);

        if (questionsCommon?.length > 0) {
            await Promise.all(questionsCommon.map(deleteQuestion));
        }
        if (questionsAi?.length > 0) {
            await Promise.all(questionsAi.map(deleteQuestionAi));
        }
    };

    return (
        <AIKnowledgeTable
            isSuccess={isSuccess}
            source={questionList.sort(
                (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
            )}
            loading={isFetching}
            onDelete={onDelete}
            onBulkRemove={onBulkRemove}
            selectedRows={selectedRows}
            setSelectedRowKeys={setSelectedRowKeys}
        />
    );
};
