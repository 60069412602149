export const initialFilters = {
    tag_names: [],
    events: [],
    user_name: '',
    community_title: '',
    limit: 10,
    offset: 0,
    date_to: null,
    date_from: null,
};
