import { RouteObject } from 'react-router';

import { RecommendationPage } from './pages/RecommendationPage';

const routes: RouteObject[] = [
    {
        path: 'recommendation',
        element: <RecommendationPage />,
    },
];

export default routes;
