const ANALYTICS_TYPES = [
    {
        value: 'all',
        label: 'Вся аналитика',
    },
    {
        value: 'moderation',
        label: 'Модерация',
    },
    {
        value: 'members',
        label: 'Участники',
    },
    {
        value: 'messages',
        label: 'Сообщения',
    },
    {
        value: 'finance',
        label: 'Финансы',
    },
];

const ANALYTICS_PERIODS = [
    {
        value: 'day',
        label: 'За день',
    },
    {
        value: 'week',
        label: 'За неделю',
    },
    {
        value: 'month',
        label: 'За месяц',
    },
    {
        value: 'year',
        label: 'За год',
    },
];

const DOWNLOAD_ACTIONS = [
    {
        value: 'download',
        label: 'Скачать',
    },
];

export default {
    ANALYTICS_TYPES,
    ANALYTICS_PERIODS,
    DOWNLOAD_ACTIONS,
};
