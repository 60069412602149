import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Badge, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { ChatList } from 'features/communities/components/ChatsList/ChatList';
import { ConfirmBlock } from 'features/communities/components/ConfirmBlock/ConfirmBlock';

import { HeaderScenarios } from '../../HeaderScenarios/HeaderScenarios';
import { blobToBase64 } from '../../../../../utils/common/blobToBase64';
import { SetOfRulesSwitch } from './components/SetOfRulesSwitch';
import { RestrictedWordsSwitch } from './components/RestrictedWordsSwitch';
import { UserComplaintSwitch } from './components/UserComplaintSwitch';
import { RuleType } from '../../../../../shared/contants/rule-types';
import { IFormikInitialValues } from '../containers/BehavioralContainerCreate';
import { BehavioralSwitchesEnum } from '../BehavioralMapper';

interface IProps {
    onSave: (values) => void;
    initialValues: IFormikInitialValues;
}

export const BehavioralForm: FC<IProps> = props => {
    const img1Ref = useRef(null);
    const img2Ref = useRef(null);
    const img3Ref = useRef(null);
    const [img1, setImg1] = useState<ArrayBuffer | string | File | null>(
        props?.initialValues.switches[BehavioralSwitchesEnum.setOfRulesSwitch]
            ?.content_image || null
    );
    const [img2, setImg2] = useState<ArrayBuffer | string | File | null>(
        props?.initialValues.switches[
            BehavioralSwitchesEnum.restrictedWordsSwitch
        ]?.warning_image || null
    );
    const [img3, setImg3] = useState<ArrayBuffer | string | File | null>(
        props?.initialValues.switches[
            BehavioralSwitchesEnum.userComplaintSwitch
        ]?.user_complaint_image || null
    );

    const navigate = useNavigate();

    useEffect(() => {
        if (img1Ref.current) {
            blobToBase64(img1Ref.current).then(val => setImg1(val));
        }
        if (img2Ref.current) {
            blobToBase64(img2Ref.current).then(val => setImg2(val));
        }
        if (img3Ref.current) {
            blobToBase64(img3Ref.current).then(val => setImg3(val));
        }
    }, [img1Ref.current, img2Ref.current, img3Ref.current]);

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: props.onSave,
    });

    return (
        <Fragment>
            <ContainerWrapper>
                <HeaderScenarios
                    inputValue={formik.values.name}
                    onChangeHeaderInput={event => {
                        formik.setFieldValue('name', event.target.value);
                    }}
                />
                <Space direction='vertical' size='large'>
                    <SetOfRulesSwitch
                        formik={formik}
                        img1Ref={img1Ref}
                        img1={img1}
                    />

                    <RestrictedWordsSwitch
                        formik={formik}
                        img2Ref={img2Ref}
                        img2={img2}
                    />

                    {/*<UserComplaintSwitch
                        formik={formik}
                        img3Ref={img3Ref}
                        img3={img3}
                    />*/}
                </Space>
            </ContainerWrapper>
            <ChatList
                selectedRows={formik.values.selectedChatIds || []}
                setSelectedRows={value => {
                    formik.setFieldValue('selectedChatIds', value);
                }}
                tableOptions={{
                    entityType: RuleType.Behavioral,
                }}
            />
            <ConfirmBlock
                onSave={formik.handleSubmit}
                onCancel={() => {
                    navigate(-1);
                }}
            >
                <Fragment>
                    <Badge
                        color='orange'
                        text={`Для ${formik.values.selectedChatIds.length} чатов текущее правило заменит действующее. Это действие нельзя отменить.`}
                    />
                </Fragment>
            </ConfirmBlock>
        </Fragment>
    );
};

const ContainerWrapper = styled.div`
    display: block;
    margin-top: 32px;
    margin-bottom: 33px;
`;
