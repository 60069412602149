import React from 'react';
import { Button, Col, List, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Secondary from '../../../../../shared/ui/SecondaryText';
import { CopyWithTooltip } from '../../../../../shared/ui/CopyWithTooltip/CopyWithTooltip';
import { rub } from '../../../../../shared/contants/styles';
import { Dropdown } from '../../../../../shared/ui/Dropdown';
import { ActionSwitch } from './components/ActionSwitch';
import { PurifiedHtml } from '../../../../../shared/ui/PurifyHtml';

interface IProps {
    source: Array<{
        command: string;
        created_at: Date;
        description: null | string;
        donate_is_active: boolean;
        id: number;
        image: null | string;
        payments_count: number;
        payments_sum: number;
        title: string;
    }>;
    loading: boolean;
    onSetStatus: any;
    deleteById: any;
    size: string;
}
export const DonateCard: React.FC<IProps> = props => {
    const navigate = useNavigate();
    const { source, onSetStatus, loading, size } = props;

    return (
        <List
            loading={loading}
            rowKey={donate => donate.id}
            dataSource={source}
            renderItem={donate => (
                <List.Item>
                    <Space
                        direction={'vertical'}
                        size={'small'}
                        style={{ width: '100%' }}
                    >
                        <Typography.Paragraph
                            style={{ marginBottom: 0 }}
                            ellipsis={{
                                rows: 1,
                            }}
                            onClick={() => navigate(`${donate.id}`)}
                        >
                            {donate.title}
                        </Typography.Paragraph>

                        {donate.description && (
                            <Typography.Paragraph
                                style={{ marginBottom: 0 }}
                                ellipsis={{
                                    rows: 2,
                                }}
                            >
                                <PurifiedHtml dirtyHtml={donate?.description} />
                            </Typography.Paragraph>
                        )}

                        <CopyWithTooltip text={donate.command} />

                        <Row>
                            <Col span={12}>
                                <Typography.Text
                                    style={{ marginRight: '2rem' }}
                                >
                                    {donate.payments_sum}
                                    {rub}
                                </Typography.Text>
                            </Col>
                            <Col span={12}>
                                <Secondary>
                                    {donate.payments_count} платежей
                                </Secondary>
                            </Col>
                        </Row>

                        <Row justify={'space-between'}>
                            <Secondary>
                                {dayjs(donate.created_at).format(
                                    'DD.MM.YYYY, HH:mm'
                                )}
                            </Secondary>

                            <StyledDropdownWithBorder
                                dropdownContent={() => (
                                    <Button
                                        size={'small'}
                                        type='text'
                                        onClick={() =>
                                            onSetStatus(
                                                donate.id,
                                                !donate.donate_is_active
                                            )
                                        }
                                    >
                                        {donate.donate_is_active
                                            ? 'Отключить'
                                            : 'Активировать'}
                                    </Button>
                                )}
                            >
                                <Button size={'small'}>
                                    {donate.donate_is_active
                                        ? 'Активен'
                                        : 'Отключен'}
                                    <DownOutlined />
                                </Button>
                            </StyledDropdownWithBorder>

                            <ActionSwitch
                                size={size}
                                id={donate.id}
                                deleteById={props.deleteById}
                            />
                        </Row>
                    </Space>
                </List.Item>
            )}
        />
    );
};

const StyledDropdownWithBorder = styled(Dropdown)`
    cursor: pointer;
    width: fit-content;
`;
