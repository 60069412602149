import React from 'react';
import Chart, { Props as ApexProps } from 'react-apexcharts';
import styled from 'styled-components';

import { useGetMessagesQuery } from 'features/analytics/services';
import { IStatisticFilters } from 'features/analytics/services/interfaces';

import { ChartWrapper } from '../ChartWrapper';

type Props = {
    readonly height?: number;
    readonly filters: IStatisticFilters;
    readonly disableTonality?: boolean;
};

export const MessagesChart: React.FC<Props> = props => {
    const colors = ['#417ff9', '#2656c6', '#1a3c98', '#061d68'];

    const {
        data: messages = {
            total_messages: 0,
            messages_tonality: '',
            message_statistic: [],
            user_messages_chart: {},
        },
        isFetching,
    } = useGetMessagesQuery(
        {
            period: props.filters.period,
            community_id: props.filters.chat || null,
            telegram_users_id: props.filters.telegram_accounts.length
                ? props.filters.telegram_accounts
                : null,
        },
        { refetchOnMountOrArgChange: true }
    );

    const firstDayPart = messages.message_statistic.filter(
        el => el.hour_interval === '1'
    );
    const secondDayPart = messages.message_statistic.filter(
        el => el.hour_interval === '2'
    );
    const thirdDayPart = messages.message_statistic.filter(
        el => el.hour_interval === '3'
    );
    const fourthDayPart = messages.message_statistic.filter(
        el => el.hour_interval === '4'
    );
    const fifthDayPart = messages.message_statistic.filter(
        el => el.hour_interval === '5'
    );
    const sixthDayPart = messages.message_statistic.filter(
        el => el.hour_interval === '6'
    );

    const dayOfWeekMap = {
        '0': 'Пн.',
        '1': 'Вт.',
        '2': 'Ср.',
        '3': 'Чт.',
        '4': 'Пт.',
        '5': 'Сб.',
        '6': 'Вс.',
    };

    const dayPart = [
        firstDayPart,
        secondDayPart,
        thirdDayPart,
        fourthDayPart,
        fifthDayPart,
        sixthDayPart,
    ].reverse();

    const intervals = {
        '0': '20:00 - 24:00',
        '1': '16:00 - 20:00',
        '2': '12:00 - 16:00',
        '3': '08:00 - 12:00',
        '4': '04:00 - 08:00',
        '5': '00:00 - 04:00',
    };

    const generateSeries = dayPart =>
        Array(7)
            .fill(null)
            .map((_, index) => {
                return {
                    x: dayOfWeekMap[index],
                    y:
                        dayPart?.find(el => +el.day_of_week === index + 1)
                            ?.count || 0,
                };
            });

    const series = dayPart.map(value => ({
        name: '',
        data: generateSeries(value),
    }));

    const options: ApexProps['options'] = {
        labels: [],
        chart: {
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            tooltip: {
                enabled: false,
            },
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex }) {
                return (
                    '<div style="padding: 8px 16px">' +
                    '<span>' +
                    'Сообщений: ' +
                    '</span>' +
                    '<span>' +
                    series[seriesIndex][dataPointIndex] +
                    '</span>' +
                    '<div style="font-weight: 600">' +
                    intervals[seriesIndex] +
                    '</div>' +
                    '</div>'
                );
            },
        },
        plotOptions: {
            heatmap: {
                radius: 5,
                colorScale: {
                    ranges: [
                        {
                            from: 0,
                            to: 5,
                            color: '#94b2fa',
                            name: 'low',
                        },
                        {
                            from: 6,
                            to: 40,
                            color: '#5490f6',
                            name: 'medium',
                        },
                        {
                            from: 41,
                            color: '#083bf6',
                            name: 'high',
                        },
                    ],
                },
            },
        },
        colors: colors,
        markers: {
            colors: colors,
        },
    };

    return (
        <ChartWrapper isLoading={isFetching} title='Сообщения'>
            <StyledContainer>
                <StyledLegendContainer>
                    <div className='counter'>{messages.total_messages}</div>
                    {props.disableTonality && (
                        <div>
                            <div className='result'>
                                {messages.messages_tonality}
                            </div>
                            <div className='description'>тональность</div>
                        </div>
                    )}
                </StyledLegendContainer>

                <StyledChart
                    height={180}
                    type='heatmap'
                    options={options}
                    series={series}
                />
            </StyledContainer>
        </ChartWrapper>
    );
};

const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledLegendContainer = styled.div`
    height: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .counter {
        color: #2f54eb;
        font-size: 36px;
        font-weight: 300;
    }

    .result {
        color: #139c55;
        font-size: 24px;
        font-weight: 300;
    }

    .description {
        font-size: 12px;
        font-weight: 400;
        color: rgba(37, 33, 41, 0.45);
    }
`;

const StyledChart = styled(Chart)`
    position: absolute;
    right: 0;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
`;
