import React, { FC, useEffect, useState } from 'react';
import { App, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { DonateTable } from '../views/DonateTable';
import {
    useCreateMutation,
    useDeleteByIdMutation,
    useGetListQuery,
    useUpdateMutation,
} from '../../../services/donate.service';
import { Page } from '../../../../../shared/ui/Page';
import { DonateCard } from '../views/DonateCard';
import { FetchButton } from '../../../../../shared/ui/FetchButton';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { useProfileData } from '../../../../../hooks/useProfileData';
import { Warning } from '../../../../../shared/ui/Warning';
import { Filters, initialFilters } from '../utils/filters';

export const DonateContainer: FC = () => {
    const { size } = useWindowSize();
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [filters, setFilters] = useState<Filters>(initialFilters);

    const [selectedRows, setSelectedRows] = useState([]);

    const [update, { isSuccess: isSuccessUpdate, error: errorUpdate }] =
        useUpdateMutation();
    const [deleteById, { isSuccess: isSuccessDel, error: errorDel }] =
        useDeleteByIdMutation();
    const [create] = useCreateMutation();
    const { data, isFetching, isSuccess, refetch } = useGetListQuery(filters);

    const { user } = useProfileData();
    const isPhoneConfirmed = user?.phone_confirmed;

    useEffect(() => {
        if (isSuccessDel) {
            notification.success({
                message: 'Донат успешно удалён',
                placement: 'top',
            });
        }
    }, [isSuccessDel]);

    useEffect(() => {
        if (isSuccessUpdate) {
            refetch();
            notification.success({
                message: 'Донат успешно создан',
                placement: 'top',
            });
        }
    }, [isSuccessDel]);

    useEffect(() => {
        if (errorDel || errorUpdate) {
            notification.error({
                message:
                    (errorDel as any).data.message || 'Что-то пошло не так',
                placement: 'top',
            });
        }
    }, [errorDel, errorUpdate]);

    useEffect(() => {
        if (isSuccessDel && data.total === 11 && filters.page > 1) {
            setFilters({
                page: 1,
                limit: 10,
                offset: 0,
            });
        }
    }, [isSuccessDel]);

    const onDelete = () => {
        if (selectedRows.length) {
            selectedRows.forEach(id => deleteById(id));
        }
    };

    const onSetStatus = (id, status) => {
        update({
            id,
            body: {
                donate_is_active: status,
            },
        });
    };

    const defaultValues = {
        title: '001 сбор донатов',
        image: '',
        description: '',
        donate_is_active: true,
        random_sum_is_active: true,
        random_sum_min: 50,
        random_sum_max: 100,
        fix_sum_1_is_active: true,
        fix_sum_2_is_active: true,
        fix_sum_3_is_active: true,
        fix_sum_1: 500,
        fix_sum_2: 1000,
        fix_sum_3: 10000,
        fix_sum_1_button: '',
        fix_sum_2_button: '',
        fix_sum_3_button: '',
        random_sum_button: '',
    };

    return (
        <Page title={'Донаты'}>
            {isPhoneConfirmed ? (
                <Button
                    disabled={isFetching}
                    type='primary'
                    style={{ marginBottom: 16 }}
                    onClick={() => {
                        create(defaultValues)
                            .then((res: any) => {
                                navigate(`${res.data.data.id}`);
                            })
                            .catch(() => {
                                notification.error({
                                    message: 'Что-то не так',
                                });
                            });
                    }}
                >
                    <PlusOutlined />
                    Добавить донат
                </Button>
            ) : (
                <Warning
                    text={'Укажите свой номер телефона, чтобы получать платежи'}
                />
            )}

            {size === 'small' ? (
                <>
                    <DonateCard
                        onSetStatus={onSetStatus}
                        source={data?.list}
                        loading={isFetching}
                        deleteById={deleteById}
                        size={size}
                    />
                    <FetchButton
                        total={data?.total}
                        filters={filters}
                        setFilters={setFilters}
                        loading={isFetching}
                    />
                </>
            ) : (
                <DonateTable
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    onSetStatus={onSetStatus}
                    setFilters={setFilters}
                    deleteById={deleteById}
                    create={create}
                    filters={filters}
                    isFetching={isFetching}
                    data={data}
                    onDelete={onDelete}
                    isPhoneConfirmed={isPhoneConfirmed}
                />
            )}
        </Page>
    );
};
