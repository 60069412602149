export const options = props => {
    return {
        chart: {
            type: 'donut',
            animations: {
                enabled: false,
            },
        },
        noData: {
            text: 'За указанный период нет данных',
            style: {
                color: '#25212973',
                fontSize: '14px',
            },
        },
        colors: ['#2F54EB', '#597EF7', '#85A5FF'],
        labels: props.labels,
        legend: {
            width: 120,
            position: 'left',
            formatter: function (val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
            },
            offsetY: 14,
            itemMargin: {
                horizontal: 0,
                vertical: 7,
            },
        },
        title: {
            text: props.title,
            offsetY: 0,
            offsetX: -1,
            style: {
                color: '#25212973',
            },
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            },
        },
        dataLabels: {
            enabled: true,
            dropShadow: false,
        },
    };
};
