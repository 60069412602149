import React, { FC, Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';

import {
    DonateMapper,
    DonateSwitchesEnum,
} from 'features/monetization/pages/donate/DonateMapper';

import { DonateForm } from '../views/DonateForm';
import { Page } from '../../../../../shared/ui/Page';
import {
    useDeleteByIdMutation,
    useGetByIDQuery,
    useUpdateMutation,
} from '../../../services/donate.service';

export interface IFormikInitialValues {
    title: string;
    command: string;
    switches: {
        [DonateSwitchesEnum.receiveDonationsSwitch]: {
            isChecked: boolean;
            description?: string;
            image?: string;
        };
        [DonateSwitchesEnum.arbitraryAmountSwitch]: {
            isChecked: boolean;
            max_price?: number;
            min_price?: number;
            text?: string;
        };
        [DonateSwitchesEnum.fixedAmountSwitch1]: {
            isChecked: boolean;
            sum?: number;
            text?: string;
        };
        [DonateSwitchesEnum.fixedAmountSwitch2]: {
            isChecked: boolean;
            sum?: number;
            text?: string;
        };
        [DonateSwitchesEnum.fixedAmountSwitch3]: {
            isChecked: boolean;
            sum?: number;
            text?: string;
        };
    };
}

export const DonateContainerEdit: FC = () => {
    const { notification } = App.useApp();

    const params = useParams();
    const navigate = useNavigate();
    const { data: donate } = useGetByIDQuery(params.id);
    const [update, { isSuccess, error }] = useUpdateMutation();
    const [deleteById, { isSuccess: isSuccessDel, error: errorDel }] =
        useDeleteByIdMutation();
    const [initialValues, setInitialValues] = useState<IFormikInitialValues>();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Донат обновлен успешно',
                placement: 'top',
            });
            navigate(-1);
        }
        if (isSuccessDel) {
            notification.success({
                message: 'Донат удален успешно',
                placement: 'top',
            });
        }
    }, [isSuccess, isSuccessDel]);

    useEffect(() => {
        if (error || errorDel) {
            notification.error({
                message: (error as any).data.message || 'Что-то пошло не так',
                placement: 'top',
            });
        }
    }, [error, errorDel]);

    useEffect(() => {
        if (donate) {
            setInitialValues(DonateMapper.convertDto(donate));
        }
    }, [donate]);

    const onSave = async (values: IFormikInitialValues) => {
        update({
            id: params.id,
            body: DonateMapper.createDto(values),
        });
    };

    const onDelete = async () => {
        deleteById(params.id).then(() => {
            navigate(-1);
        });
    };

    return (
        <Page title='Донаты'>
            {initialValues && (
                <DonateForm
                    initialValues={initialValues}
                    onSave={onSave}
                    onDelete={onDelete}
                />
            )}
        </Page>
    );
};
