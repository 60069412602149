import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { Button, Row, Space } from 'antd';

import { ReceiveDonationsSwitch } from 'features/monetization/pages/donate/views/components/ReceiveDonationsSwitch';
import { DonateSwitchesEnum } from 'features/monetization/pages/donate/DonateMapper';
import { ArbitraryAmountSwitch } from 'features/monetization/pages/donate/views/components/ArbitraryAmountSwitch';
import { FixedAmountSwitch } from 'features/monetization/pages/donate/views/components/FixedAmountSwitch';

import { HeaderScenarios } from '../../../../communities/components/HeaderScenarios/HeaderScenarios';
import { IFormikInitialValues } from '../containers/DonateContainerEdit';

interface IProps {
    initialValues: IFormikInitialValues;
    onSave: (values) => void;
    onDelete: () => void;
}

export const DonateForm: FC<IProps> = props => {
    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: props.onSave,
    });

    return (
        <Fragment>
            <ContainerWrapper>
                <HeaderScenarios
                    inputValue={formik.values.title}
                    onChangeHeaderInput={event => {
                        formik.setFieldValue('title', event.target.value);
                    }}
                />

                <ReceiveDonationsSwitch
                    control={DonateSwitchesEnum.receiveDonationsSwitch}
                    formik={formik}
                />
                <ArbitraryAmountSwitch
                    control={DonateSwitchesEnum.arbitraryAmountSwitch}
                    formik={formik}
                />
                <FixedAmountSwitch
                    control={DonateSwitchesEnum.fixedAmountSwitch1}
                    formik={formik}
                />
                <FixedAmountSwitch
                    control={DonateSwitchesEnum.fixedAmountSwitch2}
                    formik={formik}
                />
                <FixedAmountSwitch
                    control={DonateSwitchesEnum.fixedAmountSwitch3}
                    formik={formik}
                />
            </ContainerWrapper>
            <Space size='large'>
                <Button type='primary' onClick={formik.submitForm}>
                    Сохранить
                </Button>
                <Button danger onClick={props.onDelete}>
                    Удалить
                </Button>
            </Space>
        </Fragment>
    );
};

const ContainerWrapper = styled.div`
    display: block;
    margin-top: 32px;
    margin-bottom: 33px;
`;
