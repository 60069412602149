import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {
    readonly total: number;
    readonly filters: any;
    readonly setFilters: any;
    loading: boolean;
}
export const FetchButton: React.FC<IProps> = props => {
    const { filters, setFilters, total, loading } = props;
    const isDone = filters.limit >= total;

    const renderTotal = (value: number) => {
        if (value === 0) {
            return null;
        } else {
            return value;
        }
    };

    const handleStatus = () => {
        if (isDone) {
            return <>Загружены все {renderTotal(total)}</>;
        }

        if (loading) {
            return (
                <>
                    Загружаю <LoadingOutlined />
                </>
            );
        } else {
            return 'Загрузить еще';
        }
    };

    return (
        <StyledButton
            disabled={isDone || loading}
            onClick={() => {
                setFilters({
                    ...filters,
                    limit: filters.limit + 5,
                });
            }}
        >
            {handleStatus()}
        </StyledButton>
    );
};

const StyledButton = styled(Button)`
    margin-top: 1.5rem;
    width: 100%;
`;
