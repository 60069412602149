import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Card, Typography, Button, Skeleton, Space, App } from 'antd';

import { useTheme } from '../../../../../../../hooks/useTheme';
import { useReadAuthorByIdQuery } from '../../../../../services/author';
import { useCreateWebinarUserMutation } from '../../../../../services/webinars';

type TProps = {
    readonly action: string;
    readonly id: number;
    readonly link: string;
    readonly uuid: string;
};

export const WebinarAuthorPreviewContainer = (props: TProps) => {
    const { notification } = App.useApp();

    const token = useTheme();

    const {
        data: author,
        isSuccess,
        isError,
    } = useReadAuthorByIdQuery(Number(props.id));

    const [createWebinarUser, { isSuccess: isSuccessCreate, data: response }] =
        useCreateWebinarUserMutation();

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Ошибка при получении информации об авторе',
            });
        }
    }, [isError]);

    useEffect(() => {
        if (isSuccessCreate) {
            window.open(response.redirectUrl);
        }
    }, [isSuccessCreate]);

    return (
        <StyledContent>
            <Card type='inner' style={token.Card}>
                {isSuccess && author ? (
                    <StyledWrapper direction='vertical' size='large'>
                        <Typography.Text>{author.about}</Typography.Text>

                        <Button
                            type={'primary'}
                            style={{ maxWidth: 'max-content' }}
                            onClick={() => createWebinarUser(props.uuid)}
                        >
                            {props.action}
                        </Button>
                    </StyledWrapper>
                ) : (
                    <Skeleton />
                )}
            </Card>
        </StyledContent>
    );
};

const StyledContent = styled.div`
    margin: 24px 0;
`;

const StyledWrapper = styled(Space)`
    max-width: 60%;
`;
