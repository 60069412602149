import React, { FC } from 'react';
import { Flex, Select, Typography } from 'antd';
import { FormikProps } from 'formik';

import { useReadShopByIdQuery } from '../../../../shops/services/shops';
import { IProducts } from '../../../../shops/interfaces/products.interface';
import { ShopOrderType } from '../../../../shops/interfaces/shop.interface';

const filterOption = (
    input: string,
    option?: { label: string; value: string }
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const options: Array<{ value: boolean | string; label: string }> = [
    {
        value: true,
        label: ShopOrderType.pay,
    },
    {
        value: false,
        label: ShopOrderType.preorder,
    },
];

interface BuyableSwitcher {
    readonly shopId: number;
    readonly formik: FormikProps<IProducts>;
    readonly width?: number;
}
export const BuyableSwitcher: FC<BuyableSwitcher> = props => {
    const { formik, shopId } = props;
    const { data: shop, isLoading, isFetching } = useReadShopByIdQuery(+shopId);

    return (
        <Flex gap={8} vertical>
            <Typography.Text>Оформление товара</Typography.Text>
            <Select
                loading={isLoading || isFetching}
                disabled={!shop?.buyable}
                style={{
                    width: props.width,
                }}
                showSearch
                defaultValue={formik?.values?.buyable}
                placeholder='Выберите способ оплаты'
                optionFilterProp='children'
                onChange={value => formik.setFieldValue('buyable', value)}
                filterOption={filterOption}
                options={options}
            />
        </Flex>
    );
};
