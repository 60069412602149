import React, { FC } from 'react';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';

interface IProps {
    formik: any;
    fieldName: string;
}

function getValue(obj, path) {
    const keysArray = path.split('.');
    let newObj = JSON.parse(JSON.stringify(obj));
    for (let i = 0; i < keysArray.length; i++) {
        if (typeof newObj[keysArray[i]] === undefined) {
            return newObj[keysArray[i]];
        }
        newObj = newObj[keysArray[i]];
    }
    return newObj;
}

export const TimePickerFormik: FC<IProps> = props => {
    return (
        <TimePicker
            value={dayjs('00:00:00', 'HH:mm:ss').set(
                'second',
                getValue(props.formik.values, props.fieldName) || 0
            )}
            onChange={value => {
                if (value) {
                    const hourInSeconds = value.get('hour') * 60 * 60;
                    const minInSeconds = value.get('minutes') * 60;
                    props.formik.setFieldValue(
                        props.fieldName,
                        hourInSeconds + minInSeconds + value.get('second')
                    );
                } else {
                    props.formik.setFieldValue(props.fieldName, 0);
                }
            }}
        />
    );
};
