import React, { Fragment } from 'react';
import type { OnChangeValue, ActionMeta } from 'react-select';
import Creatable from 'react-select/creatable';

type Props = {
    readonly options: Array<string>;
    readonly value: Array<string>;
    readonly onChange: (tags: Array<string>) => void;
};

export const TagsSelector: React.FC<Props> = props => {
    const onCreateOption = (value: string): void => {
        props.onChange([...props.value, value]);
    };

    const onChange = (
        newValue: OnChangeValue<{ label: string; value: string }, true>,
        actionMeta: ActionMeta<unknown>
    ): void => {
        props.onChange([...newValue.map(v => v.label)]);
    };

    return (
        <Creatable
            isMulti
            onChange={onChange}
            onCreateOption={onCreateOption}
            options={mapTagsToOptions(props.options)}
            value={mapTagsToOptions(props.value)}
            placeholder='Название тега, до 50 знаков'
            noOptionsMessage={() => 'Пусто'}
            formatCreateLabel={inputValue => (
                <Fragment>
                    <b>Создать тег:</b> &laquo;{inputValue}&raquo;
                </Fragment>
            )}
        />
    );
};

const mapTagsToOptions = (tags: string[]): Array<any> => {
    return tags.map(tag => ({ label: tag, value: tag }));
};
