import React, { Fragment } from 'react';
import { App, Button, Card, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Dropdown } from 'shared/ui/Dropdown';

import { useReadWebinarByIdQuery } from '../../../../../services/webinars';
import postPlaceholder from '../../../../../../../static/images/post_placeholder.png';
import { getSrc } from '../../../../../../../utils/common/getSrc';
import { useTheme } from '../../../../../../../hooks/useTheme';
import { WebinarAuthorPreviewContainer } from '../containers/WebinarAuthorPreviewContainer';
import { ButtonBack } from '../../post/components/ButtonBack';
import { DeleteButton } from '../components/DeleteButton';

const { Text } = Typography;

export const WebinarInfo = () => {
    const token = useTheme();
    const navigate = useNavigate();
    const { notification } = App.useApp();
    const { id } = useParams();

    const { data: webinar, isSuccess: isSuccessRead } =
        useReadWebinarByIdQuery(id);

    const getWebinarType = () => {
        switch (webinar.type) {
            case 'planned':
                return 'Начать вебинар';
            case 'online':
                return 'Смотреть вебинар';
            case 'ended':
                return 'Смотреть запись';
            default:
                return 'Смотреть';
        }
    };

    const handleWebinarLink = link => {
        if (link) {
            navigator.clipboard.writeText(link);
            notification.info({
                message: 'Ссылка скопирована',
                placement: 'top',
                duration: 1,
            });
        } else {
            notification.error({
                message: 'Ссылка не найдена',
                placement: 'top',
                duration: 1.5,
            });
        }
    };

    return (
        <Fragment>
            {isSuccessRead && (
                <>
                    <ButtonBack />
                    <Card type='inner' style={token.Card}>
                        <StyledWrapper>
                            <StyledCover
                                src={
                                    !webinar.background_image
                                        ? postPlaceholder
                                        : getSrc(
                                              `/storage/${webinar.background_image}`
                                          )
                                }
                                alt={'webinar'}
                            />
                            <StyledDescription>
                                <Typography.Title
                                    level={1}
                                    style={{ margin: 0 }}
                                >
                                    {webinar.title}
                                </Typography.Title>

                                <Text type='secondary'>{webinar.start_at}</Text>
                            </StyledDescription>
                            <StyledActions>
                                <Space>
                                    <Button
                                        onClick={() =>
                                            handleWebinarLink(
                                                webinar.external_url
                                            )
                                        }
                                    >
                                        Скопировать ссылку
                                    </Button>

                                    <Dropdown
                                        disabled={false}
                                        autoAdjustOverflow={true}
                                        trigger={['hover']}
                                        destroyPopupOnHide={true}
                                        dropdownContent={() => (
                                            <Fragment>
                                                <Button
                                                    type='text'
                                                    onClick={() =>
                                                        navigate(
                                                            `/courses/author/${webinar.author_id}/webinar-edit/${id}`
                                                        )
                                                    }
                                                >
                                                    Редактировать
                                                </Button>

                                                <DeleteButton
                                                    id={id}
                                                    authorId={webinar.author_id}
                                                />
                                            </Fragment>
                                        )}
                                    >
                                        <Button type='default'>
                                            <EditOutlined />
                                        </Button>
                                    </Dropdown>
                                </Space>
                            </StyledActions>
                        </StyledWrapper>
                    </Card>
                </>
            )}

            {webinar?.author_id && (
                <WebinarAuthorPreviewContainer
                    id={webinar.author_id}
                    action={getWebinarType()}
                    link={webinar.external_url}
                    uuid={webinar.uuid}
                />
            )}
        </Fragment>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledDescription = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 60%;
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: auto;
`;

const StyledCover = styled.img`
    margin-right: 1rem;
    object-fit: cover;
    width: 250px;
    height: 150px;
`;
