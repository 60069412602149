import React, { FC, useEffect } from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import { OnboardingForm } from 'features/communities/components/Onboarding/views/OnboardingForm';
import {
    OnboardingMapper,
    OnboardingSwitchEnum,
} from 'features/communities/components/Onboarding/OnboardingMapper';

import {
    OnboardingCharActionMethodType,
    OnboardingChatActionCheckType,
} from '../../../interfaces/onboarding.interface';
import { createFormData } from '../../../../../utils/common/createFormData';
import { useCreateOnboardingMutation } from '../../../services';

export interface IFormikInitialValues {
    title: string;
    switches: {
        [OnboardingSwitchEnum.greetingsSwitch]: {
            isChecked: boolean;
            greetingImage?: string;
            content?: string;
        };
        [OnboardingSwitchEnum.deleteGreetingsSwitch]: {
            isChecked: boolean;
            duration?: number;
        };
        [OnboardingSwitchEnum.restrictMessageSendingSwitch]: {
            isChecked: boolean;
            duration?: number;
        };
        [OnboardingSwitchEnum.botJoinLimitSwitch]: {
            isChecked: boolean;
            action?: number;
        };
        [OnboardingSwitchEnum.joinLimitSwitch]: {
            isChecked: boolean;
            count?: string;
            duration?: number;
            action?: number;
        };
        [OnboardingSwitchEnum.rtlNameJoinLimitSwitch]: {
            isChecked: boolean;
            action?: number;
        };
        [OnboardingSwitchEnum.inviteBotLimitSwitch]: {
            isChecked: boolean;
            action?: number;
        };
        [OnboardingSwitchEnum.chatJoinSwitch]: {
            isChecked: boolean;
            actionType: OnboardingChatActionCheckType;
            questionImage?: string;
            content?: string;
            method?: OnboardingCharActionMethodType;
            timeout?: number;
        };
    };
    selectedChatIds: string[];
}

export const OnboardingContainerCreate: FC = () => {
    const { notification } = App.useApp();

    const navigate = useNavigate();

    const [createOnboarding, { isSuccess, error }] =
        useCreateOnboardingMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Сценарий создан успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any)?.data?.message || 'Что-то не так',
                placement: 'top',
            });
        }
    }, [error]);

    const initialValues: Partial<IFormikInitialValues> = {
        title: '001 Онбординг',
        switches: {
            greetingsSwitch: {
                isChecked: false,
                content:
                    'Добро пожаловать в чат "название чата"\n' +
                    'Мы ценим каждого участника нашего сообщества, поэтому просим внимательно ознакомиться с правилами общения в закрепленных сообщениях.\n' +
                    'Напишите коротко о себе, чтобы начать знакомство с остальными. Например, свое имя, почему решили вступить в чат и чтобы хотели получить от сообщества.\n' +
                    'Рады будем стать друзьями!',
            },
            deleteGreetingsSwitch: {
                isChecked: false,
            },
            botJoinLimitSwitch: {
                isChecked: false,
            },
            restrictMessageSendingSwitch: {
                isChecked: false,
            },
            joinLimitSwitch: {
                isChecked: false,
            },
            rtlNameJoinLimitSwitch: {
                isChecked: false,
            },
            inviteBotLimitSwitch: {
                isChecked: false,
            },
            chatJoinSwitch: {
                isChecked: false,
                actionType: 'captcha',
            },
        },
        selectedChatIds: [],
    };

    const onSave = async (values: IFormikInitialValues) => {
        const requestObject = OnboardingMapper.createDto(values);
        await createOnboarding(createFormData(requestObject));
    };

    return <OnboardingForm initialValues={initialValues} onSave={onSave} />;
};
