import React from 'react';
import styled from 'styled-components';

type Props = {
    readonly text: string;
    readonly color: string;
    readonly children?: any;
};

export const ListBagde: React.FC<Props> = props => {
    return (
        <StyledWrapper>
            <div>
                <StyledCircle color={props.color} /> {props.text}
            </div>
            <span>{props.children}</span>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
`;

const StyledCircle = styled.div<{ color: string }>`
    background-color: ${props => props.color};
    display: inline-block;
    border-radius: 50%;
    height: 1em;
    width: 1em;
    line-height: 1em;
    vertical-align: middle;
`;
