import { Outlet } from 'react-router-dom';
import { Navigate, RouteObject } from 'react-router';

import { RuleEditorContainer } from 'features/communities/components/RuleEditor/container';
import getEnvConfig from 'utils/config/env';
import { OnboardingContainerCreate } from 'features/communities/components/Onboarding/containers/OnboardingContainerCreate';
import { OnboardingContainerEdit } from 'features/communities/components/Onboarding/containers/OnboardingContainerEdit';

import { Communities } from './pages/Communities';
import { Chats } from './components/Chats';
import { Members } from './components/Members';
import { EventsContainer } from './components/Events/containers/EventsContainer';
import { AllMembersPage } from './components/Members/containers/AllMembersPage';
import { BlackListedMembersPage } from './components/Members/containers/BlackListedMembersPage';
import { WhiteListedMembersPage } from './components/Members/containers/WhiteListedMembersPage';
import { ChatMemberDetailsPage } from './components/Members/containers/ChatMemberDetailsPage';
import { ScenariosContainer } from './components/Scenarios/containers/ScenariosContainer';
import { AntispamContainerCreate } from './components/Antispam/containers/AntispamContainerCreate';
import { AntispamContainerEdit } from './components/Antispam/containers/AntispamContainerEdit';
import { AddToBlackListPage } from './components/Members/containers/AddToBlackListPage';
import { ChatMemberBlackListDetailsPage } from './components/Members/containers/ChatMemberBlackListDetailsPage';
import { ChatMemberWhiteListDetailsPage } from './components/Members/containers/ChatMemberWhiteListDetailsPage';
import { AddToWhiteListPage } from './components/Members/containers/AddToWhiteListPage';
import { BehavioralContainerCreate } from './components/Behavioral/containers/BehavioralContainerCreate';
import { BehavioralContainerEdit } from './components/Behavioral/containers/BehavioralContainerEdit';
import { KnowledgeCreateContainer } from './components/Knowlegde/containers/KnowledgeCreateContainer';
import { KnowledgeEditContainer } from './components/Knowlegde/containers/KnowledgeEditContainer';
import { ReputationContainerEdit } from './components/Reputation/containers/ReputationContainerEdit';
import { ReputationContainerCreate } from './components/Reputation/containers/ReputationContainerCreate';
import { KnowledgeContainer } from './components/Knowlegde/containers/KnowledgeContainer';
import { QuestionEditContainer } from './components/Questions/containers/QuestionEditContainer';
import { QuestionCreateContainer } from './components/Questions/containers/QuestionCreateContainer';
import { AIKnowledgeContainer } from './components/Knowlegde/containers/AIKlownledgeCotainer';
import { AIKnowledgeEditContainer } from './components/Knowlegde/containers/AIKnowledgeEditContainer';
import { AiQuestionEditContainer } from './components/Questions/containers/AiQuestionEditContainer';

const routes: RouteObject[] = [
    {
        path: 'communities',
        element: <Communities />,
        children: [
            {
                path: '',
                element: <Navigate to='chats' />,
            },
            {
                path: 'chats',
                element: <Chats />,
            },
            {
                path: 'rules',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <ScenariosContainer />,
                    },
                    {
                        path: 'conditional',
                        element: <RuleEditorContainer />,
                    },
                    {
                        path: 'conditional/:id',
                        element: <RuleEditorContainer />,
                    },
                    {
                        path: 'antispam',
                        element: <AntispamContainerCreate />,
                    },
                    {
                        path: 'antispam/:id',
                        element: <AntispamContainerEdit />,
                    },
                    {
                        path: 'behavioral',
                        element: <BehavioralContainerCreate />,
                    },
                    {
                        path: 'behavioral/:id',
                        element: <BehavioralContainerEdit />,
                    },

                    {
                        path: 'onboarding',
                        element: <OnboardingContainerCreate />,
                    },
                    {
                        path: 'onboarding/:id',
                        element: <OnboardingContainerEdit />,
                    },
                    {
                        path: 'reputation',
                        element: <ReputationContainerCreate />,
                    },
                    {
                        path: 'reputation/:id',
                        element: <ReputationContainerEdit />,
                    },
                ],
            },
            {
                path: 'members',
                element: <Members />,
                children: [
                    {
                        path: '',
                        element: (
                            <Navigate
                                to={`${
                                    getEnvConfig().PUBLIC_PATH
                                }/communities/members/all-members`}
                            />
                        ),
                    },
                    {
                        path: 'all-members',
                        element: <AllMembersPage />,
                    },
                    {
                        path: 'blacklisted',
                        element: <BlackListedMembersPage />,
                    },
                    {
                        path: 'whitelisted',
                        element: <WhiteListedMembersPage />,
                    },
                    {
                        path: 'view-member/:id',
                        element: <ChatMemberDetailsPage />,
                    },
                    {
                        path: 'view-member-blacklist/:id',
                        element: <ChatMemberBlackListDetailsPage />,
                    },
                    {
                        path: 'view-member-whitelist/:id',
                        element: <ChatMemberWhiteListDetailsPage />,
                    },
                    {
                        path: 'add-to-blacklist',
                        element: <AddToBlackListPage />,
                    },
                    {
                        path: 'add-to-whitelist',
                        element: <AddToWhiteListPage />,
                    },
                ],
            },
            {
                path: 'events',
                element: <EventsContainer />,
            },
            {
                path: 'knowledge',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <KnowledgeContainer />,
                    },

                    {
                        path: ':id',
                        element: <KnowledgeEditContainer />,
                    },
                    {
                        path: ':id/question-create',
                        element: <QuestionCreateContainer />,
                    },

                    {
                        path: 'question/:id',
                        element: <QuestionEditContainer />,
                    },

                    {
                        path: 'create/:id',
                        element: <KnowledgeCreateContainer />,
                    },
                ],
            },
            {
                path: 'ai-knowledge',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <AIKnowledgeContainer />,
                    },

                    {
                        path: 'ai-question/:id',
                        element: <AIKnowledgeEditContainer />,
                    },

                    {
                        path: 'question/:id',
                        element: <AiQuestionEditContainer />,
                    },
                ],
            },
        ],
    },
];

export default routes;
