import React, { Fragment, useEffect, useState } from 'react';
import { App, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { GenericModal } from 'shared/ui/GenericModal';

import {
    useCreateCategoryMutation,
    useDeleteCategoryMutation,
    useReadCategoryQuery,
    useUpdateCategoryMutation,
} from '../../../services';
import { CategoryTable } from '../view/CategoryTable';
import { CategoryCreate } from '../view/CategoryCreate';
import { IQueryCategory } from '../../../interfaces/category.interface';

export const CategoryContainer = props => {
    const { notification } = App.useApp();

    const [selectedRows, setSelectedRows] = useState<React.Key[] | any>([]);

    const [filters, setFilters] = useState({});
    const [value, setValue] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [categoryId, setCategoryId] = useState<string>('');

    const { data: source = { items: [], total: 0 }, isFetching } =
        useReadCategoryQuery({ knowledge_id: props.knowledgeId });
    const [
        deleteCategory,
        { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess },
    ] = useDeleteCategoryMutation();

    const [createCategory, { isLoading, isSuccess }] =
        useCreateCategoryMutation();

    const [updateCategory] = useUpdateCategoryMutation();
    const isEdit = categoryId !== '';
    const handleFiltersUpdate = update => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({});
        }
    };

    useEffect(() => {
        if (isDeleteSuccess) {
            notification.success({
                message: 'Категория успешно удалена',
                placement: 'top',
            });
            setSelectedRows([]);
        }
    }, [isDeleteSuccess]);

    const renameCategory = category => {
        setIsOpen(true);
        setValue(category.name);
        setCategoryId(category.id);
    };

    const handleCreateCategory = () => {
        setIsOpen(true);
        setValue('');
        setCategoryId('');
    };

    const handleSaveCategory = async val => {
        const values = Array.from(
            new Set(source.items.map(category => category.name))
        );

        if (values.includes(val)) {
            return notification.error({
                message: 'Категория с таким названием уже существует',
            });
        }

        try {
            if (isEdit) {
                const data = {
                    id: categoryId,
                    name: value,
                };
                const response: any = await updateCategory(data);
                if ('data' in response) {
                    notification.success({
                        message: `Категория переименована успешно`,
                        placement: 'top',
                    });
                }
            } else {
                const data: any = {
                    knowledge_id: props.knowledgeId,
                    name: value,
                };

                const response: any = await createCategory(data);
                notification.success({
                    message: `Категория ${response?.data?.data?.name} создана успешно`,
                    placement: 'top',
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsOpen(false);
        }
    };

    const handleDelete = async () => {
        await Promise.all(selectedRows.map(deleteCategory));
    };

    return (
        <Fragment>
            <GenericModal
                footer={null}
                title={'Категории'}
                width={760}
                open={props.open}
                onCancel={() => {
                    props.setIsOpenCategory(false);
                }}
            >
                <StyledActions>
                    {selectedRows?.length > 0 ? (
                        <>
                            <Button onClick={() => handleDelete()}>
                                Удалить
                            </Button>
                            <StyledSelectedCounterText>
                                Выбрано: {selectedRows.length}
                            </StyledSelectedCounterText>
                        </>
                    ) : (
                        <Button
                            disabled={isFetching}
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => handleCreateCategory()}
                        >
                            Добавить категорию
                        </Button>
                    )}
                </StyledActions>
                <StyledWrapper>
                    <CategoryTable
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        loading={isFetching}
                        deleteCategory={deleteCategory}
                        renameCategory={renameCategory}
                        onFiltersUpdate={handleFiltersUpdate}
                        source={source}
                    />
                    <CategoryCreate
                        isLoading={isDeleteLoading || isFetching}
                        isEdit={isEdit}
                        onConfirm={handleSaveCategory}
                        knowledgeId={props.knowledgeId}
                        setValue={setValue}
                        value={value}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        categoryId={categoryId}
                    />
                </StyledWrapper>
            </GenericModal>
        </Fragment>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 10;
    margin: 15px 0;
`;

const StyledSelectedCounterText = styled.div`
    margin-top: 0;
    margin-left: 20px;
`;
