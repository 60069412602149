import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { IPartProps } from '../../../../../interfaces/publications.interface';
import { blobToBase64 } from '../../../../../../../utils/common/blobToBase64';
import { getSrc } from '../../../../../../../utils/common/getSrc';

export const Image: React.FC<IPartProps> = props => {
    const { order, setPostContent, postContent, content } = props;

    const [value, setValue] = useState(content);

    const imageRef = useRef(null);

    useEffect(() => {
        if (imageRef.current) {
            blobToBase64(imageRef.current).then(val => setValue(val));
        }
    }, [imageRef.current]);

    const handleChange = (source, index) => {
        setPostContent(
            postContent.map(elem =>
                elem.order === index
                    ? { ...elem, file: source.file }
                    : { ...elem }
            )
        );
    };

    return (
        <Fragment>
            <div style={{ marginTop: 10 }}>
                <Upload
                    maxCount={1}
                    listType='picture-card'
                    className='avatar-uploader'
                    showUploadList={false}
                    beforeUpload={() => false}
                    onChange={value => {
                        imageRef.current = value?.file;
                        handleChange(value, order);
                    }}
                >
                    {value ? (
                        <StyledImage
                            src={props.isEdit ? getSrc(value) : value}
                            alt='Картинка'
                        />
                    ) : (
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Картинка</div>
                        </div>
                    )}
                </Upload>
            </div>
        </Fragment>
    );
};

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
