import React, { FC, useEffect } from 'react';
import { Row, Space, Switch, Typography } from 'antd';
import styled from 'styled-components';

import { TariffSwitchesEnum } from '../../TariffMappter';

interface IProps {
    control: TariffSwitchesEnum.demoAccess;
    formik: any;
}

export const DemoAccessSwitch: FC<IProps> = props => {
    const { formik, control } = props;
    const isDisabled = !props.formik.values.switches.paidAccess.isChecked;

    useEffect(() => {
        if (isDisabled) {
            formik.setFieldValue(`switches.${control}.isChecked`, false);
        }
    }, [isDisabled]);

    return (
        <StyledRow>
            <StyledSwitch
                disabled={isDisabled}
                checked={formik.values.switches[control].isChecked}
                onChange={value => {
                    formik.setFieldValue(
                        `switches.${control}.isChecked`,
                        value
                    );
                    formik.setFieldValue(
                        `switches.${control}.test_period_is_active`,
                        value
                    );
                }}
            />
            <Space direction='vertical' size='middle'>
                <StyledTitle level={5}>Пробный доступ на 3 дня</StyledTitle>
            </Space>
        </StyledRow>
    );
};

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledRow = styled(Row)`
    margin-bottom: 8px;
`;
