import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Button, Card, Divider } from 'antd';
import { ToolFilled } from '@ant-design/icons';

import { Dropdown } from 'shared/ui/Dropdown';
import { RulesDict } from 'features/communities/interfaces/conditional-rules.interface';

import { ITree } from './tree';
import { NodeActions } from './NodeActions';
import { ExpressionNode } from './ExpressionNode';
import { NodeDescription } from './NodeDescription';

type TProps = {
    readonly tree: ITree;
    readonly dict: RulesDict;
    readonly level: number;
    readonly onExpressionAdd: (root_id: string) => void;
    readonly onExpressionGroupAdd: (root_id: string) => void;
    readonly onDelete: (root_id: string) => void;
    readonly onDublicate: (root_id: string) => void;
    readonly toGroup: (root_id: string) => void;
    readonly onConditionChange: (node: ITree) => void;
    readonly onExpressionUpdate: (root_id: string, args) => void;
};

export const RuleTree: React.FC<TProps> = props => {
    const conditions = [...props.tree.children];
    return (
        <StyledNode type='inner' $indent={props.level > 1}>
            {conditions.map((condition, index) => (
                <StyledRow key={index}>
                    <NodeDescription
                        onConditionChange={() =>
                            props.onConditionChange(condition)
                        }
                        index={index}
                        type={props.tree.type}
                    />
                    {condition.type === 'EXPRESSION' ? (
                        <ExpressionNode
                            dict={props.dict}
                            onUpdate={update => {
                                props.onExpressionUpdate(condition.id, update);
                            }}
                            subject={condition.subject}
                            action={condition.action}
                            value={condition.value}
                        />
                    ) : (
                        <RuleTree
                            {...props}
                            tree={condition}
                            level={props.level + 1}
                        />
                    )}
                    <Dropdown
                        dropdownContent={() => {
                            const canDelete =
                                index !== 0 ||
                                (props.level > 1 && conditions.length === 1);
                            return (
                                <Fragment>
                                    {condition.type === 'EXPRESSION' &&
                                        props.level < 3 && (
                                            <Button
                                                type='text'
                                                onClick={() =>
                                                    props.toGroup(condition.id)
                                                }
                                            >
                                                Превратить в группу
                                            </Button>
                                        )}
                                    <Button
                                        type='text'
                                        onClick={() =>
                                            props.onDublicate(condition.id)
                                        }
                                    >
                                        Дублировать
                                    </Button>

                                    {canDelete && (
                                        <Fragment>
                                            <Divider style={{ margin: 0 }} />
                                            <Button
                                                onClick={() =>
                                                    props.onDelete(condition.id)
                                                }
                                                type='text'
                                            >
                                                Удалить
                                            </Button>
                                        </Fragment>
                                    )}
                                </Fragment>
                            );
                        }}
                    >
                        <StyledToolIcon />
                    </Dropdown>
                </StyledRow>
            ))}
            <NodeActions
                id={props.tree.id}
                level={props.level}
                onExpressionAdd={props.onExpressionAdd}
                onExpressionGroupAdd={props.onExpressionGroupAdd}
            />
        </StyledNode>
    );
};

const StyledNode = styled(Card)<{ $indent: boolean }>`
    padding-left: 0 !important;
    width: 100%;

    ${props =>
        !!props.$indent &&
        css`
            background-color: rgba(37, 33, 41, 0.02);
        `}

    .ant-card-body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 18px;

        @media (max-width: 576px) {
            padding: 0 5px;
        }
    }
`;

const StyledRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
`;

const StyledToolIcon = styled(ToolFilled)`
    color: rgba(37, 33, 41, 0.45);
    margin-left: auto;
`;
