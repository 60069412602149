import React, { useEffect, useState } from 'react';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';

import { IPageProps } from '../services/interfaces';

export const PostSort: React.FC<IPageProps> = props => {
    const { filters, setFilters } = props;

    const [type, setType] = useState(filters.sort);

    useEffect(() => {
        setFilters({ ...filters, sort: type });
    }, []);
    const onChange = (e: RadioChangeEvent) => {
        setType(e.target.value);
        setFilters({ ...filters, sort: e.target.value });
    };

    return (
        <Radio.Group onChange={onChange} defaultValue='desc'>
            <Radio.Button value='desc'>Лучшие</Radio.Button>
            <Radio.Button value='asc'>Худшие</Radio.Button>
        </Radio.Group>
    );
};
