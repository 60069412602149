import React from 'react';
import { Button, Card, Space, Tooltip, Typography } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { FavouriteSwitch } from '../components/FavouriteSwitch';
import { getSrc } from '../../../../../utils/common/getSrc';
import { useTheme } from '../../../../../hooks/useTheme';
import { IPublicationResponse } from '../../../interfaces/publications.interface';

interface IProps {
    readonly id?: number;
    post: IPublicationResponse;
}
export const PostMainInfo: React.FC<IProps> = props => {
    const { post } = props;
    const token = useTheme();
    const navigate = useNavigate();

    const onEdit = () => {
        navigate(`/courses/author/${post.author_id}/post-edit/${props.id}`);
    };

    return (
        <StyledCard type='inner' style={token.Card}>
            <StyledWrapper>
                {!post?.background_image ? null : (
                    <StyledImg
                        src={getSrc(`/storage/${post?.background_image}`)}
                        alt={'webinar'}
                        width={'200px'}
                    />
                )}
                <StyledDescription>
                    <Typography.Title
                        level={1}
                        style={{ margin: 0, marginBottom: '1.5rem' }}
                    >
                        {post?.title}
                    </Typography.Title>
                    <Typography.Text>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: post?.description,
                            }}
                        ></span>
                    </Typography.Text>
                </StyledDescription>
                <StyledActions>
                    <Space>
                        {/*<Tooltip title={'Посмотреть как читатель'}>
                            <Button>
                                <EyeOutlined
                                    onClick={() =>
                                        window.open(
                                            `/courses/member/post/${post.uuid}`,
                                            '_blank'
                                        )
                                    }
                                />
                            </Button>
                        </Tooltip>*/}
                        {props.id && (
                            <Button onClick={() => onEdit()}>
                                <EditOutlined />
                            </Button>
                        )}

                        <FavouriteSwitch
                            id={post.id}
                            isFavourite={post.is_favourite}
                        />
                    </Space>
                </StyledActions>
            </StyledWrapper>
        </StyledCard>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledImg = styled.img`
    margin-right: 1.5rem;
`;

const StyledDescription = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 60%;
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: auto;
`;

const StyledCard = styled(Card)`
    width: 100%;
    margin-top: 1rem;
`;
