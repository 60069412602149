import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App, notification } from 'antd';

import { IFormikInitialValues } from 'features/monetization/pages/tariff/containers/TariffContainerAdd';

import { TariffMapper, TariffSwitchesEnum } from '../TariffMappter';
import { Page } from '../../../../../shared/ui/Page';
import { TariffForm } from '../views/TariffForm';
import {
    useGetByIDQuery,
    useDeleteByIdMutation,
    useUpdateMutation,
} from '../../../services/tariff.service';

export const TariffContainerEdit: FC = () => {
    const { notification } = App.useApp();
    const params = useParams();
    const navigate = useNavigate();
    const { data: tariff } = useGetByIDQuery({ id: params.id });
    const [update, { isSuccess, error }] = useUpdateMutation();
    const [deleteById, { isSuccess: isSuccessDel, error: errorDel }] =
        useDeleteByIdMutation();
    const [initialValues, setInitialValues] = useState<IFormikInitialValues>();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Тариф обновлен успешно',
                placement: 'top',
            });
            navigate(-1);
        }
        if (isSuccessDel) {
            notification.success({
                message: 'Тариф удален успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess, isSuccessDel]);

    useEffect(() => {
        if (error || errorDel) {
            notification.error({
                message: (error as any).data.message || 'Что-то пошло не так',
                placement: 'top',
            });
        }
    }, [error, errorDel]);

    useEffect(() => {
        if (tariff) {
            setInitialValues(TariffMapper.convertDto(tariff));
        }
    }, [tariff]);

    const onSave = async (values: IFormikInitialValues) => {
        const price = values.switches[TariffSwitchesEnum.paidAccess].price;
        const paidAccess =
            values.switches[TariffSwitchesEnum.paidAccess].isChecked;

        if (paidAccess) {
            if (!price) {
                return notification.info({
                    message: 'Укажите стоимость за месяц',
                });
            }
            if (price < 50) {
                return notification.info({
                    message:
                        'Минимальная стоимость тарифа должна быть 50 рублей',
                });
            }
        }

        if (!values.community_id) {
            return notification.error({
                message: 'Нужно выбрать сообщество',
            });
        }

        await update({
            id: params.id,
            body: TariffMapper.createDto(values),
        });
    };

    const onDelete = async () => {
        await deleteById(params.id);
    };

    return (
        <Page title='Тарифы'>
            {initialValues && (
                <TariffForm
                    initialValues={initialValues}
                    onSave={onSave}
                    onDelete={onDelete}
                    tariffHref={tariff.tariff_page}
                    command={tariff['bot command']}
                />
            )}
        </Page>
    );
};
