import React, { FC, Fragment } from 'react';
import {
    Input,
    InputNumber,
    Radio,
    Space,
    Switch,
    Typography,
    Upload,
} from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

import { useOrientations } from 'hooks/useOrientation/useOrientation';

import { TextEditor } from '../../../../../../shared/ui/TextEditor/TextEditor';
import { validateImages } from '../../../../../../utils/common/validateImages';
import { BehavioralSwitchesEnum } from '../../BehavioralMapper';

interface IProps {
    formik: any;
    img2Ref: React.MutableRefObject<any>;
    img2: any;
}

export const RestrictedWordsSwitch: FC<IProps> = props => {
    const orientation = useOrientations();
    return (
        <Fragment>
            <StyledFlexSecondChild align='start' size='small'>
                <StyledSwitch
                    checked={
                        props.formik.values.switches[
                            BehavioralSwitchesEnum.restrictedWordsSwitch
                        ].isChecked
                    }
                    onChange={value => {
                        props.formik.setFieldValue(
                            `switches.${BehavioralSwitchesEnum.restrictedWordsSwitch}.isChecked`,
                            value
                        );
                    }}
                />
                <Space direction='vertical' style={{ width: '100%' }}>
                    <StyledTitle level={5}>
                        Запрещённые слова и выражения
                    </StyledTitle>
                    {props.formik.values.switches[
                        BehavioralSwitchesEnum.restrictedWordsSwitch
                    ].isChecked && (
                        <StyledWrap>
                            <Typography.Text>
                                Перечислите запрещённые слова и выражения, по
                                одному на строке
                            </Typography.Text>
                            <Input.TextArea
                                autoSize={{ minRows: 5, maxRows: 7 }}
                                value={props.formik.values.switches[
                                    BehavioralSwitchesEnum.restrictedWordsSwitch
                                ]?.restricted_words?.join('\n')}
                                onChange={event => {
                                    props.formik.setFieldValue(
                                        `switches.${BehavioralSwitchesEnum.restrictedWordsSwitch}.restricted_words`,
                                        event.target.value.split('\n')
                                    );
                                }}
                            />
                            <Typography.Text>Предупреждение</Typography.Text>
                            <StyledInner>
                                <div style={{ marginTop: 10 }}>
                                    <Upload
                                        listType='picture-card'
                                        className='avatar-uploader'
                                        showUploadList={false}
                                        beforeUpload={() => false}
                                        onChange={value => {
                                            if (validateImages(value.file)) {
                                                props.img2Ref.current =
                                                    value.file;
                                                props.formik.setFieldValue(
                                                    `switches.${BehavioralSwitchesEnum.restrictedWordsSwitch}.warning_image`,
                                                    value.file
                                                );
                                            }
                                        }}
                                    >
                                        {props.formik.values.switches[
                                            BehavioralSwitchesEnum
                                                .restrictedWordsSwitch
                                        ]?.warning_image ? (
                                            <StyledImage
                                                src={props.img2}
                                                alt='second'
                                            />
                                        ) : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>
                                                    Картинка
                                                </div>
                                            </div>
                                        )}
                                    </Upload>
                                </div>
                                <TextEditor
                                    containerHeight={84}
                                    value={
                                        props.formik.values.switches[
                                            BehavioralSwitchesEnum
                                                .restrictedWordsSwitch
                                        ]?.warning
                                    }
                                    onChange={value => {
                                        props.formik.setFieldValue(
                                            `switches.${BehavioralSwitchesEnum.restrictedWordsSwitch}.warning`,
                                            value
                                        );
                                    }}
                                />
                            </StyledInner>
                            {/* <Checkbox
                                checked={formik.values.quiet_on_restricted_words}
                                onChange={value => {
                                    formik.setValues(
                                        Object.assign(formik.values, {
                                            quiet_on_restricted_words:
                                                value.target.checked,
                                        })
                                    );
                                }}
                            >
                                Отправить без звука
                            </Checkbox> */}
                            <Typography.Text>
                                Допустимое количество нарушений
                            </Typography.Text>
                            <InputNumber
                                min={1}
                                max={10}
                                value={
                                    props.formik.values.switches[
                                        BehavioralSwitchesEnum
                                            .restrictedWordsSwitch
                                    ]?.max_violation_times
                                }
                                onChange={value => {
                                    props.formik.setFieldValue(
                                        `switches.${BehavioralSwitchesEnum.restrictedWordsSwitch}.max_violation_times`,
                                        value
                                    );
                                }}
                            />
                            <Typography.Text>
                                Действие за превышение количества нарушений
                            </Typography.Text>
                            <Radio.Group
                                buttonStyle='solid'
                                value={props.formik.values.switches[
                                    BehavioralSwitchesEnum.restrictedWordsSwitch
                                ]?.action?.toString()}
                                onChange={event => {
                                    props.formik.setFieldValue(
                                        `switches.${BehavioralSwitchesEnum.restrictedWordsSwitch}.action`,
                                        +event.target.value
                                    );
                                }}
                            >
                                <StyledRadioWrap>
                                    {orientation === 'mobile' ? (
                                        <>
                                            <Radio value='4'>Забанить</Radio>
                                            <Radio value='5'>
                                                Запретить сообщения
                                            </Radio>
                                        </>
                                    ) : (
                                        <>
                                            <Radio.Button value='4'>
                                                Забанить
                                            </Radio.Button>
                                            <Radio.Button value='5'>
                                                Запретить сообщения
                                            </Radio.Button>
                                        </>
                                    )}
                                </StyledRadioWrap>
                            </Radio.Group>
                        </StyledWrap>
                    )}
                </Space>
            </StyledFlexSecondChild>
        </Fragment>
    );
};

const StyledRadioWrap = styled.div`
    display: flex;

    @media (max-width: 576px) {
        gap: 16px;
        flex-direction: column;
    }
`;

const StyledInner = styled.div`
    display: flex;
    gap: 16px;
    align-items: start;

    @media (max-width: 992px) {
        flex-direction: column;
    }
`;

const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;

    @media (max-width: 576px) {
        margin-left: -68px;
    }
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledFlexSecondChild = styled(Space)`
    margin-bottom: 8px;
    width: 100%;

    & > div:nth-child(2) {
        display: flex;
        flex: 1;
    }
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
