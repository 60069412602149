import React, { FC } from 'react';
import { InputNumber, Space, Switch, Typography, Upload } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

import { validateImages } from 'utils/common/validateImages';
import { TextEditor } from 'shared/ui/TextEditor/TextEditor';

import { ReputationSwitchesEnum } from '../../ReputationMapper';

interface IProps {
    imgRef: React.MutableRefObject<any>;
    formik: any;
    img: any;
}

export const NotifyChangeRateSwitch: FC<IProps> = props => {
    return (
        <StyledSpace align='start' size='small'>
            <StyledSwitch
                checked={
                    props.formik.values.switches[
                        ReputationSwitchesEnum.notifyChangeRateSwitch
                    ].isChecked
                }
                onChange={value => {
                    props.formik.setFieldValue(
                        `switches.${ReputationSwitchesEnum.notifyChangeRateSwitch}.isChecked`,
                        value
                    );
                    props.formik.setFieldValue(
                        `switches.${ReputationSwitchesEnum.notifyChangeRateSwitch}.notify_about_rate_change`,
                        value
                    );
                }}
            />
            <Space direction='vertical'>
                <StyledTitle level={5}>
                    Оповещать об изменении репутации
                </StyledTitle>
                {props.formik.values.switches[
                    ReputationSwitchesEnum.notifyChangeRateSwitch
                ].isChecked && (
                    <>
                        <Typography.Text>
                            Оповещать при изменении каждые
                        </Typography.Text>
                        <div>
                            <InputNumber
                                value={
                                    props.formik.values.switches[
                                        ReputationSwitchesEnum
                                            .notifyChangeRateSwitch
                                    ]?.notify_about_rate_change_points
                                }
                                onChange={value => {
                                    props.formik.setFieldValue(
                                        `switches.${ReputationSwitchesEnum.notifyChangeRateSwitch}.notify_about_rate_change_points`,
                                        value
                                    );
                                }}
                            />
                            <span style={{ marginLeft: 8 }}>
                                пунктов репутации
                            </span>
                        </div>
                        <div>
                            <div>Текст оповещения</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginTop: 10 }}>
                                    <Upload
                                        maxCount={1}
                                        listType='picture-card'
                                        className='avatar-uploader'
                                        showUploadList={false}
                                        beforeUpload={() => false}
                                        onChange={value => {
                                            if (validateImages(value.file)) {
                                                props.imgRef.current =
                                                    value.file;
                                                props.formik.setFieldValue(
                                                    `switches.${ReputationSwitchesEnum.notifyChangeRateSwitch}.notify_about_rate_change_image`,
                                                    value.file
                                                );
                                            }
                                        }}
                                    >
                                        {props.formik.values.switches[
                                            ReputationSwitchesEnum
                                                .notifyChangeRateSwitch
                                        ]?.notify_about_rate_change_image ? (
                                            <StyledImage
                                                src={props.img}
                                                alt={'alt'}
                                            />
                                        ) : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>
                                                    Картинка
                                                </div>
                                            </div>
                                        )}
                                    </Upload>
                                </div>
                                <TextEditor
                                    containerHeight={84}
                                    value={
                                        props.formik.values.switches[
                                            ReputationSwitchesEnum
                                                .notifyChangeRateSwitch
                                        ]?.notify_about_rate_change_message
                                    }
                                    onChange={value => {
                                        props.formik.setFieldValue(
                                            `switches.${ReputationSwitchesEnum.notifyChangeRateSwitch}.notify_about_rate_change_message`,
                                            value
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
            </Space>
        </StyledSpace>
    );
};

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledSpace = styled(Space)`
    margin-bottom: 32px;
`;
