import React from 'react';
import { Col, Row, Space, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { validateImages } from '../../../../../../../utils/common/validateImages';
import { TextEditor } from '../../../../../../../shared/ui/TextEditor/TextEditor';

export const Description = props => {
    const { imgRef, formik, img, type } = props;

    console.log(img);

    return (
        <StyledRow justify={'start'} wrap>
            <div style={{ marginTop: 10, marginRight: 14 }}>
                <Upload
                    style={{ marginTop: 10 }}
                    maxCount={1}
                    listType='picture-card'
                    className='avatar-uploader'
                    showUploadList={false}
                    beforeUpload={() => false}
                    onChange={value => {
                        if (validateImages(value.file)) {
                            imgRef.current = value.file;
                            formik.setFieldValue(props.type, value.file);
                        }
                    }}
                >
                    {formik.values?.image || formik.values?.background_image ? (
                        <StyledImage src={img} alt='image for publication' />
                    ) : (
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Обложка</div>
                        </div>
                    )}
                </Upload>
            </div>

            <Col xs={24} sm={18} md={12}>
                <TextEditor
                    containerHeight={75}
                    placeholder={'Описание'}
                    value={formik?.values?.description || ''}
                    onChange={value => {
                        formik.setFieldValue('description', value);
                    }}
                />
            </Col>
        </StyledRow>
    );
};

const StyledRow = styled(Row)`
    margin: 1rem 0;
`;

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
