import React, { Fragment } from 'react';

import { useGetMessagesQuery } from 'features/analytics/services';
import { IStatisticFilters } from 'features/analytics/services/interfaces';

import { UsersTableView } from '../views/UsersTable';

type Props = {
    readonly filters: IStatisticFilters;
    readonly onFiltersUpdate: (update) => void;
};

export const UsersTableContainer: React.FC<Props> = props => {
    const {
        data: messages = {
            total_messages: 0,
            message_members_statistic: [],
        },
    } = useGetMessagesQuery({
        community_id: props.filters.chat,
        period: props.filters.period || null,
    });

    return (
        <Fragment>
            <UsersTableView
                filters={props.filters}
                onFiltersUpdate={props.onFiltersUpdate}
                source={messages.message_members_statistic}
            />
        </Fragment>
    );
};
