import React, { FC, Fragment, ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Typography, Image } from 'antd';
import styled from 'styled-components';

import { getSrc } from '../../../../../../utils/common/getSrc';

interface ILocationState {
    context?: string;
    image?: string;
    answer?: {
        context?: string;
        image?: string;
    };
    data?: ILocationState;
}

export const QuestionInTable: FC<ILocationState> = (props): ReactElement => {
    const isTableView = props.data;
    const location = useLocation();
    const navigate = useNavigate();
    const data = isTableView ? props.data : (location.state as ILocationState);
    return (
        <StyledWrapper>
            {!isTableView && (
                <Fragment>
                    {/*
                      <StyledHead>{data.title}</StyledHead>
*/}

                    <StyledActions>
                        <Button type={'link'} onClick={() => navigate(-1)}>
                            Все вопросы
                        </Button>
                    </StyledActions>
                </Fragment>
            )}
            <StyledItem>
                <StyledTitle level={5}>Вопрос:</StyledTitle>
                <StyledText>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: data?.context,
                        }}
                    ></span>
                </StyledText>
                {data?.image && (
                    <Image width={'300px'} src={getSrc(data.image)} />
                )}
            </StyledItem>
            <StyledItem>
                <StyledTitle level={5}>Ответ:</StyledTitle>
                <StyledText>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: data?.answer?.context,
                        }}
                    ></span>
                </StyledText>

                {data?.answer?.image && (
                    <Image width={'300px'} src={getSrc(data.answer.image)} />
                )}
            </StyledItem>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StyledItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
`;

const StyledActions = styled.div`
    display: flex;
    gap: 20px;
`;
const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledText = styled.p``;
