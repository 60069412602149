import React, { useState } from 'react';

import { IChatMemberQueryParams } from 'features/communities/interfaces';
import { useReadChatMembersQuery } from 'features/communities/services';

import { MembersTableSelector } from '../views/MembersTableSelector';

type Props = {
    readonly value: number[];
    readonly onChange: (ids: number[]) => void;
};
export const MembersTableSelectorContainer: React.FC<Props> = props => {
    const [filters, setFilters] = useState<IChatMemberQueryParams>({});
    const { data: source = { items: [], total: 0 }, isFetching } =
        useReadChatMembersQuery(filters);

    const handleFiltersUpdate = (update: IChatMemberQueryParams | null) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters({});
        }
    };

    return (
        <MembersTableSelector
            value={props.value}
            onSelect={props.onChange}
            filters={filters}
            onFiltersUpdate={handleFiltersUpdate}
            source={source}
            loading={isFetching}
        />
    );
};
