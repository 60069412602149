import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Empty, Table } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { TableFilter } from 'shared/ui/TableFilter';
import {
    QueryRules,
    Rule,
} from 'features/communities/interfaces/rules.interface';

import { RuleType } from '../../../../../shared/contants/rule-types';
import { ScenarioCreate } from '../components/ScenarioCreate';

type TProps = {
    readonly items: Array<Rule>;
    readonly loading?: boolean;
    readonly filters?: QueryRules;
    readonly onDelete: (id: string, type: string) => void;
    readonly onView: (id: string, type: string) => void;
    readonly onFiltersUpdate: (value: QueryRules) => void;
    readonly onBulkRemove: (row: string[] | any) => void;
    readonly isSuccess: boolean;
    readonly rowSelection: any;
    readonly selectedRows: any;
};

export const ScenariosTable: React.FC<TProps> = props => {
    const [currentPage, setCurrentPage] = useState(1);

    const { rowSelection, selectedRows } = props;

    useEffect(() => {
        if (sessionStorage.scenariosCurrentPage) {
            setCurrentPage(
                Number(sessionStorage.getItem('scenariosCurrentPage'))
            );
        }
    }, [sessionStorage.scenariosCurrentPage]);

    const onChange = page => {
        sessionStorage.setItem('scenariosCurrentPage', page);
        setCurrentPage(page);
    };

    const typesHashMap: Record<RuleType, string> = {
        if_then_rule: 'Если-То',
        moderation_rule: 'Правила поведения',
        antispam_rule: 'Антиспам',
        onboarding_rule: 'Онбординг',
        reputation_rule: 'Репутация',
    };

    return (
        <StyledWrapper>
            <StyledActions>
                {!!selectedRows.length && (
                    <Fragment>
                        <Button
                            onClick={() => props.onBulkRemove(selectedRows)}
                            type={'primary'}
                            style={{ marginRight: '20px' }}
                        >
                            Удалить
                        </Button>
                        <span>Выбрано сценариев:{selectedRows?.length}</span>
                    </Fragment>
                )}
                <ScenarioCreate />
            </StyledActions>
            <StyledTable
                loading={props.loading}
                rowKey={r => r.id || r.uuid}
                scroll={{ x: 900 }}
                rowSelection={rowSelection}
                locale={{ emptyText: <Empty description='Нет сценариев' /> }}
                dataSource={props.items}
                pagination={{
                    defaultCurrent: 1,
                    current: currentPage,
                    showTitle: true,
                    onChange: page => onChange(page),
                }}
            >
                <Table.Column
                    title={
                        <TableFilter<string>
                            icon={<FilterOutlined />}
                            title='Тип'
                            onChange={rule_type => {
                                props.onFiltersUpdate({ rule_type });
                            }}
                            value={props.filters.rule_type}
                        >
                            <TableFilter.Selector
                                options={[
                                    {
                                        value: RuleType.Conditional,
                                        label: 'Если-То',
                                    },
                                    {
                                        value: RuleType.Onboarding,
                                        label: 'Онбординг',
                                    },
                                    {
                                        value: RuleType.Antispam,
                                        label: 'Антиспам',
                                    },
                                    {
                                        value: RuleType.Behavioral,
                                        label: 'Правило поведения',
                                    },
                                    {
                                        value: RuleType.Reputation,
                                        label: 'Правило репутации',
                                    },
                                ]}
                            />
                        </TableFilter>
                    }
                    dataIndex={'type'}
                    render={type => typesHashMap[type] || ''}
                />
                <Table.Column
                    title={
                        <TableFilter
                            title='Название'
                            onChange={(rule_title: string) =>
                                props.onFiltersUpdate({ rule_title })
                            }
                        >
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    render={({ id, uuid, type, title, name }) => (
                        <Button
                            style={{ paddingLeft: 0 }}
                            onClick={() => {
                                props.onView(id || uuid, type);
                            }}
                            type='link'
                        >
                            {title || name}
                        </Button>
                    )}
                />
                <Table.Column
                    title='Количество чатов'
                    render={rule => {
                        return rule.communities?.length;
                    }}
                    sorter={(a: { communities }, b: { communities }) =>
                        a.communities?.length - b.communities?.length
                    }
                />
                <Table.Column
                    title='Действие'
                    render={({ id, uuid, type }) => (
                        <Button
                            style={{ paddingLeft: 0 }}
                            type='link'
                            onClick={() => {
                                props.onDelete(id || uuid, type);
                            }}
                        >
                            Удалить
                        </Button>
                    )}
                />
            </StyledTable>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StyledTable = styled(Table<Rule>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
