import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

export const ListTitle = ({ type }) => {
    switch (type) {
        case 'online':
            return <StyledTitle level={2}>В эфире</StyledTitle>;
        case 'ended':
            return <StyledTitle level={2}>Завершенные</StyledTitle>;
        case 'planned':
            return <StyledTitle level={2}>Запланированы</StyledTitle>;
    }
};

const StyledTitle = styled(Typography.Title)`
    margin: 0.25rem 0;
`;
