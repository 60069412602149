import React, { Fragment } from 'react';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import getEnvConfig from '../../../../../utils/config/env';
import { Dropdown } from '../../../../../shared/ui/Dropdown';

export const ScenarioCreate = props => {
    const navigate = useNavigate();

    return (
        <StyledDropdown
            dropdownContent={() => (
                <Fragment>
                    <Button
                        type='text'
                        onClick={() =>
                            navigate(
                                `${
                                    getEnvConfig().PUBLIC_PATH
                                }/communities/rules/onboarding`
                            )
                        }
                    >
                        Онбординг
                    </Button>
                    <Button
                        type='text'
                        onClick={() =>
                            navigate(
                                `${
                                    getEnvConfig().PUBLIC_PATH
                                }/communities/rules/behavioral`
                            )
                        }
                    >
                        Правила поведения
                    </Button>
                    <Button
                        type='text'
                        onClick={() => {
                            navigate(
                                `${
                                    getEnvConfig().PUBLIC_PATH
                                }/communities/rules/conditional`
                            );
                        }}
                    >
                        Если-То
                    </Button>
                    <Button
                        type='text'
                        onClick={() =>
                            navigate(
                                `${
                                    getEnvConfig().PUBLIC_PATH
                                }/communities/rules/antispam`
                            )
                        }
                    >
                        Антиспам
                    </Button>
                    {/*<Button
                                type='text'
                                onClick={() =>
                                    navigate(
                                        `${
                                            getEnvConfig().PUBLIC_PATH
                                        }/communities/rules/reputation`
                                    )
                                }
                            >
                                Репутация
                            </Button>*/}
                </Fragment>
            )}
        >
            <Button type='default'>
                Новый сценарий <DownOutlined />
            </Button>
        </StyledDropdown>
    );
};

const StyledDropdown = styled(Dropdown)`
    margin-left: auto;
    cursor: pointer;
`;
