import React, { useState } from 'react';
import { Checkbox, List, Row, Space, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { boolean } from 'yup';
import styled from 'styled-components';

import Secondary from '../../../../../shared/ui/SecondaryText';
import { getDeclension } from '../../../../../utils/common/getDeclension';
import { ICommunity } from '../../../interfaces';

type TProps = {
    readonly source: {
        items: Array<ICommunity>;
    };

    readonly selectedRows: number[];
    readonly handleRowsChange: (selectedRows) => void;
};

export const ChatsCard: React.FC<TProps> = props => {
    const { source, selectedRows } = props;

    const handleRowSelect = id => {
        const isSelected = selectedRows.indexOf(id) !== -1;

        if (isSelected) {
            const filtered = selectedRows.filter(elem => elem !== id);
            props.handleRowsChange(filtered);
        } else {
            props.handleRowsChange([...selectedRows, id]);
        }
    };

    return (
        <List
            dataSource={source.items}
            rowKey={chat => chat.id}
            renderItem={chat => (
                <StyledListItem isSelected={selectedRows.includes(chat.id)}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            width: '100%',
                        }}
                    >
                        <div style={{ marginRight: 16 }}>
                            <Checkbox
                                checked={selectedRows.includes(chat.id)}
                                onChange={() => {
                                    handleRowSelect(chat.id);
                                }}
                            />
                        </div>

                        <div style={{ width: '100%' }}>
                            <Typography.Paragraph
                                style={{ marginBottom: 0 }}
                                strong
                                ellipsis={{
                                    rows: 2,
                                }}
                            >
                                {chat.title}
                            </Typography.Paragraph>

                            <div>
                                {chat.tags.map((tag, index) => (
                                    <Tag key={index}>{tag.name}</Tag>
                                ))}
                            </div>

                            <div
                                style={{
                                    marginTop: 8,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Secondary>
                                    {dayjs(chat.created_at).format(
                                        'DD.MM.YYYY, HH:mm'
                                    )}
                                </Secondary>

                                <Secondary>
                                    <span>
                                        {chat.tags.length === 0 ? (
                                            'нет тегов'
                                        ) : (
                                            <>
                                                {chat.tags.length}
                                                {getDeclension(
                                                    chat.tags.length,
                                                    'тег',
                                                    'тега',
                                                    'тегов'
                                                )}
                                            </>
                                        )}
                                    </span>
                                </Secondary>
                            </div>
                        </div>
                    </div>
                </StyledListItem>
            )}
        />
    );
};

const StyledListItem = styled(List.Item)<{ isSelected: boolean }>`
    background: ${props => (props.isSelected ? '#F4F0FF' : 'white')};
`;
