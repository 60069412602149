import React, { FC } from 'react';
import { InputNumber, Select, Space, Switch, Typography, Upload } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

import { TimePickerFormik } from 'shared/ui/Form/Fields/TimePickerFormik';
import { validateImages } from 'utils/common/validateImages';
import { TextEditor } from 'shared/ui/TextEditor/TextEditor';

import { RatePeriodEnum } from '../../../../interfaces/reputation.interface';
import { ReputationSwitchesEnum } from '../../ReputationMapper';

interface IProps {
    imgRef: React.MutableRefObject<any>;
    formik: any;
    img: any;
}

export const RestrictAccumulateRateSwitch: FC<IProps> = props => {
    return (
        <StyledSpace align='start' size='small'>
            <StyledSwitch
                checked={
                    props.formik.values.switches[
                        ReputationSwitchesEnum.restrictAccumulateRateSwitch
                    ].isChecked
                }
                onChange={value => {
                    props.formik.setFieldValue(
                        `switches.${ReputationSwitchesEnum.restrictAccumulateRateSwitch}.isChecked`,
                        value
                    );
                    props.formik.setFieldValue(
                        `switches.${ReputationSwitchesEnum.restrictAccumulateRateSwitch}.restrict_accumulate_rate`,
                        value
                    );
                }}
            />
            <Space direction='vertical'>
                <StyledTitle level={5}>
                    Ограничить время накопления репутации
                </StyledTitle>
                {props.formik.values.switches[
                    ReputationSwitchesEnum.restrictAccumulateRateSwitch
                ].isChecked && (
                    <>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <div>Обнулять каждый</div>
                                <Select
                                    value={
                                        props.formik.values.switches[
                                            ReputationSwitchesEnum
                                                .restrictAccumulateRateSwitch
                                        ]?.restrict_accumulate_rate_period
                                    }
                                    onChange={value => {
                                        props.formik.setFieldValue(
                                            `switches.${ReputationSwitchesEnum.restrictAccumulateRateSwitch}.restrict_accumulate_rate_period`,
                                            value
                                        );
                                    }}
                                    style={{ minWidth: 200 }}
                                    options={[
                                        {
                                            value: RatePeriodEnum.first_day_of_week,
                                            label: 'Первый день недели',
                                        },
                                        {
                                            value: RatePeriodEnum.first_day_of_month,
                                            label: 'Первый день месяца',
                                        },
                                        {
                                            value: RatePeriodEnum.first_day_of_year,
                                            label: 'Первый день года',
                                        },
                                    ]}
                                />
                            </div>
                            <div style={{ marginLeft: 12 }}>
                                <div>Время оповещения</div>
                                <TimePickerFormik
                                    formik={props.formik}
                                    fieldName={`switches.${ReputationSwitchesEnum.restrictAccumulateRateSwitch}.restrict_accumulate_rate_time`}
                                />
                            </div>
                        </div>
                        <div>
                            <div>Текст оповещения при обнулении</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginTop: 10 }}>
                                    <Upload
                                        maxCount={1}
                                        listType='picture-card'
                                        className='avatar-uploader'
                                        showUploadList={false}
                                        beforeUpload={() => false}
                                        onChange={value => {
                                            if (validateImages(value.file)) {
                                                props.imgRef.current =
                                                    value.file;
                                                props.formik.setFieldValue(
                                                    `switches.${ReputationSwitchesEnum.restrictAccumulateRateSwitch}.restrict_accumulate_rate_image`,
                                                    value.file
                                                );
                                            }
                                        }}
                                    >
                                        {props.formik.values.switches[
                                            ReputationSwitchesEnum
                                                .restrictAccumulateRateSwitch
                                        ]?.restrict_accumulate_rate_image ? (
                                            <StyledImage
                                                src={props.img}
                                                alt={'alt'}
                                            />
                                        ) : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>
                                                    Картинка
                                                </div>
                                            </div>
                                        )}
                                    </Upload>
                                </div>
                                <TextEditor
                                    containerHeight={84}
                                    value={
                                        props.formik.values.switches[
                                            ReputationSwitchesEnum
                                                .restrictAccumulateRateSwitch
                                        ]?.restrict_accumulate_rate_message
                                    }
                                    onChange={value => {
                                        props.formik.setFieldValue(
                                            `switches.${ReputationSwitchesEnum.restrictAccumulateRateSwitch}.restrict_accumulate_rate_message`,
                                            value
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
            </Space>
        </StyledSpace>
    );
};

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledSpace = styled(Space)`
    margin-bottom: 32px;
`;
