import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Space, Typography } from 'antd';

import Image54 from 'static/landing/image 54.png';
import StaticText from 'shared/layouts/LandingLayout/content.json';
import getEnvConfig from 'utils/config/env';

import { Title } from './Title';
import { Container } from './Container';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';

/* eslint-disable */
interface IProps {
    innerRef: React.MutableRefObject<any>;
}
/* eslint-enable */

export const SectionFirst: FC<IProps> = ({ innerRef }) => {
    const { size } = useWindowSize();

    return (
        <Container>
            <Row ref={innerRef} id={'header'} style={{ padding: '4rem 0' }}>
                <Col xs={24} md={9}>
                    <Title level={1} text={StaticText.sectionFirst.title} />

                    <Space direction={'vertical'}>
                        <StyledList>
                            <ul style={{ listStyle: 'disc inside' }}>
                                <li>
                                    {StaticText.sectionFirst.options.option1}
                                </li>
                                <li>
                                    {StaticText.sectionFirst.options.option2}
                                </li>
                                <li>
                                    {StaticText.sectionFirst.options.option3}
                                </li>
                            </ul>
                        </StyledList>

                        <Space style={{ margin: '1.5rem 0' }}>
                            <Typography.Text style={{ margin: '24px 0' }}>
                                Получи бесплатный доступ на 3 дня
                            </Typography.Text>
                        </Space>

                        <Space direction={'horizontal'} size={'middle'}>
                            <Link
                                /*to={`${
                                    getEnvConfig().PUBLIC_PATH
                                }/auth/sign-up?reason=free`}*/
                                to={'https://shop.spodial.com/'}
                            >
                                <Button type='primary'>
                                    {
                                        StaticText.sectionFirst.actions
                                            .buttonText1
                                    }
                                </Button>
                            </Link>
                            <Button
                                onClick={() =>
                                    innerRef.current.scrollIntoView({
                                        block: 'center',
                                        inline: 'center',
                                    })
                                }
                            >
                                {StaticText.sectionFirst.actions.buttonText2}
                            </Button>
                        </Space>
                    </Space>
                </Col>

                <Col xs={24} md={8} push={size === Size.small ? 0 : 3}>
                    <StyledImage>
                        <img
                            width={'100%'}
                            src={Image54}
                            alt={
                                'Spodial - профессиональный инструмент комьюнити менеджера'
                            }
                        />
                    </StyledImage>
                </Col>
            </Row>
        </Container>
    );
};

const StyledList = styled.div`
    ul {
        list-style-position: inside;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
    }
`;

const StyledImage = styled(Space)`
    justify-content: flex-end;
    @media (max-width: 768px) {
        margin-top: 1rem;
    }
`;
