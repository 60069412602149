import qs from 'query-string';

import { IStatisticFilters } from '../services/interfaces';

export function handleFiltersUpdate(update: Partial<IStatisticFilters>): void {
    if (update) {
        this.setFilters({
            ...this.filters,
            ...update,
        });

        this.setSearchParams(
            qs.stringify({
                ...this.filters,
                ...update,
            })
        );
    } else {
        this.setFilters({
            tag_names: [],
            period: 'week',
            viewAnalitycsType: 'all',
            chat: '',
        });
    }
}
