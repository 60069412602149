import React from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';
import { Space } from 'antd';

import { useTheme } from 'hooks/useTheme';

export const Card = props => {
    const token = useTheme();

    return (
        <CardWrapper
            style={{
                ...token.Card,
            }}
        >
            <Space direction={'vertical'} style={{ width: '100%' }}>
                <Chart
                    options={props.options(props)}
                    series={props.data}
                    type={props.type}
                />
            </Space>
        </CardWrapper>
    );
};

const CardWrapper = styled.div`
    height: 100%;
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    color: rgba(37, 33, 41, 0.88);

    padding: 16px 24px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
`;
