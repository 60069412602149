import React, { Dispatch, FC, Fragment, SetStateAction } from 'react';
import { Button, Space, Table, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { CopyWithTooltip } from 'shared/ui/CopyWithTooltip/CopyWithTooltip';
import { TableFilter } from 'shared/ui/TableFilter';
import { TableSort } from 'shared/ui/TableSort/TableSort';

import { Dropdown } from '../../../../../shared/ui/Dropdown';
import { ActionSwitch } from './components/ActionSwitch';
import { Filters } from '../utils/filters';

interface IProps {
    onSetStatus: (id: number, status: boolean) => void;
    setFilters: Dispatch<SetStateAction<Filters>>;
    deleteById: (id: number) => void;
    create: (values: any) => Promise<any>;
    isFetching: boolean;
    filters: Filters;
    data: {
        list: IList[];
        total: number;
    };
    setSelectedRows: any;
    selectedRows: number[];
    onDelete: any;
    onSetActive?: any;
    isPhoneConfirmed: boolean;
}

interface IList {
    command: string;
    created_at: Date;
    description: string | null;
    donate_is_active: boolean;
    id: number;
    image: string;
    payment_count: number;
    payment_sum: number;
    title: string;
}

export const DonateTable: FC<IProps> = props => {
    const {
        onDelete,
        onSetActive,
        filters,
        setFilters,
        data,
        selectedRows,
        isPhoneConfirmed,
    } = props;

    const columns = [
        {
            title: () => (
                <TableFilter
                    title='Название доната'
                    onChange={name => {
                        setFilters(
                            Object.assign({}, filters, { search: name })
                        );
                    }}
                    value={filters.search}
                >
                    <TableFilter.Text
                        onClear={() => {
                            const _filters = { ...filters };
                            delete _filters.search;
                            setFilters(_filters);
                        }}
                    />
                </TableFilter>
            ),
            dataIndex: 'title',
            key: 'id',
            render: text => text,
            ellipsis: true,
        },
        {
            title: 'Команда',
            ellipsis: true,
            dataIndex: 'command',
            key: 'id',
            render: text => <CopyWithTooltip ellipsis text={text} />,
        },
        {
            title: () => (
                <TableSort
                    setFilters={setFilters}
                    filters={filters}
                    fieldName={'updated_at'}
                >
                    Время создания
                </TableSort>
            ),
            dataIndex: 'created_at',
            key: 'created_at',
            render: date => dayjs(date).format('DD.MM.YYYY, HH:mm'),
        },
        {
            title: () => (
                <TableSort
                    setFilters={setFilters}
                    filters={filters}
                    fieldName={'payments_count'}
                >
                    Платежей
                </TableSort>
            ),
            dataIndex: 'payments_count',
            key: 'payments_count',
            render: text => text,
        },
        {
            title: () => (
                <TableSort
                    setFilters={setFilters}
                    filters={filters}
                    fieldName={'payments_sum'}
                >
                    Сумма
                </TableSort>
            ),
            dataIndex: 'payments_sum',
            key: 'payments_sum',
            render: text => text,
        },
        {
            title: () => (
                <TableSort
                    setFilters={setFilters}
                    filters={filters}
                    fieldName={'donate_is_active'}
                >
                    Статус
                </TableSort>
            ),
            key: 'donate_is_active',
            render: record => (
                <>
                    <StyledDropdown
                        disabled={!isPhoneConfirmed}
                        dropdownContent={() => (
                            <>
                                <Button
                                    type='text'
                                    onClick={() =>
                                        props.onSetStatus(record.id, true)
                                    }
                                >
                                    Активен
                                </Button>
                                <Button
                                    type='text'
                                    onClick={() =>
                                        props.onSetStatus(record.id, false)
                                    }
                                >
                                    Отключён
                                </Button>
                            </>
                        )}
                    >
                        <Button type='default'>
                            {record?.donate_is_active ? 'Активен' : 'Отключён'}{' '}
                            <DownOutlined />
                        </Button>
                    </StyledDropdown>
                </>
            ),
        },
        {
            title: 'Действие',
            dataIndex: 'id',
            key: 'id',
            render: id => (
                <ActionSwitch id={id} deleteById={props.deleteById} />
            ),
        },
    ];

    const pagination = {
        total: data?.total || 0,
        showTotal: () => `Донатов: ${data?.total || 0}`,
        onChange: (page, pageSize) => {
            setFilters({
                ...filters,
                offset: (page - 1) * pageSize,
                limit: pageSize,
                page: page,
            });
        },
        showQuickJumper: false,
        showSizeChanger: false,
        current: filters.page || 1,
    };

    return (
        <>
            {Boolean(selectedRows.length) && (
                <Space size='middle' style={{ marginBottom: 16 }}>
                    <Button type='primary' onClick={onDelete}>
                        Удалить
                    </Button>
                    <StyledDropdownWithBorder
                        dropdownContent={() => (
                            <Fragment>
                                <Button
                                    disabled={!isPhoneConfirmed}
                                    type='text'
                                    onClick={() => onSetActive(true)}
                                >
                                    Активен
                                </Button>
                                <Button
                                    type='text'
                                    onClick={() => onSetActive(false)}
                                >
                                    Отключён
                                </Button>
                            </Fragment>
                        )}
                    >
                        <Button>
                            Статус <DownOutlined />
                        </Button>
                    </StyledDropdownWithBorder>
                    <div>
                        <Typography.Text>
                            Выбрано: {selectedRows.length || 0}
                        </Typography.Text>
                    </div>
                </Space>
            )}
            <Table
                scroll={{ x: 900 }}
                loading={props.isFetching}
                columns={columns}
                rowKey={entity => entity.id}
                dataSource={data?.list || []}
                pagination={pagination}
            />
        </>
    );
};

const StyledDropdownWithBorder = styled(Dropdown)`
    margin-left: auto;
    cursor: pointer;
`;

const StyledDropdown = styled(Dropdown)`
    cursor: pointer;

    button {
        text-align: start;
        padding-left: 0;
        padding-right: 0;
        width: auto;
        border: none;
        box-shadow: none;
    }
`;
