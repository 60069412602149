import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

type TProps<T> = {
    readonly children: React.ReactElement | string;
    readonly fieldName: string;
    readonly filters: any;
    readonly setFilters: (values) => void;
    readonly onClick?: () => void;
    readonly icon?: React.ReactElement;
};

export const TableSort = <T,>(props: TProps<T>) => {
    const [sortStatus, setSortStatus] = useState('none');

    useEffect(() => {
        if (props.filters.sort_field !== props.fieldName) {
            setSortStatus('none');
        }
    }, [props.fieldName, props.filters.sort_field]);

    const statusSwitch = () => {
        switch (sortStatus) {
            case 'up': {
                props.setFilters(
                    Object.assign({}, props.filters, {
                        sort_field: props.fieldName,
                        sort_direction: 'desc',
                    })
                );
                setSortStatus('down');
                break;
            }
            case 'down': {
                const _filters = { ...props.filters };
                delete _filters.sort_field;
                delete _filters.sort_direction;
                props.setFilters(_filters);
                setSortStatus('none');
                break;
            }
            case 'none': {
                props.setFilters(
                    Object.assign({}, props.filters, {
                        sort_field: props.fieldName,
                        sort_direction: 'asc',
                    })
                );
                setSortStatus('up');
                break;
            }
            default: {
                props.setFilters(
                    Object.assign({}, props.filters, {
                        sort_field: props.fieldName,
                        sort_direction: 'asc',
                    })
                );
                setSortStatus('up');
            }
        }
    };

    return (
        <StyledWrapper
            onClick={() => {
                statusSwitch();
            }}
        >
            <StyledTitle>{props.children}</StyledTitle>
            <StyledFilterButton>
                {sortStatus === 'up' &&
                props.filters.sort_field === props.fieldName ? (
                    <CaretUpOutlinedActive width={12} />
                ) : (
                    <CaretUpOutlined width={12} />
                )}
                {sortStatus === 'down' &&
                props.filters.sort_field === props.fieldName ? (
                    <CaretDownOutlinedActive width={12} />
                ) : (
                    <CaretDownOutlined width={12} />
                )}
            </StyledFilterButton>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
`;

const StyledTitle = styled.div`
    font-weight: bold;
`;

const CaretUpOutlinedActive = styled(CaretUpOutlined)`
    & > svg path {
        fill: #6257d2 !important;
    }
`;

const CaretDownOutlinedActive = styled(CaretDownOutlined)`
    & > svg path {
        fill: #6257d2 !important;
    }
`;

const StyledFilterButton = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
        width: 12px;
        height: 12px;
    }

    span:nth-child(2) {
        margin-top: -0.3em;
    }

    span svg path {
        fill: rgba(37, 33, 41, 0.29);
    }
`;
