const eventTypes = [
    'Удаление Юзербота из группы',
    'Команда /start отпавлена боту',
    'Команда /start отправлена в группу',
    'Команда /help отправлена в группу',
    'Узнать Telegram ID',
    'Узнать ID чата',
    'Команда /tariff отпавлена боту',
    'Команда /tariff отправлена в группу',
    'Команда /donate отправлена в группу',
    'Отписаться',
    'Сохранить вопрос-ответ',
    'Команда /help отпавлена боту',
    'Команда /donate отпавлена боту',
    'Материальная помощь',
    'Вызов команды "Мои подписки"',
    'Поиск подписки',
    'Поиск по базе знаний',
    'Бот добавлен в группу',
    'Юзербот добавлен в группу',
    'Новый пользователь в группе',
    'Создание группы',
    'Создание канала',
    'Бот стал админом в группе',
    'Новое фото группы',
    'Удаление группы',
    'Новое название группы',
    'Удаление участника',
    'Отправлено приветственное сообщение',
    'Отправлен telegram id',
    'Отправлен chat id',
    'Отправлены тарифы',
    'Отправлены тарифы в чат группы',
    'Отправлена команда /help в чат группы',
    'Отправлена команда /help боту',
    'Отправлена команда /donate в чат группы',
    'Участник отправил пожертвование',
    'Отправлено id подписки',
    'Установить тариф участнику',
    'Отправить материальную помощь',
    'Команда faq',
    'Команда "Мои подписки',
    'Команда вызова базы знаний',
    'Сохраненик вопрос-ответ',
    'Команда Отписаться',
];

export default eventTypes;
