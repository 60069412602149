import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { FilterOutlined, FilterFilled } from '@ant-design/icons';

import { useReadChatsQuery } from '../../../services';
import { TableFilter } from '../../../../../shared/ui/TableFilter';
import { useTheme } from '../../../../../hooks/useTheme';

export const ChatsFilter = props => {
    const token = useTheme();

    const { filters, onChange } = props;

    const [list, setList] = useState([]);

    const { data: source = { items: [] }, isSuccess } = useReadChatsQuery(null);

    useEffect(() => {
        if (isSuccess) {
            const chats = source?.items.map((k, index) => ({
                id: k.id,
                key: index,
                value: k.title,
                text: k.title,
            }));
            setList(chats);
        }
    }, [isSuccess]);

    const isSelected = filters.community_id !== null;

    return (
        <TableFilter
            icon={
                isSelected ? (
                    <FilterFilled style={{ color: token.colorPrimary }} />
                ) : (
                    <FilterOutlined />
                )
            }
            title={props.title}
            onChange={() => {
                onChange({
                    filters: filters,
                    community_id: null,
                });
            }}
            closeOnSelect={true}
        >
            <StyledSelect
                allowClear={isSelected}
                showSearch
                onSelect={(e, chat) => {
                    onChange({
                        community_id: chat.id,
                    });
                }}
                defaultValue={'Выберите чат'}
                placeholder={'Выберите чат'}
                options={list}
            />
        </TableFilter>
    );
};

const StyledSelect = styled(Select)`
    max-width: 100%;
    min-width: 280px;
`;
