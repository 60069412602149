import React, { useEffect, useState } from 'react';
import { App, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { GenericModal } from '../../../../../../shared/ui/GenericModal';
import { Confirm } from '../../components/Confirm';
import { useDeleteAuthorMutation } from '../../../../services/author';
import { useReadUserQuery } from '../../../../../user/services';
import getEnvConfig from '../../../../../../utils/config/env';

export const AuthorDeleteContainer = props => {
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [checkBoxStatus, setCheckboxStatus] = useState(false);
    const [deleteAuthor, { isSuccess, isLoading }] = useDeleteAuthorMutation();
    const { refetch } = useReadUserQuery();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Ваша страница успешно удалена',
            });
            navigate(`${getEnvConfig().PUBLIC_PATH}/profile`);
            refetch();
        }
    }, [isSuccess]);

    return (
        <GenericModal
            onConfirm={deleteAuthor}
            onCancel={() => props.setOpenDel(false)}
            title={'Удалить страницу'}
            open={props.open}
            okText={'Удалить'}
            okButtonProps={{
                danger: true,
                disabled: !checkBoxStatus || isLoading,
            }}
            footer={[
                <Space
                    key={'delete-footer'}
                    direction={'horizontal'}
                    style={{ justifyContent: 'space-between', width: '100%' }}
                >
                    <Typography.Text type='secondary'>
                        Передумали?
                    </Typography.Text>
                    <Typography.Link
                        onClick={() => props.setOpenDel(false)}
                        style={{ color: '#6257d2' }}
                    >
                        Вернуться к редактированию
                    </Typography.Link>
                </Space>,
            ]}
        >
            <Confirm
                checkBoxStatus={checkBoxStatus}
                setCheckboxStatus={setCheckboxStatus}
            >
                <Typography.Text>
                    Внимание! Восстановить страницу автора после удаления будет
                    невозможно. Если вы удалите страницу автора, то вместе с
                    этим удалите и все свои авторские материалы и доступны
                    читателям они больше никогда не будут.
                </Typography.Text>
            </Confirm>
        </GenericModal>
    );
};
