import React, { useState } from 'react';

import { IEventQueryParams } from 'features/communities/interfaces';
import { useFetchEventsQuery } from 'features/communities/services';

import { EventsTable } from '../views/EventsTable';
import { initialFilters } from '../utils/filters';
import { ClearFilters } from '../../../../../shared/ui/ClearFilters';
import { EventsCard } from '../views/EventsCard';
import { FetchButton } from '../../../../../shared/ui/FetchButton';
import { useWindowSize } from '../../../../../hooks/useWindowSize';

export const EventsContainer = () => {
    const { size } = useWindowSize();
    const [filters, setFilters] = useState<IEventQueryParams>(initialFilters);

    const { data: source = { items: [], total: 0 }, isFetching } =
        useFetchEventsQuery(filters);

    const handleFiltersUpdate = (update: IEventQueryParams | null) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters(initialFilters);
        }
    };

    const blockProps = {
        filters: filters,
        setFilters: setFilters,
    };

    return size === 'small' ? (
        <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            <EventsCard source={source} />
            <FetchButton
                {...blockProps}
                total={source.total}
                loading={isFetching}
            />
        </>
    ) : (
        <>
            <ClearFilters {...blockProps} initialFilters={initialFilters} />
            <EventsTable
                {...blockProps}
                onFiltersUpdate={handleFiltersUpdate}
                loading={isFetching}
                source={source}
            />
        </>
    );
};
