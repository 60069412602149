import React, { useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
    useReadPublicationByIdQuery,
    useDeletePublicationPartMutation,
} from '../../../../../services/publications';
import { useTheme } from '../../../../../../../hooks/useTheme';

export const ButtonDelete = props => {
    const { id } = useParams();
    const { colorError } = useTheme();

    const {
        data: post,
        isSuccess: isSuccessRead,
        isLoading: isLoadingRead,
    } = useReadPublicationByIdQuery(id);

    const [deletePart, { isSuccess: isSuccessDeletePart }] =
        useDeletePublicationPartMutation();

    useEffect(() => {
        if (isSuccessDeletePart) props.setOrder(props.order - 1);
    }, [isSuccessDeletePart]);

    const { postContent, setPostContent } = props;
    const deleteItem = async id => {
        const filteredContent = postContent.filter((elem, key) => key !== id);
        const isPartExist = post?.parts.map(elem => elem.id).includes(id);

        if (id === 'new') {
            const filteredNewContent = postContent.filter(
                elem => elem.order !== props.order
            );
            props.setOrder(props.order);
            setPostContent(filteredNewContent);
        }

        if (isPartExist) {
            const filteredContent = postContent.filter(
                (elem, key) => elem.id !== id
            );
            await deletePart(id);
            setPostContent(filteredContent);
            props.setOrder(props.order);
        }
    };

    useEffect(() => {
        if (isSuccessDeletePart) {
            if (post?.parts?.length > 0) {
                const last = post?.parts.reduce((acc, curr) =>
                    acc.order > curr.order ? acc : curr
                );
                props.setOrder(Number(last.order + 1));
            }
        }
    }, [isSuccessDeletePart]);

    return (
        <StyledDeleteButton
            color={colorError}
            style={props.style}
            onClick={() => deleteItem(props.id)}
        />
    );
};

const StyledDeleteButton = styled(DeleteOutlined)`
    :hover {
        color: ${props => props.color};
    }
`;
