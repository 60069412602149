import React, { useEffect } from 'react';
import { App } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { useCreateQuestionMutation } from 'features/communities/services';

import { createFormData } from '../../../../../utils/common/createFormData';
import { QuestionForm } from '../views/QuestionForm';
import {
    IQuestionCreate,
    QuestionStatus,
} from '../../../interfaces/questions.interface';

export const QuestionCreateContainer = props => {
    const { notification } = App.useApp();
    const navigate = useNavigate();
    const { id } = useParams();

    const [createQuestion, { isSuccess, error, isLoading }] =
        useCreateQuestionMutation();
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Вопрос успешно создан',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any).data.message || 'Что-то не так',
                placement: 'top',
            });
        }
    }, [error]);

    const onSave = async (values: IQuestionCreate) => {
        const requestObject: IQuestionCreate = {
            knowledge_id: values.knowledge_id,
            question_status: QuestionStatus.PUBLISHED,
            category_id: values.category_id,
            question_text: values.question_text,
            answer_text: values.answer_text,
            question_image: values.question_image as File,
            answer_image: values.answer_image as File,
        };
        if (!values.category_id) delete requestObject.category_id;
        await createQuestion(createFormData(requestObject));
    };

    const initialValues = {
        knowledge_id: Number(id),
        question_status: QuestionStatus.PUBLISHED,
        category_id: null,
        question_text: '',
        question_image: '',
        answer_text: '',
        answer_image: '',
        category_name: '',
    };

    return (
        initialValues && (
            <QuestionForm
                initialValues={initialValues}
                onSave={onSave}
                loading={isLoading}
                knowledgeId={props.knowledgeIds}
            />
        )
    );
};
