import React from 'react';
import { Col, Row } from 'antd';

import {
    IStatisticFilters,
    IStatisticsMembers,
} from 'features/analytics/services/interfaces';
import { useGetMembersQuery } from 'features/analytics/services';

import { StatsWidget } from '../StatsWidget';

type Props = {
    readonly members?: IStatisticsMembers;
    readonly filters: IStatisticFilters;
};

export const HeadStatistics: React.FC<Props> = props => {
    const { filters } = props;

    const {
        data: members = {
            totalMembers: 0,
            activeMembers: {
                value: 0,
                delta: 0,
            },
            joinMembers: {
                value: 0,
                delta: 0,
            },
            leftMembers: {
                value: 0,
                delta: 0,
            },
        },
        isFetching,
    } = useGetMembersQuery({
        period: filters.period,
        community_id: Number(filters.chat) || null,
    });

    const widgetSettings = {
        width: 'auto',
        isLoading: isFetching,
    };

    const colSettings = {
        xs: 24,
        sm: 12,
        md: 6,
    };

    return (
        <Row gutter={[16, 16]}>
            <Col {...colSettings}>
                <StatsWidget
                    {...widgetSettings}
                    title='Всего участников'
                    value={members.totalMembers}
                />
            </Col>
            <Col {...colSettings}>
                <StatsWidget
                    {...widgetSettings}
                    title='Новых участников'
                    highlight
                    value={members.joinMembers.value}
                    difference={members.joinMembers.delta}
                />
            </Col>
            <Col {...colSettings}>
                <StatsWidget
                    {...widgetSettings}
                    title='Активных участников'
                    value={members.activeMembers.value}
                    difference={members.activeMembers.delta}
                />
            </Col>
            <Col {...colSettings}>
                <StatsWidget
                    {...widgetSettings}
                    title='Ушедших участников'
                    highlight
                    value={members.leftMembers.value}
                    difference={members.leftMembers.delta}
                />
            </Col>
        </Row>
    );
};
