import { Avatar, Space, Table } from 'antd';
import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import {
    IStatisticFilters,
    IStatisticMessages,
} from 'features/analytics/services/interfaces';
import { TableFilter } from 'shared/ui/TableFilter';

type Props = {
    readonly source: IStatisticMessages['message_members_statistic'];
    readonly filters: IStatisticFilters;
    readonly onFiltersUpdate: (update) => void;
};

export const UsersTableView: React.FC<Props> = props => {
    const [selectedRows, setSelectedRows] = useState<React.Key[]>(
        props.filters.telegram_accounts
    );

    const rowSelection = {
        selectedRows: selectedRows,
        preserveSelectedRowKeys: true,
        onChange: update => {
            setSelectedRows(update);
            props.onFiltersUpdate({
                telegram_accounts: update,
            });
        },
    };

    useEffect(() => {
        /**
         * Выбор  участников для отображения графика сообщений
         */
        if (props.source.length) {
            const accounts = props.source.map(u => u.telegram_id);
            props.onFiltersUpdate({
                telegram_accounts: accounts,
            });
            setSelectedRows(props.source.map(u => u.telegram_id));
        }
    }, [props.source]);

    return (
        <Fragment>
            <StyledTable
                dataSource={props.source}
                rowKey={user => user.telegram_id}
                rowSelection={rowSelection}
            >
                <Table.Column
                    title='Сообщения'
                    render={user => <strong>{user.count_messages}</strong>}
                />
                <Table.Column
                    title={
                        <TableFilter<string> title='Имя'>
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    render={member => (
                        <Space>
                            {member.image && <Avatar src={member.image} />}
                            {!member.image && (
                                <Avatar>{member.name.charAt(0)}</Avatar>
                            )}
                            <span>{member.name}</span>
                        </Space>
                    )}
                />
                <Table.Column
                    title={
                        <TableFilter<string> title='Юзернейм'>
                            <TableFilter.Text />
                        </TableFilter>
                    }
                    dataIndex={'nick_name'}
                />
            </StyledTable>
        </Fragment>
    );
};

const StyledTable = styled(Table<any>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;
