import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { App, Skeleton } from 'antd';

import { PostForm } from './PostForm';
import {
    useCreatePublicationPartMutation,
    useReadPublicationByIdQuery,
    useUpdatePublicationMutation,
    useUpdatePublicationPartMutation,
} from '../../../../services/publications';
import { getSrc } from '../../../../../../utils/common/getSrc';
import { createFormData } from '../../../../../../utils/common/createFormData';

export const PostEditContainer = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { notification } = App.useApp();

    const [contentId, setContentId] = useState(0);
    const [postContent, setPostContent] = useState([]);
    const [open, setOpen] = useState(true);
    const [initial, setInitial] = useState<null | any>();
    const [order, setOrder] = useState(0);
    const [isError, setIsError] = useState(false);

    const [
        updatePost,
        { isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate },
    ] = useUpdatePublicationMutation();

    const [
        updateUserContent,
        { isLoading: isLoadingUpdatePart, isSuccess: isSuccessUpdatePart },
    ] = useUpdatePublicationPartMutation();
    const [
        saveUserContent,
        { isLoading: isLoadingCreatePart, isSuccess: isSuccessSavePart },
    ] = useCreatePublicationPartMutation();

    const {
        data: post,
        isSuccess: isSuccessRead,
        isLoading: isLoadingRead,
        refetch,
    } = useReadPublicationByIdQuery(id);

    useEffect(() => {
        if (isSuccessRead) {
            setPostContent(post?.parts);
        }
    }, [isSuccessRead]);

    useEffect(() => {
        if (isSuccessRead) {
            if (post.parts?.length > 0) {
                const maxOrder = post?.parts.reduce(
                    (max, part) => (part.order > max ? part.order : max),
                    post?.parts[0].order
                );

                setOrder(Number(maxOrder + 1));
            }
        }
    }, [isSuccessRead]);

    useEffect(() => {
        if (postContent?.length > 0) {
            if (isSuccessUpdate && (isSuccessSavePart || isSuccessUpdatePart)) {
                refetch();
                navigate(-1);
                notification.success({
                    message: 'Пост успешно сохранен',
                    placement: 'top',
                });
            }
        } else if (isSuccessUpdate) {
            refetch();
            navigate(-1);
            notification.success({
                message: 'Пост успешно сохранен',
                placement: 'top',
            });
        }
    }, [isSuccessUpdate, isSuccessSavePart, isSuccessUpdatePart]);

    useEffect(() => {
        if (isSuccessRead) {
            setInitial({
                description: post.description,
                price: post.price,
                title: post.title,
                background_image:
                    !!post.background_image &&
                    getSrc(`storage/${post.background_image}`),
            });
        }
    }, [isSuccessRead]);

    const onSave = async values => {
        if (values.title === '') {
            return notification.error({
                message: 'Нужно указать название поста',
            });
        }

        const data = {
            title: values.title,
            description: values.description,
            is_active: true,
            price: values.price,
            background_image: values.background_image,
        };
        if (!(values.background_image instanceof File)) {
            delete data.background_image;
        }
        if (!values.description) delete data.description;
        if (!values.price) delete data.price;

        await updatePost({ id: Number(id), body: createFormData(data) });
        await editContent();
    };

    const editContent = async () => {
        const newParts = postContent.filter(elem => elem.id === 'new');
        const oldParts = postContent.filter(elem => elem.id !== 'new');

        if (newParts?.length > 0) {
            await Promise.all(
                newParts.map(post => {
                    const data = {
                        publication_id: Number(post.publication_id),
                        order: Number(post.order),
                        type: Number(post.type),
                        file: post.file,
                        text: post.text,
                    };

                    if (!(post.file instanceof File)) {
                        delete data.file;
                    }

                    return saveUserContent(createFormData(data));
                })
            );
        }

        await Promise.all(
            oldParts.map(post => {
                const data = {
                    id: Number(post.id),
                    order: Number(post.order),
                    type: Number(post.type),
                    file: post.file,
                    text: post.text,
                };

                if (!(post.file instanceof File)) {
                    delete data.file;
                }

                return updateUserContent({
                    id: Number(post.id),
                    body: createFormData(data),
                });
            })
        );
    };

    const isEdit = true;

    const formik = useFormik({
        onSubmit: onSave,
        initialValues: initial,
    });

    if (!id && !post) {
        return <></>;
    }
    const addItem = (item, key) => {
        const userContent = {
            publication_id: Number(id),
            order: Number(order),
            type: Number(item.type),
            file: item.file,
            text: null,
            id: 'new',
            key: 'new' + postContent?.length,
        };

        setOrder(Number(order + 1));
        setPostContent([...postContent, userContent]);
        setOpen(true);
    };

    useEffect(() => {
        if (isSuccessRead && post && post.parts && post.parts?.length < 1) {
            setOrder(0);
        }
    }, [post]);

    return initial ? (
        <PostForm
            initialValues={initial}
            formik={formik}
            onSave={onSave}
            post={post}
            postContent={postContent}
            setPostContent={setPostContent}
            open={open}
            isEdit={isEdit}
            addItem={addItem}
            loading={
                isLoadingUpdate || isLoadingCreatePart || isLoadingUpdatePart
            }
            order={order}
            setOrder={setOrder}
        />
    ) : (
        <Skeleton />
    );
};
