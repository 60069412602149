import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { Badge, Button, List } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import getEnvConfig from 'utils/config/env';
import Percent from 'static/landing/percent.png';

interface IProps {
    demoRef: React.MutableRefObject<undefined>;
}

export const SectionFourth: FC<IProps> = props => {
    const getCurrentMonth = () => {
        const currentMonth = dayjs().get('month');
        switch (currentMonth) {
            case 0:
                return 'января';
            case 1:
                return 'февраля';
            case 2:
                return 'марта';
            case 3:
                return 'апреля';
            case 4:
                return 'мая';
            case 5:
                return 'июня';
            case 6:
                return 'июля';
            case 7:
                return 'августа';
            case 8:
                return 'сентября';
            case 9:
                return 'октября';
            case 10:
                return 'ноября';
            case 11:
                return 'декабря';
        }
    };

    const getLastDay = () => {
        return dayjs().endOf('month').format('DD');
    };

    const data = [
        'Создайте в своем магазине до 50 товаров',
        'Модули модерация, платный доступ, донаты включены в тариф ограничение до 3-х чатов',
        'Размещайте данные объёмом трафика для хранения медиа - 10 ГБ и 10 постов в мес.',
        'Комиссия при выводе денежных средств: 7%',
    ];

    return (
        <Fragment>
            <StyledSectionFourth ref={props.demoRef}>
                <div>
                    <SectionFourthTitle>
                        Попробовать бесплатно
                        <StyledTitleSpan>
                            до {getLastDay()} {getCurrentMonth()}
                        </StyledTitleSpan>
                    </SectionFourthTitle>
                </div>
                <StyledCardsWrapper>
                    <StyledCard>
                        <StyledInnerWrap>
                            <div style={{ paddingRight: 50 }}>
                                <StyledBadge>Акция</StyledBadge>
                                <CardTitle>
                                    Бесплатный доступ на 3 дня!
                                </CardTitle>

                                <CardText>
                                    Успей зарегистрироваться, чтобы получить
                                    бесплатный доступ к тарифу Профи.
                                </CardText>
                            </div>
                            <div>
                                <StyledImg src={Percent} />
                            </div>
                        </StyledInnerWrap>

                        <StyledInnerWrap>
                            <div>
                                <Link
                                    /*to={`${
                                        getEnvConfig().PUBLIC_PATH
                                    }/auth/sign-up?reason=free`} */
                                    to={'https://shop.spodial.com/'}
                                >
                                    <Button type={'primary'}>Бесплатно</Button>
                                </Link>
                            </div>
                            <div>
                                <CardGrayText>
                                    Акция действует до {getLastDay()}{' '}
                                    {getCurrentMonth()}
                                </CardGrayText>
                            </div>
                        </StyledInnerWrap>
                    </StyledCard>
                    <StyledCard>
                        <CardTitle>Профи</CardTitle>

                        <List>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                }}
                            >
                                {data.map((elem, index) => (
                                    <StyledListRow key={index}>
                                        <Badge color={'purple'} />
                                        <CardText>{elem}</CardText>
                                    </StyledListRow>
                                ))}
                            </div>
                        </List>

                        <ActionBlock>
                            <div>
                                <Link
                                    to={`${
                                        getEnvConfig().PUBLIC_PATH
                                    }/auth/sign-up?reason=price`}
                                >
                                    <Button>Купить</Button>
                                </Link>
                            </div>
                            {/*<Price>1000₽</Price>*/}
                        </ActionBlock>
                    </StyledCard>
                </StyledCardsWrapper>
            </StyledSectionFourth>
        </Fragment>
    );
};

const StyledImg = styled.img`
    height: 255px;
`;

const StyledInnerWrap = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;

    @media (max-width: 768px) {
        justify-content: center;
        flex-direction: column;
    }
`;

const StyledBadge = styled.div`
    padding: 4px 8px;
    border-radius: 40px;
    border: 1px solid #6257d2;
    color: #6257d2;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    width: 100px;
    height: 32px;
    margin-bottom: 16px;
`;

const StyledListRow = styled.div`
    display: flex;
    gap: 16px;
`;

const Price = styled.div`
    color: rgba(37, 33, 41, 0.88);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
`;

const CardGrayText = styled.div`
    color: rgba(37, 33, 41, 0.45);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
`;

const ActionBlock = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    gap: 8px;
`;

const CardText = styled.div`
    display: flex;
    justify-content: space-between;
    color: rgba(37, 33, 41, 0.88);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    width: 100%;
`;

const CardTitle = styled.div`
    color: #6257d2;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
`;

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 40px;
    background: #fff;
    box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
        0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
    padding: 32px 24px 32px 24px;
    width: 570px;
    height: 360px;

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
    }
`;

const StyledCardsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
`;

const StyledTitleSpan = styled.span`
    font-family: 'Unbounded', sans-serif;
    border-radius: 40px;
    background: #fff;
    padding: 9px 16px;
    color: #6257d2;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    margin-left: 5px;
    @media (max-width: 768px) {
        margin-top: 1rem;
    }
`;

const SectionFourthTitle = styled.div`
    font-family: 'Unbounded', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
    color: #fff;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const StyledSectionFourth = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #6257d2;
    border-radius: 40px;
    margin-bottom: 80px;
    padding: 70px;
`;
