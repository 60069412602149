import { Navigate, RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import { MainPage } from './pages/mainPage';

const routes: RouteObject[] = [
    {
        path: 'analytics',
        element: <Outlet />,
        children: [
            {
                path: '',
                element: <MainPage />,
                children: [
                    {
                        path: '',
                        element: <Navigate to='readers' />,
                    },
                    {
                        path: 'webinars',
                        element: <Outlet />,
                    },
                    {
                        path: 'posts',
                        element: <Outlet />,
                    },
                    {
                        path: 'readers',
                        element: <Outlet />,
                    },
                ],
            },
        ],
    },
];
export default routes;
