import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex, Image } from 'antd';

import MozhnoCoin from 'static/images/mozhno_coin.svg';
import MozhnoFooter from 'static/images/mozhno_footer.svg';
import MozhnoInsert from 'static/images/mozhno_insert.svg';
import fav from 'static/images/favicon-32x32.png';

export const MozhnoLayout = () => {
    const targetUrl = 'https://t.me/mozhnostore_bot/drops';

    useEffect(() => {
        let link = document.querySelector("link[rel~='icon']");
        document.title = 'Notcoin X MZHN';

        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = fav;
    }, []);

    const handleOpenShop = () => {
        window.open(targetUrl, '_self');
    };

    return (
        <StyledLayout justify={'space-between'} vertical>
            <div>
                <Image preview={false} src={MozhnoCoin} />
            </div>

            <Flex
                onClick={handleOpenShop}
                style={{ margin: 'auto', cursor: 'pointer' }}
                justify={'center'}
            >
                <Image preview={false} src={MozhnoInsert} />
            </Flex>
            <Flex justify={'center'}>
                <Image preview={false} src={MozhnoFooter} />
            </Flex>
        </StyledLayout>
    );
};

const StyledLayout = styled(Flex)`
    width: 100%;
    height: 100vh;
    padding: 40px;
    background: #000000;
`;
