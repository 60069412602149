import React from 'react';
import { Tabs, TabsProps } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Page } from 'shared/ui/Page';
import getEnvConfig from 'utils/config/env';
import { VirtualTourProvider } from 'shared/ui/VirtualTour/VirtualTourProvider';

export const Communities: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const tabs: TabsProps['items'] = [
        {
            label: (
                <div ref={VirtualTourProvider.get()?.communitiesOverviewRef}>
                    Обзор
                </div>
            ),
            key: '/communities/chats',
        },
        {
            label: (
                <div ref={VirtualTourProvider.get()?.communitiesScenariosRef}>
                    Сценарии
                </div>
            ),
            key: '/communities/rules',
        },
        {
            label: (
                <div ref={VirtualTourProvider.get()?.communitiesMembersRef}>
                    Участники
                </div>
            ),
            key: '/communities/members',
        },
        {
            label: (
                <div ref={VirtualTourProvider.get()?.communitiesEventsRef}>
                    События
                </div>
            ),
            key: '/communities/events',
        },
        {
            label: (
                <div ref={VirtualTourProvider.get()?.communitiesKnowledgeRef}>
                    База знаний
                </div>
            ),
            key: '/communities/knowledge',
        },
        {
            label: (
                <div ref={VirtualTourProvider.get()?.communitiesAIKnowledgeRef}>
                    База знаний ИИ
                </div>
            ),
            key: '/communities/ai-knowledge',
        },
    ];

    const onChange = (tabKey: string) => {
        const tabPath = tabs.find(t => t.key === tabKey).key;
        navigate(`${getEnvConfig().PUBLIC_PATH}${tabPath}`);
    };

    const activeTabKey =
        tabs.find(t => location.pathname.includes(t.key))?.key || tabs[0].key;

    return (
        <Page title='Сообщества'>
            <Tabs activeKey={activeTabKey} onChange={onChange} items={tabs} />
            <Outlet />
        </Page>
    );
};
