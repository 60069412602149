import React, { FC, Fragment, useState } from 'react';
import {
    Button,
    Col,
    Row,
    Space,
    Switch,
    Tooltip,
    Typography,
    Upload,
} from 'antd';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { DonateSwitchesEnum } from 'features/monetization/pages/donate/DonateMapper';

import { TextEditor } from '../../../../../../shared/ui/TextEditor/TextEditor';
import { validateImages } from '../../../../../../utils/common/validateImages';
import { blobToBase64 } from '../../../../../../utils/common/blobToBase64';

interface IProps {
    control: DonateSwitchesEnum.receiveDonationsSwitch;
    formik: any;
}

export const ReceiveDonationsSwitch: FC<IProps> = props => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [img, setImg] = useState<undefined | string | ArrayBuffer>(
        props.formik.values.switches[props.control]?.image
    );

    const handleTootip = () => {
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
        }, 1000);
    };

    return (
        <Row
            gutter={[20, 20]}
            style={{ marginBottom: '1.5rem', width: '100%' }}
        >
            <Space align='start'>
                <StyledSwitch
                    checked={
                        props.formik.values.switches[props.control].isChecked
                    }
                    onChange={value => {
                        props.formik.setFieldValue(
                            `switches.${props.control}.isChecked`,
                            value
                        );
                    }}
                />
                <StyledTitle level={5}>Активировать донат</StyledTitle>
            </Space>

            {props.formik.values.switches[props.control].isChecked && (
                <Col xs={22} span={24} style={{ marginLeft: 55 }}>
                    <Row>
                        <Col>
                            <div style={{ marginTop: 10 }}>
                                <Upload
                                    maxCount={1}
                                    listType='picture-card'
                                    className='avatar-uploader'
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    onChange={value => {
                                        if (validateImages(value.file)) {
                                            blobToBase64(value.file).then(
                                                val => {
                                                    props.formik.setFieldValue(
                                                        `switches.${props.control}.image`,
                                                        val
                                                    );
                                                    setImg(val);
                                                }
                                            );
                                        }
                                    }}
                                >
                                    {img ? (
                                        <StyledImage
                                            src={img as string}
                                            alt={'alt'}
                                        />
                                    ) : (
                                        <div>
                                            <PlusOutlined />
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                }}
                                            >
                                                Картинка
                                            </div>
                                        </div>
                                    )}
                                </Upload>
                            </div>
                        </Col>

                        <Col xs={20} sm={19} md={12}>
                            <TextEditor
                                containerHeight={84}
                                value={
                                    props.formik.values.switches[props.control]
                                        ?.description
                                }
                                onChange={value => {
                                    props.formik.setFieldValue(
                                        `switches.${props.control}.description`,
                                        value
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[10, 10]} style={{ marginTop: '1rem' }}>
                        <Col span={24}>
                            <Typography.Text>
                                Команда для сбора в Telegram:
                            </Typography.Text>
                        </Col>
                        <Col span={24}>
                            <Tooltip
                                open={isOpen}
                                onOpenChange={handleTootip}
                                title='Скопировано'
                                trigger={['click']}
                            >
                                <Button
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            props.formik.values.command
                                        );
                                    }}
                                    style={{ padding: 0 }}
                                    type='link'
                                >
                                    {props.formik.values.command}
                                    <CopyOutlined
                                        style={{
                                            marginInlineStart: 4,
                                        }}
                                    />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
};

const StyledTitle = styled(Typography.Title)`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 16px;
`;

const StyledImage = styled.img`
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
