import React, { Fragment, useEffect, useState } from 'react';

import { useGetMembersQuery } from 'features/analytics/services';
import {
    IStatisticFilters,
    IStatisticsMembers,
} from 'features/analytics/services/interfaces';

import { UsersTableView } from '../views/UsersTable';

type Props = {
    readonly filters: IStatisticFilters;
};

export const UsersTableContainer: React.FC<Props> = props => {
    const [source, setSource] = useState<IStatisticsMembers['members']>([]);
    const [filters, setFilters] = useState<{
        first_name: string;
        user_name: string;
        email: string;
    }>({
        email: '',
        first_name: '',
        user_name: '',
    });

    const {
        data: members = {
            members: [],
        },
    } = useGetMembersQuery({
        community_id: Number(props.filters.chat),
        period: props.filters.period,
    });

    useEffect(() => {
        if (members.members.length) setSource(members.members);
    }, [members.members]);

    useEffect(() => {
        let _source = members.members ? [...members.members] : [];

        _source = _source
            .filter(item =>
                filters.first_name
                    ? new RegExp(filters.first_name, 'igm').test(item.name)
                    : true
            )
            .filter(item =>
                filters.nick_name
                    ? new RegExp(filters.nick_name, 'igm').test(item.nick_name)
                    : true
            );

        setSource(_source);
    }, [filters]);

    const onFiltersUpdate = update => {
        setFilters({ ...filters, ...update });
    };

    return (
        <Fragment>
            <UsersTableView
                filters={filters}
                onFiltersUpdate={onFiltersUpdate}
                source={source}
            />
        </Fragment>
    );
};
