import { Card, Typography } from 'antd';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { useTheme } from 'hooks/useTheme';

type Props = {
    readonly title: string;
    readonly value: number | string;
    readonly difference?: number;
    readonly highlight?: boolean;
    readonly isLoading?: boolean;
    readonly width?: string;
};
export const StatsWidget: React.FC<Props> = props => {
    const token = useTheme();

    return (
        <Fragment>
            <StyledCard
                width={props.width}
                loading={props.isLoading}
                type='inner'
                style={token.Card}
            >
                <Typography.Text style={{ color: 'rgba(37, 33, 41, 0.45)' }}>
                    {props.title}
                </Typography.Text>
                <StyledValue
                    highlight={props.highlight}
                    difference={props.difference}
                >
                    <div className='value'>
                        {props.value.toLocaleString('ru-RU')}
                    </div>
                    {props.difference && (
                        <div className='difference'>{props.difference}</div>
                    )}
                </StyledValue>
            </StyledCard>
        </Fragment>
    );
};

const StyledCard = styled(Card)<{ width?: string }>`
    min-width: ${props => props.width || '270px'};
    flex: 1 0 24%;
`;
const StyledValue = styled.div<{ difference?: number; highlight?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: end;

    .value {
        font-size: 2em;
        color: #2f54eb;
        font-weight: 300;
    }

    .difference {
        ::after {
            content: '%';
        }
        color: ${props =>
            props.highlight
                ? props.difference < 0
                    ? '#BD2B30'
                    : '#139C55'
                : 'rgba(37, 33, 41, 0.45)'};
    }
`;
