import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useParams } from 'react-router-dom';

import { PostsContainer } from '../post/PostsContainer';
import { AuthorInfoContainer } from './AuthorInfoContainer';
import { WebinarContainer } from '../webinar/containers/WebinarContainer';
import { useProfileData } from '../../../../../../hooks/useProfileData';

export const AuthorContainer = () => {
    const { id } = useParams();

    const [extraButton, setExtraButton] = useState(null);
    const [activeTab, setActiveTab] = useState<string>(
        sessionStorage.authorPageTab ? sessionStorage.authorPageTab : 'posts'
    );

    const { user } = useProfileData();

    useEffect(() => {
        if (sessionStorage.authorPageTab) {
            setActiveTab(sessionStorage.getItem('authorPageTab'));
        }
    }, [sessionStorage.authorPageTab]);

    const onChangeTab = (key: string) => {
        setActiveTab(key);
        sessionStorage.setItem('authorPageTab', key);
    };

    const isAuthor = true;

    const isOwner = +id === user?.author_fields?.id;

    const authorProps = {
        extraButton: extraButton,
        setExtraButton: setExtraButton,
        activeTab: activeTab,
    };

    const items: TabsProps['items'] = [
        {
            key: 'posts',
            label: `Посты`,
            children: <PostsContainer isOwner={isOwner} {...authorProps} />,
        },
        {
            key: 'webinars',
            label: `Вебинары`,
            children: <WebinarContainer {...authorProps} />,
        },
    ];

    return (
        <>
            <AuthorInfoContainer />
            <Tabs
                tabBarExtraContent={!isAuthor ? null : extraButton}
                defaultActiveKey={activeTab}
                items={items}
                onChange={onChangeTab}
            />
        </>
    );
};
