import React, { useEffect, useState } from 'react';
import { Row, Typography } from 'antd';

import { IEventQueryParams } from '../../../features/communities/interfaces';
import { CLEAR_FILTERS } from '../../contants/actions';
import { useWindowSize } from '../../../hooks/useWindowSize';

interface TFilters {
    readonly filters: any;
    readonly initialFilters: any;
    readonly setFilters: (any) => void;
}

export const ClearFilters: React.FC<TFilters> = props => {
    const { initialFilters, filters, setFilters } = props;
    const { size } = useWindowSize();

    const [isEqual, setIsEqual] = useState(true);

    useEffect(() => {
        if (filters) {
            setIsEqual(
                JSON.stringify(filters) === JSON.stringify(initialFilters)
            );
        }
    }, [filters]);

    return isEqual ? (
        <div
            style={{
                marginBottom: '1rem',
                height: 22,
                display: size === 'small' ? 'none' : 'block',
            }}
        ></div>
    ) : (
        <Row
            justify={'end'}
            align={'middle'}
            style={{ marginBottom: size === 'small' ? 0 : '1rem' }}
        >
            <Typography.Link
                style={{ color: '#6257d2' }}
                onClick={() => setFilters(initialFilters)}
            >
                {CLEAR_FILTERS}
            </Typography.Link>
        </Row>
    );
};
